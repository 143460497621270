import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Card, Input, Radio, Alert, Timeline } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import moment from 'moment-timezone';

const FormItem = Form.Item;
const { TextArea } = Input;

class Notes extends React.Component {
  state = {
    putREQUEST: false,
    putFAILURE: false,
    putSUCCESS: false,
    putMessage: '',
    getListRequest: false,
    listNotes: []
  }
  componentDidMount() {
    this.getListNotes();
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({
        putREQUEST: true
      })
      fetchJSON('/api/v1/note/create', {
        method: 'PUT',
        body: {
          note: fieldsValue['note'],
          type: fieldsValue['type'],
          source: this.props.source,
          sourceId: this.props.sourceId,
        }
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false,
            putMessage: response.message
          });
          this.getListNotes();
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
            putMessage: response.message
          });
        }
        setTimeout(() => {
          this.setState({
            putMessage: ''
          });
        }, 3000)
       }).catch(error => {
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });
    })
  }
  getListNotes = () => {
    this.setState({
      getListRequest: true
    });
    fetchJSON(`/api/v1/note/find`, {
      method: 'post',
      body: {
        sourceId: this.props.sourceId,
        source: this.props.source
      }
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          getListRequest: false,
          listNotes: response.data
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          getListRequest: false
        });
     });
  }
  renderTimeline = () => {
    const { listNotes } = this.state;
    if (!listNotes.length) return null;
    return listNotes.map((note, index) => {
      let time = moment(note.createdAt).tz('America/Los_Angeles').format("DD MMM YY, h:mm a");
      return (
        <Timeline.Item key={index} color={note.type === 'info' ? 'green' : 'blue'}>
          <p>{note.value}</p>
          <small>{ note.type } / {time}</small>
        </Timeline.Item>
      )
    })
  }
  render() {
    const { putREQUEST, putMessage, putSUCCESS, getListRequest, listNotes } = this.state;
    const { visible, form, source, sourceId, onlyForm } = this.props;
    const { getFieldDecorator } = form;
    const showFull = typeof onlyForm === 'boolean' ? !onlyForm : true;
    return (
      <Modal
        className="nocardPadding"
        style={{ top: 20 }}
        width={600}
        visible={ visible }
        title={`Notes for ${source} #${sourceId}`}
        onOk={this.props.handleClose}
        onCancel={this.props.handleClose}
        footer={[
          <Button key="submit" type="primary" onClick={this.props.handleClose}>
            Ok
          </Button>,
        ]}
      >
      {
        showFull ?
          <Card loading = { getListRequest } >
              { listNotes.length ? <Timeline style={{
                'maxHeight': '400px',
                'overflow': 'scroll',
                'padding': '5px 0 0 5px'
              }}>
                { this.renderTimeline() }
              </Timeline>  : null }
          </Card> : null
        }
        <Card title="New note" loading = { putREQUEST }>
    			 <Form layout="vertical" onSubmit={this.handleSubmit}>
            <FormItem label="Note">
              {getFieldDecorator('note', {
                rules: [{ required: true, message: 'Please enter a note!' }],
              })(
                <TextArea autosize={{ minRows: 2, maxRows: 6 }} />
              )}
            </FormItem>
            <FormItem className="collection-create-form_last-form-item" label="Type for what note">
              {getFieldDecorator('type', {
                initialValue: 'info',
              })(
                <Radio.Group>
                  <Radio value="info">Info</Radio>
                  <Radio value="testing">Testing</Radio>
                  <Radio value="support">Support</Radio>
                </Radio.Group>
              )}
            </FormItem>
            <Button type="primary" htmlType="submit">Submit</Button>
            { putMessage ? 
              <div style={{marginTop: '20px'}}><Alert message={putMessage} type={ putSUCCESS ? "success" : "error" } /></div>
            : null }
            </Form>
        </Card>
      </Modal>
    )
  }
}

export default Form.create()(Notes);
