import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';
import { Modal, Button, Timeline, Card, Collapse, Radio, Spin, InputNumber, Alert } from 'antd';
import moment from 'moment';

const Panel = Collapse.Panel;
const RadioGroup = Radio.Group;
const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden'    
};
class Transactions extends React.Component {
  state = {
    isFetching: false,
    withdrawFetching: false,
    transactions: [],
    paymentMethods: [],
    paymentSelect: '',
    chargeAmount: 40,
    successCharge: false,
    messageCharge: ''
  }
  componentDidMount() {
    this.getTransactions();
    this.getPaymentMethods();
  }
  getPaymentMethods = () => {
    this.setState({
      isFetching: true
    })
    fetchJSON(`/api/v1/user/paymentMethods/list/${this.props.userId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.success) {
        let paymentSelect = '';
        response.data.forEach(paymentMethod => {
          if (paymentMethod.default) {
            paymentSelect = paymentMethod.token;
          }
        })
        this.setState({
          isFetching: false,
          paymentMethods: response.data,
          paymentSelect
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  getTransactions = () => {
    this.setState({
      isFetching: true
    })
    fetchJSON(`/api/v1/user/transaction/list/${this.props.userId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          isFetching: false,
          transactions: response.data,
          messageCharge: ''
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  renderTimeline = () => {
    const { transactions } = this.state;
    if (!transactions.length) return null;
    return transactions.map((transaction, index) => {
      let type = transaction.payment_instrument_type.replace('_',' ');
      type = type.charAt(0).toUpperCase() + type.slice(1);
      let status = transaction.status.replace('_',' ');
      status = status.charAt(0).toUpperCase() + status.slice(1);
      let time = moment(transaction.created_at).format("DD MMM YY, h:mm a");
      let whiteList = ['authorized','submitted_for_settlement','settling','settlement_pending','settled', 'authorization_expired'];
      return (
        <Timeline.Item key={index} color={whiteList.includes(transaction.status) ? 'blue' : 'red'}>
          <p><b>Amount:</b> { transaction.amount }</p>
          <p><b>Date:</b> { time }</p>
          <p><b>Status:</b> { status }</p>
          <p><b>{type}:</b> {transaction.display}</p>
          { transaction.plan_id ? <p><b>Plan:</b> { transaction.plan_id === 'year' ? 'Annual' : 'Daily'} subscription </p> : null }
        </Timeline.Item>
      )
    })
  }
  renderPaymentMethods = () => {
    const { paymentMethods } = this.state;
    if (!paymentMethods.length) return null;
    return paymentMethods.map((paymentMethod, index) => {
      return (
        <Radio key={index} value={paymentMethod.token}>
          <img alt="" src={paymentMethod.image_url} className="payment__img" />
          {paymentMethod.display}
        </Radio>
      )
    });
  }
  changePaymentMethod = e => {
    this.setState({
      paymentSelect: e.target.value
    });
  }
  changeChargeAmount = value => {
    this.setState({
      chargeAmount: value
    });
  }
  chargeMoneyFromUser = () => {
    // chargeMoneyFromUser
    this.setState({
      withdrawFetching: true
    })
    fetchJSON('/api/v1/user/transaction/withdraw', {
      method: 'post',
      body: {
        token: this.state.paymentSelect,
        amount: parseInt(this.state.chargeAmount, 10)
      }
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          withdrawFetching: false,
          messageCharge: response.message,
          successCharge: true
        });
        this.getTransactions();
      } else {
        this.setState({
          withdrawFetching: false,
          messageCharge: response.message,
          successCharge: false
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          withdrawFetching: false,
          successCharge: false
        });
     });
  }
  render() {
    const { isFetching, withdrawFetching,
      //paymentMethods,
      chargeAmount, messageCharge, successCharge, transactions } = this.state;
    return (
      <Modal
        className="nocardPadding"
        style={{ top: 20 }}
        visible={this.props.visible}
        title="Transaction list"
        onOk={this.props.handleClose}
        onCancel={this.props.handleClose}
        footer={[
          <Button key="submit" type="primary" onClick={this.props.handleClose}>
            Ok
          </Button>,
        ]}
      >
        <Card loading = { isFetching }>
  				{transactions.length ? <Timeline style={{
            'minHeight': '200px',
            'maxHeight': '400px',
            'overflow': 'scroll',
            'padding': '5px 0 0 5px'
          }}>
            { this.renderTimeline() }
          </Timeline> : 'Transaction not found' }
        </Card>
        { false ? <Card> <Collapse bordered={false}>
            <Panel header="Withdraw money" key="1" style={customPanelStyle}>
              <Spin spinning={withdrawFetching}>
                <h3>Payment Method</h3>
                <RadioGroup onChange={this.changePaymentMethod} value={this.state.paymentSelect} style={{marginBottom: '20px'}}>
                  {this.renderPaymentMethods()}
                </RadioGroup>
                <h3>Select amount (max $100)</h3>
                <div>
                  <InputNumber
                    defaultValue={chargeAmount}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={this.changeChargeAmount}
                    max={100}
                    value= {chargeAmount}
                  />
                </div>
                <div style={{'padding': '20px 0'}}>
                  <Button key="submit" type="primary" onClick={this.chargeMoneyFromUser}>
                    Withdraw money
                  </Button>
                </div>
                { messageCharge ? 
                  <div style={{marginBottom: '20px'}}><Alert message={messageCharge} type={ successCharge ? "success" : "error" } /></div>
                : null }
              </Spin>
            </Panel>
          </Collapse> </Card>: null }
         
        
        
      </Modal>
    )
  }
};

export default Transactions;
