import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Input, Row, Col, Spin, message, Select, Radio } from 'antd';
import AddressField from './components/AddressField/AddressField';
import fetchJSON from 'services/utils/fetchJSON';
import * as _sharedModules from './modules/_modules';

const FormItem = Form.Item;
const { TextArea } = Input;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

class AddVenue extends React.Component {
  constructor() {
    super();
    this.state = {
      venue: {
        title: '',
        address: '',
        description: '',
        contacts: {},
        coordinates: {},
        country_code: ''
      },
      putREQUEST: false,
      putFAILURE: false,
      putSUCCESS: false,
      orgList: [],
      isFetching: false,
      formatted_address: '',
      types: [],
      business_categories: [],
      business_info: [],
      showSubcategory: false,
      noBusinessType: true

    }
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
  }
  componentDidMount() {
    this.mounted = true;
    if (!this.props.organisationId) this.getOrgList();
    this.getBusinessTypes();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      
      if (err) {
        return;
      }
      const { showSubcategory } = this.state;
      if (!showSubcategory) {
        fieldsValue.business_subcategory_id = null;
      }
      fieldsValue.formatted_address = this.state.formatted_address;
      //console.log(fieldsValue, err)
      this.setState({
        putREQUEST: true
      })

      fetchJSON(`/api/v1/venue/create`, {
        method: 'PUT',
        body: fieldsValue
      }).then(response => {
        if (!this.mounted) return false;
        console.log(response);
        if (response.success) {
          message.success('Successfully created');
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false
          }, () => {
            this.props.eventCreated(response.data.id);
          });
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
          });
          message.error(response.message);
        }
        setTimeout(() => {
          this.setState({
            errorMessage: ''
          });
        }, 3000)
       }).catch(error => {
          console.log(error);
          if (this.mounted) {
            this.setState({
              putSUCCESS: false,
              putREQUEST: false,
              putFAILURE: true,
            });
          }
       });  
    })
  }
  checkAddress = (rule, value, callback) => {
    if (!value.correct) {
      callback('Select correct address!');
      return;
    }
    if (value.address) {
      this.setState({
        formatted_address: value.formatted_address
      })
      callback();
      return;
    }
    callback('Please enter address!');
  }
  getOrgList = () => {
    this.setState({
      isFetching: true
    })
    fetchJSON('/api/v1/organisation/list', {
      method: 'get'
    }).then(response => {
      if (!this.mounted) return;
      //console.log(response);
      if (response.success) {
        this.setState({
          isFetching: false,
          orgList: response.data
        });
      }
     }).catch(error => {
        console.log(error);
        if (this.mounted) {
          this.setState({
            isFetching: false
          });
        }
     });
  }
  render() {
    const { putREQUEST, venue, orgList, showSubcategory, noBusinessType } = this.state;
    const { handleClose, visible, organisationId } = this.props;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    
    return (
      <Modal
        style={{ top: 20 }}
        visible={ visible }
        width={800}
        title="Add venue"
        onOk={ handleClose }
        onCancel={ handleClose }
        footer={false}
      >
        <Form style={{minHeight: '300px'}} onSubmit={this.handleSubmit}>
          <input type="hidden" name="organisationId" value={organisationId} />

          { organisationId ? <div style={{'display': 'none'}}>
            {getFieldDecorator('organisationId', {
              rules: [{ required: false, message: 'Please enter a title!' }],
              initialValue: organisationId
            })(
              <Input />
            )}
          </div> : null }
          
          <Spin spinning={putREQUEST} >
            <Row gutter={8}>
              <Col span={24}>
                <FormItem>
                  <Row>
                    <label className="form-label-custom">Name</label>
                  </Row>
                  <Row >
                    {getFieldDecorator('title', {
                      rules: [{ required: true, message: 'Please enter a title!' }],
                    })(
                      <Input placeholder="Enter name"/>
                    )}
                  </Row>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8} style={{display: 'none'}}>
              <Col span={24}>
                <FormItem>
                  <Row>
                    <label className="form-label-custom">Description</label>
                  </Row>
                  <Row >
                    {getFieldDecorator('description', {
                      rules: [{ required: false, message: 'Please enter a description!' }],
                    })(
                      <TextArea autosize={{ minRows: 1, maxRows: 6 }} />
                    )}
                  </Row>
                </FormItem>
              </Col>
            </Row>
            { !organisationId ? <Row gutter={8}>
              <Col span={24}>
                <FormItem>
                  <Row>
                    <label className="form-label-custom">Organization</label>
                  </Row>
                  <Row >
                    {getFieldDecorator('organisationId', {
                      rules: [{ required: true, message: 'Please choose a organisation!' }],
                    })(
                      <Select placeholder="Choose organization">
                        { orgList.map((org, key) => <Option key={key} value={org.id}>{org.name}</Option>) }
                      </Select>
                    )}
                  </Row>
                </FormItem>
              </Col>
            </Row> : null }

            <Row gutter={8}>
              <Col span={8}>
                <FormItem >
                  <Row>
                    <label className="form-label-custom">Type of business</label>
                  </Row>
                    {getFieldDecorator('business_type_id', {
                      rules: [{ required: true, message: 'Please choose business type!' }]
                    })(
                      <Select placeholder="Select location type" onChange={this.sortBusinessCategories}>
                        {this.renderBusinessTypesList()}
                      </Select>
                    )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem >
                  <Row>
                    <label className="form-label-custom">Type of location</label>
                  </Row>
                    {getFieldDecorator('business_category_id', {
                      rules: [{ required: true, message: 'Please choose business category!' }]
                    })(
                      <Select disabled={noBusinessType} placeholder="Select location category">
                        {this.renderBusinessCategoriesList()}
                      </Select>
                    )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem style={{display: showSubcategory ? '' : 'none'}}>
                  <Row>
                    <label className="form-label-custom">Subtype of location</label>
                  </Row>
                    {getFieldDecorator('business_subcategory_id', {
                      rules: [{ required: showSubcategory ? true : false, message: 'Please choose business subcategory!' }]
                    })(
                      <Select disabled={noBusinessType} placeholder="Select location subcategory">
                        {this.renderBusinessSubcategoriesList()}
                      </Select>
                    )}
                </FormItem>
              </Col>
            </Row>


            <FormItem >
              <Row>
                <label className="form-label-custom">Type of venue</label>
              </Row>
                {getFieldDecorator('is_public', {
                  rules: [{ required: true, message: 'Please choose type of venue!' }],
                  initialValue: 1
                })(
                <RadioGroup >
                  <RadioButton value={1}>Public</RadioButton>
                  <RadioButton value={0}>Private</RadioButton>
                </RadioGroup>
                )}
            </FormItem>
            <FormItem>
              <Row gutter={8}>
                <Col span={24}>
                  <Row>
                    <label className="form-label-custom">Address</label>
                  </Row>
                  <Row>
                    {getFieldDecorator('addressGroup', {
                      initialValue: {
                        address: venue.address,
                        addressComponents: venue.address_components,
                        coordinates: venue.coordinates,
                        timezone: venue.timezone,
                        place_id: venue.place_id,
                        currency_code: venue.currency_code,
                        opening_hours: venue.opening_hours,
                        country_code: venue.country,
                        correct: true
                      },
                      rules: [{ validator: this.checkAddress }]
                    })(
                      <AddressField />
                    )}
                  </Row>
                </Col>
              </Row>
            </FormItem>
            <FormItem >
              <Row style={{float: 'right'}}>
                <Button type="primary" htmlType="submit">
                  Add venue
                </Button>
              </Row>
            </FormItem>
          </Spin>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(AddVenue);
