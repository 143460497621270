import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Card, Input, message } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const FormItem = Form.Item;

class DeletePersonalData extends React.Component {
  state = {
    putREQUEST: false,
    putFAILURE: false,
    putSUCCESS: false,
    putMessage: '',
    getListRequest: false,
    listNotes: [],
    showCode: false,
    phone: '',
    code: '',
    stage: 1
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      const { stage } = this.state;
      const { userId } = this.props;

      this.setState({
        putREQUEST: true
      });

      fetchJSON('/api/v1/user/deletePersonalData', {
        method: 'POST',
        body: {
          phone: fieldsValue['phone'],
          code: fieldsValue['code'],
          stage: stage,
          userId: userId
        }
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false,
            stage: response.stage
          });
          message.success(response.message);
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
            stage: response.stage || 1
          });
          message.error(response.message);
        }
       }).catch(error => {
          console.log(error);
          message.error('Something wrong, try later');
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });
    })
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  render() {
    const { putREQUEST, showCode, phone, code, stage } = this.state;
    const { visible, form, userId } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Modal
        className="nocardPadding"
        style={{ top: 20 }}
        width={600}
        visible={ visible }
        title="Delete Data"
        onOk={this.props.handleClose}
        onCancel={this.props.handleClose}
        footer={[]}
      >
        <Card loading = { putREQUEST }>
          <Form layout="vertical" onSubmit={this.handleSubmit}>
            <h2>Delete personal data for user #{userId}</h2>
            {stage !== 3 ?
              <React.Fragment>
                <p>Enter phone number authorized for this operation</p>
                <FormItem label="Phone">
                  {getFieldDecorator('phone', {
                    rules: [{ required: true, message: 'Please enter a phone number!' }],
                    initialValue: phone
                  })(
                    <Input disabled={showCode} name="phone" onChange={this.onChange}  />
                  )}
                </FormItem>
                {stage === 2 ? <FormItem label="Confirmation code">
                  {getFieldDecorator('code', {
                    rules: [{ required: true, message: 'Please enter a code!' }],
                    initialValue: code
                  })(
                    <Input name="code" onChange={this.onChange} />
                  )}
                </FormItem> : null }
                <Button type="primary" htmlType="submit">Submit</Button>
              </React.Fragment>
            : <h1>Personal data is deleted</h1>}
            </Form>
        </Card>
      </Modal>
    )
  }
}

export default Form.create()(DeletePersonalData);
