import React from 'react';
import { Modal, Input, Button } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const { TextArea } = Input;

class CreateAndEditTemplate extends React.Component {
  state = {
    text: '',
    newHeader: '',
    isFetching: false
	}

  getData = () => {
  	this.setState({
  		text: this.props.value,
  		newHeader: this.props.header
  	})
  }
  componentDidMount() {
  	this.getData()
  }
  changeTemplateText = e => {
  	this.setState({
      text: e.target.value
    });
  }
  changeHeader = e => {
  	this.setState({
      newHeader: e.target.value
    });
  }
  addTemplate = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        isFetching: true
        })
      fetchJSON('/api/v1/user/sms/templates/create', {
        method: 'post',
        body: {
          text: this.state.text,
          header: this.state.newHeader
        }
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            isFetching: false
          }); 
        resolve();
        this.props.handleClose();
        } else {
            this.setState({
              isFetching: false
            });
          }
      }).catch(error => {
        console.log(error);
          reject();
          this.setState({
            isFetching: false
          });
        })
      }).catch(() => console.log('Oops errors!'));
  }
  updateTemplate = (templateId) => {
    return new Promise((resolve, reject) => {
      this.setState({
        isFetching: true
        })
      fetchJSON(`/api/v1/user/sms/templates/update/${templateId}`, {
        method: 'put',
        body: {
          text: this.state.text,
          header: this.state.newHeader
        }
    }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            isFetching: false
          }); 
        resolve();
        this.props.handleClose();
        } else {
          this.setState({
            isFetching: false
          });
        }
    }).catch(error => {
        console.log(error);
          reject();
          this.setState({
            isFetching: false
          });
      })
    }).catch(() => console.log('Oops errors!'));
  }

  render() {
  	const { handleClose, visible, templateId }= this.props;
  	const { text, newHeader } = this.state;

  	return (
    	<Modal
        style={{ top: 200 }}
        visible={ visible }
        width={400}
        title="Add or edit template"
        footer={false}
        onOk={handleClose}
        onCancel={handleClose}
        >     
          <Input placeholder="Header" onChange={this.changeHeader}  value={newHeader} />
          <div style={{'padding': '10px 0'}}>
            <TextArea placeholder="Text" autosize={{ minRows: 2, maxRows: 6 }} value={text} onChange={this.changeTemplateText} />  
          </div>
       	  { templateId ?
            <Button key="submit" type="primary" onClick={ () => { this.updateTemplate(templateId) }} > Save </Button> : 
            <Button key="submit" type="primary" onClick={ this.addTemplate } > Save </Button> }           
        </Modal>
  	)}
  }

export default CreateAndEditTemplate;