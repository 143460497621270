import React from 'react';
import { Modal, message, Select } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import _ from 'lodash';

const confirm = Modal.confirm;
const { Option } = Select;

const getLocationsList = function getLocationsList(spnsQuantity) {
  return new Promise((resolve, reject) => {
    fetchJSON('/api/v1/venue/list', {
      method: 'put'
    }).then(response => {
      if (typeof this.mounted !== 'undefined' && !this.mounted) return false;
      //response.data
      const list = response.data;
      const venuesList = [];
      const venuesListFull = [];
      if (list && typeof list === 'object' && list.length) {
        list.forEach((venue, i) => {
          //console.log('venue', venue)
          const groupId = _.get(venue, 'groups[0].group_id');
          const groupKeyId = _.get(venue, 'groups[0].group.key_id');
          venuesListFull.push({
            uuid: venue.uuid,
            title: venue.title
          });
          if (venue.groups.length && groupId) {
            let canAdd = true;
            const installationType = _.get(venue, 'groups[0].group.type_of_instalation');
            const supernovas = _.get(venue, 'groups[0].group.supernovas');
            if ((supernovas && typeof supernovas === 'object' && supernovas.length > 0) || spnsQuantity > 1) {
              if (installationType === 'floor' || installationType === 'counter') {
                canAdd = false;
              }
            }
            venuesList.push({
              uuid: venue.uuid,
              title: venue.title,
              groupId,
              canAdd,
              groupKeyId
            });
          }
        });
      }
      this.setState({
        venuesList,
        venuesListFull
      }, resolve);
    }).catch(error => {
      console.log(error);
      reject();
    });
  });
}
const assignToGroup = function assignToGroup(supernovasList = null) {
  let supernovasToAssign = [];
  if (!Array.isArray(supernovasList)) {
    supernovasToAssign.push(this.state.supernovaObj.id);
  }
  else {
    supernovasToAssign = supernovasList;
  }
  this.setState({
    supernovasToAssign
  })
  this.getLocationsList(supernovasToAssign.length).then(() => {
    const { venuesList } = this.state;
    if (venuesList.length) {
      this.selectTargetLocation();
    } else {
      // There is no venues with linked group
      const { venuesListFull } = this.state;
      if (venuesListFull.length) {
        confirm({
          className: "UserManuals__btns-revert",
          title: <React.Fragment>All existing Locations do not have linked Location IDs. If you want to assign Supernova to the existing Location first you must link Location ID.</React.Fragment>,
          cancelText: 'Got it',
          centered: true,
          okButtonProps: {style: {display: 'none'}},
          cancelButtonProps: {type: 'primary'},
        });
      } else {
        // there is no locations at all
        confirm({
          title: <React.Fragment>You do not have any locations.<br/>Please create the location first</React.Fragment>,
          okText: 'Got it',
          cancelText: 'Later',
          centered: true
        });
      }
    }
  })
  .catch(err => {
    console.log('err', err);
  });
}
const selectTargetLocationId = function selectTargetLocationId(groupId) {
  const { venuesList } = this.state;
  let canAdd;
  venuesList.forEach((venue) => {
    if (venue.groupKeyId === groupId) {
      canAdd = venue.canAdd;
    }
  })
  this.setState({
    target_group_id: groupId
  }, () => {
    if (this.assignSupernovaConfirm) {
      this.assignSupernovaConfirm.update({
        okButtonProps: {disabled: groupId && canAdd ? false : true},
        content: this.content(canAdd ? false : true)
      });
    }
  });
}

const content = function content(showInstallationRestrictionMessage = false) {
  const { venuesList } = this.state;
  return (
    <div>
      <p style={{display: showInstallationRestrictionMessage ? '' : 'none', color: 'red'}}>You can't assign supernova to this location. Floor or Counter installation types can have only one Supernova.</p>
      <Select defaultValue="Select location" style={{ width: '100%' }} onChange={this.selectTargetLocationId}>
        {venuesList.map((item, index) => {
          return (
            <Option key={index} value={item.groupKeyId}>
              {item.title} ({item.groupKeyId})
            </Option>
          )
        })}
      </Select>
    </div>
  );
};
const assignServer = function assignServer(group_id, supernovaId, callback) {
  this.setState({
    putREQUEST: true
  })
  fetchJSON('/api/v1/supernova/assignToGroup', {
    method: 'POST',
    body: {
      group_id,
      supernovaId
    }
  }).then(response => {
    if (response.success) {
      callback(200);
    }
    else {
      callback(400);    
    }
  }).catch(error => {
    callback(400);
    message.error('Please try again');
    console.log(error);
  })
}
const selectTargetLocation = function selectTargetLocation() {
  const { target_group_id } = this.state;
  this.assignSupernovaConfirm = confirm({
    title: 'Choose location where you want to assign Supernova',
    content: this.content(),
    okText: 'Assign',
    onOk: () => {
      const { target_group_id, supernovasToAssign } = this.state;
      const promises = [];
      const responses = [];
      supernovasToAssign.forEach((supernovaId) => {
        promises.push(new Promise((resolve, reject) => {
          this.assignServer(target_group_id, supernovaId, (code) => {
            responses.push(code);
            resolve();
          })
        }));
      });
      Promise.all(promises).then((values) => {
        if (typeof this.mounted !== 'undefined' && !this.mounted) return false;
        if (this.assignSupernovaConfirm) {
          this.assignSupernovaConfirm.destroy()
        }
        this.setState({
          supernovasToAssign: null,
          selectedRowKeys: [],
          selectedRowValues: []
        })
        if (responses.indexOf(400) === -1) {
          this.getSupernovasList();
          message.success('Done!')
        } else {
          message.error('Something went wrong');
        }
      });
    },
    okButtonProps: { disabled: target_group_id ? false : true },
    cancelButtonProps: { style: { display: 'none' } },
    onCancel: () => { this.setState({target_group_id: null, supernovasToAssign: null}) },
    maskClosable: true,
    centered: true
  });
}

export {
  getLocationsList,
  assignToGroup,
  selectTargetLocation,
  selectTargetLocationId,
  content,
  assignServer
};