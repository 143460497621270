import React, { PureComponent } from 'react';
import { Layout, Spin, Row, Col, Switch, Alert, Empty, Select, Button, Skeleton } from 'antd';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Pie,
  PieChart,
  Cell,
  CartesianGrid,
  ComposedChart,
  ReferenceLine
} from 'recharts';

import * as _sharedModules from '../../modules/_modules';

import { emptySurveyObj, emptyNpsObj } from '../../modules/surveyreports';
import {
  DashboardTwoTone,
  SmileTwoTone,
  FrownTwoTone
} from '@ant-design/icons';

import './styles.less';

const { Content } = Layout;
const { Option } = Select;

class SurveyReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      COLORS: ['#EF5FA7', '#00A2FF', '#FFC805', '#ff2d55', '#ff9500', '#ffcc00'],
      COLORS_PHONE_DYING: ['#4cd964', '#ff9500', '#ff3b30'],
      // fetch indicators
      isSurveyFetching: false,
      isNpsFetching: false,
      // dataObjects
      surveyData: emptySurveyObj,
      npsData: emptyNpsObj,
      // time objects
      retentionWheelTimeOverride: false,
      shouldUpdateTurnOffOverrides: false,
      update: undefined,
      overrideLocationChoice: false,
      showEmptyGeneralSurvey: false,
      showEmptyNps: false,
      npsAgeFilter: false,
      npsCableFilter: false,
      npsGenderFilter: false
    };

    const requiredModulesNames = ['getFullSurveyData', 'getBodyObjSurvey', 'getSurveyData', 'getNpsData'];
    Object.keys(_sharedModules).forEach((_moduleName) => {
      if (requiredModulesNames.indexOf(_moduleName) > -1) {
        this[_moduleName] = _sharedModules[_moduleName].bind(this);
      }      
    });
  }
  componentDidMount() {
    this.mounted = true;
    // remove when all done
    this.getFullSurveyData();
    this.singleLocationMode = this.props.singleLocationMode;
  }
  componentWillUnmount(){
    this.mounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    const filterTypeChange = this.props.filterByLocationType !== prevProps.filterByLocationType;
    const orgChange = !this.props.filterByLocationType && this.props.targetOrgId !== prevProps.targetOrgId;
    const businessTypeChange = this.props.filterByLocationType && this.props.targetBusinessTypeId !== prevProps.targetBusinessTypeId;
    const businessCategoryChange = this.props.filterByLocationType && this.props.targetBusinessCategoryId !== prevProps.targetBusinessCategoryId;
    const venueChange = !this.props.filterByLocationType && prevProps.targetVenueId !== this.props.targetVenueId;
    if (filterTypeChange || orgChange || venueChange || businessTypeChange || businessCategoryChange) {
      this.getFullSurveyData();
    }
    if (this.props.childShouldUpdateDateChanged) {
      this.props.turnOffShouldUpdate();
      this.getFullSurveyData();
    }
    if (this.props.updateLocationMultiChange) {
      this.getFullSurveyData();
      this.props.turnOffShouldUpdateMultiLocations();
    }
    this.singleLocationMode = this.props.singleLocationMode;
  }
  changeState = (key, value) => {
    this.setState({
      [key]: value
    })
  }
  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (percent && percent !== 1 && percent >= 0.06) {
      return (
        <text x={x} y={y} fill="white" style={{fontSize: '20px', fontWeight: 400}} textAnchor={"middle"} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      ); 
    }
    if (percent === 1) {
      return (
        <text x={cx} y={cy} fill="white" style={{fontSize: '20px', fontWeight: 400}} textAnchor={"middle"} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      ); 
    }
    return null;
  };
  
  CustomTooltipNPS = ({ active, payload, label }) => {
    if (active && payload && payload.length && payload[0].payload) {
      const percentage = payload[0].payload.percentage;
      const users_answered = payload[0].payload.count;
      if (users_answered) {
        return (
          <div className="custom-tooltip-survey-age-gender">
            <p className="label">Score {label}</p>
            <p className="inside-tooltip-row">{users_answered ? `${users_answered} user(s). It's ` : ''}<span className="bold">{percentage}%</span> of those who answered.</p>
          </div>
        );
      }
    }

    return null;
  };
  CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length && payload[0].payload) {
      const percentage = payload[0].payload.percentage;
      const male = payload[0].payload.male || 0;
      const female = Math.abs(payload[0].payload.female) || 0;
      const non_binary = payload[0].payload.non_binary || 0;
      const all = (non_binary + female + male) || 0;
      return (
        <div className="custom-tooltip-survey-age-gender">
          <p className="label">{label} years old</p>
          <p className="inside-tooltip-row">{all ? `${all} user(s). It's ` : ''}{percentage}% of users.</p>
          <p className="inside-tooltip-row"><span className="pink">
            Female: </span><span>{female}</span> users 
            {all && female ? <span> (<span className="bold">{female !== all ? ((female/all) * 100).toFixed(2) : 100}%</span> of users in this group)</span>: ''}
          </p>
          <p className="inside-tooltip-row"><span className="blue">
            Male:</span> {male} users
            {all && male ? <span> (<span className="bold">{male !== all ? ((male/all) * 100).toFixed(2) : 100}%</span> of users in this group)</span>: ''}
          </p>
          <p className="inside-tooltip-row"><span className="grad-text">
            Non-binary:</span> {non_binary} users
            {all && non_binary ? <span> (<span className="bold">{non_binary !== all ?  ((non_binary/all) * 100).toFixed(2) : 100}%</span> of users in this group)</span>: ''}
          </p>
        </div>
      );
    }

    return null;
  };
  renderLegend = (props) => {
    const { payload } = props;
    setTimeout(() => this.updatePayload(payload), 50);
    return null;
  }
  updatePayload = (payload) => {
    const { update } = this.state;
    if (typeof update === 'undefined') {
      this.setState({
        externalPayloadWorryPhoneDyingLegend: payload,
        update: true
      }, () => {
        setTimeout(() => {
          this.setState({
            update: false
          })
        }, 500);
      })
    }
  };
  mainBlockRender = () => {
    const { isSurveyFetching, surveyData, COLORS, COLORS_PHONE_DYING, externalPayloadWorryPhoneDyingLegend } = this.state;
    return (
      <Spin spinning={isSurveyFetching} style={{width: '100vw'}}>
        <Row style={{marginTop: '18px'}}>
        { surveyData.ageToGender && surveyData.ageToGender.length ?
          <Col sm={15} xs={24}>
            <div className="circle-data-container">
              <div className="label">
                <label>Demographics</label>
              </div>
              <div style={{height: '500px', display: 'flex', flexDirection: 'column'}}>
                <ResponsiveContainer height="100%">
                <ComposedChart
                    layout="vertical"
                    width={500}
                    height={400}
                    stackOffset="sign"
                    data={surveyData.ageToGender}
                    margin={{
                      top: 40,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" vertical={false} />
                    <XAxis 
                      padding={{ left: 20, right: 20 }}
                      type="number" 
                      domain={[-1 * surveyData.max_amount_gender_age, surveyData.max_amount_gender_age]}
                      tick={<CustomizedAxisTick />}
                    />
                      />
                    <YAxis dataKey="name" type="category" scale="band" label={{value: 'Age', offset: 30, position: 'top'}}/>
                    <ReferenceLine x={0} stroke="rgba(0, 0, 0, 0.0)"/>
                    <Tooltip content={this.CustomTooltip} />
                    <Legend iconType="circle"/>
                    <Bar dataKey="female" name="Female" fill="#EF5FA7" stackId="stack" barSize={32}/>
                    <Bar dataKey="male" name="Male" fill="#00A2FF" stackId="stack" barSize={32}/>
                  </ComposedChart>
                </ResponsiveContainer>
                </div>
            </div>
          </Col> : null }
          { surveyData.gender && surveyData.gender.length ? 
          <Col sm={9} xs={24}>
            <div className="circle-data-container">
              <div className="label">
                <label>Gender</label>
              </div>
              <div style={{height: '300px', display: 'flex', flexDirection: 'column'}}>
                { surveyData.gender &&
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie 
                        dataKey="count" 
                        data={surveyData.gender}
                        label={this.renderCustomizedLabel}
                        labelLine={false}
                        cx="50%" cy="50%" 
                        innerRadius={'0%'}
                        outerRadius={'88%'} 
                        fill="#8884d8"
                        legendType="circle"
                      >
                        { surveyData.gender.map((entry, index) => <Cell key={index} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} /> ) }
                      </Pie>
                      <Legend layout="vertical" verticalAlign="middle" align="right"/> 
                      <Tooltip/>
                    </PieChart>
                  </ResponsiveContainer> }
              </div>
            </div>
          </Col> : null }
        </Row>
        { surveyData.worry_your_phone_dying.total.length || surveyData.worry_your_phone_dying.female.length || surveyData.worry_your_phone_dying.male.length ? 
        <Row style={{margin: '72px 0px'}}>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Row>
                  <div>
                    <h2 className="label">Worry about phone dying</h2>
                  </div>
                </Row>
                <Row className="override-recharts-legend">
                  <Legend payload={externalPayloadWorryPhoneDyingLegend}/>
                </Row>
              </Col>
            </Row>
            <Row>
              { surveyData.worry_your_phone_dying.total.length && 
              <Col sm={8} xs={24}>
                <div className="circle-data-container">
                  <div className="label">
                    <label>Total</label>
                  </div>
                  <div style={{height: '300px', display: 'flex', flexDirection: 'column'}}>
                    { surveyData.worry_your_phone_dying && surveyData.worry_your_phone_dying.total &&
                      <ResponsiveContainer>
                        <PieChart>
                          <Pie 
                            dataKey="count" 
                            data={surveyData.worry_your_phone_dying.total}
                            label={this.renderCustomizedLabel}
                            labelLine={false}
                            cx="50%" cy="50%" 
                            innerRadius={'0%'}
                            outerRadius={'88%'} 
                            fill="#8884d8"
                          >
                            { surveyData.worry_your_phone_dying.total.map((entry, index) => <Cell key={index} stroke={COLORS_PHONE_DYING[index % COLORS_PHONE_DYING.length]} fill={COLORS_PHONE_DYING[index % COLORS_PHONE_DYING.length]} /> ) }
                          </Pie>
                          <Tooltip/>
                        </PieChart>
                      </ResponsiveContainer> }
                  </div>
                </div>
              </Col> }
              { surveyData.worry_your_phone_dying.female.length &&
              <Col sm={8} xs={24}>
                <div className="circle-data-container">
                  <div className="label">
                    <label>Female</label>
                  </div>
                  <div style={{height: '300px', display: 'flex', flexDirection: 'column'}}>
                    { surveyData.worry_your_phone_dying && surveyData.worry_your_phone_dying.female &&
                      <ResponsiveContainer>
                        <PieChart>
                          <Pie 
                            dataKey="count" 
                            data={surveyData.worry_your_phone_dying.female}
                            label={this.renderCustomizedLabel}
                            labelLine={false}
                            cx="50%" cy="50%" 
                            innerRadius={'0%'}
                            outerRadius={'88%'} 
                            fill="#8884d8"
                          >
                            { surveyData.worry_your_phone_dying.female.map((entry, index) => <Cell key={index} stroke={COLORS_PHONE_DYING[index % COLORS_PHONE_DYING.length]} fill={COLORS_PHONE_DYING[index % COLORS_PHONE_DYING.length]} /> ) }
                          </Pie>
                          <Tooltip/>
                          <Legend iconType="circle" content={this.renderLegend} />
                        </PieChart>
                      </ResponsiveContainer> }
                  </div>
                </div>
              </Col> }
              { surveyData.worry_your_phone_dying.male.length ?
              <Col sm={8} xs={24}>
                <div className="circle-data-container">
                  <div className="label">
                    <label>Male</label>
                  </div>
                  <div style={{height: '300px', display: 'flex', flexDirection: 'column'}}>
                    { surveyData.worry_your_phone_dying && surveyData.worry_your_phone_dying.male &&
                      <ResponsiveContainer>
                        <PieChart>
                          <Pie 
                            dataKey="count" 
                            data={surveyData.worry_your_phone_dying.male}
                            label={this.renderCustomizedLabel}
                            labelLine={false}
                            cx="50%" cy="50%" 
                            innerRadius={'0%'}
                            outerRadius={'88%'} 
                            fill="#8884d8"
                          >
                            { surveyData.worry_your_phone_dying.male.map((entry, index) => <Cell key={index} stroke={COLORS_PHONE_DYING[index % COLORS_PHONE_DYING.length]} fill={COLORS_PHONE_DYING[index % COLORS_PHONE_DYING.length]} /> ) }
                          </Pie>
                          <Tooltip/>
                        </PieChart>
                      </ResponsiveContainer> }
                  </div>
                </div>
              </Col> : null }
            </Row>
          </Col>
        </Row> : null }
      </Spin>
    )
  }
  setFilterNpsGender = (value) => {
    this.setState({
      npsGenderFilter: value
    }, () => {
      const bodyObj = this.getBodyObjSurvey();
      this.getNpsData(bodyObj);
    })
  }
  setFilterNpsAge = (value) => {
    this.setState({
      npsAgeFilter: value
    }, () => {
      const bodyObj = this.getBodyObjSurvey();
      this.getNpsData(bodyObj);
    })
  }
  setFilterNpsCable = (value) => {
    this.setState({
      npsCableFilter: value
    }, () => {
      const bodyObj = this.getBodyObjSurvey();
      this.getNpsData(bodyObj);
    })
  }
  resetAllNpsFilters = () => {
    this.setState({
      npsGenderFilter: false,
      npsAgeFilter: false,
      npsCableFilter: false     
    }, () => {
      const bodyObj = this.getBodyObjSurvey();
      this.getNpsData(bodyObj);
    })
  }
  npsRender = () => {
    const { isNpsFetching, npsData, npsAgeFilter, npsCableFilter, npsGenderFilter } = this.state;
    const agesArr = ['18-20', '20-25', '25-30', '35-40', '40-50', '50-60','60-70', '70-80', '80-100'];
    const cablesArr = ['lightning', 'usb_c', 'micro_usb', 'double', 'triple'];
    const cablesVocabulary = {
      lightning: 'Lightning', 
      usb_c: 'Type-C',
      micro_usb: 'Micro USB',
      double: 'Double',
      triple: 'Triple'
    }
    return (
      <Spin spinning={isNpsFetching} style={{width: '100vw'}}>
        <Row style={{marginTop: '18px'}}>
          <Col span={24}>
            <Row>
              <div>
                <h2 className="label">Net Promoter Score</h2>
              </div>
            </Row>
            <Row style={{margin: '10px 0px 18px 0px'}}>
              <Select
                value={npsGenderFilter || []}
                placeholder="Filter by gender"
                style={{width: '220px'}}
                onChange={(value) => this.setFilterNpsGender(value)}
                allowClear={true}
                mode="multiple"
              >
                <Option key={0} value="female">Female</Option>
                <Option key={1} value="male">Male</Option>
                <Option key={2} value="non_binary">Non binary</Option>
              </Select>
              <Select
                placeholder="Filter by age"
                value={npsAgeFilter || []}
                style={{width: '220px', marginLeft: '8px'}}
                onChange={(value) => this.setFilterNpsAge(value)}
                allowClear={true}
                mode="multiple"
              >
                { agesArr.map((age, index) => {
                  return <Option key={index} value={age}>{age}</Option>
                })}
              </Select>
              <Select
                placeholder="Filter by cable"
                value={npsCableFilter || []}
                style={{width: '220px', marginLeft: '8px'}}
                onChange={(value) => this.setFilterNpsCable(value)}
                allowClear={true}
                mode="multiple"
              >
                {
                  cablesArr.map((cable, index) => {
                    return <Option key={index} value={cable}>{cablesVocabulary[cable]}</Option>
                  })
                }
              </Select>
              <Button
                onClick={this.resetAllNpsFilters}
                style={{marginLeft: '8px'}}
                type="primary"
                >
                Reset all filters
              </Button>
            </Row>
          </Col>
          <Col span={24}>
            <div style={{width: '100%', height: '400px'}}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={npsData.nps_score.length ? npsData.nps_score : emptyNpsObj.nps_score}
                  margin={{
                    top: 20,
                    right: 30,
                    left: -20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid vertical={false} horizontal={false}/>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip content={this.CustomTooltipNPS} />
                  <Bar name="Amount of users" dataKey="count" fill="#5856d6" >
                    {npsData.nps_score.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={index < 6 ? '#ff3b30' : index < 8 ? '#ff9500' : '#4cd964'} />
                    ))}      
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>  
          </Col>
          <Row className="nps-part">
            <Col sm={8} xs={24}>
              <p className="formula">
                <DashboardTwoTone twoToneColor={"#5856d6"} className="nps-part formula dashboard-icon"/>
                <span className="nps-part formula name">NPS</span> = %<SmileTwoTone className="nps-part formula emotion-icon" twoToneColor={'#4cd964'} /> - 
                %<FrownTwoTone className="nps-part formula emotion-icon" twoToneColor={"#ff3b30"} /> 
              </p>
            </Col>
            <Col sm={16} xs={24}>
              <div className="nps-explain">
                <div className="header">
                  <label> What is a good NPS score? </label>
                </div>
                <div className="nps-explanation-row">
                  <div className="nps-explanation-container w-50">
                    <span className="color-line red">
                      <p className="text-outside-left">-100</p>
                    </span>
                    <span className="title" style={{color: '#ff3b30'}}>Needs improvement</span>
                    <span className="value">(-100 - 0)</span>
                  </div>
                  <div className="nps-explanation-container w-15">
                    <span className="color-line yellow">
                      <p>0</p>
                    </span>
                    <span className="title" style={{color: '#ff9500'}}>Good</span>
                    <span className="value">(0 - 30)</span>
                  </div>
                  <div className="nps-explanation-container w-20">
                    <span className="color-line light-green">
                      <p>30</p>
                    </span>
                    <span className="title" style={{color: '#4cd964'}}>Great</span>
                    <span className="value">(30 - 70)</span>
                  </div>
                  <div className="nps-explanation-container w-15">
                    <span className="color-line dark-green">
                      <p>70</p>
                      <p className="text-outside-right">100</p>
                    </span>
                    <span className="title" style={{color: '#1EAA36'}}>Excellent</span>
                    <span className="value">(70 - 100)</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Col span={24}>
            <Row>
              <Col sm={5} xs={12}>
                <div className="circle-data-container margin-24" style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                  <div className="label">
                    <label>
                      Total
                    </label>
                  </div>
                  <div className="circle-wrapper" style={{marginTop: '12px'}}>
                    <div className="circle" style={{background: npsData.total_score < 0 ? '#ff3b30' : npsData.total_score < 30 ? '#ff9500' : npsData.total_score < 70 ? "#4cd964" : "#1EAA36"}}>
                      <div className="text">{npsData.total_score ? `${npsData.total_score.toFixed()}%` : 0}</div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={4} xs={0}/>
              <Col sm={5} xs={12}>
                <div className="circle-data-container margin-24" style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                  <div className="label">
                    <label>
                      Female
                    </label>
                  </div>
                  <div className="circle-wrapper" style={{marginTop: '12px'}}>
                    <div className="circle" style={{background: npsData.female_score < 0 ? '#ff3b30' : npsData.female_score < 30 ? '#ff9500' : npsData.female_score < 70 ? "#4cd964" : "#1EAA36"}}>
                      <div className="text">{npsData.female_score ? `${npsData.female_score.toFixed()}%` : 0}</div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={4} xs={0}/>
              <Col sm={5} xs={12}>
                <div className="circle-data-container margin-24" style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                  <div className="label">
                    <label>
                      Male
                    </label>
                  </div>
                  <div className="circle-wrapper" style={{marginTop: '12px'}}>
                    <div className="circle" style={{background: npsData.male_score < 0 ? '#ff3b30' : npsData.male_score < 30 ? '#ff9500' : npsData.male_score < 70 ? "#4cd964" : "#1EAA36"}}>
                      <div className="text">{npsData.male_score ? `${npsData.male_score.toFixed()}%` : 0}</div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    )
  }
  handleLocationOverrideChange = (value) => {
    const { overrideLocationChoice } = this.state;
    if (overrideLocationChoice !== value) {
      this.setState({
        overrideLocationChoice: value
      }, () => {
        this.getFullSurveyData();
      })
    }
  }
  render() {
    const {
      isSurveyFetching,
      isNpsFetching,
      showEmptyNps,
      showEmptyGeneralSurvey,
      overrideLocationChoice
    } = this.state;
    
    return (
      <div>
      { isNpsFetching && isSurveyFetching ? 
       <Skeleton/> 
       :
      <Content className="unselectable survey-reports">
        <Row style={{textAlign: 'center'}}>
          <div className="label">
            <h2 style={{fontSize: '30px', marginLeft: '12px'}}>Surveys</h2>
          </div>
        </Row>
        <Row>
          <Alert 
            type="info"
            closable={true}
            message={<span style={{fontSize: '16px'}}>Please note that <span style={{fontWeight: 500}}>not all users</span> of the system had answered this questions.</span>}
            closeText={<span style={{fontSize: '16px'}}>Got it!</span>}
          />
        </Row>
        <Row style={{marginTop: '18px', display: 'flex', flexDirection: 'row'}}>
          <p style={{fontSize: '16px', lineHeight: 1.2}}>Show all users who has answered regardless of location choice. </p>
          <Switch
            style={{marginLeft: '8px'}}
            onChange={this.handleLocationOverrideChange}
            checked={overrideLocationChoice}
            />
        </Row>
        <Row>
          { (isNpsFetching || isSurveyFetching || !showEmptyNps || !showEmptyGeneralSurvey) ? 
            <div style={{width: '100%'}}>
              <Row style={{minHeight: '100px'}}>
                {this.mainBlockRender()}
              </Row>
              <Row style={{minHeight: '100px'}}>
                {this.npsRender()}
              </Row>
            </div>
          : <Empty style={{margin: '30px auto'}}/> }
        </Row>
      </Content> }
      </div>
    )
  }
};

export default SurveyReports;

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {Math.abs(payload.value)}
        </text>
      </g>
    );
  }
}
class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, dataObj, index } = this.props;
    return (
      <text x={x} y={y} dy={22} fill={"#fff"} fontSize={18} fontWeight={400} textAnchor="middle">
        {Math.abs(dataObj[index].percentage)+'%'}
      </text>
    );
  }
}