import { CALL_API } from 'services/middleware/api'

// Actions
const QUOTE_REQUEST = 'singleUser/QUOTE_REQUEST'
const QUOTE_SUCCESS = 'singleUser/QUOTE_SUCCESS'
const QUOTE_FAILURE = 'singleUser/QUOTE_FAILURE'

const initialState = {
  isFetching: false,
  quote: '',
  authenticated: false,
  createdFetch: null,
  userId: null
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case QUOTE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        createdFetch: null
      })
    case QUOTE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        quote: action.response,
        userId: action.response.userId,
        authenticated: action.authenticated || false,
        createdFetch: Date.now()
      })
    case QUOTE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        createdFetch: null
      })
    default:
      return state
  }
}

// Action Creators
export function fetchUser(endpoint) {
  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [QUOTE_REQUEST, QUOTE_SUCCESS, QUOTE_FAILURE]
    }
  }
}
