import { CALL_API } from 'services/middleware/api'

export const QUOTE_REQUEST = 'sessions/QUOTE_REQUEST'
export const QUOTE_SUCCESS = 'sessions/QUOTE_SUCCESS'
export const QUOTE_FAILURE = 'sessions/QUOTE_FAILURE'


// Uses the API middlware to get a quote
export function fetchQuote(endpoint) {
  return {
    [CALL_API]: {
      endpoint: endpoint,
      types: [QUOTE_REQUEST, QUOTE_SUCCESS, QUOTE_FAILURE]
    }
  }
}

// Same API middlware is used to get a
// secret quote, but we set authenticated
// to true so that the auth header is sent
export function fetchSecretQuote(endpoint) {
  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [QUOTE_REQUEST, QUOTE_SUCCESS, QUOTE_FAILURE]
    }
  }
}

// export const handleTableChange = (pagination, filters, sorter) => {
//   return dispatch => {

//   }
//   const pager = { ...this.state.pagination };
//   pager.current = pagination.current;
//   dispatch({
//     type: INCREMENT
//   })
//   this.setState({
//     pagination: pager,
//   });

//   this.fetch({
//     results: pagination.pageSize,
//     page: pagination.current,
//     sortField: sorter.field,
//     sortOrder: sorter.order,
//     ...filters,
//   });
// }