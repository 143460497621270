import {
  QUOTE_REQUEST, QUOTE_SUCCESS, QUOTE_FAILURE
} from './actions';
import _ from 'lodash';

const initialState = {
  isFetching: false,
  quote: '',
  authenticated: false,
  createdFetch: null,
  pagination: {
    pageSize: 20
  },
  groupFilter: []
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case QUOTE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        createdFetch: null
      })
    case QUOTE_SUCCESS:
      const groupFilter = [];
      const storage = [];
      action.response.data.forEach(electron => {
        let groupKeyId = _.get(electron, 'group_id');
        if (groupKeyId) {
          if (storage.indexOf(groupKeyId) === -1) {
            storage.push(groupKeyId);
            groupFilter.push({
              text: groupKeyId, value: groupKeyId
            });
          }
        }
      })
      return Object.assign({}, state, {
        isFetching: false,
        quote: action.response,
        authenticated: action.authenticated || false,
        createdFetch: Date.now(),
        groupFilter: groupFilter
      })
    case QUOTE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        createdFetch: null
      })
    default:
      return state
   }
}
