import React from 'react';
import { Table, Button, Col, Row, message, Tag, Modal } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import AddUser from './AddUser';
import EditNebulaUser from './EditNebulaUser';
import NebulaUsersSettings from './NebulaUsersSettings';

import _ from 'lodash';

const confirm = Modal.confirm;


class AppsUsers extends React.Component {
  state = {
    filteredInfo: {},
    sortedInfo: {},
    searchText: '',
    filtered: false,
    usersCP: [],
    usersNebula: [],
    isFetching: false,
    visibleAddUser: false,
    platform: '',
    visibleEditUsersPhone: false,
    nebulaUser: {},
    visibleNebulaSettings: false
  };
  componentDidMount() {
    document.title = 'Control Panel Users';
    this.getUsers();
  }
  getUsers = () => {
    const { organisationId } = this.props;
    fetchJSON(`/api/v1/organisation/usersList/${organisationId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      this.setState({
        usersCP: response.data.cp,
        usersNebula: response.data.nebula,
        isFetching: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false
      });
    });
  }
  columns = (platform = false) => {
    // let { sortedInfo, filteredInfo } = this.state;
    // sortedInfo = sortedInfo || {};
    // filteredInfo = filteredInfo || {};
    const columns = [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    }];
    if (platform === 'nebula') {
      columns.push({
        title: 'Warehouses',
        dataIndex: 'warehouses',
        key: 'warehouses',
        render: (text, record) => {
          if (!record.warehouses) return null;
          return (
            <React.Fragment>
              {record.warehouses.map((warehouse, i) => <Tag key={i} color="magenta" style={{ cursor: 'default' }}>{warehouse.title}</Tag>)}
            </React.Fragment>
          )
        }
      });
      columns.push({
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => (
          <span style={{color: record.status === 2 ? 'red' : 'green'}}>{record.status === 2 ? 'Suspended' : 'Active'}</span>
        )
      });
      columns.push({
        title: 'Surveys reports',
        dataIndex: 'reports_surveys',
        key: 'reports_surveys',
        render: (text, record) => (
          <span  style={{color: record.reports_surveys === 0 ? 'red' : 'green'}}>{record.reports_surveys === 0 ? 'Off' : 'On'}</span>
          )
      });
      columns.push({
        title: 'Trends reports',
        dataIndex: 'reports_trends',
        key: 'reports_trends',
        render: (text, record) => (
          <span  style={{color: record.reports_trends === 0 ? 'red' : 'green'}}>{record.reports_trends === 0 ? 'Off' : 'On'}</span>
          )
      });
      columns.push({
        title: 'Users section',
        dataIndex: 'show_users_tab',
        key: 'show_users_tab',
        render: (text, record) => (
          <span  style={{color: record.show_users_tab === 0 ? 'red' : 'green'}}>{record.reports_trends === 0 ? 'Off' : 'On'}</span>
          )
      });
    }

    columns.push({
      title: 'Actions',
      key: 'actions',
      render: (text, record) => {
        if (record.platform !== 'nebula') return null;

        return (
          <React.Fragment>
            <Button onClick={() => this.editNebulaUser(record)} size="small">
              Edit user
            </Button>
            <Button onClick={() => {
              if (record.status === 2) {
                Modal.warning({
                  title: `User is suspended. Log in under user's credentials is unavailable.`
                })
              } else {
                this.loginAsUser(record.id, record.status)}
              }   
            } size="small" style={{ marginLeft: '8px' }}>
              Log in as user
            </Button>
            <Button 
              onClick={() => {
                const status = record.status === 2 ? 1 : 2;
                this.suspendNebulaUser(record.id, status)
              }} 
              size="small" style={{ marginLeft: '8px' }}>
              {record.status === 2 ? 'Activate user' : 'Suspend user'}
            </Button>
            <Button 
              onClick={() => {
                this.resendInvitation(record.id);
              }} 
              size="small" style={{ marginLeft: '8px' }}>
              Resend invitation
            </Button>
          </React.Fragment>
        )
      }
    });

    return columns;
  }
  resendInvitation = (userId) => {
    console.log('resendInvitation', userId);
    const { organisationId } = this.props;
    confirm({
      title: "Are you sure you want to resend an invitation email?",
      content: "The action can reset the user's password.",
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            isFetching: true
          })
          fetchJSON(`/api/v1/organisation/resendNebulaInvitation/${organisationId}`, {
            method: 'post',
            body: {
              userId
            }
          }).then(response => {
            console.log(response);
            if (response.code === 200) {
              message.success('Success!');
              this.setState({
                isFetching: false
              });
              // this.getUsers()
              resolve()
            } else {
              this.setState({
                isFetching: false
              });
              reject();
              message.error('Something went wrong, please try again.');
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                isFetching: false
              });
              message.error('ERR');
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  suspendNebulaUser = (userId, status) => {
    fetchJSON(`/api/v1/organisation/suspendNebulaUser/${userId}`, {
      method: 'put',
      body: {
        status
      }
    }).then(response => {
      if (response.success) {
        message.success('Success!');
        this.getUsers()     
      } else {
        message.error('Something went wrong, please try again.');
      }
     }).catch(error => {
        console.log(error);
        message.error('ERR');
     });
  }
  editNebulaUser = (nebulaUser) => {
    this.setState({
      nebulaUser,
      visibleEditUsersPhone: true
    })
  }
  loginAsUser = (user_id) => {
    console.log('user_id', user_id);
    const { organisationId } = this.props;
    fetchJSON(`/api/v1/organisation/getNebulaUserToken/${organisationId}`, {
      method: 'post',
      body: {
        user_id
      }
    }).then(response => {
      let baseUrl = 'nebula.electrontogo.com';
      if (process.env.NODE_ENV === "development") {
        baseUrl = 'app8.e2g.app';
      }
      if (process.env.REACT_APP_ENV === "staging") {
        baseUrl = 'tnebula.electrontogo.com';
      }
      message.success('We are going to nebula now');
      const url = `http://${baseUrl}/loginWithToken?token=${response.token}`;
      const win = window.open(url, '_blank');
      win.focus();
    }).catch(error => {
      console.log(error);
      message.error('Something went wrong, repeat');
    });
  }
  getData = (platform) => {
    const data = [];
    
    let users = [];

    const { usersCP, usersNebula } = this.state;
    if (platform === 'cp') {
      users = usersCP;
    }
    if (platform === 'nebula') {
      users = usersNebula;
    }
    if (!users) return data;

    users.forEach((user, i) => {
      const obj = {
        key: i,
        id: user.id,
        name: user.name || user.first_name,
        email: user.email,
        phone: user.phone,
        platform
      };
      if (platform === 'nebula') {
        const warehouses = [];
        const warehousesIds = [];
        const directories = _.get(user, 'directories');
        if (directories && directories.length) {
          directories.forEach((directory) => {
            let directory_title = _.get(directory, 'directory.title');
            let directory_id = _.get(directory, 'directory.id');
            if (directory_title) {
              if (directory_title.indexOf('directory_user_') > -1) {
                directory_title = directory_title.replace('directory_user_', '');
                directory_title = `User's warehouse #${directory_title}`;
              }

              warehousesIds.push(directory_id);
              warehouses.push({
                id: directory_id,
                title: directory_title,
              });
            }
          });
        }
        let reports_surveys;
        let reports_trends;
        let show_users_tab;
        if (user.settings && Object.keys(user.settings) && Object.keys(user.settings).length) {
          reports_surveys = user.settings.reports_surveys;
          reports_trends = user.settings.reports_trends;
          show_users_tab = user.settings.show_users_tab;
        }
        obj.reports_trends = reports_trends || 0;
        obj.reports_surveys = reports_surveys || 0;
        obj.show_users_tab = show_users_tab || 0;
        obj.warehouses = warehouses;
        obj.warehousesIds = warehousesIds;
        obj.status = user.status;
      }
      data.push(obj);
    });
    return data;
  }
  handleTableChange = (pagination, filters, sorter) => {
    console.log('handleTableChange', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  openModalAddUser = (platform) => {
    this.setState({
      platform
    }, () => {
      this.setState({
        visibleAddUser: true
      })
    })
  }
  render() {
    const { visibleAddUser, platform, visibleEditUsersPhone, nebulaUser, visibleNebulaSettings } = this.state;
    const { isFetching, pagination, organisationId } = this.props;
    return (
      <React.Fragment>
         <React.Fragment>
            <Row gutter={8} style={{marginBottom: '15px'}}>
              <Col span={12}>
                <h1 className="table-title">Nebula users
                  <Button style={{marginLeft:'8px'}} type="primary" size="small" onClick={ () => { this.openModalAddUser('nebula') } }>
                    Add nebula user
                  </Button>
                  <Button style={{marginLeft:'8px'}} size="small" onClick={ () => { this.setState({ visibleNebulaSettings: true }) } }>
                    Edit access settings for all users
                  </Button>
                </h1>
              </Col>
              <Col span={12}>
              </Col>
            </Row>
            <Table
              columns={ this.columns('nebula') }
              loading={ isFetching }
              dataSource={ this.getData('nebula') }
              pagination={ pagination }
              onChange={ this.handleTableChange }
            />
          </React.Fragment>
          <Row gutter={8} style={{marginBottom: '15px', marginTop: '25px'}}>
            <Col span={8}>
              <h1 className="table-title">Control Panel users
                <Button style={{marginLeft:'8px'}} type="primary" size="small" onClick={ () => { this.openModalAddUser('cp') } }>
                  Add cp user
                </Button>
              </h1>
            </Col>
            <Col span={16}>
            </Col>
          </Row>
          <Table
            columns={ this.columns() }
            loading={ isFetching }
            dataSource={ this.getData('cp') }
            pagination={ pagination }
            onChange={ this.handleTableChange }
          />
          { visibleAddUser ? <AddUser
            organisationId={organisationId}
            platform={platform}
            visible={visibleAddUser}
            renderList = { this.getUsers }
            handleClose={ () => { this.modalVisible('visibleAddUser', false) } } />
            : null
          }
          { visibleEditUsersPhone ? <EditNebulaUser
            organisationId={organisationId}
            nebulaUser={nebulaUser}
            visible={visibleEditUsersPhone}
            renderList = { this.getUsers }
            handleClose={ () => { this.modalVisible('visibleEditUsersPhone', false) } } />
            : null
          }
          { visibleNebulaSettings ?
            <NebulaUsersSettings
              organisationId={organisationId}
              visible={visibleNebulaSettings}
              renderList={ this.getUsers}
              handleClose={ () => { this.modalVisible('visibleNebulaSettings', false) } } 
            />: null }
      </React.Fragment>
    )
  }
};

export default AppsUsers;