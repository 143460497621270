import React from 'react';
import _ from 'lodash'
import { Input, Row, Col, Card } from 'antd';
import { Map, Marker } from 'google-maps-react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import fetchJSON from 'services/utils/fetchJSON';
import countryByCurrencyCode from './components/country-by-currency-code.json';

class AddressField extends React.Component {
	constructor(props) {
		super(props);

		const value = this.props.value || {};

		var markers = [];
		if (value.coordinates) {
			markers.push({
				name: "Current position",
				position: value.coordinates
			})
		}
		this.state = {
			address: value.address,
			addressComponents: value.addressComponents,
			coordinates: value.coordinates,
			correct: true,
			markers,
			timezone: value.timezone,
			place_id: value.place_id,
			currency_code: value.currency_code,
			opening_hours: value.opening_hours || {},
			country_code: value.country,
			formatted_address: value.formatted_address
		};
	}
	componentWillReceiveProps(nextProps) {
		// Should be a controlled component.
		if ('value' in nextProps) {
			const value = nextProps.value;
			this.setState(value);
		}
	}
	handleAddressChange = (address) => {
		if (!('value' in this.props)) {
			this.setState({ address, correct: true });
		}
		if (!address) {
			this.triggerChange({ address, addressComponents: {}, coordinates: {}, correct: false });
		} else {
			this.triggerChange({ address, correct: true });
		}
	}
	fillInAddressComponents = (result) => {
		let addressComponents = result.address_components;
		const { place_id } = result;
		let postal_code = _.find(addressComponents, function (ac) {
			return ac.types[0] === 'postal_code'
		})
		if (postal_code) {
			postal_code = postal_code.short_name;
		} else {
			postal_code = '';
		}
		let locality = _.find(addressComponents, function (ac) {
			return ac.types[0] === 'locality'
		})
		if (locality) {
			locality = locality.long_name;
		} else {
			locality = ''
		}
		let street_number = _.find(addressComponents, function (ac) {
			return ac.types[0] === 'street_number'
		});
		if (street_number) {
			street_number = street_number.short_name;
		} else {
			street_number = ''
		}
		let route = _.find(addressComponents, function (ac) {
			return ac.types[0] === 'route'
		});
		if (route) {
			route = route.short_name;
		} else {
			route = '';
		}
		let administrative_area_level_1 = _.find(addressComponents, function (ac) {
			return ac.types[0] === 'administrative_area_level_1'
		});
		if (administrative_area_level_1) {
			administrative_area_level_1 = administrative_area_level_1.long_name;
		} else {
			administrative_area_level_1 = '';
		}

		let country = _.find(addressComponents, function (ac) {
			return ac.types[0] === 'country'
		});
		let country_code = '';

		if (country) {
			country_code = country.short_name;
			country = country.long_name;
		} else {
			country = '';
		}

		var lat = result.geometry.location.lat();
		var lng = result.geometry.location.lng();
		const _addressComponents = {
			postal_code,
			locality,
			street_number,
			route,
			administrative_area_level_1,
			country
		};
		const coordinates = {
			lat,
			lng
		};
		let currency_code = '';
		if (country) {
			currency_code = this.getCurrencyCode(country);
		}

		const markers = [...this.state.markers];
		markers[0] = { ...markers[0], position: coordinates };
		this.setState({
			formatted_address: result.formatted_address,
			addressComponents: _addressComponents,
			coordinates,
			country_code,
			markers,
			place_id,
			currency_code,
			correct: true
		});

		if (place_id) {
			this.getOpeningHours(place_id);
		}
		this.triggerChange({
			formatted_address: result.formatted_address,
			addressComponents: _addressComponents,
			coordinates,
			country_code,
			place_id,
			currency_code,
			correct: true
		});
	}
	handleSelect = (address) => {
		this.setState({ address })
		this.triggerChange({ address });

		geocodeByAddress(address)
			.then(results => {
				if (results.length) {
					this.fillInAddressComponents(results[0]);
					if (results[0]) {
						var lat = results[0].geometry.location.lat();
						var lng = results[0].geometry.location.lng();
						this.getTimeZone(lat, lng);
					}
				}
			})
			.catch(error => console.error(error));
	}
	getCurrencyCode = (country) => {
		const currency_code = countryByCurrencyCode.filter((item) => {
			return item.country === country;
		});
		if (currency_code.length) {
			return currency_code[0].currency_code;
		}
		return 'USD';
	}
	getTimeZone = (lat, lng) => {
		fetchJSON('/api/v1/venue/timezone', {
			method: 'post',
			body: {
				lat,
				lng
			}
		})
			.then(response => {
				// console.log(response);
				if (response.success) {
					console.log('response', response.data);
					this.setState({
						timezone: response.data.timeZoneId
					}, () => {
						this.triggerChange({
							timezone: response.data.timeZoneId
						});
					});
				}
			}).catch(error => {
				console.log(error);
			});
	}
	getOpeningHours = (place_id) => {
		fetchJSON('/api/v1/venue/placeApiData', {
			method: 'post',
			body: {
				place_id
			}
		})
			.then(response => {
				// console.log(response);
				if (response.success) {
					const { opening_hours } = response.data;
					if (typeof opening_hours === 'object') {
						if (Object.keys(opening_hours).indexOf('open_now') > -1) {
							delete opening_hours.open_now;
							opening_hours.can_use_google = true;
						}
					}
					this.setState({
						opening_hours: opening_hours || {}
					}, () => {
						this.triggerChange({
							opening_hours: opening_hours || {}
						});
					});
				}
			}).catch(error => {
				console.log(error);
			});
	}
	handleCurrencyChange = (currency) => {
		if (!('value' in this.props)) {
			this.setState({ currency });
		}
		this.triggerChange({ currency });
	}
	triggerChange = (changedValue) => {
		// Should provide an event to pass value to Form.
		const onChange = this.props.onChange;
		if (onChange) {
			onChange(Object.assign({}, this.state, changedValue));
		}
	}
	onError = (status, clearSuggestions) => {
		console.log(status, this.props);
		this.triggerChange({ addressComponents: {}, coordinates: {}, correct: false });
	}
	onMarkerDragEnd = (coord, index) => {
		const { latLng } = coord;
		const lat = latLng.lat();
		const lng = latLng.lng();

		const coordinates = {
			lat,
			lng
		};
		this.setState(prevState => {
			const markers = [...this.state.markers];
			markers[index] = { ...markers[index], position: { lat, lng } };
			return { markers, coordinates, correct: true };
		}, () => {
			this.triggerChange({
				coordinates,
				correct: true
			});
		});
		console.log('onMarkerDragEnd', coordinates);
		this.getTimeZone(coordinates.lat, coordinates.lng);
	};
	getInput = (getInputProps) => {
		return (<input {...getInputProps({
			placeholder: 'Search places ...',
			className: 'ant-input',
		})}
		/>)
	}
	render() {
		const { address, coordinates, timezone, currency_code, place_id } = this.state;
		let addressComponents = this.state.addressComponents;
		const { wrapIntoFormItem } = this.props;
		const inputProps = {
			value: address,
			onChange: this.handleAddressChange,
			placeholder: "Search places..."
		}
		const cssClasses = {
			root: 'ant-row',
			input: 'ant-input',
			autocompleteContainer: 'ant-select-dropdown venue-select-dropdown',
			autocompleteItem: 'ant-select-dropdown-menu-item',
			autocompleteItemActive: 'ant-select-dropdown-menu-item-active'
		}
		if (!addressComponents) {
			addressComponents = {
				street_number: '',
				route: '',
				locality: '',
				administrative_area_level_1: '',
				country: '',
				postal_code: ''
			};
		}
		const google = window.google;
		const addressErrorMessage = 'Please, enter correct address and select right option from a dropdown list';
		return (
			<Col span={24}>
				<Row>
					<PlacesAutocomplete
						value={address}
						onChange={this.handleAddressChange}
						onSelect={this.handleSelect}
						onError={this.onError}
					>
						{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
							<div className="ant-row address-container">
								{wrapIntoFormItem ?
									<Form.Item
										name="addressInput"
										rules={[{
											required: true,
											message: addressErrorMessage,
										}, ({ getFieldValue }) => ({
											validateTrigger: 'onSubmit',
											validator(rule, value) {
												//. console.log('value', value, 'coordinates', coordinates);
												if (coordinates.lat) {
													return Promise.resolve();
												}
												if (!value) return Promise.reject();
												return Promise.reject(addressErrorMessage);
											},
										})]}
										style={{ marginBottom: 0, width: '100%' }}>
										{this.getInput(getInputProps)}
									</Form.Item>
									: this.getInput(getInputProps)}
								{suggestions && suggestions.length ? <div className="ant-select-dropdown address-select-dropdown">
									{loading && <div className="ant-select-dropdown-menu-item">Loading...</div>}
									{suggestions.map((suggestion, index) => {
										suggestion.id = index;
										const className = suggestion.active
											? 'ant-select-item ant-select-item-option ant-select-item-option-active'
											: 'ant-select-item ant-select-item-option';
										return (
											<div
												{...getSuggestionItemProps(suggestion, {
													className
												})}

											>
												<span>{suggestion.description}</span>
											</div>
										);
									})}
								</div> : null}
							</div>
						)}
					</PlacesAutocomplete>
				</Row>
				<Card style={{ marginTop: '20px' }}>
					<Row gutter={8}>
						<Col span={14}>
							<Row gutter={8}>
								<Col span={12}>
									<Row>
										<label className="form-label-custom">Street number</label>
									</Row>
									<Row >
										<Input value={addressComponents.street_number} readOnly={true} />
									</Row>
								</Col>
								<Col span={12}>
									<Row>
										<label className="form-label-custom">Route</label>
									</Row>
									<Row >
										<Input value={addressComponents.route} readOnly={true} />
									</Row>
								</Col>
							</Row>
							<Row gutter={8}>
								<Col span={12} style={{ marginTop: '10px' }}>
									<Row>
										<label className="form-label-custom">City</label>
									</Row>
									<Row >
										<Input value={addressComponents.locality} readOnly={true} />
									</Row>
								</Col>
								<Col span={12} style={{ marginTop: '10px' }}>
									<Row>
										<label className="form-label-custom">Region/State</label>
									</Row>
									<Row >
										<Input value={addressComponents.administrative_area_level_1} readOnly={true} />
									</Row>
								</Col>
							</Row>
							<Row gutter={8}>
								<Col span={12} style={{ marginTop: '10px' }}>
									<Row>
										<label className="form-label-custom">Zip code</label>
									</Row>
									<Row >
										<Input value={addressComponents.postal_code} readOnly={true} />
									</Row>
								</Col>
								<Col span={12} style={{ marginTop: '10px' }}>
									<Row>
										<label className="form-label-custom">Country</label>
									</Row>
									<Row >
										<Input value={addressComponents.country} readOnly={true} />
									</Row>
								</Col>
							</Row>
							<Row gutter={8}>
								<Col span={12} style={{ marginTop: '10px' }}>
									<Row>
										<label className="form-label-custom">Lat</label>
									</Row>
									<Row >
										<Input value={coordinates.lat} readOnly={true} />
									</Row>
								</Col>
								<Col span={12} style={{ marginTop: '10px' }}>
									<Row>
										<label className="form-label-custom">Lng</label>
									</Row>
									<Row >
										<Input value={coordinates.lng} readOnly={true} />
									</Row>
								</Col>
							</Row>
							<Row gutter={8}>
								<Col span={12} style={{ marginTop: '10px' }}>
									<Row>
										<label className="form-label-custom">TimeZone</label>
									</Row>
									<Row >
										<Input value={timezone ? timezone : ''} readOnly={true} />
									</Row>
								</Col>
								<Col span={12} style={{ marginTop: '10px' }}>
									<Row>
										<label className="form-label-custom">Currency</label>
									</Row>
									<Row >
										<Input value={currency_code ? currency_code : ''} readOnly={true} />
									</Row>
								</Col>
							</Row>
							<Row gutter={8}>
								<Col span={24} style={{ marginTop: '10px' }}>
									<Row>
										<label className="form-label-custom">Google Places ID</label>
									</Row>
									<Row >
										<Input value={place_id ? place_id : ''} readOnly={true} />
									</Row>
								</Col>
							</Row>
						</Col>
						<Col span={10}>
							<Row style={{ 'position': 'relative', 'padding': '20px' }}>
								<Col span={24}>
									<Map
										google={google}
										style={{
											width: "100%",
											height: "240px"
										}}
										zoom={16}
										initialCenter={Object.keys(coordinates) && Object.keys(coordinates).length ? coordinates : { lat: 0, lng: 0 }}
										center={coordinates}
										streetViewControl={false}
										mapTypeId={google.maps.MapTypeId.ROADMAP}
										mapTypeControl={false}
									>
										{this.state.markers.map((marker, index) => (
											<Marker
												key={index}
												position={marker.position}
												draggable={true}
												onDragend={(t, map, coord) => this.onMarkerDragEnd(coord, index)}
												name={marker.name}
											/>
										))}
									</Map>
								</Col>
							</Row>
						</Col>
					</Row>
				</Card>
			</Col>
		);
	}
}
export default AddressField;