import React from 'react';
import Settings from 'services/config/Settings';
import { Layout, Card, Row, Col, Select, Empty, Button, Skeleton, Switch, Modal } from 'antd';
import { getTimeDistance } from 'services/utils/utils';
import qs from 'qs'; 

import * as _sharedModules from './modules/_modules';

import GeneralReports from './components/GeneralReports/GeneralReports';
import SurveyReports from './components/SurveyReports/SurveyReports';
import TrendsReports from './components/TrendsReports/TrendsReports';

import './styles.less';

const { Content } = Layout;

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgListFetching: false,
      initialDataLoaded: false,
      venuesList: [],
      targetOrgId: null,
      targetVenueId: null,
      general_date_type: 'total',
      rangePickerValue: getTimeDistance('total'),
      datePickerSize: 'default',
      timezone: 'America/Los_Angeles',
      filterTypeOrder: 'all',
      analiticsLoading: false,
      filterByLocationType: false,
      businessInfoSorted: [],
      targetBusinessTypeId: null,
      targetBusinessCategoryId: null,
      businessCategoriesList: [],
      venuesCategoriesList: [],
      childShouldUpdateDateChanged: false,
      tabsUrlParams: ['general', 'money', 'trends', 'compare','survey', 'maintenance'],
      urlAnalized: false,
      shouldUpdateTurnOffOverrides: false,
      // several locations choice 
      overrideLocationChoiceGlobal: false,
      updateLocationMultiChange: false,
      orgsIds: [],
      venuesIds: [],
      shouldUpdateMultilocal: false,
      customLocationsChoiceActivated: false
    };
    const requiredModulesNames = [
      'getOrgList', 
      'analiseUrlOnMount', 
      'renderOrganisationOptions',
      'sortVenuesList',
      'renderVenuesOptions',
      'renderBusinessTypesOptions',
      'renderBusinessCategoriesOptions',
      'sortBusinessCategoriesList',
      'sortVenuesByCategory'
      ];

    Object.keys(_sharedModules).forEach((_moduleName) => {
      if (requiredModulesNames.indexOf(_moduleName) > -1) {
        this[_moduleName] = _sharedModules[_moduleName].bind(this);
      }      
    });
  }
  componentDidMount() {
    this.mounted = true;
    this.singleLocationMode = true;
    let activeTab = 'general';
    const { tabsUrlParams } = this.state;
    let paramFromUrl = {};
    if (window.location.search) {
      paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (paramFromUrl.tab && tabsUrlParams.indexOf(paramFromUrl.tab) > -1) {
        activeTab = paramFromUrl.tab;
      }
    }
    this.setState({
      activeTab
    }, () => {
      this.getOrgList(paramFromUrl);
      this.setDocumentTitle();
    });
  }
  componentWillUnmount(){
    this.mounted = false;
  }
  setNewUrlParams = ({ tab, org, venue, businessType, businessCategory, wrongParamsAfterCheck = false }) => {
    const { orgList, filterByLocationType, targetOrgId, urlAnalized } = this.state;

    const oldParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const newUrlParams = {
      tab: tab || this.state.activeTab,
      filterByType: !urlAnalized && oldParams.filterByType ? oldParams.filterByType : filterByLocationType
    };
    if (!wrongParamsAfterCheck) {
      if (!filterByLocationType) {
        let orgUuid = oldParams.org;
        let venueUuid = oldParams.venue;
        if (org) {
          if (org === -1) {
            orgUuid = 'all';
            venueUuid = null;
            this.singleLocationMode = false;
          } else if (Array.isArray(orgList)) {
            orgList.forEach((organisation) => {
              if (organisation.id === org) {
                orgUuid = organisation.uuid;
                venueUuid = null;
              }
            })
          }
        }
        if (venue) {
          if (Array.isArray(orgList)) {
            orgList.forEach((organisation) => {
              if (venue === -1) {
                if (organisation.id === targetOrgId) {
                  orgUuid = organisation.uuid;
                  venueUuid = 'all';
                  this.singleLocationMode = false;
                }
              } else if (organisation.venuesIds.indexOf(venue) > -1) {
                orgUuid = organisation.uuid;
                organisation.venues.forEach((venueObj) => {
                  if (venueObj.id === venue) {
                    venueUuid = venueObj.uuid;
                  }
                })
              }
            })
          } 
        }
        if (orgUuid) newUrlParams.org = orgUuid;
        if (venueUuid) newUrlParams.venue = venueUuid;
      } else {
        const { targetBusinessTypeId, businessInfoSorted } = this.state;
        let typeUuid = oldParams.businessType;
        let categoryUuid = oldParams.businessCategory;
        if (businessType) {
          if (Array.isArray(businessInfoSorted)) {
            businessInfoSorted.forEach((business_type) => {
              if (business_type.id === businessType) {
                typeUuid = business_type.uuid;
                categoryUuid = null;
              }
            })
          }
        }
        if (businessCategory) {
          if (Array.isArray(businessInfoSorted)) {
            businessInfoSorted.forEach((business_type) => {
              if (businessCategory === -1) {
                if (business_type.id === targetBusinessTypeId) {
                  typeUuid = business_type.uuid;
                  categoryUuid = 'all';
                  this.singleLocationMode = false;
                }
              } else if (business_type.categoriesIds.indexOf(businessCategory) > -1) {
                typeUuid = business_type.uuid;
                business_type.business_categories.forEach((categoryObj) => {
                  if (categoryObj.id === businessCategory) {
                    categoryUuid = categoryObj.uuid;
                  }
                })
              }
            })
          } 
        }
        if (typeUuid) newUrlParams.businessType = typeUuid;
        if (categoryUuid) newUrlParams.businessCategory = categoryUuid;
      }
    }
    const newUrlParamsString = qs.stringify(newUrlParams);
    if (newUrlParamsString) this.props.history.push(`?${newUrlParamsString}`);
    if (wrongParamsAfterCheck) {
      this.setState({
        urlAnalized: true
      })
    }
  }
  onOrgSelectChange = (targetOrgId, imitation = false) => {
    // change url params
    if (!imitation) {
      this.setNewUrlParams({ org: targetOrgId });
    }
    this.singleLocationMode = targetOrgId !== -1;
    this.setState({
      targetOrgId,
      targetVenueId: targetOrgId === -1 ? -1 : null
    }, this.sortVenuesList)
  }
  onBusinessTypeChange = (targetBusinessTypeId) => {
    this.setNewUrlParams({ businessType: targetBusinessTypeId })
    this.setState({
      targetBusinessTypeId,
      targetBusinessCategoryId: null,
      venuesCategoriesList: []
    }, this.sortBusinessCategoriesList)
  }
  setDocumentTitle = (activeTab) => {
    const tabList = this.getTabList();
    let tabAdditive = '';
    tabList.forEach((tab) => {
      if (tab.key === activeTab) {
        tabAdditive = `${tab.tab} | `;
      }
    });
    document.title = `Reports | ${tabAdditive} ${Settings.title}`;
  }
  selectDate = (type) => {
    const { timezone } = this.state;
    this.setState({
      general_date_type: type,
      rangePickerValue: getTimeDistance(type, timezone),
      childShouldUpdateDateChanged: true,
      shouldUpdateTurnOffOverrides: true
    })
  }
  isActive(type) {
    const { rangePickerValue, timezone } = this.state;
    const value = getTimeDistance(type, timezone);
    if (!rangePickerValue[0] || !rangePickerValue[1]) {
      return '';
    }
    if (
      rangePickerValue[0].isSame(value[0], 'day') &&
      rangePickerValue[1].isSame(value[1], 'day')
    ) {
      return 'ant-btn-primary';
    }

    return '';
  }
  handleRangePickerChange = (rangePickerValue) => {
    const { timezone } = this.state;
    rangePickerValue[0].tz(timezone).startOf('day');
    rangePickerValue[1].tz(timezone).endOf('day');
    this.setState({
      rangePickerValue,
      childShouldUpdateDateChanged: true
    })
  }
  onTabChange = (key, type) => {
    //const { targetBranchId, targetRestaurantId } = this.state;
    this.setDocumentTitle(key);
    this.setNewUrlParams({ tab: key })
    this.setState({
      [type]: key,
      //reportsData: this.getEmptyReportObj(key)
    })
  }
  getTabList = () => {
    return [
      { key: 'general', tab: 'General' },
      { key: 'trends', tab: 'Trends' },
      { key: 'survey', tab: 'Survey results' },
      { key: 'money', tab: 'Money' },  
      { key: 'compare', tab: 'Compare' },
      { key: 'maintenance', tab: 'Maintenance' }
    ]
  }
  changeFilterType = (fromSwitchLocation = false) => {
    const newfilterByLocationType = !fromSwitchLocation ? !this.state.filterByLocationType : this.state.filterByLocationType;
    this.setState({
      filterByLocationType: newfilterByLocationType,
      targetOrgId: null,
      targetVenueId: null,
      targetBusinessCategoryId: null,
      targetBusinessTypeId: null
    }, () => {
      const urlObj = fromSwitchLocation ? { wrongParamsAfterCheck: true } : {};
      this.setNewUrlParams(urlObj) 
    })
  }
  loadInfoCustomLocationChoice = () => {
    const { venuesIds, orgsIds } = this.state;
    if (!venuesIds.length || !orgsIds.length) {
      Modal.error({
        className: 'small-modal-warnings',
        title: 'Error!',
        content: 'Choose locations first.',
        autoFocusButton: null
      })
      return;
    }
    this.singleLocationMode = false;
    this.setState({
      updateLocationMultiChange: true,
      customLocationsChoiceActivated: true
    })
  }
  render() {
    const { 
      venuesCategoriesList,
      targetBusinessCategoryId,
      targetBusinessTypeId,
      filterByLocationType,
      initialDataLoaded,
      orgListFetching,
      targetOrgId,
      targetVenueId,
      rangePickerValue,
      activeTab,
      childShouldUpdateDateChanged,
      venuesList,
      urlAnalized,
      general_date_type,
      shouldUpdateTurnOffOverrides,
      overrideLocationChoiceGlobal,
      updateLocationMultiChange,
      orgsIds,
      venuesIds,
      customLocationsChoiceActivated
    } = this.state;

    const showTotalButton = true;
    const showMonthButton = ['survey', 'trends'].indexOf(activeTab) === -1;
    const showWeekButton = ['survey', 'trends'].indexOf(activeTab) === -1;
    const showYearButton = ['survey', 'trends'].indexOf(activeTab) === -1;

    return (
      <Content className="reports">
        <Row className="select-container">
          <Col sm={16} xs={24}>
            <Row>
              <h2>Reports</h2>
            </Row>
            { !filterByLocationType ? 
            <Col span={24}>
              <Row className="select-container" style={{marginBottom: '8px'}}>
                <span style={{fontWeight: 500, fontSize: '14px'}}>Choose list of locations</span>
                <Switch
                  style={{margin: 'auto 6px'}}
                  checked={overrideLocationChoiceGlobal} 
                  onChange={(value) => { 
                    this.setState({ overrideLocationChoiceGlobal: value, customLocationsChoiceActivated: false })
                    this.changeFilterType(true)
                  }} 
                  size={"small"}
                  />
              </Row>
            </Col> : null }
            { !overrideLocationChoiceGlobal ?
              <Row>
              { !filterByLocationType &&
                <Col sm={6} xs={24}>
                  <Select 
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      const name = option && option.props && option.props.children ? option.props.children : '';
                      return name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    className="org-select" 
                    loading={!initialDataLoaded || orgListFetching || !urlAnalized} 
                    value={targetOrgId || undefined} 
                    placeholder="Organization" 
                    onChange={(value) => this.onOrgSelectChange(value)}>
                    {this.renderOrganisationOptions()}
                  </Select>
                </Col> }
              { !filterByLocationType &&
                <Col sm={6} xs={24} style={{display: targetOrgId === -1 ? 'none' : ''}}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      const name = option && option.props && option.props.children ? option.props.children : '';
                      return name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }} 
                    className="venue-select" disabled={!targetOrgId || !urlAnalized} value={targetVenueId  || undefined}  placeholder="Venue" 
                    onChange={(value) => {
                      this.singleLocationMode = value !== -1;
                      this.setState({targetVenueId: value})
                      this.setNewUrlParams({ venue: value })
                    }}>
                    {this.renderVenuesOptions()}
                  </Select>
                </Col> }
              { filterByLocationType && 
                <Col sm={6} xs={24}>
                  <Select onChange={(value) => this.onBusinessTypeChange(value)} value={targetBusinessTypeId || undefined} loading={!initialDataLoaded || orgListFetching || !urlAnalized} className="venue-select" placeholder="Type of business">
                    {this.renderBusinessTypesOptions()}
                  </Select>
                </Col> }
              { filterByLocationType &&
                <Col sm={6} xs={24}>
                  <Select onChange={(value) => {
                    this.sortVenuesByCategory(value);
                    this.singleLocationMode = false;
                    this.setNewUrlParams({ businessCategory: value })
                  }} value={targetBusinessCategoryId || undefined} disabled={!targetBusinessTypeId || !urlAnalized}  loading={!initialDataLoaded || orgListFetching || !urlAnalized} className="venue-select" placeholder="Type of Location">
                    {this.renderBusinessCategoriesOptions()}
                  </Select>
                </Col> }
                { urlAnalized ?
                  <Col className="mob-top-margin" sm={4} xs={24}>
                  <Button onClick={() => { this.changeFilterType() }} type="primary">{ filterByLocationType ? 'Filter by Location' : 'Filter by business type'}</Button>
                </Col> : null }
            </Row> :  
            <Row>
              <Col span={10}>
                <Select 
                  showSearch
                  allowClear={true}
                  mode={"multiple"}
                  onClear={() => this.setState({ customLocationsChoiceActivated: false })}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    const name = option && option.props && option.props.children ? option.props.children : '';
                    return name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  className="org-select" 
                  loading={!initialDataLoaded || orgListFetching || !urlAnalized} 
                  value={orgsIds} 
                  placeholder="Organizations" 
                  onChange={(value) => {
                    const newState = { orgsIds: value };
                    if (!value || !value.length) {
                      newState.venuesIds = [];
                    }
                    this.setState(newState, () => this.sortVenuesList()) 
                  }}>
                    {this.renderOrganisationOptions(false)}
                </Select>
              </Col>
              <Col span={10}>
                <Select 
                  showSearch
                  allowClear={true}
                  mode={"multiple"}
                  onClear={() => this.setState({ customLocationsChoiceActivated: false })}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    const name = option && option.props && option.props.children ? option.props.children : '';
                    return name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  className="org-select" 
                  loading={!initialDataLoaded || orgListFetching || !urlAnalized} 
                  value={venuesIds} 
                  placeholder="Venues"
                  disabled={!orgsIds.length || !urlAnalized}
                  onChange={(value) => {
                    const venuesIds = value.indexOf(-1) > -1 ? [-1] : value;
                    this.setState({ venuesIds }) 
                  }}>
                    {this.renderVenuesOptions()}
                </Select>
              </Col>
              <Col span={4}>
                <Button type="primary" onClick={this.loadInfoCustomLocationChoice}>Confirm</Button>
              </Col>
            </Row> }
          </Col>
          <Col sm={8} xs={24} style={{marginTop: 'auto'}}>
            <Row>
              <div className="date-select">
                <React.Fragment>
                  <div className="date-container-btns">
                    { showWeekButton &&
                    <button className={"ant-btn "+this.isActive('week')} onClick={() => this.selectDate('week')}>
                      <div>Week</div>
                    </button> }
                    { showMonthButton &&
                    <button className={"ant-btn "+this.isActive('month')} onClick={() => this.selectDate('month')}>
                      <div>Month</div>
                    </button> }
                    { showYearButton &&
                    <button className={"ant-btn "+this.isActive('year')} onClick={() => this.selectDate('year')}>
                      <div>Year</div>
                    </button> }
                    { showTotalButton &&
                    <button className={"ant-btn "+this.isActive('total')} onClick={() => this.selectDate('total')}>
                      <div>Total</div>
                    </button> }
                  </div>
                </React.Fragment>
              </div>
            </Row>
          </Col>
        </Row>
        { urlAnalized && 
        <Card
          className="main-card"
          tabList={this.getTabList()}
          activeTabKey={activeTab}
          onTabChange={(key) => { this.onTabChange(key, 'activeTab'); }}
        >
          { (overrideLocationChoiceGlobal && customLocationsChoiceActivated && orgsIds.length && venuesIds.length) || (targetOrgId && targetVenueId && !filterByLocationType) || (filterByLocationType && targetBusinessTypeId && targetBusinessCategoryId && venuesCategoriesList.length) ?
            <div>
            { activeTab === 'general' && 
              <GeneralReports 
                history={this.props.history} 
                singleLocationMode={this.singleLocationMode}
                filterByLocationType={filterByLocationType}
                targetOrgId={targetOrgId}
                targetVenueId={targetVenueId}
                targetBusinessTypeId={targetBusinessTypeId}
                targetBusinessCategoryId={targetBusinessCategoryId}
                defaultRangePickerValue={rangePickerValue}
                general_date_type={general_date_type}
                venuesCategoriesList={venuesCategoriesList}
                fullVenuesList={venuesList}
                // rangePicker change handle
                childShouldUpdateDateChanged={childShouldUpdateDateChanged}
                turnOffShouldUpdate={() => this.setState({ childShouldUpdateDateChanged: false })}
                shouldUpdateTurnOffOverrides={shouldUpdateTurnOffOverrides}
                turnOffShouldUpdateTimeOverride={() => this.setState({ shouldUpdateTurnOffOverrides: false })}
                // custom locations choice
                overrideLocationChoiceGlobal={overrideLocationChoiceGlobal}
                updateLocationMultiChange={updateLocationMultiChange}
                turnOffShouldUpdateMultiLocations={() => this.setState({ updateLocationMultiChange: false })}
                orgsIds={orgsIds}
                venuesIds={venuesIds}
            />}
            { activeTab === 'survey' && 
              <SurveyReports 
                history={this.props.history}
                singleLocationMode={this.singleLocationMode}
                filterByLocationType={filterByLocationType}
                targetOrgId={targetOrgId}
                targetVenueId={targetVenueId}
                targetBusinessTypeId={targetBusinessTypeId}
                targetBusinessCategoryId={targetBusinessCategoryId}
                defaultRangePickerValue={rangePickerValue}
                general_date_type={general_date_type}
                venuesCategoriesList={venuesCategoriesList}
                fullVenuesList={venuesList}
                // rangePicker change handle
                childShouldUpdateDateChanged={childShouldUpdateDateChanged}
                turnOffShouldUpdate={() => this.setState({ childShouldUpdateDateChanged: false })}
                shouldUpdateTurnOffOverrides={shouldUpdateTurnOffOverrides}
                turnOffShouldUpdateTimeOverride={() => this.setState({ shouldUpdateTurnOffOverrides: false })}
                // custom locations choice
                overrideLocationChoiceGlobal={overrideLocationChoiceGlobal}
                updateLocationMultiChange={updateLocationMultiChange}
                turnOffShouldUpdateMultiLocations={() => this.setState({ updateLocationMultiChange: false })}
                orgsIds={orgsIds}
                venuesIds={venuesIds}
            />}
            { activeTab === 'trends' && 
              <TrendsReports 
                history={this.props.history}
                singleLocationMode={this.singleLocationMode}
                filterByLocationType={filterByLocationType}
                targetOrgId={targetOrgId}
                targetVenueId={targetVenueId}
                targetBusinessTypeId={targetBusinessTypeId}
                targetBusinessCategoryId={targetBusinessCategoryId}
                defaultRangePickerValue={rangePickerValue}
                general_date_type={general_date_type}
                venuesCategoriesList={venuesCategoriesList}
                fullVenuesList={venuesList}
                // rangePicker change handle
                childShouldUpdateDateChanged={childShouldUpdateDateChanged}
                turnOffShouldUpdate={() => this.setState({ childShouldUpdateDateChanged: false })}
                shouldUpdateTurnOffOverrides={shouldUpdateTurnOffOverrides}
                turnOffShouldUpdateTimeOverride={() => this.setState({ shouldUpdateTurnOffOverrides: false })}
                // custom locations choice
                overrideLocationChoiceGlobal={overrideLocationChoiceGlobal}
                updateLocationMultiChange={updateLocationMultiChange}
                turnOffShouldUpdateMultiLocations={() => this.setState({ updateLocationMultiChange: false })}
                orgsIds={orgsIds}
                venuesIds={venuesIds}
            />}

          { ['money', 'compare', 'maintenance'].indexOf(activeTab) > -1 && <Empty/> }
            </div>
          : <Empty/> }
        </Card> }
        { !urlAnalized && 
          <Card
            className="main-card"
            tabList={this.getTabList()}
            activeTabKey={activeTab}
          >
            <div>
              <Skeleton
                active={true}
                loading={true}
              />
            </div>
          </Card>
        }
      </Content>
    )
  }
};

export default Reports;
