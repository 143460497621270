import { combineReducers } from 'redux'
// import { routerReducer } from 'react-router-redux'
import { connectRouter } from 'connected-react-router'


import { reducer as authReducers } from 'screens/Login/data/reducer';
import { reducer as sessionsReducers } from 'screens/Dashboard/screens/Sessions/data/reducer';
import { reducer as transactionsReducers } from 'screens/Dashboard/screens/Transactions/data/reducer';
import { reducer as ticketsReducers } from 'screens/Dashboard/screens/Tickets/data/reducer';
import { reducer as organisationsReducers } from 'screens/Dashboard/screens/Organisations/data/reducer';
import { reducer as electronRedusers } from 'screens/Dashboard/screens/Electrons/data/reducer';
import { reducer as promocodesRedusers } from 'screens/Dashboard/screens/Promocodes/data/reducer';
import { reducer as singleSessionReducers, closeSessionReducer } from 'screens/Dashboard/screens/Sessions/components/SingleSession/data/reducer';
import { reducer as electronHistoryReducers } from 'screens/Dashboard/components/ElectronHistory/data/reducer';
import * as userRedusers from 'screens/Dashboard/components/SingleUserCard/modules';

export default (history) => combineReducers({
  router: connectRouter(history),
  auth: authReducers,
  sessions: sessionsReducers,
  singleSession: singleSessionReducers,
  electronHistory: electronHistoryReducers,
  closeSession: closeSessionReducer, 
  transactions: transactionsReducers,
  electrons: electronRedusers,
  organisations: organisationsReducers,
  promocodes: promocodesRedusers,
  tickets: ticketsReducers,
  ...userRedusers
})