import React from 'react';
import { Modal, Button, Row, Table } from 'antd';

class Contacts extends React.Component {
  
  state = {
  	filteredInfo: {}
  }

  columns = () => {
    return [{
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Office phone',
      dataIndex: 'office_phone',
      key: 'office_phone'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Related to',
      dataIndex: 'relation',
      key: 'relation'
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note'
    },
    {
      title: 'Tag',
      dataIndex: 'tag',
      key: 'tag'
    },
    {
    	title: 'Actions',
    	dataIndex: 'action',
    	key: 'action',
    	render: (text, record) => (
    	  <Button size="small" onClick={() => {this.props.addContact(record.key, record.name, record.phone, record.email, record.note, record.office_phone, record.tag)}} >
    	    Add
    	  </Button>
    	)

    }];
  }

  getData = () => {
  	const { ignoredContacts } = this.props;
  	let { venuesContacts } = this.props.contacts;

  	venuesContacts = venuesContacts.flatMap((item,index) => {
  		let contacts = [];
  		if (item.contacts.length > 0) {
  			item.contacts.forEach(contact => {
  				contact.type = 'Venue'
  				contact.relation = item.title;
  				contact.owner_id = item.venue_id;
   				if (ignoredContacts.indexOf(contact.id) === -1) {
  					contacts.push(contact)
  				}
  			})
  		}
  		return contacts;
  	})

  	return venuesContacts.map((item, index) => {
  		return {
  			key: item.id,
  			name: item.name,
  			phone: item.phone,
  			email: item.email,
  			type: item.type,
  			relation: item.relation,
  			owner_id: item.owner_id,
  			note: item.note,
  			tag: item.tag
  		}
  	})

  }
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters
    })
  }

  render() {
  	const { visible, handleClose } = this.props;

    return (
      <Modal
        style={{ top: 20 }}
        visible={ visible }
        width={1200}
        onOk={ handleClose }
        onCancel={ handleClose }
        footer={false}
      >
        <h2>Contacts related to the organisation</h2>
        <Row/>
         <div>
           <Table
            columns={ this.columns() }
            loading={ false }
            dataSource={ this.getData() }
            pagination={{ pageSize: 7 }}
            onChange={this.handleTableChange}
           />
        </div>
      </Modal>
    )
  }
};

export default Contacts;