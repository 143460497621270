import Auth from 'services/utils/Auth'

// Actions
const STATUS_USER_REQUEST = 'singleUser/STATUS_USER_REQUEST'
const STATUS_USER_SUCCESS = 'singleUser/STATUS_USER_SUCCESS'
const STATUS_USER_FAILURE = 'singleUser/STATUS_USER_FAILURE'

const initialState = {
  isFetching: false,
  quote: ''
};
// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STATUS_USER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case STATUS_USER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: '',
        quote: action.response
      })
    case STATUS_USER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message
      })
    default:
      return state
  }
}
export default reducer;

// Action Creators
export const requestStatus = (params) => {
  return {
    type: STATUS_USER_REQUEST,
    isFetching: true,
    params
  }
}

export const receiveStatus = (response) => {
  return {
    type: STATUS_USER_SUCCESS,
    isFetching: false,
    response
  }
}

export const errorStatus = (message) => {
  return {
    type: STATUS_USER_FAILURE,
    isFetching: false,
    message: message
  }
}

export const changeUserStatus = (params, successAction) => {
  let token = Auth.getToken() || null
  let config = {
    method: 'PUT',
    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type':'application/x-www-form-urlencoded' },
    body: `status=${params.status}`
  }
  return dispatch => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestStatus(params))
    return fetch(`/api/v1/user/status/${params.userId}`, config)
      .then(response => response.json())
      .then(response =>  {
        console.log(response);
        if (!response.success) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(errorStatus(response.message))
          return Promise.reject(response)
        } else {
          // Dispatch the success action
          successAction();
          dispatch(receiveStatus(response))
        }
      }).catch(err => console.log("Error: ", err))
  }
}