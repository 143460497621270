import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Settings from 'services/config/Settings';
import { CloseCircleOutlined } from '@ant-design/icons';
import {
  Table,
  Layout,
  Input,
  Row,
  Col,
  Button,
  Tooltip,
  Tag,
  Select,
  Modal,
  message,
} from 'antd';
import { Link } from 'react-router-dom';
import { fetchSecretQuote } from './data/actions'
import fetchJSON from 'services/utils/fetchJSON';
import SmsAuthenticationPrompt from 'screens/Dashboard/components/SmsAuthenticationPrompt/SmsAuthenticationPrompt';
import PricingSettings from 'screens/Dashboard/components/PricingSettings/PricingSettings';

import AddOrg from './components/SingleOrg/AddOrg';
import './styles.less';

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

class Organisations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    filteredInfo: {},
    sortedInfo: {},
    searchText: '',
    filtered: false,
    data: [],
    visibleAddOrg: false,
    typeFilter: [],
    detailedTable: false,
    businessTypesLoaded: false,
    selectedRowKeys: [],
    selectedRowValues: [],
    visibleSmsAuthenticationPrompt: false,
    smsAuthenticationAction: '',
    doAfterSmsAuthentication: false,
    visiblePricingSettings: false,
    currencyCode: '',
    venuesToChangePricing: []
    };
    this.totalNumberTable = 0;
    this.totalNumberInterval = null;
  }
  componentDidMount() {
    document.title = `Organisations | ${Settings.title}`;
    this.props.dispatch(fetchSecretQuote('/organisation/list'))
    this.getBusinessInfo()
    this.setState({
      totalNumberTable: this.props.totalNumberTable || 0
    })
    this.props.pagination.showTotal = (total) => {
      return <p id="totalNumber" style={{display: 'none'}}>{this.renderTotal(total)}</p>
    } 
  }
  componentWillUnmount(){
    clearInterval(this.totalNumberInterval);
  }
  trackTotalNumberValue = () => {
    clearInterval(this.totalNumberInterval);
    const { totalNumberTable } = this.state;
    if (totalNumberTable !== this.totalNumberTable) {
      this.setState({
        totalNumberTable: this.totalNumberTable
      });
    }
    this.totalNumberInterval = null;
  }
  renderTotal = (total) => {
   this.totalNumberTable = total;
   const { totalNumberTable } = this.state;
   if (totalNumberTable !== this.totalNumberTable && !this.totalNumberInterval) {
    this.totalNumberInterval = setInterval(this.trackTotalNumberValue, 100); 
   }
   return total;
  }
  lessThanAgo = (date) => {
    if (!date) return false;
    const _AGO = 1000 * 60 * 3;
    let anMinuteAgo = Date.now() - _AGO;
    return date < anMinuteAgo;
  }
  getBusinessInfo =  () => {
    fetchJSON('/api/v3/general/org-types', {
      method: 'get'
    }).then(response => {
      if (response.success) {
        let businessTypes = {};
        const typeFilter = [];
        const business_types = response.data.business_types;
        if (business_types) {
          business_types.forEach((type) => {
            businessTypes[type.id] = type.title;
            typeFilter.push({ text: type.title, value: type.title})
          })
        }
        this.setState({
          business_types: businessTypes,
          businessTypesLoaded: true,
          typeFilter
        });
      }
     }).catch(error => {
        console.log(error);
     });
  }
  isEllipsisActive = (e) => {
    if (e) {
      return (e.offsetWidth < e.scrollWidth)
    }
    else {
      return false
    }
  }
  columns = () => {
    let { sortedInfo, filteredInfo, typeFilter } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    let columns = [];
    const titleItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Organization</p>
          </div>
        )
      },
      dataIndex: 'name',
      key: 'name',
      width: 120,
      sorter: (a, b) => a.name > b.name ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`otgTitleText_${record.key}}`)) ? record.name : ''}>
            <Link to={`/organisations/${record.id}`}> 
              <p id={`otgTitleText_${record.key}}`} style={{margin:0, width: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                {record.name}
              </p>
            </Link>
          </Tooltip>
        </div>
      )
    };
    const nebulaRelationItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Nebula relation</p>
          </div>
        )
      },
      dataIndex: 'nebula_relation',
      key: 'nebula_relation',
      width: 90,
      render: (text, record) => {
        if (!record.console_creator) return null;
        return (<span style={{fontSize: '13px'}}>
          {record.related_org ? 
            <React.Fragment>
              Org: <Link to={"/organisations/"+record.related_org.id}>{record.related_org.name}</Link>
              <br/>
            </React.Fragment>
            : null
          }
          Creator: {record.console_creator} 
        </span>);
      },
    };
    const typeOfOrgItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Type of Org</p>
          </div>
        )
      },
      dataIndex: 'partner_type',
      key: 'partner_type',
      width: 120,
      align: 'center',
      filters:  [
        { text: 'Location Owner', value: 'Location Owner' },
        { text: 'Partner', value: 'Partner' },
        { text: 'Distributor', value: 'Distributor' },
        { text: 'Operator', value: 'Operator' },
        { text: 'System integrator', value: 'System integrator' },
        { text: 'Sponsor', value: 'Sponsor' }
      ],
      filteredValue: filteredInfo.partner_type || null,
      onFilter: (value, record) => {
        return record.partner_type === value
      }
    };
    const businessTypeItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Business type</p>
          </div>
        )
      },
      dataIndex: 'businessType',
      key: 'businessType',
      width: 110,
      align: 'center',
      filters: typeFilter,
      filteredValue: filteredInfo.businessType || null,
      onFilter: (value, record) => {
        return record.businessType === value
      }
    };
    const warehouseItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Warehouse</p>
          </div>
        )
      },
      dataIndex: 'warehouse',
      key: 'warehouse',
      align: 'center',
      width: 130,
      render: (text, record) => {
        if (!record.directoryRender) return null;
        return (<span>{record.directoryRender}</span>);
      },
    };
    const locationsItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Locations</p>
          </div>
        )
      },
      dataIndex: 'venues',
      key: 'venues',
      align: 'center',
      width: 30,
      sorter: (a, b) => a.venues > b.venues ? -1 : 1,
      sortOrder: sortedInfo.columnKey === 'venues' && sortedInfo.order,
      render: (text, record) => (
        <div style={{whiteSpace: 'nowrap', margin: 0}}>
          {record.venues > 0 ? 
            <Link to={`/organisations/${record.id}?venues=1`}>
              {record.venues}
            </Link> : <p style={{margin: 0}}>{record.venues}</p>}
        </div>
      )
    }
    const nebulaUsersItem = {
      title: 'Nebula users',
      dataIndex: 'nebulaUsers',
      key: 'nebulaUsers',
      align: 'center',
      width: 30,
      sorter: (a, b) => a.nebulaUsers > b.nebulaUsers ? -1 : 1,
      sortOrder: sortedInfo.columnKey === 'nebulaUsers' && sortedInfo.order,
      render: (text, record) => (
        <div style={{whiteSpace: 'nowrap', margin: 0}}>
          <Link to={`/organisations/${record.id}?appsUsers=1`}>
            {record.nebulaUsers}
          </Link>
        </div>
      )
    };
    const ticketsItem = {
      title: 'Tickets',
      dataIndex: 'tickets',
      key: 'tickets',
      align: 'center',
      width: 30,
      sorter: (a, b) => a.tickets > b.tickets ? -1 : 1,
      sortOrder: sortedInfo.columnKey === 'tickets' && sortedInfo.order,
      render: (text, record) => (
        <div style={{whiteSpace: 'nowrap', margin: 0}}>
        { record.tickets > 0 ? 
          <Link to={`/tickets?org=${record.id}`}>
            {record.tickets}
          </Link> : <p style={{margin: 0}}>0</p> }
        </div>
      )
    };
    const contactsItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Contact</p>
          </div>
          )
      },
      dataIndex: 'contact',
      key: 'contact',
      width: 40,
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`contactText_${record.key}}`)) ? <ul style={{listStyle: 'decimal', margin: 0}}>{record.contactsList}</ul> : ''}>
            <p id={`contactText_${record.key}}`} style={{margin:0, width: '40px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              {record.contact}
            </p>
          </Tooltip>
        </div>
      )
    };
    const reportsItem = {
      title: 'Reports',
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      width: 40,
      render: (text, record) => (
        <div style={{whiteSpace: 'nowrap', margin: 0}}>
          <Link to={`/organisations/${record.id}?statistics=1`}>
            Open
          </Link>
        </div>
      )
    };
    const statusItem = {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Active', value: 'Active' },
        { text: 'Inactive', value: 'Inactive' },
        { text: 'Suspended', value: 'Suspended' },
        { text: 'Onboarding', value: 'Onboarding' },
      ],
      width: 60,
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => {
        return record.status.includes(value)
      },
      render: (text, record) => (
        <div style={{whiteSpace: 'nowrap', margin: 0}}>
          <p style={{margin: 0, color: record.statusColor}}>
            {record.status}
          </p>
        </div>
      )
    };
    const covidItem = {
      title: 'Active covid19',
      dataIndex: 'covid',
      key: 'covid',
      align: 'center',
      width: 60,
      filters: [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' }
      ],
      filteredValue: filteredInfo.covid || null,
      onFilter: (value, record) => {
        return record.covid === value
      },
      render: (text, record) => (
        <div style={{whiteSpace: 'nowrap', margin: 0}}>
          <p style={{margin: 0, color: record.covid === 'Yes' ? 'green' : 'red'}}>
            {record.covid}
          </p>
        </div>
      )
    };
    const surveyItem = {
      title: 'Survey',
      dataIndex: 'survey',
      key: 'survey',
      align: 'center',
      width: 60,
      filters: [
        { text: 'On', value: 'On' },
        { text: 'Off', value: 'Off' }
      ],
      filteredValue: filteredInfo.survey || null,
      onFilter: (value, record) => {
        return record.survey === value
      },
      render: (text, record) => (
        <div style={{whiteSpace: 'nowrap', margin: 0}}>
          <p style={{margin: 0, color: record.survey === 'On' ? 'green' : 'red'}}>
            {record.survey}
          </p>
        </div>
      )
    };
    const delayNotificationsItem = {
      title: 'Delay notifications',
      dataIndex: 'delay_notifications',
      key: 'delay_notifications',
      align: 'center',
      width: 60,
      filters: [
        { text: 'On', value: 'On' },
        { text: 'Off', value: 'Off' }
      ],
      filteredValue: filteredInfo.delay_notifications || null,
      onFilter: (value, record) => {
        return record.delay_notifications === value
      },
      render: (text, record) => (
        <div style={{whiteSpace: 'nowrap', margin: 0}}>
          <p style={{margin: 0, color: record.delay_notifications === 'On' ? 'green' : 'red'}}>
            {record.delay_notifications}
          </p>
        </div>
      )
    };
    columns = [titleItem, nebulaRelationItem, typeOfOrgItem, businessTypeItem, warehouseItem, locationsItem, nebulaUsersItem, ticketsItem, contactsItem, reportsItem, statusItem, covidItem, surveyItem, delayNotificationsItem];
    return columns;
  }
  getData = () => {
    const data = [];
    if (!this.props.quote) return data;
    let organisations = this.props.quote.data;

    if (this.state.filtered) {
      organisations =  this.state.data;
    }
    const { business_types } = this.state;
    organisations.forEach((organisation, i) => {
      let statusColor = 'green';
      let status = 'Active';
      if (organisation.status === 0) {
        status = 'Inactive';
        statusColor = 'blue';
      }
      if (organisation.status === 2) {
        status = 'Suspended';
        statusColor = 'red';
      }
      if (organisation.status === 3) {
        status = 'Onboarding';
        statusColor = 'black';
      }
      const directoryRender = [];
      const warehouse = _.get(organisation, 'directories[0].id');
      if (organisation.directories && organisation.directories.length) {
        organisation.directories.forEach((relation, k) => {
          if (relation.directory) {
            directoryRender.push(<Link key={k} to={`/organisations/${organisation.id}?warehouses=1`}>
              <Tag color="magenta" style={{margin: '3px'}}>{relation.directory.title}</Tag></Link>)
          }
        })
      }
      let consoleCreator = '';
      let related_org = '';
      if (organisation.console_creator) {
        //console.log('organisation.console_creator', organisation.console_creator);
        
        const { console_creator } = organisation;
        
        consoleCreator = `#${console_creator.id}. ${console_creator.first_name}`;
        related_org = _.get(organisation, 'console_creator.related_org');
        //console.log('related_org', related_org);
      }
      let venues = 0;
      const venueUuids = [];
      let businessTypeIds = {};
      let currencyCodes = [];
      if (Array.isArray(organisation.venues) && organisation.venues.length) {
        organisation.venues.forEach((item) => {
          if (item.venues) {
            venues += 1;
            if (venueUuids.indexOf(item.venues.uuid) === -1) {
              venueUuids.push(item.venues.uuid)
            }
            if (item.venues.currency_code) {
              if (currencyCodes.indexOf(item.venues.currency_code) === -1) {
                currencyCodes.push(item.venues.currency_code);
              }             
            }
            if (item.venues.business_type_id) {
              if (!businessTypeIds[item.venues.business_type_id]) {
                businessTypeIds[item.venues.business_type_id] = 0;
              }
              businessTypeIds[item.venues.business_type_id] += 1;
            }
          }
        });
      }
      let maxCountType = 0;
      let businessTypeKey = '';
      Object.keys(businessTypeIds).forEach((key) => {
        if (businessTypeIds[key] > maxCountType) {
          maxCountType = businessTypeIds[key];
          businessTypeKey = key;
        }
      })
      const businessType = business_types[businessTypeKey] ? business_types[businessTypeKey] : '';

      let nebulaUsers = organisation.console_users ? organisation.console_users.length : 0;
      const nebulaUsersIds = [];
      if (nebulaUsers) {
        organisation.console_users.forEach((user) => {
          if (nebulaUsersIds.indexOf(user.id) === -1) {
            nebulaUsersIds.push(user.id)
          }
        })
      }
      let contact = '';
      let contactsList = [];
      const newContactsList = [];
      if (organisation.contacts_list && organisation.contacts_list.length > 0) {
        let count = 1;
        organisation.contacts_list.forEach((contact) => {
          const contactArr = [];
          if (contact.contact) {
            if (contact.contact.name) {
              contactArr.push(contact.contact.name);
            }
            if (contact.contact.phone) {
              contactArr.push(contact.contact.phone);
            }
            if (contact.contact.email) {
              contactArr.push(contact.contact.email);
            }
          }
          const fullContact = contactArr.join(', ');
          if (fullContact && newContactsList.indexOf(fullContact) === -1) {
            newContactsList.push(fullContact);
          }
        })
        if (newContactsList && newContactsList.length > 0) {
          contact = newContactsList[0];
          newContactsList.forEach((contact) => {
            contactsList.push(<li key={count}>{contact}</li>)
            count += 1;       
          })       
        }
      }
      data.push({
        key: i,
        id: organisation.id,
        name: organisation.name,
        partner_type: organisation.partner_type ? organisation.partner_type.title : '',
        address: organisation.address,
        status: status,
        statusColor,
        venues,
        venueUuids,
        business_name: organisation.business_name,
        console_creator: consoleCreator,
        related_org,
        directoryRender,
        warehouse: warehouse || '-',
        nebulaUsers,
        nebulaUsersIds,
        tickets: organisation.ticketsAmount,
        covid: organisation.active_while_corona ? 'Yes' : 'No',
        survey: organisation.survey_before_release ? 'On' : 'Off',
        surveyStatus: organisation.survey_before_release,
        covidStatus: organisation.active_while_corona,
        contact,
        contactsList,
        businessType,
        currencyCode: currencyCodes.length ? currencyCodes[0] : 'USD',
        canChangePricing: currencyCodes.length <= 1 ? true : false,
        delay_notifications: organisation.delay_notifications ? 'On' : 'Off'
      })
    });
    return data;
  }
  handleTableChange = (pagination, filters, sorter) => {
    console.log('handleTableChange', pagination, filters, sorter);
    setTimeout(() => {
      if (!document.getElementById("totalNumber")) {
        this.setState({
          totalNumberTable: 0
        })
      }
    }, 100)
    // used for tooltips in cells (because it searchs for html element)
    setTimeout(() => {
      const { pagination } = this.props;
      this.setState({
        pagination: {
          ...pagination,
          update: !pagination.update
        }
      })
    }, 100)
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }

  emitEmpty = () => {
    this.setState({ 
      data: [],
      searchText: '',
      filtered: null
    });
  }
  modalVisible = (key, value) => {
    this.setState({ 
      [key]: value,
      selectedRowKeys: [],
      selectedRowValues: [] 
    });
    if (key === 'visiblePricingSettings' && !value) {
      this.setState({
        currencyCode: ''
      })
    }
  }
  onRowSelect = (selectedRowKeys, selectedRowValues) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRowValues);
    this.setState({
      selectedRowKeys,
      selectedRowValues
    });
  }
  deleteOrgnRequest = (orgId) => {
    Modal.confirm({
      title: 'Are you sure you want to delete Organization?',
      okText: 'Delete',
      onOk: () => {
        this.setState({
          visibleSmsAuthenticationPrompt: true,
          smsAuthenticationAction: 'delete_organisation',
          doAfterSmsAuthentication: () => {
            return new Promise((resolve, reject) => {
              //console.log(organisationId)
              fetchJSON(`/api/v1/organisation/delete/${orgId}`, {
                method: 'put',
              }).then(response => {
                //console.log(response);
                if (response.success) {
                  message.success('Successfully deleted');
                  this.handleTableUpdate();
                  resolve();
                } else {
                    message.error(response.message);
                  }
              }).catch(error => {
                  console.log(error);
                  reject();
                })
              }).catch(() => console.log('errors'));
              }
            });
      },
      onCancel: () => {
        this.setState({
          selectedRowKeys: [],
          selectedRowValues: []                    
        })
      },
      centered: true
    });
  }
  handleTableUpdate = () => {
    const { searchText } = this.state;
    Modal.destroyAll();
    if (searchText) {
      window.location = '/organisations';
    } else {
      this.setState({
        selectedRowKeys: [],
        selectedRowValues: [],
        visiblePricingSettings: false,
        currencyCode: ''
      }, () => { this.props.dispatch(fetchSecretQuote('/organisation/list'))})
    }
  }
  updateOrgList = (parameter, value) => {
    const { selectedRowValues } = this.state;
    let orgIds = [];
    selectedRowValues.forEach((org) => {
      if (orgIds.indexOf(org.id) === -1) {
        if (parameter === 'active_while_corona' && !org.covidStatus) {
          orgIds.push(org.id);
        }
        if (parameter === 'survey_before_release' && (org.surveyStatus !== value)) {
          orgIds.push(org.id);
        }
      }
    })
    if (!orgIds.length && parameter === 'survey_before_release') {
      Modal.warning({
        title: `Survey in selected Organizations is already ${value ? 'on' : 'off'}`, 
        okText: 'Got it',
        maskClosable: true,
        onOk: () => {
          this.setState({
            selectedRowValues: [],
            selectedRowKeys: []
          }, Modal.destroyAll)
        } 
      })
      return;            
    } else {
      const promises = [];
      const responses = [];
      orgIds.forEach((orgId) => {
        promises.push(new Promise((resolveLocal, reject) => {
          this.updateOrg(orgId, parameter, value, (response = false) => {
            if (response) {
              responses.push(response);
            }
            resolveLocal();
          })
        }));
      });
      Promise.all(promises).then((values) => {
        //console.log('responses', responses);
        if (responses.indexOf(400) === -1) {
          this.handleTableUpdate(); 
        } else {
          message.error('Something went wrong, please try again');  
        }
      })
    }
  }
  actions = (value) => {
    const { selectedRowValues } = this.state;
    if (!selectedRowValues || !selectedRowValues.length) {
      Modal.warning({
        title: 'Please, select at least one Organization.', 
        okText: 'Got it',
        maskClosable: true })
      return;
    }
    const orgId = selectedRowValues[0].id;
    if (orgId) {
      if (value === 'pricing') {
        let canChange = true;
        const currencyCodes = [];
        const venuesToChange = [];
        selectedRowValues.forEach((item) => {
          if (item.venues) {
            item.venueUuids.forEach((uuid) => {
              if (venuesToChange.indexOf(uuid) === -1 && uuid) {
                venuesToChange.push(uuid)
              }
            })
          }
          if (!item.canChangePricing) {
            canChange = false;
          }
          if (currencyCodes.indexOf(item.currencyCode) === -1 && item.venues) {
            currencyCodes.push(item.currencyCode)
          }
        })
        canChange = currencyCodes.length > 1 ? false : canChange; 
        canChange = venuesToChange.length === 0 ? false : canChange;
        if (canChange) {
          this.setState({
            visiblePricingSettings: true,
            currencyCode: currencyCodes[0],
            venuesToChangePricing: venuesToChange
          })
        } else {
          const title = venuesToChange.length ? 'For group change of pricing currencies in Locations Organisation(s) own must be the same.' :
            'There are no Locations to change pricing in.';
          Modal.warning({
            title,
            maskClosable: true,
            okText: 'Got it',
            onOk: () => {
              this.setState({
                selectedRowKeys: [],
                selectedRowValues: []
              })
            }
          })
        }
      }
      if (value === 'covid' || value === 'survey') {
        let parameter = value === 'survey' ? 'survey_before_release' : 'active_while_corona';
        if (selectedRowValues.length === 1) {
          let changedStatus;
          let question;
          if (value === 'covid') {
            changedStatus = selectedRowValues[0].covidStatus ? 0 : 1;
            question = `Are you sure you want to make this Organization ${changedStatus ? 'active' : 'inactive'} during covid19?`
          } else {
            changedStatus = selectedRowValues[0].surveyStatus ? 0 : 1;
            question = `Are you sure you want to turn ${changedStatus ? 'on' : 'off'} survey for this Organization?`
          } 
          if (typeof changedStatus !== 'undefined') {
            Modal.confirm({
              className: 'modal-buttons-center',
              title: question,
              okButtonProps: {style: {width: '80px'}},
              cancelButtonProps: {style: {width: '80px'}},
              okText: 'Yes',
              onOk: () => {
                this.updateOrg(orgId, parameter, changedStatus)
              },
              onCancel: () => {
                this.setState({
                  selectedRowKeys: [],
                  selectedRowValues: []
                })
              }
            })
          }
        } else {  
          if (value === 'survey') {
            Modal.confirm({
              className: "modal-confirm-no-btns",
              title: 'Do you want to turn Survey ON or OFF?',
              okText: 'Yes',
              maskClosable: true,
              closable: true,
              content: (
                <Row style={{marginTop: '8px', width: 'fit-content'}}>
                  <Button onClick={() => this.updateOrgList(parameter, 1)} type="primary">Turn on</Button>
                  <Button onClick={() => this.updateOrgList(parameter, 0)} type="primary" style={{margin: '0px 12px'}}>Turn off</Button>
                  <Button onClick={() => this.setState({ selectedRowKeys: [], selectedRowValues: []}, Modal.destroyAll) }>Cancel</Button>
                </Row>
              ),
            }) 
          } else {
            let isSomeInactive = false;
            selectedRowValues.forEach((org) => {
              if (!org.covidStatus) isSomeInactive = true;
            })
            if (!isSomeInactive) {
              Modal.warning({
                title: 'All Organizations are already active! You can not turn OFF group of Locations for Covid19 reason.', 
                okText: 'Got it',
                maskClosable: true,
                onOk: () => {
                  this.setState({
                    selectedRowValues: [],
                    selectedRowKeys: []
                  })
                } 
              })
            } else {
              Modal.confirm({
                className: 'modal-buttons-center',
                title: 'Are you sure you want to change working status of this Organizations during covid19?',
                okText: 'Yes, make them active',
                maskClosable: true,
                closable: true,
                onOk: () => {
                  this.updateOrgList(parameter, 1);
                },
                onCancel: () => {
                  this.setState({
                    selectedRowKeys: [],
                    selectedRowValues: []
                  })
                }
              }) 
            }
          }
        }
      }
      if (value === 'delete' || value === 'suspend' || value === 'suspend_nebula') {
        if (selectedRowValues.length === 1) {
          if (value === 'delete') {
            this.deleteOrgnRequest(orgId)
          };
          if (value === 'suspend') {
             this.setState({
              visibleSmsAuthenticationPrompt: true,
              smsAuthenticationAction: 'suspend_organisation',
              doAfterSmsAuthentication: () => {
                this.updateOrg(orgId, 'status', 2);
              }
            });
          }
          if (value === 'suspend_nebula') {
            if (selectedRowValues[0].nebulaUsersIds && selectedRowValues[0].nebulaUsersIds.length) {
              this.setState({
                visibleSmsAuthenticationPrompt: true,
                smsAuthenticationAction: 'suspend_nebula_for_org',
                doAfterSmsAuthentication: () => {
                  const promises = [];
                  const responses = [];
                  selectedRowValues[0].nebulaUsersIds.forEach((userId) => {
                    promises.push(new Promise((resolveLocal, reject) => {
                      this.suspendNebulaUser(userId, (response = false) => {
                        if (response) {
                          responses.push(response);
                        }
                        resolveLocal();
                      })
                    }));
                  });
                  Promise.all(promises).then((values) => {
                    //console.log('responses', responses);
                    if (responses.indexOf(400) === -1) {
                      message.success('Success!');
                      this.setState({
                        selectedRowKeys: [],
                        selectedRowValues: []
                      })
                    } else {
                      message.error('Something went wrong, please try again');  
                    }
                  })                
                }
              }); 
            } else {
              message.success('There are no Nebula users.');
              this.setState({
                selectedRowKeys: [],
                selectedRowValues: []
              })
            }           
          }       
        } else {
          Modal.warning({
            title: 'Please, select only one Organization for this action.', 
            maskClosable: true })
          return;       
        } 
      }
    }
  }
  suspendNebulaUser = (userId, callback) => {
    fetchJSON(`/api/v1/organisation/suspendNebulaUser/${userId}`, {
      method: 'put'
    }).then(response => {
      if (response.success) {
        callback(200);
      } else {
        callback(400);
      }
     }).catch(error => {
        console.log(error);
        callback(400);
     });
  }
  updateOrg = (orgId, parameter, value, callback = false) => {
    let bodyObj = {};
    bodyObj[parameter] = value;
    fetchJSON(`/api/v1/organisation/${orgId}`, {
      method: 'post',
      body: bodyObj
    }).then(response => {
      //console.log(response);
      if (response.success) {
        if (callback) {
          callback(200);
        } else {
          message.success('Success!');
          this.handleTableUpdate();
        }             
      } else {
        if (callback) {
          callback(400);
        } else {
          message.error(response.message);
        }   
      }
     }).catch(error => {
        console.log(error);
        if (callback) {
          callback(400);
        } else {
          message.error('ERR');
        }
     });
  }
  onSearch = (e) => {
    this.setState({ searchText: e.target.value });
    const searchText = e.target.value;
    const reg = new RegExp(e.target.value, 'gi');
    this.setState({
      filtered: !!searchText,
      data: _.flatMap(this.props.quote.data, record => {
        const nameMatch = record.name ? record.name.match(reg) : false;
        const addressMatch = record.address ? record.address.match(reg) : false;
        const businessNameMatch = record.business_name ? record.business_name.match(reg) : false;
        if (!nameMatch && !addressMatch && !businessNameMatch) {
          return null;
        }
        return {          
          ...record,
          };
      }).filter(record => !!record),
    });
    if (e.target.value === ''){
      this.setState({
        data: this.props.quote.data,
        filtered: null
      });
    }
  }
  render() {
    const { isFetching, pagination } = this.props;
    const { searchText, visibleAddOrg, totalNumberTable, businessTypesLoaded,selectedRowKeys, visibleSmsAuthenticationPrompt, 
      doAfterSmsAuthentication, smsAuthenticationAction, selectedRowValues, visiblePricingSettings, currencyCode, venuesToChangePricing 
    } = this.state;
    let covidAddForButton = '';
    let surveyAddForButton = '';
    if (selectedRowValues && selectedRowValues[0]) {
      if (selectedRowValues.length === 1) {
        covidAddForButton = selectedRowValues[0].covidStatus ? 'on' : 'off';
        surveyAddForButton = selectedRowValues[0].surveyStatus ? 'off' : 'on';
      } else {
        covidAddForButton = 'off';
      }
    }
    return (
      <Content className="fill-bg-table">
        <Row gutter={8}>
          <Col span={20}>
            <h1 className="table-title" style={{marginTop: '4px', display: 'inline-flex'}} >
            Organisations
              <Button type="primary" size="small" style={{margin: '6px 0px 0px 8px'}} onClick={ () => { this.modalVisible('visibleAddOrg', true) } }>
                Add organisation
              </Button>
              <Link to={"/organisations/statistics"} className="ant-btn ant-btn-sm" style={{margin: '6px 0px 0px 8px'}}>
                Statistics
              </Link>
              <p style={{margin: '6px 0px 0px 8px', fontWeight: '500', fontSize: '15px'}}>
                Quantity: {totalNumberTable}
              </p>
            </h1>
          </Col>
          <Col span={4}>
            { visibleAddOrg ? <AddOrg visible={visibleAddOrg} history={this.props.history} handleClose={ () => { this.modalVisible('visibleAddOrg', false) } } /> : null }
          </Col>
        </Row>
        <Row gutter={8} style={{marginBottom: '8px', alignItems: 'center'}}>
          <Col style={{display: 'inline-flex'}} sm={12}>
            <Select style={{ width: '180px'}} placeholder="Actions" onChange={(value) => this.actions(value)} value={'Actions'}>
              <Option value="pricing">Pricing</Option>
              <Option value="covid">Covid-19 Lockdown {covidAddForButton}</Option>
              <Option value="survey">Survey {surveyAddForButton}</Option>
              <Option value="suspend_nebula">Suspend Nebula</Option>
              <Option value="suspend" style={{color: 'red'}}>Suspend</Option>
              <Option value="delete" style={{color: 'red'}}>Delete</Option>
            </Select>
          </Col>
          <Col span={12}>
            <div style={{width: 'fit-content', marginLeft: 'auto'}}>
             <Search 
              style={{marginBottom: '8px', width: '305px'}} 
              disabled={isFetching} 
              onChange={this.onSearch} 
              placeholder="Search organization"
              allowClear
              />
           </div>
          </Col>
        </Row>
        <Table
          className="no-margin-small-table"
          rowSelection = {
            {
              selectedRowKeys: selectedRowKeys,
              onChange: this.onRowSelect
            }
          }
          size="small"
          columns={ this.columns() }
          loading={ isFetching || !businessTypesLoaded}
          dataSource={ businessTypesLoaded ? this.getData() : null }
          pagination={{
            ...pagination,
            showSizeChanger: true
          }}
          onChange={ this.handleTableChange }
          showSorterTooltip={false}
          scroll={{x: 'fit-content'}}
        />
        { visibleSmsAuthenticationPrompt ? <SmsAuthenticationPrompt
          visible={visibleSmsAuthenticationPrompt}
          action={ smsAuthenticationAction }
          doAfter={ doAfterSmsAuthentication }
          handleClose={ () => { this.modalVisible('visibleSmsAuthenticationPrompt', false) } } />
          : null
        }
          <Modal 
            visible={visiblePricingSettings}
            destroyOnClose={true}
            width={800}
            onCancel={() => { this.modalVisible('visiblePricingSettings', false) }}
            bodyStyle={{height: 'fit-content', display: 'flex'}}
            footer={null}
          >
            <div style={{width: '-webkit-fill-available'}}>
              <PricingSettings 
                source='organisation'
                currencyCode={currencyCode}
                relatedIds={venuesToChangePricing} 
                default={true} 
                handleClose={() => { this.modalVisible('visiblePricingSettings', false) }}
                handleTableUpdate={this.handleTableUpdate}
              />
            </div>
          </Modal>
      </Content>
    )
  }
}


const mapStateToProps = (state) =>{
  const { organisations } = state;
  const { quote, isFetching, createdFetch, pagination, totalNumberTable } = organisations;
  return {
    quote,
    isFetching,
    createdFetch,
    pagination,
    totalNumberTable
  }
}


export default connect(mapStateToProps)(Organisations)
