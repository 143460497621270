import { Modal, message } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import _ from 'lodash';

const confirm = Modal.confirm;

const countSupernovasInGroup = function countSupernovasInGroup(groupId) {
  return new Promise((resolve, reject) => {
    fetchJSON(`/api/v1/group/${groupId}`, {
      method: 'get'
    }).then(response => {
      if (typeof this.mounted !== 'undefined' && !this.mounted) resolve();
      const groupSupernovasList = _.get(response.data, 'supernovas');
      const countSupernovasInGroup = groupSupernovasList ? groupSupernovasList.length : 0;
      if (countSupernovasInGroup) {
        resolve(countSupernovasInGroup)
      }
      else {
        reject();
      }
    }).catch(error => {
      reject(error);
    });
  })
}
const confirmIntention = function updateConfirmRemoving() {
  confirm({
    className: 'UserManuals__btns-center',
    title: 'Are you sure you want to remove Supernova from Location ID?',
    okText: 'Yes',
    cancelText: 'No',
    cancelButtonProps: { size: 'small', style: { marginLeft: '20px', width: '60px' } },
    okButtonProps: { size: 'small', style: { width: '60px' } },
    onOk: () => {
      this.unlinkGroupIdRequest()
    }
  })
}

const confirmRemovingSupernova = function confirmRemovingSupernova(intentionConfirmed = false) {
  confirm({
    className: 'UserManuals__btns-center',
    title: 'To avoid any confusions before you unlink Supernova you must turn it off and remove it from the Location. Did you phisically remove Supernova from the Location?',
    okText: 'Yes',
    cancelText: 'No',
    cancelButtonProps: { size: 'small', style: { marginLeft: '20px', width: '60px' } },
    okButtonProps: { size: 'small', style: { width: '60px' } },
    onOk: () => {
      if (intentionConfirmed) {
        this.unlinkGroupIdRequest()
      } else {
        this.confirmIntention()
      }
    }
  })
}

const unlinkGroupIdRequest = function unlinkGroupIdRequest() {
  const { supernovaObj } = this.state;
  const groupId = _.get(supernovaObj, 'group.group_id');
  this.countSupernovasInGroup(groupId)
    .then((result) => {
      if (!result || typeof result !== 'number') {
        if (this.mounted) {

        }
        message.error('Please try again')
        return false;
      }
      if (result > 1) {
        this.unlinkGroupId()
      }
      else {
        confirm({
          title: 'This is the only Supernova linked to this location. Removing it will deactivate Location.',
          cancelText: 'Cancel',
          okText: 'Remove and deactivate location',
          cancelButtonProps: { style: { marginRight: '20px' }, type: 'primary', size: 'small' },
          okButtonProps: { style: { marginRight: '26px' }, size: 'small' },
          onOk: () => {
            this.unlinkGroupId();
            this.deactivateLocation();
          },
          centered: true
        });
      }
    })
}
const deactivateLocation = function deactivateLocation() {
  const { supernovaObj } = this.state;
  const venueId = _.get(supernovaObj, 'group.group.venue_groups.venue.id');
  this.setState({
    isFetching: true,
  });
  fetchJSON(`/api/v1/venue/${venueId}`, {
    method: 'post',
    body: {
      status: 0
    }
  }).catch(error => {
    console.log(error);
    this.setState({
      isFetching: false,
    });
  });
}
const unlinkGroupId = function unlinkGroupId() {
  this.setState({
    isFetching: true
  });
  const { supernovaObj } = this.state;
  fetchJSON(`/api/v1/supernova/unlinkGroup/${supernovaObj.id}`, {
    method: 'put',
    body: {
      machineId: supernovaObj.string_id
    }
  }).then(response => {
    if (typeof this.mounted !== 'undefined' && !this.mounted) return false;
    if (response.success) {
      this.getSupernovasList()
      this.setState({
        isFetching: false,
        supernovaObj: {},
        selectedRowKeys: [],
        selectedRowValues: []
      })
      message.success('Success. Location ID is disconnected');
    } else {
      this.setState({
        isFetching: false
      });
      message.error('Error, try again.')
    }
  }).catch(error => {
    console.log(error);
    this.setState({
      isFetching: false
    });
    message.error('Error, try again.')
  });
}
export {
  unlinkGroupIdRequest,
  deactivateLocation,
  unlinkGroupId,
  countSupernovasInGroup,
  confirmRemovingSupernova,
  confirmIntention
};