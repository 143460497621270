import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers';
import api from 'services/middleware/api'

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
const middleware = [
  thunkMiddleware,
  api,
  routerMiddleware(history)
];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

export default createStore(
  rootReducer(history),
  initialState,
  composedEnhancers
);