import React, { PureComponent } from 'react';
import { ClockCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip as AntdTooltip } from 'antd';
import { Layout, Spin, Row, Col, Switch, Select, Alert, Statistic, Card, Button, Progress } from 'antd';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Pie,
  PieChart,
  Cell,
  CartesianGrid,
  ComposedChart,
  ReferenceLine,
  LineChart,
  Line,
  Label
} from 'recharts';
import * as _sharedModules from '../../modules/_modules';

import DateSelectChoice from '../DateSelectChoice/DateSelectChoice';

import { emptyPersonalInfoObj, emptyGeneralUsagePatternsObj, emptyTimePatternsDataObj, emptyMembershipsPatternsUsageObj } from '../../modules/trendsreports';

import './styles.less';

const { Content } = Layout;
const Option = Select.Option;

class TrendsReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      COLORS: ['#EF5FA7', '#00A2FF', '#FFC805', '#ff2d55', '#ff9500', '#ffcc00'],
      BAR_USAGE_COLORS: ["#00A2FF","#1797F0","#2E8DE0","#4682D1","#5D77C1","#746DB2","#8B62A2","#A25893","#B94D83","#D14274","#E83864","#FF2D55"],
      GRAPH_MEMBERSHIPS_COLORS: ['#ff2d55', '#ff9500', '#5856d6', '#4cd964', '#22223b', '#925F9E', '#0db39e'],
      // fetch indicators
      isPersonalInfoFetching: false,
      isGeneralUsagePatternsFetching: false,
      isTimePatternsUsageFetching: false,
      isMembershipsPatternsUsageFetching: false,
      // dataObjects
      personalInfoData: emptyPersonalInfoObj,
      generalUsagePatternsData: emptyGeneralUsagePatternsObj,
      timePatternsData: emptyTimePatternsDataObj,
      membershipsPatternsData: emptyMembershipsPatternsUsageObj,
      // time objects
      shouldUpdateTurnOffOverrides: false,
      membershipPatternsTimeOverride: false,
      membershipPatternsRange: null,
      update: undefined,
      membershipsFilter: ['first_time_free', 'hourly', 'daily'],
      disableLocationChoicePersonalInfo: false,
      disableLocationChoiceUsagePatterns: false,
      showEmpty: false,
      usageGenderFilter: false,
      usageAgeFilter: false,
      usageCableFilter: false,
      dateRangeMembershipsGraph: 'weekly'
    };
    const requiredModulesNames = ['getFullTrendsData', 'getBodyObjTrends', 'getPersonalInfoData', 'getGeneralUsagePatternsData', 'getTimePatternsUsage', 'getMembershipsPatternsUsage'];
    Object.keys(_sharedModules).forEach((_moduleName) => {
      if (requiredModulesNames.indexOf(_moduleName) > -1) {
        this[_moduleName] = _sharedModules[_moduleName].bind(this);
      }      
    });
  }
  componentDidMount() {
    this.mounted = true;
    this.getFullTrendsData();
    this.singleLocationMode = this.props.singleLocationMode;
  }
  componentWillUnmount(){
    this.mounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    const filterTypeChange = this.props.filterByLocationType !== prevProps.filterByLocationType;
    const orgChange = !this.props.filterByLocationType && this.props.targetOrgId !== prevProps.targetOrgId;
    const businessTypeChange = this.props.filterByLocationType && this.props.targetBusinessTypeId !== prevProps.targetBusinessTypeId;
    const businessCategoryChange = this.props.filterByLocationType && this.props.targetBusinessCategoryId !== prevProps.targetBusinessCategoryId;
    const venueChange = !this.props.filterByLocationType && prevProps.targetVenueId !== this.props.targetVenueId;
    if (filterTypeChange || orgChange || venueChange || businessTypeChange || businessCategoryChange) {
      this.getFullTrendsData();
    }
    if (this.props.shouldUpdateTurnOffOverrides) {
      this.setState({
        shouldUpdateTurnOffOverrides: this.props.shouldUpdateTurnOffOverrides,
        membershipPatternsTimeOverride: false
      });
      this.props.turnOffShouldUpdateTimeOverride()
    }
    if (this.props.childShouldUpdateDateChanged) {
      this.props.turnOffShouldUpdate();
      this.getFullTrendsData();
    }
    if (this.props.updateLocationMultiChange) {
      this.getFullTrendsData();
      this.props.turnOffShouldUpdateMultiLocations();
    }
    this.singleLocationMode = this.props.singleLocationMode;
  }
  changeState = (key, value) => {
    this.setState({
      [key]: value
    })
  }
  renderMemberhipsUsagePatterns = () => {
    const { membershipsFilter, dateRangeMembershipsGraph, isMembershipsPatternsUsageFetching, GRAPH_MEMBERSHIPS_COLORS, membershipsPatternsData, shouldUpdateTurnOffOverrides } = this.state;
    const memberships_types = ['hourly', 'daily', 'monthly', 'annual','free_private', 'first_time_free', 'promocode'];
    const memberships_names = ['Hourly', 'Daily', 'Monthly', 'Annual','Free private', 'First time free', 'Promocode'];
    return (
      <div style={{width: '100%'}}>
        <Spin spinning={isMembershipsPatternsUsageFetching}>
          <Row>
            <Col span={24} style={{display: 'flex', flexDirection: 'row'}}>
              <div style={{marginRight: '12px'}}>
                <h3>Memberships</h3>
                <Select
                  value={membershipsFilter || []}
                  placeholder="Memberships"
                  style={{width: '400px'}}
                  onChange={(value) => this.setState({ membershipsFilter: value })}
                  mode="multiple"
                >
                  { memberships_types.map((value, i) => 
                    <Option key={i} value={value}>{memberships_names[i]}</Option>
                    )}
                </Select>
              </div>
              <DateSelectChoice
                turnOffShouldUpdate={() => this.setState({ shouldUpdateTurnOffOverrides: false })}
                shouldUpdateTurnOffOverrides={shouldUpdateTurnOffOverrides}
                updateDataOnTimeChange={(isTimeOverrided, newRange, rangeType) => this.controllTimeOverrideBlock('membershipPatternsTimeOverride', isTimeOverrided, this.getMembershipsPatternsUsage, newRange, rangeType)}
              />
              <div className="row-label-btns" style={{margin: 'auto 0'}}>
                <h3/>
                <button className={dateRangeMembershipsGraph === 'weekly' ? 'ant-btn ant-btn-primary ant-btn-round' : 'ant-btn ant-btn-round'} onClick={() => this.changeState('dateRangeMembershipsGraph', 'weekly')}>
                  Weekly
                </button>
                <button className={dateRangeMembershipsGraph === 'daily' ? 'ant-btn ant-btn-primary ant-btn-round' : 'ant-btn ant-btn-round'} onClick={() => this.changeState('dateRangeMembershipsGraph', 'daily')}>
                  Daily
                </button>
              </div>
            </Col>
          </Row>
          <Row className="top-margin">
            <Col span={24}>
              <div style={{height: '480px', width: '100%'}}>
                <ResponsiveContainer width="95%" height="100%">
                  <LineChart
                    width={500}
                    height={300}
                    data={dateRangeMembershipsGraph === 'weekly' ? membershipsPatternsData.graphWeeklyData : membershipsPatternsData.graphDailyData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 0,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis dataKey="name" />
                    <XAxis tickLine={false} dataKey="x"/>
                    <YAxis />
                    <Tooltip />
                    { dateRangeMembershipsGraph === 'weekly' ? 
                      Object.keys(membershipsPatternsData.reference_lines_years_weeks).map((year_key, index) => {
                       return  <ReferenceLine key={index} x={membershipsPatternsData.reference_lines_years_weeks[year_key]} 
                        stroke="gray" strokeWidth={2} strokeDasharray="3 3" label={{value: year_key, position: 'top', offset: 10}} />
                      })
                    : null }
                    { dateRangeMembershipsGraph === 'daily' ? 
                      Object.keys(membershipsPatternsData.reference_lines_years_days).map((year_key, index) => {
                       return  <ReferenceLine key={index} x={membershipsPatternsData.reference_lines_years_days[year_key]} 
                        stroke="gray" strokeWidth={2} strokeDasharray="3 3" label={{value: year_key, position: 'top', offset: 10}} />
                      })
                    : null }
                    <Legend iconSize={24} verticalAlign="top" height={50}/>
                      { memberships_types.map((value, index) => {
                        if (membershipsFilter.indexOf(value) > -1) {
                          return <Line key={index} name={memberships_names[index]} strokeWidth={3} type="monotone" dataKey={value} stroke={GRAPH_MEMBERSHIPS_COLORS[index % 12]} /> 
                        }
                        return null;
                      })
                    }
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Col>
            { membershipsPatternsData.pieChartData && membershipsPatternsData.pieChartData.length ? 
            <Col sm={10} xs={24} style={{marginTop: '12px'}}>
              <div className="circle-data-container">
                <div className="label">
                  <label>Memberships</label>
                </div>
                <div style={{height: '300px', display: 'flex', flexDirection: 'column'}}>
                  { membershipsPatternsData.pieChartData &&
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie 
                          dataKey="count" 
                          data={membershipsPatternsData.pieChartData}
                          label={this.renderCustomizedLabel}
                          labelLine={false}
                          cx="50%" cy="50%" 
                          innerRadius={'48%'}
                          outerRadius={'100%'} 
                          paddingAngle={2}
                          fill="#8884d8"
                          legendType="circle"
                          isAnimationActive={false}
                        >
                          { membershipsPatternsData.pieChartData.map((entry, index) => <Cell key={index} stroke={GRAPH_MEMBERSHIPS_COLORS[index % 12]} fill={GRAPH_MEMBERSHIPS_COLORS[index % 12]} /> ) }
                        <Label fill="#6B6F6E" className="inner-pie-label" value={membershipsPatternsData.membershipsAmount} position="center"/>
                        </Pie>
                        <Legend layout="vertical" verticalAlign="middle" align="right"/> 
                        <Tooltip/>
                      </PieChart>
                    </ResponsiveContainer> }
                </div>
              </div>
            </Col> : null }
          </Row>
        </Spin>
      </div>
    );  
  }
  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (percent && percent !== 1 && percent >= 0.06) {
      return (
        <text x={x} y={y} fill="white" style={{fontSize: '20px', fontWeight: 400}} textAnchor={"middle"} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      ); 
    }
    if (percent === 1) {
      return (
        <text x={cx} y={cy} fill="white" style={{fontSize: '20px', fontWeight: 400}} textAnchor={"middle"} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      ); 
    }
    return null;
  };
  CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length && payload[0].payload) {
      const percentage = payload[0].payload.percentage;
      const male = payload[0].payload.male || 0;
      const female = Math.abs(payload[0].payload.female) || 0;
      const non_binary = payload[0].payload.non_binary || 0;
      const all = (non_binary + female + male) || 0;
      return (
        <div className="custom-tooltip-survey-age-gender">
          <p className="label">{label} years old</p>
          <p className="inside-tooltip-row">{all ? `${all} user(s). It's ` : ''}{percentage}% of users.</p>
          <p className="inside-tooltip-row"><span className="pink">
            Female: </span><span>{female}</span> users 
            {all && female ? <span> (<span className="bold">{female !== all ? ((female/all) * 100).toFixed(2) : 100}%</span> of users in this group)</span>: ''}
          </p>
          <p className="inside-tooltip-row"><span className="blue">
            Male:</span> {male} users
            {all && male ? <span> (<span className="bold">{male !== all ? ((male/all) * 100).toFixed(2) : 100}%</span> of users in this group)</span>: ''}
          </p>
          <p className="inside-tooltip-row"><span className="grad-text">
            Non-binary:</span> {non_binary} users
            {all && non_binary ? <span> (<span className="bold">{non_binary !== all ?  ((non_binary/all) * 100).toFixed(2) : 100}%</span> of users in this group)</span>: ''}
          </p>
        </div>
      );
    }

    return null;
  };
  personalInfoRender = () => {
    const { isPersonalInfoFetching, personalInfoData, COLORS } = this.state;
    return (
      <Spin spinning={isPersonalInfoFetching}>
        <Row style={{marginTop: '8px', display: 'flex', flexDirection: 'row'}}>
        { personalInfoData.ageToGender && personalInfoData.ageToGender.length ?
          <Col sm={18} xs={24}>
            <div className="circle-data-container">
              <div className="label">
                <label></label>
              </div>
              <div style={{height: '600px', display: 'flex', flexDirection: 'column'}}>
                <ResponsiveContainer height="100%">
                <ComposedChart
                    layout="vertical"
                    width={500}
                    height={400}
                    stackOffset="sign"
                    data={personalInfoData.ageToGender}
                    margin={{
                      top: 40,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" vertical={false} />
                    <XAxis 
                      padding={{ left: 20, right: 20 }}
                      type="number" 
                      domain={[-1 * personalInfoData.max_amount_gender_age, personalInfoData.max_amount_gender_age]}
                      tick={<CustomizedAxisTick />}
                    />
                      />
                    <YAxis dataKey="name" type="category" scale="band" label={{value: 'Age', offset: 30, position: 'top'}}/>
                    <ReferenceLine x={0} stroke="rgba(0, 0, 0, 0.0)"/>
                    <Tooltip content={this.CustomTooltip} />
                    <Legend iconType="circle"/>
                    <Bar dataKey="female" name="Female" fill="#EF5FA7" stackId="stack" barSize={34}/>
                    <Bar dataKey="male" name="Male" fill="#00A2FF" stackId="stack" barSize={32}/>
                  </ComposedChart>
                </ResponsiveContainer>
                </div>
            </div>
          </Col> : null }
          { personalInfoData.gender && personalInfoData.gender.length ? 
          <Col sm={6} xs={24} style={{display: 'flex'}}>
            <div className="circle-data-container" style={{margin: 'auto 0'}}>
              <div className="label">
                <label>Gender</label>
              </div>
              <div style={{height: '300px', display: 'flex', flexDirection: 'column'}}>
                { personalInfoData.gender &&
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie 
                        dataKey="count" 
                        data={personalInfoData.gender}
                        label={this.renderCustomizedLabel}
                        labelLine={false}
                        cx="50%" cy="50%" 
                        innerRadius={'0%'}
                        outerRadius={'88%'} 
                        fill="#8884d8"
                        legendType="circle"
                      >
                        { personalInfoData.gender.map((entry, index) => <Cell key={index} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} /> ) }
                      </Pie>
                      <Legend layout="vertical" verticalAlign="bottom" align="center"/> 
                      <Tooltip/>
                    </PieChart>
                  </ResponsiveContainer> }
              </div>
            </div>
          </Col> : null }
          <Col sm={2} xs={0}/>
        </Row>
        <Row style={{marginTop: '12px'}}>
          <Col sm={5} xs={12}>
            <div className="circle-data-container margin-24" style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
              <div className="label">
                <label>
                  Multilocations users
                </label>
              </div>
              <div className="circle-wrapper" style={{marginTop: '12px'}}>
                <div className="circle">
                  <div className="text">{personalInfoData.multilocations_users_percentage ? `${personalInfoData.multilocations_users_percentage}%` : 0}</div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={4} xs={0}/>
          { personalInfoData.charging_cables && personalInfoData.charging_cables.length ? 
          <Col sm={6} xs={24}>
            <div className="circle-data-container">
              <div className="label">
                <label>Charging cables</label>
              </div>
              <div style={{height: '300px', display: 'flex', flexDirection: 'column'}}>
                { personalInfoData.charging_cables &&
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie 
                        dataKey="count" 
                        data={personalInfoData.charging_cables}
                        label={this.renderCustomizedLabel}
                        labelLine={false}
                        cx="50%" cy="50%" 
                        innerRadius={'0%'}
                        outerRadius={'88%'} 
                        fill="#8884d8"
                        legendType="circle"
                      >
                        { personalInfoData.charging_cables.map((entry, index) => <Cell key={index} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} /> ) }
                      </Pie>
                      <Legend layout="vertical" verticalAlign="middle" align="right"/> 
                      <Tooltip/>
                    </PieChart>
                  </ResponsiveContainer> }
              </div>
            </div>
          </Col> : null }
        </Row>
      </Spin>
    )
  }
  handleLocationOverrideChangePersonal = (value) => {
    const { disableLocationChoicePersonalInfo } = this.state;
    if (disableLocationChoicePersonalInfo !== value) {
      this.setState({
        disableLocationChoicePersonalInfo: value
      }, () => {
        const bodyObj = this.getBodyObjTrends();
        this.getPersonalInfoData(bodyObj);
      })
    }
  }
  handleLocationOverrideChangeUsage = (value) => {
    const { disableLocationChoiceUsagePatterns } = this.state;
    if (disableLocationChoiceUsagePatterns !== value) {
      this.setState({
        disableLocationChoiceUsagePatterns: value
      }, () => {
        const bodyObj = this.getBodyObjTrends();
        this.getGeneralUsagePatternsData(bodyObj);
      })
    }
  }
  customTooltipUsageBar = ({ active, payload, label }) => {
    if (active && payload && payload.length && payload[0].payload) {
      const percentage = payload[0].payload.percentage;
      const users_answered = payload[0].payload.count;
      if (users_answered) {
        return (
          <div className="custom-tooltip-survey-age-gender">
            <p className="label">{ label === '1' ? `${label} session` : `${label} sessions` }</p>
            <p className="inside-tooltip-row">{users_answered ? `${users_answered} user(s). It's ` : ''}<span className="bold">{percentage}% of users with sessions.</span>.</p>
          </div>
        );
      }
    }

    return null;
  };
  controllTimeOverrideBlock = (name, isTimeOverrided, callback, newRange, rangeType) => {
    const bodyObj = this.getBodyObjTrends();
    let timeValueName;
    const timeValueObj = {
      type: rangeType,
      momentObj: newRange
    };
    switch(name) {
      case 'membershipPatternsTimeOverride':
        timeValueName = 'membershipPatternsRange';
        break;
      default:
        break;
    }
    this.setState({
      [name]: isTimeOverrided,
      [timeValueName]: timeValueObj
    }, () => {
      callback(bodyObj);
    })
  }
  customTooltipWeekdaysUsage = ({ active, payload, label }) => {
    if (active && payload && payload.length && payload[0].payload) {
      const sessions = payload[0].payload.sessions;
      const sessions_percentage = payload[0].payload.sessions_percentage;
      const new_users = payload[0].payload.new_users;
      const new_users_percentage = payload[0].payload.new_users_percentage;
      if (sessions) {
        return (
          <div className="custom-tooltip-survey-age-gender">
            <p className="label">{label}</p>
            <p className="inside-tooltip-row">{sessions ? `${sessions} sessions(s). It's ` : ''}<span className="bold">{sessions_percentage.toFixed(1)}% of all sessions.</span></p>
            <p className="inside-tooltip-row">{new_users ? `${new_users} new user(s). It's ` : ''}<span className="bold">{new_users_percentage.toFixed(1)}% of all new users.</span></p>
          </div>
        );
      }
    }
    return null;
  };
  customTooltipHourlyUsage = ({ active, payload, label }) => {
    if (active && payload && payload.length && payload[0].payload) {
      const returns = payload[0].payload.returns;
      const checkouts = payload[0].payload.checkouts;
      const checkouts_percentage = payload[0].payload.checkouts_percentage;
      const returns_percentage = payload[0].payload.returns_percentage;
      if (checkouts || returns) {
        return (
          <div className="custom-tooltip-survey-age-gender">
            <p className="label">{label}</p>
            { checkouts ?
              <p className="inside-tooltip-row">{checkouts} checkouts(s). It's
                <span className="bold"> {checkouts_percentage.toFixed(1)}% of all checkouts.</span>
              </p> : 
              <p>0 checkouts.</p> 
            }
            { returns ?
              <p className="inside-tooltip-row">{returns} return(s). It's
                <span className="bold"> {returns_percentage.toFixed(1)}% of all returns.</span>
              </p> : 
              <p>0 returns.</p> 
            }
          </div>
        );
      }
    }
    return null;
  };
  generalUsagePatternsDataRender = () => {
    const { isGeneralUsagePatternsFetching, 
      generalUsagePatternsData, BAR_USAGE_COLORS, usageGenderFilter, usageAgeFilter, usageCableFilter } = this.state;
    const agesArr = ['18-20', '20-25', '25-30', '35-40', '40-50', '50-60','60-70', '70-80', '80-100'];
    const cablesArr = ['lightning', 'usb_c', 'micro_usb', 'double', 'triple'];
    const cablesVocabulary = {
      lightning: 'Lightning', 
      usb_c: 'Type-C',
      micro_usb: 'Micro USB',
      double: 'Double',
      triple: 'Triple'
    }
    return (
      <Spin spinning={isGeneralUsagePatternsFetching}>
        { generalUsagePatternsData.super_users_bar_chart.length ?
          <div style={{marginTop: '8px', width: '100%'}}>
            <Row>
              <Select
                value={usageGenderFilter || []}
                placeholder="Filter by gender"
                style={{width: '220px'}}
                onChange={(value) => this.setFilterUsageGender(value)}
                allowClear={true}
                mode="multiple"
              >
                <Option key={0} value="female">Female</Option>
                <Option key={1} value="male">Male</Option>
                <Option key={2} value="non_binary">Non binary</Option>
              </Select>
              <Select
                placeholder="Filter by age"
                value={usageAgeFilter || []}
                style={{width: '220px', marginLeft: '8px'}}
                onChange={(value) => this.setFilterUsageAge(value)}
                allowClear={true}
                mode="multiple"
              >
                { agesArr.map((age, index) => {
                  return <Option key={index} value={age}>{age}</Option>
                })}
              </Select>
              <Select
                placeholder="Filter by cable"
                value={usageCableFilter || []}
                style={{width: '220px', marginLeft: '8px'}}
                onChange={(value) => this.setFilterUsageCable(value)}
                allowClear={true}
                mode="multiple"
              >
                {
                  cablesArr.map((cable, index) => {
                    return <Option key={index} value={cable}>{cablesVocabulary[cable]}</Option>
                  })
                }
              </Select>
              <Button
                onClick={this.resetAllUsageFilters}
                style={{marginLeft: '8px'}}
                type="primary"
                >
                Reset all filters
              </Button>
            </Row>
            <Row style={{display: 'flex', flexDirection: 'row', marginTop: '12px'}}>
              <Col sm={16} xs={24}>
                <div style={{width: '100%', height: '400px'}}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={500}
                      height={300}
                      data={generalUsagePatternsData.super_users_bar_chart}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 0,
                        bottom: 30
                      }}
                    >
                      <CartesianGrid vertical={false} horizontal={false}/>
                      <XAxis dataKey="name" label={{value: "Number of session", position: 'insideBottom', offset: -20}}/>
                      <YAxis label={{ value: 'Amount of users', angle: -90, position: 'insideLeft' }}/>
                      <Tooltip content={this.customTooltipUsageBar} />
                      <Bar name="Amount of users" dataKey="count" fill="#5856d6">
                        {generalUsagePatternsData.super_users_bar_chart.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={BAR_USAGE_COLORS[index % 12]} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>  
              </Col>
              <Col style={{margin: 'auto 0'}} sm={8} xs={24}>
                { generalUsagePatternsData.is_super_users ? 
                <Row>
                  <Progress
                    className="super-users-progress"
                    width={180}
                    type="circle" 
                    percent={100} 
                    format={() => 
                      <span className="super-users-text">
                        {generalUsagePatternsData.super_users_amount} super users!
                        <AntdTooltip title="Users with more than 100 sessions."><QuestionCircleOutlined
                          style={{color: '#5856d6', fontSize: '20px'}} /></AntdTooltip>
                      </span>}
                    strokeColor={{
                      '0%': BAR_USAGE_COLORS[0],
                      '100%': BAR_USAGE_COLORS[11],
                    }}
                    />

                </Row> : null }
                <Row>
                  <Card className="avg-charging-card">
                    <Statistic
                      title={<p style={{fontSize: '18px'}}>Average charging time</p>}
                      value={generalUsagePatternsData.avg_charging_time}
                      precision={2}
                      valueStyle={{ color: '#00A2FF' }}
                      prefix={<ClockCircleOutlined />}
                    />
                    <Statistic
                      title={<p style={{fontSize: '18px'}}>Middle charging time</p>}
                      value={generalUsagePatternsData.mid_charging_time}
                      precision={2}
                      valueStyle={{ color: '#00A2FF' }}
                      prefix={<ClockCircleOutlined />}
                    />
                  </Card>
                </Row>
              </Col>
            </Row>
          </div>
          : null }
      </Spin>
    );
  }
  renderTimeUsagePatterns = () => {
    const { isTimePatternsUsageFetching, 
      timePatternsData, BAR_USAGE_COLORS, usageGenderFilter, usageAgeFilter, usageCableFilter } = this.state;
    const agesArr = ['18-20', '20-25', '25-30', '35-40', '40-50', '50-60','60-70', '70-80', '80-100'];
    const cablesArr = ['lightning', 'usb_c', 'micro_usb', 'double', 'triple'];
    const cablesVocabulary = {
      lightning: 'Lightning', 
      usb_c: 'Type-C',
      micro_usb: 'Micro USB',
      double: 'Double',
      triple: 'Triple'
    }
    return (
      <Spin spinning={isTimePatternsUsageFetching}>
        { timePatternsData.hourly_usage.length ?
          <div style={{marginTop: '8px', width: '100%'}}>
            <Row>
              <h3>Hourly usage of electron</h3>
            </Row>
            <Row style={{display: 'flex', flexDirection: 'row', marginTop: '12px'}}>
              <Col sm={24} xs={24}>
                <div style={{width: '100%', height: '400px'}}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={500}
                      height={300}
                      data={timePatternsData.hourly_usage}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 5,
                        bottom: 30
                      }}
                    >
                      <CartesianGrid vertical={false} horizontal={false}/>
                      <XAxis dataKey="name"/>
                      <YAxis label={{ value: 'Percent of sessions', angle: -90, position: 'insideLeft' }}/>
                      <Tooltip content={this.customTooltipHourlyUsage} />
                      <Legend verticalAlign="top" align="center"
                        formatter={(value, entry, index) => {
                          return entry.dataKey === 'returns_percentage' ? 'Returns' : 'Checkouts'
                        }} />
                      <Bar name="Check out" dataKey="checkouts_percentage" fill={BAR_USAGE_COLORS[0]}/>
                      <Bar name="Return" dataKey="returns_percentage" fill={BAR_USAGE_COLORS[11]}/>
                    </BarChart>
                  </ResponsiveContainer>
                </div>  
              </Col>
            </Row>
          </div>
          : null }
        { timePatternsData.weekday_usage.length ?
          <div style={{marginTop: '8px', width: '100%'}}>
            <Row>
              <h3>Usage by week day</h3>
            </Row>
            <Row style={{display: 'flex', flexDirection: 'row', marginTop: '12px'}}>
              <Col sm={24} xs={24}>
                <div style={{width: '100%', height: '400px'}}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={500}
                      height={300}
                      data={timePatternsData.weekday_usage}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 5,
                        bottom: 30
                      }}
                    >
                      <CartesianGrid vertical={false} horizontal={false}/>
                      <XAxis dataKey="name"/>
                      <Legend verticalAlign="top" align="center"
                        formatter={(value, entry, index) => {
                          return entry.dataKey === 'sessions_percentage' ? 'Sessions' : 'New users'
                        }} />
                      <YAxis label={{ value: 'Percent of sessions', angle: -90, position: 'insideLeft' }}/>
                      <Tooltip content={this.customTooltipWeekdaysUsage} />
                      <Bar name="Sessions" dataKey="sessions_percentage" fill="#5856d6"/>
                      <Bar name="New users" dataKey="new_users_percentage" fill="#4cd964"/>
                    </BarChart>
                  </ResponsiveContainer>
                </div>  
              </Col>
            </Row>
          </div>
          : null }
      </Spin>
    );  
  }
  setFilterUsageGender = (value) => {
    this.setState({
      usageGenderFilter: value
    }, () => {
      const bodyObj = this.getBodyObjTrends();
      this.getGeneralUsagePatternsData(bodyObj);
    })
  }
  setFilterUsageAge = (value) => {
    this.setState({
      usageAgeFilter: value
    }, () => {
      const bodyObj = this.getBodyObjTrends();
      this.getGeneralUsagePatternsData(bodyObj);
    })
  }
  setFilterUsageCable = (value) => {
    this.setState({
      usageCableFilter: value
    }, () => {
      const bodyObj = this.getBodyObjTrends();
      this.getGeneralUsagePatternsData(bodyObj);
    })
  }
  resetAllUsageFilters = () => {
    this.setState({
      usageGenderFilter: false,
      usageAgeFilter: false,
      usageCableFilter: false     
    }, () => {
      const bodyObj = this.getBodyObjTrends();
      this.getGeneralUsagePatternsData(bodyObj);
    })
  }

  render() {
    return (
      <Content className="unselectable trends-reports">
        <Row>
          <Alert 
            type="info"
            closable={true}
            message={<span style={{fontSize: '16px'}}>
              Please note that <span style={{fontWeight: 500}}>not all users</span> of the system had told us their gender and age.<
            /span>}
            closeText={<span style={{fontSize: '16px'}}>Got it!</span>}
          />
        </Row>
        <Row style={{marginTop: '18px'}}>
          <h2>Demographics</h2>
        </Row>
        <Row style={{display: 'flex', flexDirection: 'row'}}>
          <p style={{fontSize: '16px', lineHeight: 1.2}}>Show all users regardless of location choice. </p>
          <Switch
            style={{marginLeft: '8px'}}
            onChange={this.handleLocationOverrideChangePersonal}
            />
        </Row>
        <Row>
          { this.personalInfoRender() }
        </Row>
        <Row style={{marginTop: '18px'}}>
          <h2 className="label">Usage patterns</h2>
        </Row>
        <Row style={{display: 'flex', flexDirection: 'row'}}>
          <p style={{fontSize: '16px', lineHeight: 1.2}}>Show all users regardless of location choice. </p>
          <Switch
            style={{marginLeft: '8px'}}
            onChange={this.handleLocationOverrideChangeUsage}
            />
        </Row>
        <Row>
          { this.generalUsagePatternsDataRender() }
        </Row>
        <Row style={{marginTop: '18px'}}>
          <h2>Memberships usage patterns</h2>
        </Row>
        <Row>
          { this.renderMemberhipsUsagePatterns() }
        </Row>
        <Row style={{marginTop: '18px'}}>
          <h2 className="label">Time usage patterns</h2>
        </Row>
        <Row>
          { this.renderTimeUsagePatterns() }
        </Row>
      </Content>
    )
  }
}

export default TrendsReports;

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {Math.abs(payload.value)}
        </text>
      </g>
    );
  }
}
class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, dataObj, index } = this.props;
    return (
      <text x={x} y={y} dy={22} fill={"#fff"} fontSize={18} fontWeight={400} textAnchor="middle">
        {Math.abs(dataObj[index].percentage)+'%'}
      </text>
    );
  }
}