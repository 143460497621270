import React from 'react';
import moment from 'moment-timezone';
import Settings from 'services/config/Settings';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Table, Layout, Input, Row, Col, message, Button, Card } from 'antd';
import _ from 'lodash';
import SendCommand from './components/SendCommand';
import { Link } from 'react-router-dom';
import fetchJSON from 'services/utils/fetchJSON';

require("moment-duration-format");

const { Content } = Layout;
const Search = Input.Search;
const nowTime = () => moment.utc().utcOffset(0, true);
const readTime = (time) => moment(time)
class SupernovasMonitor extends React.Component {
  state = {
    filteredInfo: {},
    sortedInfo: {
      order: 'descend',
      columnKey: 'internet'
    },
    searchText: '',
    filtered: false,
    data: {},
    searchData: {},
    expandedRowKeys: [],
    visibleShowCommand: false,
    machineIdToSend: ''
  };
  componentDidMount() {
    this.mounted = true;
    document.title = `Supernovas Monitor | ${Settings.title}`;
    this.getServerData();
    this.countRows();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getServerData = () => {
    if (!this.mounted) return false;
    this.setState({
      isFetching: true
    })
    fetchJSON('/api/v1/supernova/monitor', {
      method: 'get'
    }).then(response => {
      if (!this.mounted) return false;
      if (response.success) {
        this.setState({
          isFetching: false,
          data: response.data,
        })
      } else {
        this.setState({
          isFetching: false
        })
      }
     }).catch(error => {
        console.log(error);
        if (this.mounted) {
          this.setState({
            isFetching: false
          })          
        }
     });
  }
  refreshData = () => {
    this.getServerData();
  }
  lessThanAgo = (date) => {
    if (!date) return false;
    const _AGO = 1000 * 60 * 3;
    let anMinuteAgo = Date.now() - _AGO;
    return date < anMinuteAgo;
  }
  countRows = () => {
    const expandedRowKeys = Array.from(Array(1000).keys());
    this.setState({
      expandedRowKeys
    });
    return true;
  }
  columns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return [
    {
      title: 'LINE',
      dataIndex: 'internet',
      key: 'internet',
      align: 'center',
      render: (text, record) => (
        <span
          style={{ color: record.internet === 'online' ? 'green': 'red' }}
        ><LegacyIcon type={record.internet === 'online' ? "global" : "global"} style={{fontSize: '16px'}} /></span>
      ),
      filters: [
        { text: 'Online', value: 'online' },
        { text: 'Offline', value: 'offline' }
      ],
      filteredValue: filteredInfo.internet || null,
      onFilter: (value, record) => {
        return record.internet.includes(value)
      },
      sorter: (a, b) => a.internet > b.internet ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'internet' && sortedInfo.order
    },
    {
      title: 'ID',
      dataIndex: 'string_id',
      key: 'string_id'
    },
    {
      title: 'Version',
      dataIndex: 'esp_version',
      key: 'esp_version',
      sortOrder: sortedInfo.columnKey === 'esp_version' && sortedInfo.order,
      sorter: (a, b) => a.esp_version - b.esp_version
    },
    {
      title: 'Group',
      dataIndex: 'groupid',
      key: 'groupid',
      render: (text, record) => (
        <span>
          {record.groupid}
        </span>
      ),
    },
    {
      title: 'Electrons',
      dataIndex: 'electrons',
      key: 'electrons',
      sortOrder: sortedInfo.columnKey === 'electrons' && sortedInfo.order,
      sorter: (a, b) => a.electrons - b.electrons
    },
    {
      title: 'Uptime',
      dataIndex: 'uptime',
      key: 'uptime',
      sortOrder: sortedInfo.columnKey === 'uptime' && sortedInfo.order,
      sorter: (a, b) => a.uptime - b.uptime
    },
    {
      title: 'Inventory',
      dataIndex: 'inventory',
      key: 'inventory',
      sortOrder: sortedInfo.columnKey === 'inventory' && sortedInfo.order,
      sorter: (a, b) => a.uptime - b.uptime
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Button className="ant-btn ant-btn-sm" onClick={ () => { this.showCommandDialog('visibleShowCommand', true, record.string_id) } } style={{marginRight: '8px'}}>
            Send command
          </Button>
          <Link to={"/monitor/"+record.string_id} className="ant-btn ant-btn-sm" style={{marginRight: '8px'}}>
            Show Log
          </Link>
          <Link to={"/supernovas/"+record.string_id} className="ant-btn ant-btn-sm">
            Open
          </Link>
        </span>
      ),
    }];
  }
  getData = () => {
    const data = [];
    if (!this.state.data) return data;

    let response = this.state.data;
    if (this.state.filtered) {
      response = this.state.searchData;
    }
    let supernovas = [];
    try {
      supernovas = response.supernovas;
    } catch (err) {
      // err
    }

    if (typeof supernovas === 'object') {
      if (supernovas.length) {
        supernovas.forEach((supernova, i) => {
          let groupId = _.get(supernova, 'group.group.key_id');
          let organisation = _.get(supernova, 'group.group.venue_groups.venue.organisation.organisation');

          // console.log('organisation', organisation);

          data.push({
            key: i,
            string_id: supernova.string_id,
            id: supernova.id,
            groupid: groupId,
            internet: supernova.status,
            electrons: supernova.electrons ? supernova.electrons.length : 0,
            esp_version: supernova.esp_version,
            expanded: typeof supernova.broker !== 'object' ? null : supernova.broker,
            uptime: typeof supernova.broker === 'object' ? supernova.broker.uptime : '',
            inventory: typeof supernova.broker === 'object' ? supernova.broker.lastGoodInventory : '',
            organisation: organisation || false,
            organisation_id: organisation ? organisation.id : 0
          });

        });
      }
    }
    return data;
  }
  jsonToTable = (json) => {
    if (typeof json !== 'object') {
      return null;
    }
    if (!Object.keys(json).length) {
      return null;
    }
    return (
        <ul>
        {
          Object.keys(json).map(function(item, key){
            return <li key={Math.random()}>{item}: {json[item].toString()}</li>;
          })
        }
        </ul>
    );
  }
  showCommandDialog = (key, value, machineId) => {
    this.setState({
      [key]: value,
      machineIdToSend: machineId
    });
  }
  successResultCommand = () => {
    message.success('Command successfully sent');
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  onSearch = (e) => {
    this.setState({ searchText: e.target.value.trim() });
    const searchText = e.target.value.trim();
    const reg = new RegExp(e.target.value.trim(), 'gi');
    const { data } = this.state;
    const { supernovas, groups } = data;
    console.log(groups, 'groups')
    const spnIds = [];
    const groupKeys = [];
    if (supernovas && groups && Array.isArray(supernovas) && typeof groups === 'object' && !Array.isArray(groups)) {
      if (supernovas.length) {
        supernovas.forEach((supernova) => {
          const groupKeyId = _.get(supernova, 'group.group.key_id');
          if (groupKeyId) {
            if (groupKeys.indexOf(groupKeyId) > -1 && supernova.string_id && spnIds.indexOf(supernova.string_id) === -1) {
              spnIds.push(supernova.string_id);
            }
          }
          if (supernova.string_id && supernova.string_id.match(reg)) {
            if (groupKeyId && groupKeyId.match(reg) && groupKeys.indexOf(groupKeyId) === -1) {
              groupKeys.push(groupKeyId);
            }
            if (spnIds.indexOf(supernova.string_id) === -1) {
              spnIds.push(supernova.string_id);
            }
            if (groupKeyId && groupKeys.indexOf(groupKeyId) === -1) {
              groupKeys.push(groupKeyId);
            }
            
          }
        })
      }
      if (Object.keys(groups) && Object.keys(groups).length) {
        Object.keys(groups).forEach((key) => {
          if (key.match(reg)) {
            if (groupKeys.indexOf(key) === -1) {
              groupKeys.push(key)
            }
          }
        })
      }
    }
    const searchData = {
      supernovas: [],
      groups: {}
    };
    if (spnIds.length) {
      spnIds.forEach((id) => {
        supernovas.forEach((supernova) => {
          if (id === supernova.string_id) {
            searchData.supernovas.push(supernova);
          }
        })
      })
    }
    if (groupKeys.length) {
      groupKeys.forEach((key) => {
        if (groups[key]) {
          searchData.groups[key] = groups[key];
        }
      })
    }
    this.setState({
      filtered: !!searchText,
      searchData: searchData
    });
    if (e.target.value === ''){
      this.setState({
        searchData: [],
        filtered: false
      });
    }
  }
  emitEmpty = () => {
    this.setState({ 
      searchData: [],
      searchText: '',
      filtered: false
    });
  }
  supernovasOnline = () => {
    let data = 0;
    if (!this.state.data) return data;

    let response = this.state.data;

    if (this.state.filtered) {
      response = this.state.searchData;
    }
    try {
      if (response) {
        if (response.supernovas) {
          response.supernovas.forEach((supernova, i) => {
            if (supernova.status === 'online') data += 1;
          });
        }
      }
    } catch (err) {
      //err
    }
    

    return data;
  }
  getExpand = (record) => {
    if (!record.expanded) return null;
    const columns = [
      { title: 'Nest 1', dataIndex: 'nest_1', key: 'nest_1' },
      { title: 'Nest 2', dataIndex: 'nest_2', key: 'nest_2' },
      { title: 'Nest 3', dataIndex: 'nest_3', key: 'nest_3' },
      { title: 'Nest 4', dataIndex: 'nest_4', key: 'nest_4' },
      { title: 'Nest 5', dataIndex: 'nest_5', key: 'nest_5' },
      { title: 'Nest 6', dataIndex: 'nest_6', key: 'nest_6' },
      { title: 'Nest 7', dataIndex: 'nest_7', key: 'nest_7' },
      { title: 'Time ago', dataIndex: 'time_ago', key: 'time_ago' },
    ];
  
    const lastVoltage = record.expanded.voltages;
    const lastVoltageTime = record.expanded.last_voltage;
    
    const data = [];

    if (lastVoltage) {

      let time_ago = null;
      if (lastVoltageTime) {
        const happend = readTime(lastVoltageTime);
        const now = nowTime();
        const ms = now.diff(happend)
        const dur = moment.duration(ms);
        time_ago = dur.format("d [d] h [h] m [min]");
      }

      data.push({
        key: 0,
        nest_1: lastVoltage[0],
        nest_2: lastVoltage[1],
        nest_3: lastVoltage[2],
        nest_4: lastVoltage[3],
        nest_5: lastVoltage[4],
        nest_6: lastVoltage[5],
        nest_7: lastVoltage[6],
        time_ago: time_ago
      });
    }
    
    if (!data.length) return null;
    return (
      <Row gutter={8}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered={false}
          size="middle"
        />
      </Row>
    );
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  showCommandDialog = (key, value, machineId) => {
    this.setState({
      [key]: value,
      machineIdToSend: machineId
    });
  }
  runInventory = (source) => {
    fetchJSON(`/api/v1/supernova/talk/${source}`, {
      method: 'PUT',
      body: {
        command: 'INVENTORYRUN'
      }
    }).then(response => {
      console.log(response);
      if (response.success) {
        message.success('OK');
      } else {
        message.error('Error');
      }
     }).catch(error => {
        console.log(error);
        message.error('Error');
     });
  }
  renderTable = () => {
    const { expandedRowKeys, data, isFetching } = this.state;
    const items = this.getData();
    let groups = {};

    if (typeof data === 'object' && data) {
      groups = data.groups;
    }
    
    var grouped = _.mapValues(_.groupBy(items, 'organisation_id'));

    console.log('grouped', grouped);

    const renderIt = [];
    if (grouped) {
      if (Object.keys(grouped).length) {
        Object.keys(grouped).sort((a, b) => {return b-a}).forEach((orgId) => {
          if (orgId === '0') return;
          const byGroup = _.mapValues(_.groupBy(grouped[orgId], 'groupid'));
          renderIt.push(
            <React.Fragment key={orgId}>
              <h1>Org: {grouped[orgId][0].organisation.name}</h1>
              {Object.keys(byGroup).map(group_id => {
                return <div key={group_id} style={{
                  marginBottom: "20px"
                }}>
                <h2>{group_id}</h2>
                <div>
                  Actions:
                  <Link to={"/monitor/"+group_id} className="ant-btn ant-btn-sm" style={{marginLeft: '8px'}}>
                    Log
                  </Link>
                  <button onClick={e => this.runInventory(group_id)} className="ant-btn ant-btn-sm" style={{marginLeft: '8px'}}>
                    Run Inventory
                  </button>
                </div>
                {Object.keys(groups).length ? 
                <React.Fragment>
                {groups[group_id] ? <div style={{marginBottom: '20px'}}>
                  Status: {groups[group_id].status}<br/>
                  Release ready: { groups[group_id].releaseReady ? 'yes' : 'no' }<br/>
                  { groups[group_id].releaseReady ?
                    <React.Fragment>Next release nest: { `#${groups[group_id].besty.nest} ${groups[group_id].besty.supernova}` }<br/></React.Fragment>
                  : null}
                  Total nests: { groups[group_id].list.length ? groups[group_id].list.length * 7 : 0 }<br/>
                  Free nests: {groups[group_id].freeNests}
                  { groups[group_id].launched ?
                  <React.Fragment><br/>Launched: {groups[group_id].launched} </React.Fragment>
                  : null }
                </div> : null }
                </React.Fragment>
                : null }
                <Table
                  style={{marginTop: '6px'}}
                  columns={ this.columns() }
                  loading={ isFetching }
                  dataSource={ byGroup[group_id] }
                  pagination={false}
                  onChange={ this.handleTableChange }
                  expandedRowRender={record => <React.Fragment>{this.getExpand(record)}</React.Fragment>}
                  expandRowByClick={true}
                  expandedRowKeys={expandedRowKeys}
                  bordered
                  size="middle"
                /></div>
              })}
            </React.Fragment>
          );

        });
      }
      if (grouped['0']) {
        renderIt.push(<div key={0} style={{
                marginBottom: "20px"
              }}>
              <h2>No group</h2>
              <Table
                  style={{marginTop: '6px'}}
                  columns={ this.columns() }
                  loading={ isFetching }
                  dataSource={ grouped['0'] }
                  pagination={false}
                  onChange={ this.handleTableChange }
                  expandedRowRender={record => <React.Fragment>{this.getExpand(record)}</React.Fragment>}
                  expandRowByClick={true}
                  expandedRowKeys={expandedRowKeys}
                  bordered
                  size="middle"
                /></div>)
      }
    }     
    return renderIt;
  }
  render() {

    const { searchText, visibleShowCommand, isFetching } = this.state;
    const suffix = searchText ? <CloseCircleOutlined key="1" onClick={this.emitEmpty} /> : null;
    return (
      <Content className="fill-bg-table">
        <Row gutter={8}>
          <Col span={18}>
            <h1 className="table-title">
              Supernovas {this.supernovasOnline()}
              <Button className="ant-btn ant-btn-sm" onClick={ () => { this.showCommandDialog('visibleShowCommand', true, 'SUPRENOVAS') } } style={{marginLeft: '8px'}}>
                Send everyone
              </Button>
              <Button className="ant-btn ant-btn-sm" onClick={ this.refreshData } style={{marginLeft: '8px'}}>
                Refresh data
              </Button>
              <Link to={"/monitor/SUPERNOVAS"} className="ant-btn ant-btn-sm" style={{marginLeft: '8px'}}>
                Show Log
              </Link>
            </h1>
          </Col>
          <Col span={6}>
           <Search style={{marginBottom: '6px'}} size="large" disabled={isFetching} ref={ele => this.searchText = ele} suffix={suffix} onChange={this.onSearch} placeholder="Search supernovas" value={searchText} 
           onPressEnter={this.onSearch} />
          </Col>
        </Row>
        <Card loading={isFetching}>
          { this.renderTable() }
        { visibleShowCommand ?
          <SendCommand 
            visible={visibleShowCommand}
            successResult={this.successResultCommand}
            machineId={this.state.machineIdToSend}
            handleClose={ () => { this.modalVisible('visibleShowCommand', false) } }
            /> : null }
        </Card>
      </Content>
    )
  }
}

export default SupernovasMonitor
