import React from 'react';
import { Modal, Button, Row, Col } from 'antd';
import domtoimage from 'dom-to-image';
import qrcode from 'screens/Dashboard/components/GroupSetting/qrcode';
import { saveAs } from 'file-saver';
import { CompactPicker } from 'react-color';
import ScanMeLogo from './scanMeLogo';

require('potrace-browser');

class QrGenerator extends React.Component {
  state = {
    loading: false,
    fillColor: '#000000',
    bgColor: '#FFFFFF',
    showIconScanMe: true
  }
  generateQR = (groupId) => {
    const { fillColor, bgColor } = this.state;
    const typeNumber = 0;
    const errorCorrectionLevel = 'H';
    const qr = qrcode(typeNumber, errorCorrectionLevel);
    const url = `https://e2g.app/i/${groupId}`;
    qr.addData(url);
    qr.make();
    const render = qr.createTableTag(25, 0, fillColor, bgColor);
    if (render) {
      return {
        __html: render
      };
    }
    return {
      __html: 'error'
    };
  }
  downloadPNG = () => {
    const { groupId } = this.props;
    this.setState({
      loading: true
    });

    domtoimage.toPng(document.getElementById('qrTarget'))
      .then((dataUrl) => {
        return saveAs(dataUrl, `qr-${groupId}-${(new Date()).toLocaleTimeString()}.png`);
      }).then(() => {
        this.setState({
          loading: false
        })
      });
  }
  downloadSVG = () => {
    const { groupId } = this.props;
    this.setState({
      loading: true
    });

    domtoimage.toPng(document.getElementById('qrTarget'))
      .then((dataUrl) => {
        window.Potrace.loadImageFromUrl(dataUrl);
        window.Potrace.process(function(){
          const svg = window.Potrace.getSVG(1);
          saveAs(new Blob([svg], {type: "image/svg+xml"}), `qr-${groupId}-${(new Date()).toLocaleTimeString()}.svg`);
        });
      }).then(() => {
        this.setState({
          loading: false
        })
      });
  }
  handleColor = (color, colorName) => {
    this.setState({ [colorName]: color.hex });
  }
  doPreset = (e, presetName) => {
    let fillColor = '#000000';
    let bgColor = '#FFFFFF';
    switch(presetName) {
      case 'Brand': 
        fillColor = '#FC4554';
        break;
      case 'WB': 
        fillColor = '#FFFFFF';
        bgColor = '#000000';
        break;
      default:
    }
    this.setState({
      fillColor,
      bgColor
    })
  }
  toggleIcon = () => {
    const { showIconScanMe } = this.state;
    this.setState({
      'showIconScanMe': !showIconScanMe
    });
  }
  render() {
    const { loading, fillColor, bgColor, showIconScanMe } = this.state;
  	const { handleClose, groupId, visible } = this.props;
    const boxStyle = {
      position: 'relative',
      padding: '20px',
      background: bgColor,
      display: 'inline-block',
    }
    return (
    	<Modal
        style={{ top: 20 }}
        visible={ visible }
        width={1000}
        title={`QR generator #${groupId}`}
        onOk={ handleClose }
        onCancel={ handleClose }
        footer={false}
      > 
        <div style={{
          marginBottom: '30px'
        }}>
          <Row gutter={8}>
            <Col span={4}>
              <h3>Download</h3>
              <Button onClick={ this.downloadPNG } loading={loading} style={{marginBottom: '8px'}}>
                Download PNG
              </Button>
              <Button onClick={ this.downloadSVG } loading={loading} style={{marginBottom: '8px'}}>
                Download SVG (B&W only)
              </Button>
              <h3>Scan Me Icon</h3>
              <Button onClick={ this.toggleIcon } style={{marginBottom: '8px'}}>
                {showIconScanMe ? 'Hide Icon' : 'Show Icon' } 
              </Button>
            </Col>
            <Col span={5}>
              <h3>Presets</h3>
              <Button onClick={ e => this.doPreset(e, 'Brand') } style={{marginBottom: '8px'}}>
                Brand Colors
              </Button>
              <Button onClick={ e => this.doPreset(e, 'BW') } style={{marginBottom: '8px'}}>
                Black and White
              </Button>
              <Button onClick={ e => this.doPreset(e, 'WB') } style={{marginBottom: '8px'}}>
                White and Black
              </Button>
            </Col>
            <Col span={7}>
              <h3>Fill color</h3>
              <CompactPicker
                color={ fillColor }
                onChangeComplete={ e => this.handleColor(e, 'fillColor') }
              />
            </Col>
            <Col span={7}>
              <h3>Background Color</h3>
              <CompactPicker
                color={ bgColor }
                onChangeComplete={ e => this.handleColor(e, 'bgColor') }
              />
            </Col>
            
          </Row>
        </div>
        <div style={{textAlign: 'center'}}>
          <span id="qrTarget" style={boxStyle}>
            <span dangerouslySetInnerHTML={this.generateQR(groupId)}></span>
            {showIconScanMe ? <ScanMeLogo fillColor={fillColor} bgColor={bgColor} /> : null }
          </span>
        </div>
      </Modal>
    )
  }
};

export default QrGenerator;
