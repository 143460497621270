import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Button } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const { TextArea } = Input;
const FormItem = Form.Item;

class GroupSetting extends React.Component {
	state = {
    putREQUEST: false,
    putFAILURE: false,
    putSUCCESS: false,
    putMessage: '',
    group: {
    	key_id: '',
    	notes: ''
    },
    getListRequest: false
  }
  componentDidMount() {
    this.getGroupSetting();
  }
  getGroupSetting = () => {
  	this.setState({
      getListRequest: true
    });
    fetchJSON(`/api/v1/group/${this.props.groupId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          getListRequest: false,
          group: response.data
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          getListRequest: false
        });
     });
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({
        putREQUEST: true
      })
      fetchJSON(`/api/v1/group/${this.props.groupId}`, {
        method: 'POST',
        body: fieldsValue
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false
          }, () => {
          	this.props.renderGroups();
          	this.props.handleClose();
          });
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false
          });
        }
       }).catch(error => {
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });
    })
  }
  render() {
  	const { group } = this.state;
  	const { visible, handleClose, form, groupId } = this.props;
  	const { getFieldDecorator } = form;
    return (
    	<Modal
        style={{ top: 20 }}
        visible={ visible }
        width={400}
        title={`Group settings #${group.key_id}`}
        onOk={ handleClose }
        onCancel={ handleClose }
        footer={false}
      >
	      <Form layout="vertical" onSubmit={this.handleSubmit}>
	      	<div style={{'display': 'none'}}>
            {getFieldDecorator('groupId', {
              rules: [{ required: false, message: 'Please enter a title!' }],
              initialValue: groupId
            })(
              <Input />
            )}
          </div> 
          <FormItem label="Note">
            {getFieldDecorator('notes', {
              rules: [{ required: true, message: 'Please enter a note!' }],
              initialValue: group.notes
            })(
              <TextArea autosize={{ minRows: 2, maxRows: 6 }} />
            )}
          </FormItem>
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(GroupSetting);
