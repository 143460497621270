import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Radio, Select } from 'antd';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;


const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'    
  };

class QcUserForm extends React.Component {
  state = {
    isFetching: false,
    onSelect: []
  }

  searchSupernovas = (text) => {
    if (text) {
      const supernovasList = this.props.listSupernovasIds;
      this.setState({
        isFetching: true
      })
      supernovasList.forEach(supernovaId => {
        if (text === supernovaId) {
          this.setState({
            isFetching: false,
            onSelect: (<Option key={supernovaId}>{supernovaId}</Option>)
          })    
        }
      })
    }
  }

  resetFilter = () => {
    this.setState({
      onSelect: [],
      isFetching: false
    })
  }

  render() {

    const { form } = this.props;
    const { getFieldDecorator } = form;
    const onSelect = this.state.onSelect;
    const isFetching = this.state.isFetching;

    return (
      <Form>
        <Row gutter={8}>
          <Col span={24}>
            <FormItem>
              <Row>
                <label className="form-label-custom">Name</label>
              </Row>
              <Row >
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Please enter a name' }],
                })(
                  <Input />
                )}
              </Row>
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <FormItem>
              <Row>
                <label className="form-label-custom">Description</label>
              </Row>
              <Row >
                {getFieldDecorator('description', {
                  rules: [{ required: false, message: 'Please enter a description' }],
                })(
                  <TextArea autosize={{ minRows: 1, maxRows: 6 }} />
                )}
              </Row>
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <FormItem>
              <Row>
                <label className="form-label-custom">Phone number</label>
              </Row>
              <Row >
                {getFieldDecorator('phone', {
                  rules: [{ required: true, message: 'Please enter a phone' }],
                })(
                  <Input />
                )}
              </Row>
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <FormItem>
              <Row>
                <label className="form-label-custom">Role</label>
              </Row>
              {getFieldDecorator('role', {
                rules: [{ required: true, message: 'Please select a role' }],
              })(
                <Radio.Group>
                  <Radio value={'tester'}>Tester</Radio>
                  <Radio value={'installer'}>Installer</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <FormItem>
              <Row>
                <label className="form-label-custom">Status</label>
              </Row>
              {getFieldDecorator('status', {
                rules: [{ required: true, message: 'Please select a status' }],
              })(
                <Radio.Group>
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>Disable</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <FormItem>
              <Row>
                <label className="form-label-custom">Group assignment</label>
              </Row>
                {getFieldDecorator('group_assignment', {
                  rules: [{ required: false, message: 'Please select a status' }],
                })(
                  <Radio.Group>
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <FormItem>
              <Row>
                <label className="form-label-custom">Access</label>
              </Row>
              {getFieldDecorator('access_label', {
                rules: [{ required: true, message: 'Please select an access' }],  
              })(
                <Radio.Group>
                  <Radio style={radioStyle} value={'Limited by supernova status'}>Limited by supernova status</Radio>      
                    <div style={{display: (this.props.form.getFieldValue('access_label') === 'Limited by supernova status') ? 'block' : 'none'  }}>
                      <Row>
                        <label className="form-label-custom">Status</label>
                      </Row>
                      {getFieldDecorator('access_to', {
                        rules: [{ required: false, message: 'Please select status for access' }],
                      })(
                        <Radio.Group>
                          <Radio.Button value={'factory'}>Factory</Radio.Button>
                          <Radio.Button value={'fulfillment'}>Fulfillment</Radio.Button>
                          <Radio.Button value={'tested'}>Tested</Radio.Button>
                          <Radio.Button value={'shipped'}>Shipped</Radio.Button>
                          <Radio.Button value={'active'}>Active</Radio.Button>
                        </Radio.Group>
                      )}
                    </div> 
                  <Radio style={radioStyle} value={'Limited by supernova ID'}>Limited by supernova ID</Radio>  
                    <div style={{display: (this.props.form.getFieldValue('access_label') === 'Limited by supernova ID') ? 'block' : 'none'  }}>                  
                      <Row>
                        <label className="form-label-custom">Supernovas IDs</label>
                      </Row>
                      {getFieldDecorator('access', {
                        rules: [{ required: false, message: 'Please select supernovas for access' }],
                      })(
                        <Select
                          showSearch
                          loading ={isFetching}
                          mode="multiple"
                          style={{ width: '100%' }}
                          notFoundContent={null}
                          placeholder="Please select supernovas IDs"  
                          onSearch={this.searchSupernovas}
                          onChange={this.resetFilter}
                          defaultActiveFirstOption={false}
                        >
                          {onSelect}
                        </Select> 
                      )}
                    </div>
                  <Radio style={radioStyle} value={'Any non-production supernova'}>Any non-production supernova</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <FormItem>
              <Row>
                <label className="form-label-custom">Login expiration</label>
              </Row>
              <Row>
                {getFieldDecorator('login_expiration', {
                  rules: [{ required: true, message: 'Please enter login expiration' }],
                })(
                  <Input />
                )}
              </Row>
            </FormItem>
          </Col>
        </Row>
        <FormItem>
          <Row style={{float: 'right'}}>
            <Button type="primary" htmlType="submit" onClick={this.props.onSubmit}>
              Save
            </Button>
          </Row>
        </FormItem>
      </Form>
    )
  }
}

export default QcUserForm;