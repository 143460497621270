import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, message } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

import QcUserForm from './QcUserForm';

const fields = ['name', 'description', 'phone', 'role', 'status', 'access', 'group_assignment', 'access_to', 'access_label', 'login_expiration'];

class AddQcUser extends React.Component {
  state = {
    listSupernovasIds: [],
    supernovas: []
  }

  componentDidMount() {
    this.getListSupernovas();
  }
  getListSupernovas = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        isFetching: true
      })
      fetchJSON('/api/v1/supernova/list', {
        method: 'get'
      }).then(response => {
        if (response.success) {
          this.setState({
            supernovas: response.data
          }, this.getListSupernovasIds); 
        resolve();
        } 
      }).catch(error => {
          console.log(error);
          reject();
        })
      }).catch(() => console.log('Oops errors!'));
  }

  getListSupernovasIds = () => {
    const supernovas = this.state.supernovas;
    const stringsSupernovas = [];
    for (let i = 0; i < supernovas.length; i++) {
      if (stringsSupernovas.indexOf(supernovas[i].string_id) === -1) {
        stringsSupernovas.push(supernovas[i].string_id)
      }
    }
    this.setState({
      listSupernovasIds: stringsSupernovas,
    })
  }
  onSubmit = () => {
    this.props.form.validateFields(fields)
      .then(values => {
        let data = this.props.form.getFieldsValue(fields);
        if (data && typeof data == 'object') {
          if (data.access_label === 'Limited by supernova ID') {
            if (!Array.isArray(data.access) || !data.access.length) {
              throw new Error('Please select supernovas for access');
            }
            data.access_to = null;
            this.props.form.setFieldsValue({'access_to': null});
          }
          else if (data.access_label === 'Limited by supernova status') {
            if (data.access_to == null) {
              throw new Error(`Please select status for access`);
            }
            data.access = [];
            this.props.form.setFieldsValue({'access': [] })
          }
          else if (data.access_label === 'Any non-production supernova') {
            data.access = [];
            data.access_to = null;
            this.props.form.setFieldsValue({'access': [] })
            this.props.form.setFieldsValue({'access_to': null})
          }
          if (Number(data.phone)) { 
            data.phone = Number(data.phone) 
          } else {
            throw new Error('Please enter correct phone')
          }
          if (Number(data.login_expiration)) {
            data.session_duration = Number(data.login_expiration) * 8;
          } else {
            throw new Error('Login expiration must be a number')
          }
          return data
        }
      else {
        throw new Error('Please try again')
        }
      })
      .then(data => {
        if (data && typeof data == 'object') {
          return new Promise((resolve, reject) => {
            fetchJSON(`/api/v1/qcusers/create`, {
              method: 'post',
              body: {
                name: data.name,
                description: data.description,
                phone: data.phone,
                role: data.role,
                status: data.status,
                group_assignment: data.group_assignment,
                access: data.access,
                access_to: data.access_to,
                session_duration: data.session_duration
              }
            }).then(response => {
              if (response.success) {
                  message.success('User succesfully created')
                  this.props.handleClose()
                  this.props.onClose() 
              resolve();
              }
              else if (response.message === 'user already exist') {
                message.error(`User with this phone number already exist`);
                }
            }).catch(error => {
                console.log(error);
                message.error('Please try again');
                reject();
              })
          }).catch(() => console.log('Oops errors!'));
        }
        else {
          throw new Error('Please try again')
        }
      })
      .catch(error => {
        const errors = ['Please select supernovas for access', `Please select status for access`, 'Please enter correct phone', 'Login expiration must be a number', 'Please try again']
        const index = errors.indexOf(error.message)
        if (index > -1 ) {
          message.error(errors[index])
        }
        else {
          message.error('Please fill in all fields');
        }
     }) 
  }

  render () {
  	const { handleClose, visible } = this.props;
    const form = this.props.form;
    return (
      <Modal
        style={{ top: 20 }}
        visible={ visible }
        width={800}
       	title="Add QC user"
       	onOk={ handleClose }
       	onCancel={ handleClose }
       	footer={false}
      >
      <QcUserForm form={form} onSubmit={this.onSubmit} listSupernovasIds={this.state.listSupernovasIds} /> 
      </Modal>
    )
  }
}

export default Form.create()(AddQcUser)

