import React from 'react';
import * as _modules from '../modules/_modules';
import { CloseCircleOutlined, GlobalOutlined } from '@ant-design/icons';
import { Table, Layout, Input, Row, Col, Tooltip, Tag, Button, Select, Modal } from 'antd';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import AdaIcon from 'components/images/icon-wheelchair-symbol.svg';
import fetchJSON from 'services/utils/fetchJSON';
import '../../Venues/styles.less';

const { Content } = Layout;
const Search = Input.Search;
const { Option } = Select;

export const statuses = [ 
  { text:'Active', value: 'active' },
  { text: 'Standby', value: 'standby' },
  { text: 'Repair', value: 'repair' },
  { text: 'Suspended', value: 'suspended' },
  { text: 'EOL', value: 'eol' },
];

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

class SupernovasTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    filteredInfo: {},
    sortedInfo: {
      order: 'ascend',
      columnKey: 'internet'
    },
    searchText: '',
    selectedRowKeys: [],
    selectedRowValues: [],
    filtered: false,
    data: [],
    searchData: [],
    detailedTable: false,
    searchParameter: false,
    groupFilter: [],
    orgFilter: [],
    modelFilter: [],
    regionFilter: []
    };
    this.totalNumberTable = 0;
    this.totalNumberInterval = null;
    Object.keys(_modules).forEach((_moduleName) => {
      this[_moduleName] = _modules[_moduleName].bind(this);
    });
  }
  componentDidMount() {
  	this.mounted = true;
    this.getSupernovasList();
    this.setState({
      pagination: {
        pageSizeOptions: ['10', '30', '50', '100'],
        showTotal: (total) => {
          return <p id="totalNumber" style={{display: 'none'}}>{this.renderTotal(total)}</p>
        }
      }
    })
  }
  componentWillUnmount() {
  	this.mounted = false;
    clearInterval(this.totalNumberInterval);
  }
  getSupernovasList = () => {
    if (!this.mounted) return false;
    this.setState({
      isFetching: true
    })
    let bodyObj = {};
    if (this.props.supernovaIds) {
    	bodyObj.supernovaIds = this.props.supernovaIds;
    }
    fetchJSON('/api/v1/supernova/list', {
      method: 'put',
      body: bodyObj
    }).then(response => {
      if (!this.mounted) return false;
      if (response.success) {
        const groupFilter = [];
        const orgFilter = [];
        const regionFilter = [];
        const modelFilter = []; 
        const storage = [];
        const storageOrg = [];
        const storageRegion = [];
        const storageModel = [];
        if (response.data && response.data.length > 0) {
          response.data.forEach(supernova => {
            let orgTitle = _.get(supernova, 'group.group.venue_groups.venue.organisation.organisation.name');
            let region = _.get(supernova, 'type.region');
            let model = _.get(supernova, 'type.title');
            let groupKeyId = _.get(supernova, 'group.group.key_id');
            if (orgTitle) {
              if (storageOrg.indexOf(orgTitle) === -1) {
                storageOrg.push(orgTitle);
                orgFilter.push({
                  text: orgTitle, value: orgTitle
                })
              }
            }
            if (region) {
              if (storageRegion.indexOf(region) === -1) {
                storageRegion.push(region);
                regionFilter.push({
                  text: region, value: region
                })
              }
            }
            if (model) {
              if (storageModel.indexOf(model) === -1) {
                storageModel.push(model);
                modelFilter.push({
                  text: model, value: model
                })
              }
            }
            if (groupKeyId) {
              if (storage.indexOf(groupKeyId) === -1) {
                storage.push(groupKeyId);
                groupFilter.push({
                  text: groupKeyId, value: groupKeyId
                });
              }
            }
          })
        }
        this.setState({
          isFetching: false,
          data: response.data,
          groupFilter,
          orgFilter,
          modelFilter,
          regionFilter,
        }, () => this.props.updateTotalNumberTable(response.data.length))
      } else {
        this.setState({
          isFetching: false
        })
      }
     }).catch(error => {
        console.log(error);
        if (this.mounted) {
          this.setState({
            isFetching: false
          })          
        }
     });
  }
  trackTotalNumberValue = () => {
    clearInterval(this.totalNumberInterval);
    if (this.props.getTotalNumberTable() !== this.totalNumberTable) {
      this.props.updateTotalNumberTable(this.totalNumberTable)
    }
    this.totalNumberInterval = null;
  }
  renderTotal = (total) => {
   this.totalNumberTable = total;
   if (this.props.getTotalNumberTable() !== this.totalNumberTable && !this.totalNumberInterval) {
    this.totalNumberInterval = setInterval(this.trackTotalNumberValue, 100); 
   }
   return total;
  } 
  isEllipsisActive = (e) => {
    if (e) {
      return (e.offsetWidth < e.scrollWidth)
    }
    else {
      return false
    }
  }
  columns = () => {
    let { sortedInfo, filteredInfo, detailedTable, regionFilter, orgFilter, modelFilter} = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    const signalStrengthList = [
      { text: 'Marginal', value: 'Marginal' },
      { text: 'OK', value: 'OK' },
      { text: 'Good', value: 'Good' },
      { text: 'Excellent', value: 'Excellent' },
      { text: 'WiFi', value: 'WiFi'}
    ];

    let LineItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap'}}>
            <p style={{margin: 0}}>Online</p>
          </div>
          )
      },
      dataIndex: 'internet',
      key: 'internet',
      render: (text, record) => (
        <span
          style={{ color: record.internet === 'online' ? 'green': 'red' }}
        ><GlobalOutlined style={{fontSize: '16px'}} /></span>
      ),
      filters: [
        { text: 'Online', value: 'online' },
        { text: 'Offline', value: 'offline'}
      ],
      align: 'center',
      filteredValue: filteredInfo.internet || null,
      onFilter: (value, record) => {
        return record.internet.includes(value)
      },
      sorter: (a, b) => a.internet > b.internet ? -1 : 1,
      sortOrder: sortedInfo.columnKey === 'internet' && sortedInfo.order,
      width: 100
    };
    let IdItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Supernova ID</p>
          </div>
          )
      },
      dataIndex: 'string_id',
      key: 'string_id',
      align: 'center',
      width: 130,
      render: (text, record) => (
        <Link to={`/supernovas/${record.string_id}`}> 
          {record.string_id}
        </Link>
    )};
    let ActualStatusItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Status</p>  
          </div>
          )
      },
      dataIndex: 'actual_status',
      key: 'actual_status',
      align: 'center',
      width: 138,
      filters: statuses,
      filteredValue: filteredInfo.actual_status || null,
      onFilter: (value, record) => {
        if (record.actual_status === value) return true;
      },
      sorter: (a, b) => a.actual_status > b.actual_status ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'actual_status' && sortedInfo.order,
      render: (text, record) => (
        <p style={{margin: 0}}>{capitalize(text)}</p>
      ),
    };
    let issuesItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Issues</p>  
          </div>
          )
      },
      key: 'issues',
      dataIndex: 'issues',
      align: 'center',
      width: 77,
      render: (text, record) => (
        <span
          style={{ color: '' }}
        >-</span>
      ),
    };
    let typeItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Type</p>  
          </div>
          )
      },
      key: 'type',
      dataIndex: 'type',
      align: 'center',
      width: 85,
      sorter: (a, b) => a.type > b.type ? 1 : -1,
      filters: [
        { text: 'Cellular', value: 'Cellular' },
        { text: 'WiFi', value: 'WiFi' }
      ],
      filteredValue: filteredInfo.type || null,
      onFilter: (value, record) => {
        return record.type.includes(value)
      },
      sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order
    }
    let locationItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Location</p>  
          </div>
          )
      },
      dataIndex: 'venue',
      key: 'venue',
      width: 120,
      render: (text, record) => (
        <div style={{whiteSpace: 'nowrap'}}>    
          { record.venue ?
          <Tooltip title={this.isEllipsisActive(document.getElementById(`venueTitleText_${record.key}}`)) ? record.venue : ''}>
            <Link to={`/venues/${record.venueUuid}`}> 
              <p id={`venueTitleText_${record.key}}`} style={{margin:0, width: '105px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                {record.venue}
              </p>
            </Link> 
          </Tooltip> : null }
        { !record.venue ? 
          <p style={{marginBottom: '0px'}}>Warehouse</p> : null }
        </div>
      )
    };
    let signalStrengthItem = {
      title: () => {
        return (
          <div style={{display: 'inline-flex', whiteSpace: 'nowrap'}}>
             <p style={{margin: 0}}>Signal strength</p>
          </div>
          )
      },
      filters: signalStrengthList,
      filteredValue: filteredInfo.signalStrength || null,
      onFilter: (value, record) => {
        return record.signalStrength.includes(value)
      },
      key: 'signalStrength',
      dataIndex: 'signalStrength',
      align: 'center',
      width: 160
    }
    let covid19 =     {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
           <p style={{margin: 0}}>Quarantine</p>
          </div>
          )
      },
      key: 'quarantine',
      dataIndex: 'quarantine',
      align: 'center',
      width: 120,
      render: (text, record) => (
        <span
          style={{ color: record.covid_quarantine ? 'green': 'red' }}
        >{text}</span>
      ),
    }
    let ticketsItem =    {
        title: () => {
          return (
            <div style={{ whiteSpace: 'nowrap' }}>
              <p style={{margin: 0}}>Tickets</p>
            </div>
            )
        },
        key: 'tickets',
        dataIndex: 'tickets',
        align: 'center',
        width: 80,
        sorter: (a, b) => a.tickets > b.tickets ? -1 : 1,
        sortOrder: sortedInfo.columnKey === 'tickets' && sortedInfo.order,
        render: (text, record) => (
          <div style={{whiteSpace: 'nowrap', margin: 0}}>
          { record.tickets > 0 ? 
            <Link to={`/tickets?spn=${record.id}`}>
              {record.tickets}
            </Link> : <p style={{margin: 0}}>0</p> }
          </div>
        )
    };
    let regionItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
            <p style={{margin: 0}}>Region</p>
          </div>
          )
      },
      filters: regionFilter,
      filteredValue: filteredInfo.region || null,
      onFilter: (value, record) => {
        return record.region.includes(value)
      },
      align: 'center',
      key: 'region',
      dataIndex: 'region',
      width: 120
    }
    let sessionsItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Sessions</p>
          </div>
          )
      },
      key: 'sessions',
      dataIndex: 'sessions',
      align: 'center',
      sorter: (a, b) => a.sessions > b.sessions ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'sessions' && sortedInfo.order,
      width: 90
    }
    let locationIdItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Location ID</p>
          </div>
          )
      },
      dataIndex: 'groupid',
      key: 'groupid',
      render: (text, record) => (
        <Link to={`/venues/${record.venueUuid}?groups=1`}> 
          <Tag color="green" style={{cursor: "pointer", display: record.groupid ? '' : 'none'}}> 
            {record.groupid}
          </Tag>
        </Link>
      ),
      align: 'center',
      width: 113,
      filters:  [
        { text: 'Has loc ID', value: 'Has loc ID' },
        { text: 'No Loc ID', value: 'No Loc ID' }
      ],
      filteredValue: filteredInfo.groupid || null,
      onFilter: (value, record) => {
        if (record.groupid) {
          if (value === 'Has loc ID') {
            return true
          }
        }
        else if (value === 'No Loc ID' ) {
          return true
        }
        return false
      }
    };
    let electronsItem = {
      title: 'Electrons',
      dataIndex: 'electrons',
      key: 'electrons',
      align: 'center',
      width: 93,
      sortOrder: sortedInfo.columnKey === 'electrons' && sortedInfo.order,
      sorter: (a, b) => a.electrons - b.electrons
    };
    let organisationItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Organization</p>
          </div>
          )
      },
      dataIndex: 'organisation',
      key: 'organisation',
      width: 140,
      filters: orgFilter,
      filteredValue: filteredInfo.organisation || null,
      onFilter: (value, record) => {
        return _.get(record, 'organisation') === value;
      },
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`organisationTitleText_${record.key}}`)) ? record.organisation : ''}>
            <p id={`organisationTitleText_${record.key}}`} style={{margin:0, width: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              {record.organisation}
            </p>
          </Tooltip>
        </div>
        )
    };
    let adaItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <img style={{ maxWidth: '18px' }} src={AdaIcon} alt="wheelchair symbol"/>
          </div>
          )
      },
      key: 'ada_status',
      align: 'center',
      dataIndex: 'ada_status',
      width: 90,
      render: (text, record) => (
        <span
          style={{ color: record.ada_status === 'On' ? 'green': 'red' }}
        >{text}</span>
      ),
    }
    let modelItem = {
      title: 'Model',
      dataIndex: 'model',
      align: 'center',
      key: 'model',
      width: 135,
      filters: modelFilter,
      filteredValue: filteredInfo.model || null,
      onFilter: (value, record) => {
        return record.model.includes(value)
      },
    };
    let warrantyItem = {
      title: 'Warranty',
      dataIndex: 'warranty',
      key: 'warranty',
      align: 'center',
      width: 120,
      render: (text, record) => (
          <p style={{color: record.warranty === 'Expired' ? 'red' : '', marginBottom: '0px'}}>{record.warranty}</p>
      ),
      filters:  [
        { text: 'Active', value: 'Active' },
        { text: 'Expired', value: 'Expired' }
      ],
      filteredValue: filteredInfo.warranty || null,
      onFilter: (value, record) => {
        if (record.warranty) {
          if (value === 'Expired') {
            return record.warranty.includes(value)
          }
          else return !record.warranty.includes(value) && record.warranty !== 'Expired'
        }
        return null
      }
    };
    let wareHouseItem = {
      title: 'Warehouse',
      dataIndex: 'warehouse',
      key: 'warehouse',
      align: 'warehouse',
      width: 120,
    };
    if (this.state.detailedTable) {

    }
    const columns = [];
    if (detailedTable) {
      LineItem.fixed = 'left';
    }
    const generalColumns = [
      LineItem,
      signalStrengthItem,
      IdItem,
      ActualStatusItem,
    ];
    if (!this.props.inGroup) {
    	generalColumns.push(locationItem, locationIdItem)
    }
    generalColumns.push(
    	electronsItem,
      sessionsItem,
      issuesItem,
      ticketsItem
    )
    const detailedColumns = [
      modelItem,
      typeItem,
      regionItem,
      warrantyItem,
      organisationItem,
      wareHouseItem,
      covid19,
      adaItem
    ];

    columns.push(...generalColumns);
    if (detailedTable) {
      columns.push(...detailedColumns);
    }
    return columns;
  }
  getData = () => {
    const activeStatuses = ['active', 'fulfillment', 'factory', 'tested', 'shipped', 'inactive'];
    const data = [];
    let response = this.state.data;
    if (this.state.filtered) {
      response = this.state.searchData;
    }
    if (Array.isArray(response) && response.length) {
      response.forEach((supernova, i) => {
        let venueTitle = _.get(supernova, 'group.group.venue_groups.venue.title');
        let venueId = _.get(supernova, 'group.group.venue_groups.venue.id');
        let venueUuid = _.get(supernova, 'group.group.venue_groups.venue.uuid');
        let groupId = _.get(supernova, 'group.group.key_id');
        let group_domestic_id = _.get(supernova, 'group.group_id');
        let covid_quarantine = _.get(supernova, 'group.group.covid_quarantine');
        let orgTitle = _.get(supernova, 'group.group.venue_groups.venue.organisation.organisation.name');
        let model = _.get(supernova, 'type.title');
        let region = _.get(supernova, 'type.region');
        if (activeStatuses.indexOf(supernova.actual_status) > -1) {
          supernova.actual_status = groupId ? 'active' : 'standby';
        }
        let signalStrength = '';
        if (supernova.status === 'online') {
          if (supernova.string_id.substring(0, 2) === 'SC') {
            signalStrength = supernova.signal_strength;
          }
          else {
            signalStrength = 'WiFi';
          }
        }
        //console.log('supernova warehouses', supernova.warehouses);
        data.push({
          key: i,
          string_id: supernova.string_id,
          id: supernova.id,
          groupid: groupId,
          internet: supernova.status,
          actual_status: supernova.actual_status,
          electrons: supernova.electrons ? supernova.electrons.length : 0,
          venue: venueTitle,
          venueId: venueId,
          esp_version: supernova.esp_version,
          type: supernova.string_id.substring(0, 2) === 'SC' ? 'Cellular' : 'WiFi',
          tickets: supernova.ticketsAmount,
          issues: 'OK',
          ada_status: supernova.ada_status ? 'On': 'Off' ,
          signalStrength: signalStrength || '-',
          quarantine: covid_quarantine ? 'On' : 'Off' ,
          covid_quarantine,
          warranty: supernova.warranty,
          region: region || '-',
          sessions: supernova.user_sessions,
          organisation: orgTitle,
          model: model,
          group_domestic_id,
          venueUuid,
          warehouse: supernova.warehouses
        })
      });
    }
    return data;
  }
  handleTableChange = (pagination, filters, sorter) => {
    //console.log('handleTableChange', pagination, filters, sorter);
    setTimeout(() => {
      if (!document.getElementById("totalNumber")) {
        this.props.updateTotalNumberTable(0);
      }
    }, 100)
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  onSearch = (e) => {
    this.setState({ searchText: e.target.value });
    const { searchParameter, data } = this.state;
    const searchText = e.target.value;
    const reg = new RegExp(e.target.value, 'gi');
    this.setState({
      filtered: !!searchText,
      searchData: _.flatMap(data, record => {
        const idMatch = record.string_id.match(reg) && (searchParameter === 'spnId' || !searchParameter);
        let groupId = _.get(record, 'group.group.key_id');
        let venueTitle = _.get(record, 'group.group.venue_groups.venue.title');
        let orgTitle = _.get(record, 'group.group.venue_groups.venue.organisation.organisation.name');
        const venueTitleMatch = venueTitle && !this.props.inGroup ? venueTitle.match(reg) && (searchParameter === 'venueTitle' || !searchParameter) : false;
        const orgTitleMatch = orgTitle && !this.props.inGroup ? orgTitle.match(reg) && (searchParameter === 'orgName' || !searchParameter) : false;
        const groupIdMatch = groupId && !this.props.inGroup ? groupId.match(reg) && (searchParameter === 'groupId' || !searchParameter) : false;
        if (!groupIdMatch && !idMatch && !orgTitleMatch && !venueTitleMatch) {
          return null;
        }
        return {          
          ...record,
          };
      }).filter(record => !!record),
    });
    if (e.target.value === ''){
      this.setState({
        data,
        filtered: false
      });
    }
  }
  onRowSelect = (selectedRowKeys, selectedRowValues) => {
    //console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRowValues);
    let unlinkDisabled = false;
    if (selectedRowKeys && (selectedRowKeys.length > 1 || (selectedRowValues.length === 1 && !selectedRowValues[0].groupid))) {
      unlinkDisabled = true;
    }
    this.setState({
      selectedRowKeys,
      selectedRowValues,
      unlinkDisabled
    });
  }
  emitEmpty = () => {
    this.setState({ 
      searchData: [],
      searchText: '',
      filtered: false
    });
  }
  changeSearchParameter = (value) => {
    const { searchText } = this.state;
    const searchObj = {
      target: {
        value: searchText
      }
    }
    this.setState({
      searchParameter: value
    }, () => this.onSearch(searchObj) )
  }
  actions = (value) => {
    const { selectedRowValues } = this.state;
    if (!selectedRowValues || !selectedRowValues.length) {
      Modal.warning({
        title: 'Please, select one Supernova for this action.', 
        maskClosable: true })
      return;
    }
    if (value === 'assign') {
      const supernovasToAssign = [];
      let canAssign = true;
      selectedRowValues.forEach((row) => {
        if (row.groupid) {
          canAssign = false;
        }
        if (supernovasToAssign.indexOf(row.id) === -1) {
          supernovasToAssign.push(row.id);
        }
      })
      if (canAssign) {
        this.assignToGroup(supernovasToAssign)
      } else {
        Modal.warning({title: 'Please, select only those Supernovas which do not have a Location ID.', maskClosable: true});
        this.setState({
          selectedRowValues: [],
          selectedRowKeys: []
        })
      }
    }
    if (value === 'unlink' && selectedRowValues[0]) {
      const supernovaObj = {group: {group: {venue_groups: {venue: {}}}}};
      supernovaObj.group.group_id = selectedRowValues[0].group_domestic_id;
      supernovaObj.group.group.venue_groups.venue.id = selectedRowValues[0].venueUuid;
      supernovaObj.id = selectedRowValues[0].id;
      supernovaObj.string_id = selectedRowValues[0].string_id;
      this.setState({supernovaObj}, this.confirmRemovingSupernova(true))
    }
  }
  render() {
    const { searchText, detailedTable, selectedRowKeys, unlinkDisabled, isFetching, pagination } = this.state;
    const suffix = searchText ? <CloseCircleOutlined key="1" onClick={this.emitEmpty} /> : null;
	  const searchBy = (
	    <Select style={{ width: '170px'}} onChange={ (value) => this.changeSearchParameter(value) } placeholder="Search by">
	      <Option value="spnId">Supernova ID</Option>
	      <Option style={{display: this.props.inGroup ? 'none' : ''}} value="groupId">Location ID</Option>
	      <Option style={{display: this.props.inGroup ? 'none' : ''}} value="venueTitle">Location name</Option>
	      <Option style={{display: this.props.inGroup ? 'none' : ''}} value="orgName">Organization name</Option>
	    </Select>
	  );
    return (
      <Content className="fill-bg-table">
        <Row gutter={8} style={{marginBottom: '8px', alignItems: 'center'}}>
          <Col style={{display: 'inline-flex'}} sm={12}>
            <Select style={{ width: '180px'}} placeholder="Actions" onChange={ (value) => this.actions(value) } value={'Actions'}>
              <Option value="assign">Assign</Option>
              <Option value="unlink" disabled={unlinkDisabled} >Unlink</Option>
            </Select>
            <Button onClick={() => this.setState({ detailedTable: !this.state.detailedTable }) } style={{marginLeft: '16px'}}>{detailedTable ? 'General' : 'Detailed'}</Button>
          </Col>
          <Col span={12}>
            <div style={{width: 'fit-content', marginLeft: 'auto'}}>
             <Search 
              style={{marginBottom: '8px', width: '425px', marginLeft: 'auto'}} 
              disabled={isFetching} 
              onChange={this.onSearch} 
              placeholder="Search supernova"
              allowClear 
              addonBefore={searchBy} 
             />
           	</div>
         	</Col>
      	</Row>
        <Table
        	className="scrollYTable"
          rowSelection = {
            {
              selectedRowKeys: selectedRowKeys,
              onChange: this.onRowSelect
            }
          }
          columns={ this.columns() }
          loading={ isFetching }
          size="middle"
          dataSource={ this.getData() }
          pagination={{
            ...pagination,
            showSizeChanger: true
          }}
          scroll={ detailedTable ? { x: 'max-content' } : {}}
          onChange={ this.handleTableChange }
          showSorterTooltip={false}
        />
      </Content>
    )
  }
}

export default SupernovasTable