import React from 'react';
import { CloudUploadOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Card, Button, Row, Alert, Col, Radio, Spin, Modal } from 'antd';
import Notes from 'screens/Dashboard/components/Notes/Notes';
import fetchJSON from 'services/utils/fetchJSON';
import ElectronHistory from 'screens/Dashboard/components/ElectronHistory/ElectronHistory';
import qs from 'qs';
import { Link } from 'react-router-dom';
import HistoryLog from 'screens/Dashboard/components/HistoryLog/HistoryLog';
import ElectronSupernovaInfo from './components/ElectronSupernovaInfo';

const FormItem = Form.Item;
const confirm = Modal.confirm;

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};
const tabList = [{
  key: 'info',
  tab: 'Info',
}, {
  key: 'history',
  tab: 'History',
}, {
  key: 'history_log',
  tab: 'Statistics',
}];

const statuses = [
  { text: 'On factory', value: 'on_factory' },
  { text: 'Active', value: 'active' },
  { text: 'Repair', value: 'repair' },
  { text: 'EOL', value: 'eol' },
  { text: 'Opened', value: 'opened' },
  { text: 'Lost', value: 'lost' }
];

const realiseStatuses = [
  { text: 'OK', value: 'ok' },
  { text: 'Stopped', value: 'stopped' }
];

class SingleElectron extends React.Component {
  state = {
    isFetching: false,
    errorMessage: '',
    visibleNotes: false,
    owner_string_id: '',
    supernova: {
      id: 0,
      string_id: ''
    },
    electron: {
      string_id: '',
      status: {
        owner_type: 'supernova',
        owner_id: 0,
        nest_id: 0
      },
      hardware_revision: '',
      cable_types: ''
    },
    visibleElectronHistory: false,
    fetchingStatus: false,
    activeTab: 'history'
  }
  componentDidMount() {
    const electronId = this.props.electronId;
    let paramFromUrl = {};
    if (window.location.search) {
      paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    }
    let activeTab = 'info';
    if (Object.keys(paramFromUrl).length) {
      Object.keys(paramFromUrl).forEach((param) => {
        if (paramFromUrl[param]) {
          activeTab = param;
        }
      });
    }
    this.setState({
      electronId,
      activeTab: activeTab
    }, this.getSingleElectron)

  }
  getSingleElectron = () => {
    this.setState({
      isFetching: true
    })
    fetchJSON(`/api/v1/electrons/${this.state.electronId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          isFetching: false,
          electron: response.data
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  showNotes = () => {
    this.setState({
      visibleNotes: true
    })
  }
  hideNotes = () => {
    this.setState({
      visibleNotes: false
    })
  }
  showModal = () => {
    this.setState({
      visibleElectronHistory: true,
    });
  }
  handleClose = () => {
    this.setState({ visibleElectronHistory: false });
  }
  onReleaseStatusChange = (e) => {
    const { electronId, electron } = this.state;
    const release_status = e.target.value;
    electron.release_status = release_status;
    confirm({
      title: `Change electron release status to "${release_status}"?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            fetchingStatus: true
          })
          fetchJSON(`/api/v1/electrons/updateStatus/${electronId}`, {
            method: 'put',
            body: {
              release_status: release_status
            }
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                fetchingStatus: false,
                electron
              });
              resolve()
            } else {
              this.setState({
                fetchingStatus: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                fetchingStatus: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  onStatusChange = (e) => {
    const { electronId, electron } = this.state;
    const actual_status = e.target.value;
    electron.actual_status = actual_status;
    confirm({
      title: `Change electron status to "${actual_status}"?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            fetchingStatus: true
          })
          fetchJSON(`/api/v1/electrons/updateStatus/${electronId}`, {
            method: 'put',
            body: {
              actual_status: actual_status
            }
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                fetchingStatus: false,
                electron
              });
              resolve()
            } else {
              this.setState({
                fetchingStatus: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                fetchingStatus: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  onDelete = () => {
    const { electronId, electron } = this.state;
    electron.actual_status = 'archive';
    confirm({
      title: `Are you sure you want to delete this electron?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            fetchingStatus: true
          })
          fetchJSON(`/api/v1/electrons/updateStatus/${electronId}`, {
            method: 'put',
            body: {
              actual_status: 'archive'
            }
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                fetchingStatus: false,
                electron
              });
              window.location.href = "/electrons";
              resolve()
            } else {
              this.setState({
                fetchingStatus: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                fetchingStatus: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

    eject = () => {
    const { electron } = this.state;
    const supernova_string_id = electron.status.owner_type === 'supernova' ? electron.owner_string_id : null;
    const nest = electron.status.nest_id ? electron.status.nest_id : null;
    const command = 'r'+nest;
    confirm({
      title: `Eject electron from nest ${nest} of supernova "${supernova_string_id}"?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            rebootLoading: true
          })
          fetchJSON(`/api/v1/supernova/talk/${supernova_string_id}`, {
            method: 'put',
            body: {
              command
            }
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                ejectLoading: false,
                electron
              });
              resolve()
            } else {
              this.setState({
                ejectLoading: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                ejectLoading: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  onTabChange = (key, type) => {  
    
    if (this.props.modal === false) {
      const electronId  = this.props.electronId;
      this.props.history.push(`/electrons/${electronId}?${key}=1`);
    }      
    this.setState({ [type]: key });

    
  }
  render() {
    const {
      isFetching,
      errorMessage,
      visibleNotes,
      electronId,
      electron,
      fetchingStatus,
      ejectLoading,
      activeTab
    } = this.state;

    let ownerUrl = '';
    let status = '';
    let lookUp = '';

    if (electron.status.owner_type === 'supernova') {
      status = 'IN';
      ownerUrl = 'supernovas';
      lookUp = `/supernovas/${electron.owner_string_id}`;
    }
    if (electron.status.owner_type === 'lost_from_supernova') {
      status = 'LOST';
      ownerUrl = 'supernovas';
      lookUp = `/supernovas/${electron.owner_string_id}`;
    }
    if (electron.status.owner_type === 'stolen_from_supernova') {
      status = 'STOLEN';
      ownerUrl = 'supernovas';
      lookUp = `/supernovas/${electron.owner_string_id}`;
    }
    if (electron.status.owner_type === 'user') {
      status = 'OUT';
      ownerUrl = 'users';
      lookUp = `/users/${electron.status.owner_id}`;
    }
    if (electron.status.owner_type === 'storage') {
      status = 'WAREHOUSE';
      ownerUrl = false;
      lookUp = false;
    }

    if (electron.status.owner_type === 'lost_forever') {
      status = 'LOST FOREVER';
      ownerUrl = false;
      lookUp = false;
    }
    const ownerSupernova = ['supernova', 'lost_from_supernova', 'stolen_from_supernova'];

    return (
      <div className="modalWindow">
        <Card
          loading={isFetching}
          type="inner"
          title={"Electron #"+electron.string_id}
          tabList={tabList}
          activeTabKey={activeTab}
          onTabChange={(key) => { this.onTabChange(key, 'activeTab'); } }
          extra={(!this.props.isExternal) ? <Button type="danger" onClick={this.onDelete} style={{float: 'right'}} >
                  <DeleteOutlined /> Delete
                </Button> : null}
          > 
          { activeTab ==='info' ?
            <Form>
              { errorMessage ? 
                <FormItem><Alert message={errorMessage} type="error"/></FormItem>
              : null }
              <FormItem
                  {...formItemLayout}
                >
                <Row gutter={8}>
                  <Col span={12}>
                    <Row>
                      <label className="form-label-custom">ID</label>
                    </Row>
                    <Row >
                      <Input value={ electron.string_id } readOnly={true} />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <label className="form-label-custom">
                        { ownerUrl ? 
                          <React.Fragment>
                          { ownerUrl === 'supernovas' ? 'Supernova ID' : 'User phone' }
                          </React.Fragment>
                          : null
                        }
                      </label>
                      { lookUp ? 
                        <React.Fragment>
                          <Link to={`${lookUp}`} target="_blank" className="ant-btn ant-btn-sm">Look up</Link>
                        </React.Fragment>
                      : null }
                    </Row>
                    <Row >
                      <Input value={ electron.owner_string_id } readOnly={true} />
                    </Row>
                  </Col>
                </Row>
              </FormItem>
              <FormItem
                  {...formItemLayout}
                >
                <Row gutter={8}>
                  <Col span={12}>
                    <Row>
                      <label className="form-label-custom">Cable types</label>
                    </Row>
                    <Row >
                      <Input value={ electron.cable_types } readOnly={true} />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <label className="form-label-custom">Hardware revision</label>
                    </Row>
                    <Row >
                      <Input value={ electron.hardware_revision } readOnly={true} />
                    </Row>
                  </Col>
                </Row>
              </FormItem>
              { ownerSupernova.indexOf(electron.status.owner_type) > -1 ? 
                  <ElectronSupernovaInfo electronId={electronId} />
                  : null}    
              <FormItem
              {...formItemLayout}
              >
                <Row>
                  <label className="form-label-custom">Release Status</label>
                </Row>
                <Spin spinning={ fetchingStatus }>
                  <RadioGroup onChange={this.onReleaseStatusChange} value={ electron.release_status }>
                    { realiseStatuses.map(status => <RadioButton key={status.value} value={status.value}>{status.text}</RadioButton>) }
                  </RadioGroup>
                </Spin>
              </FormItem>
               <FormItem
              {...formItemLayout}
              >
                <Row>
                  <label className="form-label-custom">Actual Status</label>
                </Row>
                <Spin spinning={ fetchingStatus }>
                  <RadioGroup onChange={this.onStatusChange} value={ electron.actual_status }>
                    { statuses.map(status => <RadioButton key={status.value} value={status.value}>{status.text}</RadioButton>) }
                  </RadioGroup>
                </Spin>
              </FormItem>
              { !isFetching ? 
                <div>
                <FormItem
                    {...formItemLayout}
                  >
                  <Row gutter={8}>
                    <Col span={24}>
                      <Row>
                        <label className="form-label-custom">Use status</label>
                      </Row>
                      <Row >
                        <Input value={ status } readOnly={true} />
                      </Row>
                    </Col>
                  </Row>
                </FormItem>
                </div>
                : null}
              <FormItem
                {...formItemLayout}
              >
                <Row className="btn-group">
                { (status === 'IN' && electron.owner_string_id && electron.status.nest_id) ?
                <Button loading={ejectLoading} type="primary" onClick={ this.eject }>
                    <CloudUploadOutlined /> Eject
                  </Button> : null }
                  <Button onClick={ this.showNotes }>
                    <QuestionCircleOutlined /> Notes
                  </Button>
                  { visibleNotes ? <Notes visible={visibleNotes} handleClose={this.hideNotes} sourceId={electronId} source="electron" /> : null }
                </Row>
              </FormItem>
            </Form> : null }
          { activeTab === 'history' ? 
            <div>
               <ElectronHistory modalView={false} electronID={electronId} />
            </div>
          : null}
          { activeTab === 'history_log' ?
              <React.Fragment> {electronId ? <HistoryLog source="electron" sourceId={electronId} /> : null }</React.Fragment>
           : null }
        </Card>
      </div>
    );
  }
}

export default SingleElectron;