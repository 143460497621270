import React, { PureComponent } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Layout, Spin, Row, Col, Switch, Checkbox, Select, Alert } from 'antd';
import { Tooltip as AntdTooltip } from 'antd';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Pie,
  PieChart,
  Cell,
  CartesianGrid,
  Label,
  LineChart,
  Line,
  ReferenceLine
} from 'recharts';

import * as _sharedModules from '../../modules/_modules';

import { emptyUsageObj, emptyEssentialDataObj, emptyLifetimeObj, emptyUtilisationObj, emptyRetentionObj } from '../../modules/generalreports';

import LifetimeCard from './components/LifetimeCard/LifetimeCard';
import DateSelectChoice from '../DateSelectChoice/DateSelectChoice';

import './styles.less';


const { Content } = Layout;
const { Option } = Select;

class GeneralReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      COLORS: ['#5856d6', '#4cd964', '#ff3b30', '#ff2d55', '#ff9500', '#ffcc00'],
      // usage block
      dateRangeUserSessionsGraph: 'weekly',
      activeUsersSessionsGraph: false,
      // utilisation block
      utilisationBarChart: 'station',
      utilisationVenueId: false,
      // fetch indicators
      isEssentialFetching: false,
      isRetentionFetching: false,
      isUsageFetching: false,
      isUtilisationFetching: false,
      isLifetimeFetching: false,
      // dataObjects
      essentialData: emptyEssentialDataObj,
      usageData: emptyUsageObj,
      utilisationData: emptyUtilisationObj,
      lifetimeData: emptyLifetimeObj,
      retentionData: emptyRetentionObj,
      // time objects
      retentionWheelTimeOverride: false,
      usageTimeOverride: false,
      utilisationTimeOverride: false,
      retentionWheelRange: null,
      usageRange: null,
      utilisationRange: null,
      shouldUpdateTurnOffOverrides: false,
      retentionAllTime: false,
      offline_supernova_warning: false
    };

    const requiredModulesNames = ['getBodyObjGeneral', 'getRetentionData', 'getFullGeneralData', 'getEssential', 'getLifetimeResults', 'getUsageData', 'getUtilisationData'];

    Object.keys(_sharedModules).forEach((_moduleName) => {
      if (requiredModulesNames.indexOf(_moduleName) > -1) {
        this[_moduleName] = _sharedModules[_moduleName].bind(this);
      }      
    });
  }
  componentDidMount() {
    this.mounted = true;
    this.notForTest = false;
    this.singleLocationMode = this.props.singleLocationMode;
    this.getFullGeneralData();
  }
  componentWillUnmount(){
    this.mounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    const filterTypeChange = this.props.filterByLocationType !== prevProps.filterByLocationType;
    const orgChange = !this.props.filterByLocationType && this.props.targetOrgId !== prevProps.targetOrgId;
    const businessTypeChange = this.props.filterByLocationType && this.props.targetBusinessTypeId !== prevProps.targetBusinessTypeId;
    const businessCategoryChange = this.props.filterByLocationType && this.props.targetBusinessCategoryId !== prevProps.targetBusinessCategoryId;
    const venueChange = !this.props.filterByLocationType && prevProps.targetVenueId !== this.props.targetVenueId;
    if (filterTypeChange || orgChange || venueChange || businessTypeChange || businessCategoryChange) {
      this.getFullGeneralData();
    }
    if (this.props.childShouldUpdateDateChanged) {
      this.getFullGeneralData();
      this.props.turnOffShouldUpdate();
    }
    if (this.props.shouldUpdateTurnOffOverrides) {
      this.setState({
        shouldUpdateTurnOffOverrides: this.props.shouldUpdateTurnOffOverrides,
        retentionWheelTimeOverride: false,
        usageTimeOverride: false,
        utilisationTimeOverride: false
      });
      this.props.turnOffShouldUpdateTimeOverride()
    }
    if (this.props.updateLocationMultiChange) {
      this.getFullGeneralData();
      this.props.turnOffShouldUpdateMultiLocations();
    }
    this.singleLocationMode = this.props.singleLocationMode;
  }
  changeState = (key, value) => {
    this.setState({
      [key]: value
    })
  }
  essentialBlockRender = () => {
    const { isEssentialFetching, essentialData, COLORS } = this.state;
    const { targetVenueId } = this.props;
    return (
      <Spin spinning={isEssentialFetching}>
        <Row style={{marginTop: '8px', marginLeft: '-12px'}}>
          { !this.singleLocationMode &&
            <Col sm={4} xs={12}>
              <div className="circle-data-container">
                <div className="label">
                  <label>
                    Locations online
                  </label>
                </div>
                <div className="circle-wrapper">
                  <div className="circle">
                    <div className="text">{essentialData.locationsOnline}</div> 
                  </div>
                </div>
              </div>
            </Col>  }
          { !this.singleLocationMode && <Col sm={1} xs={0}/> }
          <Col sm={this.props.targetVenueId !== -1 && essentialData.supernovaStations ? 4 : 6} xs={24}>
            <div className="circle-data-container">
              <div className="label" style={{width: this.props.targetVenueId !== -1 && !essentialData.supernovaStations ? '66%' : '100%'}}>
                <label>Supernova stations</label>
              </div>
              <div style={{height: '240px', display: 'flex'}}>
              { essentialData.supernovaStations ?
                <ResponsiveContainer>
                  <PieChart width={200} height={100}>   
                    <Pie 
                      dataKey="count" 
                      data={essentialData.supernovasPieData} 
                      cx="50%" cy="50%" 
                      innerRadius={'48%'}
                      outerRadius={'88%'} 
                      paddingAngle={5}
                      fill="#8884d8"
                      legendType="diamond"
                      isAnimationActive={false}
                    >
                      { essentialData.supernovasPieData.map((entry, index) => <Cell key={index} stroke={COLORS[index + 1 % COLORS.length]} fill={COLORS[index + 1 % COLORS.length]} /> ) }
                      <Label fill="#6B6F6E" className="inner-pie-label" value={essentialData.supernovaStations} position="center"/>
                    </Pie>
                    <Legend verticalAlign="bottom" align="center"/> 
                    <Tooltip/>
                  </PieChart>
                </ResponsiveContainer> : 
                <Col span={16} style={{margin: this.props.targetVenueId !== -1 ? '0 auto 0 0': '0 auto'}}>
                  <div className="circle-wrapper">
                    <div className="circle">
                      <div className="text">{essentialData.supernovaStations}</div>
                    </div>
                  </div>
                </Col> }
              </div>
            </div>
          </Col>
          { targetVenueId !== -1 && essentialData.supernovaStations !== 0 && <Col sm={2} xs={0}/> }
          <Col sm={6} xs={24}>
            <div className="circle-data-container">
              <div className="label">
                <label>Electrons</label>
              </div>
              <div style={{height: '240px', display: 'flex'}}>
                { essentialData.electrons ?
                <ResponsiveContainer>
                  <PieChart width={200} height={100}>   
                    <Pie 
                      dataKey="count" 
                      data={essentialData.electronsPieData} 
                      cx="50%" cy="50%" 
                      innerRadius={'48%'}
                      outerRadius={'88%'} 
                      paddingAngle={5}
                      fill="#8884d8"
                      legendType="diamond"
                      isAnimationActive={false}
                    >
                      { essentialData.electronsPieData.map((entry, index) => <Cell key={index} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} /> ) }
                      <Label fill="#6B6F6E" className="inner-pie-label" value={essentialData.electrons} position="center"/>
                    </Pie>
                    <Legend verticalAlign="bottom" align="center"/> 
                    <Tooltip/>
                  </PieChart>
                </ResponsiveContainer> :
                <Col span={16} style={{margin: '0 auto'}}>
                  <div className="circle-wrapper">
                    <div className="circle">
                      <div className="text">{essentialData.electrons}</div>
                    </div>
                  </div>
                </Col> }
              </div>
            </div>
          </Col>
          { targetVenueId !== -1 && <Col sm={2} xs={0}/>}
          <Col sm={4} xs={12}>
            <div className="circle-data-container">
              <div className="label">
                <label>
                  Active delay return
                </label>
                <AntdTooltip title="Users that have electron and return time exceed 24 hours"><QuestionCircleOutlined
                  style={{color: '#5856d6', fontSize: '16px', marginLeft: '8px', lineHeight: '1.7'}} /></AntdTooltip>
              </div>
              <div className="circle-wrapper">
                <div className="circle red">
                  <div className="text">{essentialData.activeDelayReturn}</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
    )
  }
  usageBlockRender = () => {
    const { usageData, retentionData, retentionAllTime, shouldUpdateTurnOffOverrides, isRetentionFetching, isUsageFetching, COLORS, dateRangeUserSessionsGraph, activeUsersSessionsGraph } = this.state;
    //console.log(usageData.sessionsPieData, 'usageData.sessionsPieData')
    return (
      <div>
        <Spin spinning={isRetentionFetching}>
          <Row>
            <Col span={24}>
              <DateSelectChoice
                name="retention-wheel"
                turnOffShouldUpdate={() => this.setState({ shouldUpdateTurnOffOverrides: false })}
                shouldUpdateTurnOffOverrides={shouldUpdateTurnOffOverrides}
                updateDataOnTimeChange={(isTimeOverrided, newRange, rangeType) => this.controllTimeOverrideBlock('retentionWheelTimeOverride', isTimeOverrided, this.getRetentionData, newRange, rangeType)}
                incorrectDataWarning={false}
              />
            </Col>
            <Col span={24}>
              <Row className="margin-24" style={{display: 'inline-flex'}}>
                <div className="retention-wheel-container">
                  <div className="center-descr"><span>Growth</span></div>
                  <div className="reg-descr">{`${retentionData.register.percentage}%` }</div>
                  <div className="checkout-descr">{`${retentionData.check_out.percentage.toFixed()}%` }</div>
                  <div className="return-descr" style={{left: retentionData.return.percentage.toFixed().length === 3 ? '2%' : retentionData.return.percentage.toFixed().length === 2 ? '4%' : '6%'}}>{`${retentionData.return.percentage.toFixed()}%` }</div>
                </div>
                { retentionData.register.percentage ?
                <div className="retention-wheel-container-legend-web">
                  <Row>
                    <Col span={24}>
                      <ul type="square">
                        { this.props.targetOrgId === -1 && false ? <li>{`${retentionData.open.users} users opened app.`}</li> : null }
                        <li style={{color: '#5856D6'}}>{`${retentionData.register.users} users registered.`}</li>
                        <li style={{color: '#FF3B30'}}>{`${retentionData.check_out.users} users checked out electron. ${retentionData.check_out.percentage.toFixed()}% from registered.`}</li>
                        <li style={{color: '#4CD964'}}>{`${retentionData.return.users} user returned. ${retentionData.return.percentage.toFixed()}% from those who checked out electron.`}</li>
                      </ul>
                    </Col>
                    <Col span={24}>
                      <Row style={{paddingInlineStart: '20px', display: 'inline-flex'}}>
                        <Checkbox checked={retentionAllTime} onChange={(e) => {
                          this.setState({ 
                            retentionAllTime: e.target.checked
                            }, () => {
                              const bodyObj = this.getBodyObjGeneral();
                              this.getRetentionData(bodyObj);
                            })
                        }}/>
                        <p style={{marginLeft: '8px', fontSize: '16px', lineHeight: '1.5', marginBottom: '0px'}}>Show data for all period</p>
                        <AntdTooltip title="Checkouts and returns data is shown in choosen time period by default. Click here if you want to see if users had checkout and return up to date."><QuestionCircleOutlined
                          style={{color: '#5856d6', fontSize: '16px', marginLeft: '8px', lineHeight: '1.7'}} /></AntdTooltip>
                      </Row>
                    </Col>
                  </Row>
                </div> : null }
              </Row>
            </Col>
          </Row>
        </Spin>
        <Spin spinning={isUsageFetching}>
          <Row className="top-margin">
            <Col span={24}>
              <DateSelectChoice
                name="usage"
                turnOffShouldUpdate={() => this.setState({ shouldUpdateTurnOffOverrides: false })}
                shouldUpdateTurnOffOverrides={shouldUpdateTurnOffOverrides}
                daily={true}
                updateDataOnTimeChange={(isTimeOverrided, newRange, rangeType) => this.controllTimeOverrideBlock('usageTimeOverride', isTimeOverrided, this.getUsageData, newRange, rangeType)}           
              />
            </Col>
            { this.singleLocationMode ?     
              <Col sm={usageData.uniqueUsers ? 5 : 4} xs={24}>
                <div className="circle-data-container">
                  <div className="label">
                    <label>Active users</label>
                    <AntdTooltip title="Users that had at least one session"><QuestionCircleOutlined
                      style={{color: '#5856d6', fontSize: '16px', marginLeft: '8px', lineHeight: '1.7'}} /></AntdTooltip>
                  </div>
                  <div style={{height: '240px', display: 'flex'}}>
                    { usageData.uniqueUsers ?
                    <ResponsiveContainer>
                      <PieChart width={200} height={100}>   
                        <Pie 
                          dataKey="count" 
                          data={usageData.uniqueUsersPieData} 
                          cx="50%" cy="50%" 
                          innerRadius={'48%'}
                          outerRadius={'88%'} 
                          paddingAngle={5}
                          fill="#8884d8"
                          legendType="diamond"
                          isAnimationActive={false}
                        >
                          { usageData.uniqueUsersPieData.map((entry, index) => <Cell key={index} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} /> ) }
                          <Label fill="#6B6F6E" className="inner-pie-label" value={usageData.uniqueUsers} position="center"/>
                        </Pie>
                        <Legend verticalAlign="bottom" align="center"/> 
                        <Tooltip/>
                      </PieChart>
                    </ResponsiveContainer> :
                    <Col span={24} style={{margin: '0 auto'}}>
                      <div className="circle-wrapper">
                        <div className="circle">
                          <div className="text">{usageData.uniqueUsers}</div>
                        </div>
                      </div>
                    </Col> }
                  </div>
                </div>
              </Col> : null }
            { !usageData.uniqueUsers && <Col xs={0} sm={1}/> }
            <Col sm={usageData.sessions ? 5 : 4} xs={24}>
              <div className={this.singleLocationMode ? "circle-data-container margin-24" : "circle-data-container"}>
                <div className="label">
                  <label>Sessions</label>
                  <AntdTooltip title="Pickup of the charger by users"><QuestionCircleOutlined
                    style={{color: '#5856d6', fontSize: '16px', marginLeft: '8px', lineHeight: '1.7'}} /></AntdTooltip>
                </div>
                <div style={{height: '240px', display: 'flex'}}>
                  { usageData.sessions ? 
                  <ResponsiveContainer>
                    <PieChart width={200} height={100}>   
                      <Pie 
                        dataKey="count" 
                        data={usageData.sessionsPieData} 
                        cx="50%" cy="50%" 
                        innerRadius={'48%'}
                        outerRadius={'88%'} 
                        paddingAngle={5}
                        fill="#8884d8"
                        legendType="diamond"
                        isAnimationActive={false}
                      >
                        { usageData.sessionsPieData.map((entry, index) => <Cell key={index} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} /> ) }
                        <Label fill="#6B6F6E" className="inner-pie-label" value={usageData.sessions} position="center"/>
                      </Pie>
                      <Legend verticalAlign="bottom" align="center"/> 
                      <Tooltip/>
                    </PieChart>
                  </ResponsiveContainer> :
                  <Col span={24} style={{margin: '0 auto'}}>
                    <div className="circle-wrapper">
                      <div className="circle">
                        <div className="text">{usageData.sessions}</div>
                      </div>
                    </div>
                  </Col> }
                </div>
              </div>
            </Col>
            <Col sm={1} xs={0}/>
            { !usageData.uniqueUsers && <Col xs={0} sm={1}/> }
            { this.singleLocationMode ?
            <Col sm={4} xs={12}>
              <div className="circle-data-container">
                <div className="label">
                  <label>
                    Avg session per user
                  </label>
                </div>
                <div className="circle-wrapper">
                  <div className="circle">
                    <div className="text">{usageData.avgSessionPerUser}</div>
                  </div>
                </div>
              </div>
            </Col> : null}
            <Col sm={1} xs={0}/>
            <Col sm={4} xs={12}>
              <div className="circle-data-container margin-24">
                <div className="label">
                  <label>
                    Avg session per day
                  </label>
                </div>
                <div className="circle-wrapper">
                  <div className="circle">
                    <div className="text">{usageData.avgSessionPerDay}</div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="top-margin">
            <Col span={24}>
              <Row className="row-label-btns">
                <label>New users vs sessions weekly</label>
                <button className={dateRangeUserSessionsGraph === 'weekly' ? 'ant-btn ant-btn-primary ant-btn-round' : 'ant-btn ant-btn-round'} onClick={() => this.changeState('dateRangeUserSessionsGraph', 'weekly')}>
                  Weekly
                </button>
                <button className={dateRangeUserSessionsGraph === 'daily' ? 'ant-btn ant-btn-primary ant-btn-round' : 'ant-btn ant-btn-round'} onClick={() => this.changeState('dateRangeUserSessionsGraph', 'daily')}>
                  Daily
                </button>
              </Row>
              { this.singleLocationMode ?
              <Row style={{marginTop: '6px'}}>
                <span style={{color: '#6B6F6E', fontSize: '16px', lineHeight: 1.2}}>Show active users</span>
                <Switch style={{marginLeft: '8px'}} onChange={(value) => this.setState({ activeUsersSessionsGraph: value })}/>
              </Row> : null }
            </Col>
          </Row>
          <Row className="top-margin">
            <div style={{height: '400px', width: '100%'}}>
              <ResponsiveContainer width="95%" height="100%">
                <LineChart
                  width={500}
                  height={300}
                  data={dateRangeUserSessionsGraph === 'weekly' ? usageData.graphWeeklyData : usageData.graphDailyData }
                  margin={{
                    top: 5,
                    right: 30,
                    left: 0,
                    bottom: 5
                  }}
                >
                  <CartesianGrid vertical={false} />
                  <XAxis dataKey="name" />
                  <XAxis tickLine={false} dataKey="x"/>
                  <YAxis />
                  <Tooltip />
                  { dateRangeUserSessionsGraph === 'weekly' ? 
                    Object.keys(usageData.reference_lines_years_weeks).map((year_key, index) => {
                     return  <ReferenceLine key={index} x={usageData.reference_lines_years_weeks[year_key]} 
                      stroke="gray" strokeWidth={2} strokeDasharray="3 3" label={{value: year_key, position: 'top', offset: 10}} />
                    })
                  : null }
                  { dateRangeUserSessionsGraph === 'daily' ? 
                    Object.keys(usageData.reference_lines_years_days).map((year_key, index) => {
                     return  <ReferenceLine alwaysShow={true} key={index} x={usageData.reference_lines_years_days[year_key]} 
                      stroke="gray" strokeWidth={2} strokeDasharray="3 3" label={{value: year_key, position: 'top', offset: 10}} />
                    })
                  : null }
                  <Legend iconSize={24} verticalAlign="top" height={50}/>
                  { activeUsersSessionsGraph && 
                    <Line name="Active users" strokeWidth={3} type="monotone" dataKey="active" stroke="#5856d6" /> }
                  { activeUsersSessionsGraph && 
                    <Line name="Registered users" strokeWidth={3} type="monotone" dataKey="new" stroke="#4cd964" /> }
                  { activeUsersSessionsGraph && 
                    <Line name="Sessions" strokeWidth={3} type="monotone" dataKey="sessions" stroke="#ff2d55" /> }
                  { !activeUsersSessionsGraph && 
                    <Line name="Registered users" strokeWidth={3} type="monotone" dataKey="new" stroke="#4cd964" /> }
                  { !activeUsersSessionsGraph && 
                    <Line name="Sessions" strokeWidth={3} type="monotone" dataKey="sessions" stroke="#ff2d55" /> }
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Row>
          { this.singleLocationMode ?
          <Row className="top-margin">
            <Col span={24}>
              <Row>
                <Col sm={usageData.usersFreeToPaid ? 9 : 7} xs={24}>
                  <div className="circle-data-container">
                    <div className="label">
                      <label style={{whiteSpace: 'nowrap'}}>Users upgraded from free to paid</label>
                    </div>
                    <div style={{height: '240px', display: 'flex'}}>
                    { usageData.usersFreeToPaid ?
                      <ResponsiveContainer>
                        <PieChart width={200} height={100}>   
                          <Pie 
                            dataKey="count" 
                            data={usageData.usersFreeToPaidPieData} 
                            cx="50%" cy="50%" 
                            innerRadius={50}
                            outerRadius={90} 
                            paddingAngle={5}
                            fill="#8884d8"
                            legendType="diamond"
                            isAnimationActive={false}
                          >
                            { usageData.usersFreeToPaidPieData.map((entry, index) => <Cell key={index} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} /> ) }
                            <Label fill="#6B6F6E" className="inner-pie-label" value={usageData.usersFreeToPaid} position="center"/>
                          </Pie>
                          <Legend verticalAlign="middle" align="right" layout="vertical"/> 
                          <Tooltip/>
                        </PieChart>
                      </ResponsiveContainer> :
                      <Col span={14} style={{margin: '0 auto'}}>
                        <div className="circle-wrapper">
                          <div className="circle">
                            <div className="text">{usageData.usersFreeToPaid}</div>
                          </div>
                        </div>
                      </Col> }
                    </div>
                  </div>
                </Col>
                <Col sm={3} xs={0}/>
                <Col sm={usageData.usersDailyToLongterm ? 9 : 7} xs={24}>
                  <div className="circle-data-container margin-24">
                    <div className="label">
                      <label style={{whiteSpace: 'nowrap'}}>Users upgraded from daily to longterm</label>
                    </div>
                    <div style={{height: '240px', display: 'flex'}}>
                    { usageData.usersDailyToLongterm ?
                      <ResponsiveContainer>
                        <PieChart width={200} height={100}>   
                          <Pie 
                            dataKey="count" 
                            data={usageData.usersDailyToLongtermPieData} 
                            cx="50%" cy="50%" 
                            innerRadius={50}
                            outerRadius={90} 
                            paddingAngle={5}
                            fill="#8884d8"
                            legendType="diamond"
                            isAnimationActive={false}
                          >
                            { usageData.usersDailyToLongtermPieData.map((entry, index) => <Cell key={index} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} /> ) }
                            <Label fill="#6B6F6E" className="inner-pie-label" value={usageData.usersDailyToLongterm} position="center"/>
                          </Pie>
                          <Legend verticalAlign="middle" align="right" layout="vertical"/> 
                          <Tooltip/>
                        </PieChart>
                      </ResponsiveContainer> :
                      <Col span={14} style={{margin: '0 auto'}}>
                        <div className="circle-wrapper">
                          <div className="circle">
                            <div className="text">{usageData.usersDailyToLongterm}</div>
                          </div>
                        </div>
                      </Col> }
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row> : null }
        </Spin>
      </div>
    );
  }
  utilisationBlockRender = () => {
    const { isUtilisationFetching, shouldUpdateTurnOffOverrides, utilisationBarChart, utilisationData, utilisationVenueId } = this.state;
    const avg_utilisation_station = !this.singleLocationMode && utilisationVenueId && utilisationData.venuesData[utilisationVenueId] ? utilisationData.venuesData[utilisationVenueId].avgPerStation : utilisationData.avgPerStation;
    return (
      <Spin spinning={isUtilisationFetching}>
        <Row>
          <Col span={24}>
            <Row>
              { !this.singleLocationMode ?
                <div style={{marginRight: '8px'}}>
                  <Row>
                    <h3>Select location</h3>
                  </Row>
                  <Row>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        const name = option && option.props && option.props.children ? option.props.children : '';
                        return name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }} 
                      style={{width: '200px', margin: 'auto 0'}}
                      placeholder="Location"
                      value={utilisationVenueId}
                      onChange={(value) => { this.setState({ utilisationVenueId: value })}}
                      >
                      {utilisationData.venuesInfo.map((venue, i) => <Option key={i} value={venue.venue_id}>{venue.title}</Option>)}
                    </Select>
                  </Row>
                </div>
                : null }
              <DateSelectChoice
                name="utilisation"
                turnOffShouldUpdate={() => this.setState({ shouldUpdateTurnOffOverrides: false })}
                shouldUpdateTurnOffOverrides={shouldUpdateTurnOffOverrides}
                updateDataOnTimeChange={(isTimeOverrided, newRange, rangeType) => this.controllTimeOverrideBlock('utilisationTimeOverride', isTimeOverrided, this.getUtilisationData, newRange, rangeType)}
              />
            </Row>
          </Col>    
          <Col span={24}>
            <Row style={{display: 'inline-flex', width: '100%'}}>
              <Col sm={18} xs={24}>
                <Row className="row-label-btns">
                  <label>Utilization rate avg</label>
                  { false ? 
                    <span>
                      <button className={utilisationBarChart === 'station' ? 'ant-btn ant-btn-primary ant-btn-round' : 'ant-btn ant-btn-round'} onClick={() => this.changeState('utilisationBarChart', 'station')}>
                        Per station
                      </button>
                      <button className={utilisationBarChart === 'electron' ? 'ant-btn ant-btn-primary ant-btn-round' : 'ant-btn ant-btn-round'} onClick={() => this.changeState('utilisationBarChart', 'electron')}>
                        Per charger
                      </button>
                    </span> : null }
                </Row>
                <Row>
                  <div style={{width: '100%', height: '400px'}}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        width={500}
                        height={300}
                        data={(!this.singleLocationMode && utilisationVenueId && utilisationData.venuesData[utilisationVenueId]) ? utilisationData.venuesData[utilisationVenueId].barChartData : utilisationData.barChartData}
                        margin={{
                          top: 20,
                          right: 30,
                          left: -20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid vertical={false} horizontal={false}/>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Legend verticalAlign="top" align="center"
                          formatter={(value, entry, index) => {
                            return entry.dataKey === 'return' ? 'Returns' : 'Checkouts'
                          }} />
                        <Tooltip formatter={(value, name, props) => {
                          return !props.dataKey ? [] : props.dataKey === 'checkout' ? [`${value.toFixed()}%`, 'Checkouts. On average', props] : [`${value.toFixed()}%`, 'Returns. On average', props]
                        }}/>
                        { utilisationBarChart === 'station' && <Bar name="Station" dataKey="checkout" fill="#4cd964" /> }
                        { utilisationBarChart === 'station' && <Bar name="Station" dataKey="return" fill="#5856d6" /> }
                        { utilisationBarChart === 'electron' && <Bar name="Electron" dataKey="electron" fill="#5856d6" /> }   
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Row>
              </Col>
              <Col sm={4} xs={24} style={{display: 'flex'}}>
                <div className="circle-data-container margin-24" style={{margin: 'auto 0px auto 30px'}}>
                  <div className="label">
                    <label>
                      Utilization avg
                    </label>
                  </div>
                  <div className="circle-wrapper">
                    <div className="circle green">
                      { utilisationBarChart === 'station' ?
                        <div className="text">{avg_utilisation_station.toFixed()}%</div>
                        : <div className="text">{utilisationData.avgPerCharger.toFixed()}%</div> }
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    )
  }
  controllTimeOverrideBlock = (name, isTimeOverrided, callback, newRange, rangeType) => {
    const bodyObj = this.getBodyObjGeneral();
    let timeValueName;
    const timeValueObj = {
      type: rangeType,
      momentObj: newRange
    };
    switch(name) {
      case 'retentionWheelTimeOverride':
        timeValueName = 'retentionWheelRange';
        break;
      case 'usageTimeOverride':
        timeValueName = 'usageRange';
        break;
      case 'utilisationTimeOverride':
        timeValueName = 'utilisationRange';
        break;
      default:
        break;
    }
    this.setState({
      [name]: isTimeOverrided,
      [timeValueName]: timeValueObj
    }, () => {
      callback(bodyObj);
    })
  }
  render() {
    const {
      isLifetimeFetching,
      lifetimeData,
      offline_supernova_warning
    } = this.state;
    
    return (
      <Content className="unselectable">
        { offline_supernova_warning ?
        <Row style={{marginBottom: '12px'}}>
          <Alert 
            type="error"
            style={{width: '100%'}}
            closable={true}
            message={<span style={{fontSize: '16px'}}>Please note that yesterday supernova was offline.</span>}
            closeText={<span style={{fontSize: '16px'}}>Ok</span>}
          />
        </Row> : null }
        <Row>
          <h2 className="header">Essential
            <AntdTooltip title="Current data which does not depend on selected time period.">
              <QuestionCircleOutlined
                style={{color: '#5856d6', lineHeight: '2.2', fontSize: '18px', marginLeft: '8px'}} />
            </AntdTooltip>
          </h2>
        </Row>
        { this.essentialBlockRender() }
        <Row style={{marginTop: '16px'}}>
          <h2 className="header">Usage</h2>
        </Row>
        {this.usageBlockRender()}
        <Row style={{marginTop: '16px'}}>
          <h2 className="header">Utilization</h2>
        </Row>
        { this.utilisationBlockRender()}
        <Row style={{marginTop: '16px'}}>
          <h2 className="header">Lifetime milestones</h2>
        </Row>   
        <Row>
          <Col span={24}>
            <Spin spinning={isLifetimeFetching}>
              <Row>
                <LifetimeCard
                  data={lifetimeData}
                  singleLocationMode={this.singleLocationMode}
                />
              </Row>
            </Spin>
          </Col>
        </Row>
      </Content>
    );
  }
}

export default GeneralReports;