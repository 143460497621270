import React from 'react';
import { DatePicker, Select, Table, Spin } from 'antd';
import { Row, Col } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import { getTimeDistance } from 'services/utils/utils';
import {
  Tooltip,
  Legend,
  ResponsiveContainer,
  Pie,
  PieChart,
  Cell,
} from 'recharts';

const { RangePicker } = DatePicker;
const Option = Select.Option;
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
const tooltipStyles = {
  margin: '0px',
  padding: '10px',
  backgroundColor: 'rgb(255, 255, 255)',
  border: '1px solid rgb(204, 204, 204)',
  whiteSpace: 'nowrap'
}

const columns = [
  {
    title: 'Timing',
    dataIndex: 'title',
  },
  {
    title: 'Amount',
    dataIndex: 'count',
  },
  {
    title: 'Percentage',
    dataIndex: 'percentage',
  },
  {
    title: 'Users',
    dataIndex: 'users',
  },
];
var distinctColors = require('distinct-colors');
var number_of_colors = 100;
var COLORSs = distinctColors({count: number_of_colors});

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#ffeb3b', '#d4fc79', '#fda085', '#fbc2eb'];
COLORSs.forEach(color => {
  COLORS.push(`rgba(${color._rgb[0]}, ${color._rgb[1]}, ${color._rgb[2]}, ${color._rgb[3]})`);
});


class SubscriptionsTooltip extends React.Component {
  render() {
    const { active } = this.props;
    if (active) {
      const { payload } = this.props;
      return (
        <div className="recharts-default-tooltip" style={tooltipStyles}>
          {`${payload[0].name}: ${payload[0].payload.percent}`}
        </div>
      );
    }
    return null;
  }
}

class Statistics extends React.Component {
  state = {
    isFetching: false,
    filteredInfo: {},
    sortedInfo: {},
    filtered: false,
    data: [],
    rangePickerValue: getTimeDistance('total'),
    planFilterValues: [],
    planFilterValue: 'no_value',
    totalSession: 0,
    groups: {},
    usersSessions: {},
    usersPlans: {}
  };
  componentDidMount() {
    document.title = 'Sessions Statistics';
    this.getStatistics();
  }
  getStatistics = () => {
    const { rangePickerValue } = this.state;
    
    const url = '/api/v1/session/statistics';

    this.setState({
      isFetching: true
    });

    fetchJSON(url, {
      method: 'post',
      body: {
        dates: [rangePickerValue[0].format(), rangePickerValue[1].format()]
      }
    }).then(response => {
      console.log(response);
      let planFilterValues = [];
      if (response.data.planFilter) {
        planFilterValues = Object.keys(response.data.planFilter);
      }
      this.setState({
        statistics: response.data,
        isFetching: false,
        planFilterValues,
        totalSession: response.data.total,
        groups: response.data.groups,
        usersSessions: response.data.usersSessions,
        usersPlans: response.data.planFilter
      });
    }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false
      });
    });
  }
  isActive(type) {
    const { rangePickerValue } = this.state;
    const value = getTimeDistance(type);
    if (!rangePickerValue[0] || !rangePickerValue[1]) {
      return '';
    }
    if (
      rangePickerValue[0].isSame(value[0], 'day') &&
      rangePickerValue[1].isSame(value[1], 'day')
    ) {
      return 'ant-btn-primary';
    }

    return '';
  }
  selectDate = (type) => {
    this.setState({
      rangePickerValue: getTimeDistance(type),
    }, this.getStatistics);
  }
  handleRangePickerChange = (rangePickerValue) => {
    console.log('rangePickerValue', rangePickerValue);
    rangePickerValue[0].tz('America/Los_Angeles').startOf('day');
    rangePickerValue[1].tz('America/Los_Angeles').endOf('day');
    this.setState({
      rangePickerValue,
    }, this.getStatistics);
  }
  planFilter = (value) => {
    this.setState({
      planFilterValue: value
    });
  }
  getList = () => {
    const { planFilterValue, groups, usersSessions, usersPlans } = this.state;
    const data = [];
    // console.log('groups', groups);
    let list = JSON.parse(JSON.stringify(groups));
    const chart = [];
    
    if (planFilterValue !== 'no_value') {

      const usersFilteredId = usersPlans[planFilterValue];
      if (usersFilteredId && usersFilteredId.length) {
        const allowedListGlobal = [];
        usersFilteredId.forEach(uid => {
          usersSessions[uid].forEach((sessionId) => {
            allowedListGlobal.push(sessionId);
          });
        });
        Object.keys(list).forEach(key => {
            list[key] = list[key].filter(sessionId => {
              if (allowedListGlobal.indexOf(sessionId) > -1) return true;
              return false;
            });
        });
      }
    }
    let totalHere = 0;
    if (typeof list === 'object') {
      if (Object.keys(list).length) {
        Object.keys(list).forEach((key, index) => {
          if (list[key].length) {
            totalHere += list[key].length;
          }
        });
        Object.keys(list).forEach((key, index) => {
          if (list[key].length) {
            let users = [];
            Object.keys(usersSessions).forEach((uid) => {
              usersSessions[uid].forEach((sessionId) => {
                if (list[key].indexOf(sessionId) > -1) {
                  if (users.indexOf(uid) === -1) {
                    users.push(uid);
                  }
                }
              });
            });
            let title = capitalize(key.split('_').join(' '));
            let count = list[key].length;
            let percentage = ((list[key].length/totalHere)*100).toFixed(2);
            data.push({
              key: index,
              title: title,
              percentage: `${percentage}%`,
              count: count,
              users: users.length
            });
            chart.push({ name: title, percent: `${percentage}%`, count: count  })
          }
        });
      }
    }

    return(<React.Fragment>
      <h2>Sessions: {totalHere}</h2>
      <Row gutter={8}>
        <Col span={12}>
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              total: data.length,
              pageSize: data.length,
              hideOnSinglePage: true
            }}
          />
        </Col>
        <Col span={12}>
          <div style={{paddingLeft: '25px'}}>
            <ResponsiveContainer height={400} width='100%'>
              <PieChart>
                <Pie
                  data={chart}
                  dataKey="count"
                  cx="50%" cy="50%" 
                  innerRadius={30}
                  outerRadius={80} 
                  fill="#8884d8"
                  paddingAngle={5}
                >
                  {
                    chart.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                  }
                </Pie>
                <Tooltip content={<SubscriptionsTooltip />} />
                <Legend align="left" layout='vertical' verticalAlign='top' />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Col>
    </Row>
    </React.Fragment>)
  }
  render() {
    const {
      rangePickerValue,
      planFilterValues,
      planFilterValue,
      totalSession,
      isFetching
    } = this.state;
    
    return (
      <div className="wrapper StatisticsScreen">
        <div>
          <Row gutter={8}>
            <Col span={8}>
              <h1 className="table-title">
                Sessions Statistics (Total in system: {totalSession})
              </h1>
            </Col>
          </Row>
          <div className="usageElectronWrap">
            <Row gutter={8}>
              <Col span={24} className="textAlignRight">
                { planFilterValues.length ? <Select value={planFilterValue} onChange={this.planFilter} style={{minWidth: '200px', 'paddingRight': '10px'}}>
                  <Option key="999" value="no_value">No filter</Option>
                  { planFilterValues.map((value, key) => <Option key={key} value={value}>{value}</Option>) }
                </Select> : null }
                <div className="usageElectron">
                  <button className={"ant-btn "+this.isActive('today')} onClick={() => this.selectDate('today')}>
                    Today
                  </button>
                  <button className={"ant-btn "+this.isActive('week')} onClick={() => this.selectDate('week')}>
                    Week
                  </button>
                  <button className={"ant-btn "+this.isActive('month')} onClick={() => this.selectDate('month')}>
                    Month
                  </button>
                  <button className={"ant-btn "+this.isActive('sixmonths')} onClick={() => this.selectDate('sixmonths')}>
                    Last 6 month
                  </button>
                  <button className={"ant-btn "+this.isActive('year')} onClick={() => this.selectDate('year')}>
                    Year
                  </button>
                  <button className={"ant-btn "+this.isActive('total')} onClick={() => this.selectDate('total')}>
                    Total
                  </button>
                </div>
                <RangePicker
                  value={rangePickerValue}
                  onChange={this.handleRangePickerChange}
                />
              </Col>
            </Row>
          </div>
          <div style={{background: '#fff', 'padding': '16px', 'marginTop': '16px'}}>
            <Spin spinning={ isFetching }>
              {this.getList()}
            </Spin>
          </div>
        </div>
      </div>
    )
  }
};

export default Statistics;