import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Input,
  Button,
  Row,
  Alert,
  Col,
  Modal,
  DatePicker,
  Select,
  message,
  InputNumber,
} from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const Option = Select.Option;
const moment = require('moment');

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

class AddPromo extends React.Component {
  state = {
    isFetching: false,
    errorMessage: '',
    visibleNotes: false,
    promocodeId: '',
    promocode: {
      transactions: []
    },
    visibleElectronHistory: false,
    fetchingStatus: false,
    activeTab: 'info',
    rangePickerValue: [],
    organisationsList: [],
    venueList: [],
    plansList: [],
    privateList: [],
    privateType: ''
  }
  componentDidMount() {
    this.getLists();
  }
  handleRangePickerChange = (rangePickerValue) => {
    this.setState({
      rangePickerValue,
    });
  }
  getLists = () => {
    this.setState({
      isFetching: true
    })
    fetchJSON(`/api/v1/promocode/constants`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          isFetching: false,
          organisationsList: response.data.organisationsList,
          venueList: response.data.venueList,
          plansList: response.data.plansList
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  handleClose = () => {
    this.setState({ visibleElectronHistory: false });
  }
  
  registerPrivateList = (value) => {
    const { organisationsList, venueList } = this.state;
    let privateList = [];
    if (value === 'organisations') {
      organisationsList.forEach(organisation => {
        privateList.push({
          id: organisation.id,
          title: organisation.name
        })
      })
      console.log(privateList);
    }
    if (value === 'venues') {
      venueList.forEach(venue => {
        privateList.push({
          id: venue.id,
          title: venue.title
        })
      })
    }
    this.setState({
      privateList
    })
  }
  handleDependenciesChange = (value) => {
    const { organisationsList, venueList } = this.state;
    let privateList = [];
    if (value === 'organisations') {
      organisationsList.forEach(organisation => {
        privateList.push({
          id: organisation.id,
          title: organisation.name
        })
      })
      console.log(privateList);
    }
    if (value === 'venues') {
      venueList.forEach(venue => {
        privateList.push({
          id: venue.id,
          title: venue.title
        })
      })
    }
    this.props.form.setFields({
      private_id: {
        value: ''
      },
    });
    this.setState({
      privateList,
      privateType: value
    })
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({
        putREQUEST: true
      })
      fetchJSON(`/api/v1/promocode/create`, {
        method: 'post',
        body: fieldsValue
      }).then(response => {
        console.log(response);
        if (response.success) {
          message.success('Successfully create');
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false
          });
	        this.props.history.push(`/promocodes/${response.data.id}`);
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
          });
          message.error(response.message);
        }
        setTimeout(() => {
          this.setState({
            errorMessage: ''
          });
        }, 3000)
       }).catch(error => {
          console.log(error);
          message.error('Promotional code already exists');
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });  
    })
  }
  disabledDate = (current) => {
	  // Can not select days before today and today
	  return current && current < moment().subtract(2, 'd').endOf('day');
	}
  genetateCode = () => {
  	const generate = require('nanoid/generate');
    const promocode = generate('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 7);
		this.props.form.setFields({
		  promocode_key: {
		    value: promocode
		  },
		});
  }
  render() {
    const {
      errorMessage,
      promocode,
      privateList,
      plansList,
      privateType
    } = this.state;
    const { handleClose, visible, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        style={{ top: 20 }}
        visible={ visible }
        width={900}
        title="Add promo"
        onOk={ handleClose }
        onCancel={ handleClose }
        footer={false}
      >
        <Form onSubmit={this.handleSubmit}>
          { errorMessage ? 
            <FormItem><Alert message={errorMessage} type="error"/></FormItem>
          : null }
          <Row gutter={8}>
            <Col span={6}>
              <FormItem>
                <Row>
                  <label className="form-label-custom">Promocode</label>
                  <Button onClick={this.genetateCode} size="small">Generate</Button>
                </Row>
                <Row >
                  {getFieldDecorator('promocode_key', {
                    rules: [{ required: true, message: 'Please enter a promocode!' }],
                    initialValue: promocode.promocode_key
                  })(
                    <Input />
                  )}
                </Row>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem>
                <Row>
                  <label className="form-label-custom">Valid dates from-to</label>
                </Row>
                <Row gutter={8}>
                  {getFieldDecorator('validDates', {
                    rules: [{ type: 'array', required: true, message: 'Please select time!' }],
                  })(
                    <RangePicker disabledDate={this.disabledDate} style={{width: '100%'}} />
                  )}
                </Row>
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem>
                <Row>
                  <label className="form-label-custom">Quantity limit</label>
                </Row>
                <Row >
                  {getFieldDecorator('quantity_limit', {
                    rules: [{ required: true, message: 'Please enter a quantity limit!' }],
                    initialValue: 1
                  })(
                    <InputNumber min={1}  style={{width: '100%'}} />
                  )}
                </Row>
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem>
                <Row>
                  <label className="form-label-custom">Limit per user</label>
                </Row>
                <Row >
                  {getFieldDecorator('permitted_use_per_user', {
                    rules: [{ required: true, message: 'Please enter a permitted use per user!' }],
                    initialValue: '0'
                  })(
                    <Select>
                      <Option value='0'>Unlimit</Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="6">6</Option>
                      <Option value="7">7</Option>
                      <Option value="8">8</Option>
                      <Option value="9">9</Option>
                      <Option value="10">10</Option>
                    </Select>
                  )}
                </Row>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={8}>
              <FormItem
                  {...formItemLayout}
                >
                  <Col span={24}>
                    <Row>
                      <label className="form-label-custom">Promo value: choose plan</label>
                    </Row>
                    <Row >
                      {getFieldDecorator('plan_id', {
                        rules: [{ required: true, message: 'Please choose plan!' }],
                      })(
                        <Select>
                          {plansList.map((plan, key) => <Option key={key} value={plan.id}>{plan.view_label} {plan.price} {plan.currency}</Option>) }
                        </Select>
                      )}
                    </Row>
                  </Col>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                  {...formItemLayout}
                >
                  <Col span={24}>
                    <Row>
                      <label className="form-label-custom">Private dependencies</label>
                    </Row>
                    <Row >
                      {getFieldDecorator('private_type', {
                        rules: [{ required: false, message: 'Please choose plan!' }],
                      })(
                        <Select onChange={this.handleDependenciesChange}>
                          <Option value=''>No dependencies</Option>
                          <Option value="organisations">Organisations</Option>
                          <Option value="venues">Venues</Option>
                          <Option value="user">User</Option>
                        </Select>
                      )}
                    </Row>
                  </Col>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                  {...formItemLayout}
                >
                  <Col span={24}>
                    <Row>
                      <label className="form-label-custom">Dependency value</label>
                    </Row>
                    <Row >
                      {privateType !== 'user' ? 
                      <React.Fragment>
                        {getFieldDecorator('private_id', {
                          rules: [{ required: false, message: 'Please choose value!' }],
                        })(
                          <Select showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            <Option key={100} value=''>No value</Option>
                            {privateList.map((item, key) => <Option key={key} value={item.id}>{item.title}</Option>) }
                          </Select>
                        )}
                      </React.Fragment>
                      :
                      <React.Fragment>
                        {getFieldDecorator('private_id', {
                          rules: [{ required: true, message: 'Please enter user_id!' }],
                        })(
                          <InputNumber style={{width: '100%'}} />
                        )}
                      </React.Fragment>
                      }
                    </Row>
                  </Col>
              </FormItem>
            </Col>
          </Row>
          <FormItem >
            <Row style={{float: 'right'}}>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Row>
          </FormItem>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(AddPromo);