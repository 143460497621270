import React from 'react';
import { Col, Row, Spin, Modal, Table, Button, message } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import AddInvoice from './AddInvoice';
import EditInvoice from './EditInvoice';
import copy from 'clipboard-copy';

const confirm = Modal.confirm;

class Invoicing extends React.Component {
  state = {
    isFetching: false,
    list: [],
    visibleCreateNew: false,
    invoiceObj: {}
  };
  componentDidMount() {
    document.title = 'Invoicing';
    this.getList();
  }
  getList = () => {
    this.setState({
      isFetching: true
    });
    const { organisationId } = this.props;
    fetchJSON(`/api/v1/organisation/invoicing/${organisationId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.code === 200) {
        this.setState({
          list: response.data
        });
      }
      this.setState({
        isFetching: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false
      });
    });
  }
  
  columns = () => {
    // let { sortedInfo, filteredInfo } = this.state;
    // sortedInfo = sortedInfo || {};
    // filteredInfo = filteredInfo || {};
    return [{
      title: 'Order',
      dataIndex: 'sales_order_name',
      key: 'sales_order_name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Braintree ID',
      dataIndex: 'braintree_vault_id',
      key: 'braintree_vault_id',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <React.Fragment>
            <Button onClick={() => this.copyLink(record.key_id)} className="ant-btn ant-btn-sm" style={{marginRight: '8px'}}>
              Copy Link
            </Button>
            {record.status === 'pending' ? <span>
              <Button onClick={() => this.editInvoice(record)} className="ant-btn ant-btn-sm">
                Edit
              </Button>
              <Button onClick={() => this.deleteInvoice(record.key_id)} className="ant-btn ant-btn-sm" style={{marginLeft: '8px'}}>
                Delete
              </Button>
            </span> : null }
          </React.Fragment>
        )
      }
    }];
  }
  copyLink = (invoiceUuid) => {
    console.log('copyLink', invoiceUuid);
    let baseUrl = 'nebula.electrontogo.com';
    if (process.env.NODE_ENV === "development") {
      baseUrl = 'app8.e2g.app';
    }
    if (process.env.REACT_APP_ENV === "staging") {
      baseUrl = 'tnebula.electrontogo.com';
    }
    message.success('A link copied to the clipboard');
    const url = `http://${baseUrl}/pay/invoice?i=${invoiceUuid}`;
    copy(url);
  }
  editInvoice = (record) => {
    this.setState({
      invoiceObj: record
    }, () => {
      this.updateState('visibleEditInvoice', true);
    });
  }
  getData = () => {
    const data = [];
    const { list } = this.state;
    if (!list) return data;

    list.forEach((item, i) => {
      // console.log('item', item);
      data.push({
        key: i,
        id: item.id,
        key_id: item.key_id,
        sales_order_name: item.sales_order_name,
        status: item.status,
        braintree_vault_id: item.braintree_vault_id,
        price: item.price,
        currency: item.currency,
        items: item.items
      });
    });
    return data;
  }
  deleteInvoice = (invoice_uuid) => {
    console.log('deleteInvoice', invoice_uuid);
    confirm({
      title: 'Are you sure you want to delete this invoice?',
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            isFetching: true
          })
          fetchJSON(`/api/v1/organisation/invoicing/${invoice_uuid}`, {
            method: 'delete'
          }).then(response => {
            console.log(response);
            if (response.code === 200) {
              this.setState({
                isFetching: false,
              });
              this.getList();
              resolve();
            } else {
              this.setState({
                isFetching: false
              });
              reject();
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                fetchingStatus: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  updateState = (key, value) => {
    this.setState({
      [key]: value
    });
  }
  render() {
    const { 
      isFetching,
      visibleCreateNew,
      visibleEditInvoice,
      invoiceObj
    } = this.state;
    const { organisationId } = this.props;

    return (
      <React.Fragment>
        <Spin spinning={isFetching}>
          <Row gutter={8} style={{marginBottom: '15px'}}>
            <Col span={8}>
              <h1 className="table-title">
                Invoicing
                <Button type="primary" style={{margin: '6px 8px 0px 8px'}} size="small" onClick={ () => { this.updateState('visibleCreateNew', true) } }>
                  Add Invoice
                </Button>
              </h1>
            </Col>
          </Row>
          <Table
            columns={ this.columns() }
            loading={ isFetching }
            dataSource={ this.getData() }
          />
        </Spin>
        { visibleCreateNew ? <AddInvoice
          organisationId={organisationId}
          visible={visibleCreateNew}
          renderList = { this.getList }
          handleClose={ () => { this.updateState('visibleCreateNew', false) } } />
          : null
        }
        { visibleEditInvoice ? <EditInvoice
          organisationId={organisationId}
          invoiceObj={invoiceObj}
          visible={visibleEditInvoice}
          renderList = { this.getList }
          handleClose={ () => { this.updateState('visibleEditInvoice', false) } } />
          : null
        }
      </React.Fragment>
    )
  }
};

export default Invoicing;