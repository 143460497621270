import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Layout, Button, Row, Col } from 'antd';

// import Widgets from './components/Widgets/Widgets';
const Widgets = React.lazy(() => import('./components/Widgets/Widgets'));
const Milestones = React.lazy(() => import('./components/Milestones'));
const RangeCharts = React.lazy(() => import('./components/RangeCharts'));
const Charts = React.lazy(() => import('./components/Charts'));
// import Milestones from './components/Milestones';
// import RangeCharts from './components/RangeCharts';

import Statistics from './screens/Organisations/components/Statistics/Statistics';

import 'font-awesome/css/font-awesome.css';
import './styles.less';
import './override_styles.less';


const { Content } = Layout;

class Dashboard extends React.Component {
  state = {
    RenderMilestones: false,
    RenderChart: false
  }

  componentDidMount() {
    document.title = 'Dashboard';
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this), { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this))
  }

  handleScroll() {
    if (window.pageYOffset > 760) {
      this.setState({ RenderMilestones: true })
    }
    if (window.pageYOffset > 125) {
      // this.setState({ RenderChart: true })
    }
    console.log(window.pageYOffset);
  }
  render() {
    // eslint-disable-next-line react/prop-types
    const { role } = this.props;
    const { RenderMilestones, RenderChart } = this.state;
    console.log(RenderMilestones);

    return (
      <React.Fragment>
        {role === 'admin' ? <Content>
          <Row gutter={8}>
            <Col span={18}>
              <h1 className="table-title">Dashboard</h1>
            </Col>
          </Row>

          <Row className="widgets-container-row">
            <Suspense fallback={<div>Loading...</div>}>
              <Widgets />
            </Suspense>


          </Row>

          <Row>
            <Suspense fallback={<div>Loading...</div>}>
              <RangeCharts />
            </Suspense>
            <Row  style={{width:'100%',textAlign:'center',display:'block',padding:'20px'}}>
              <Button onClick={() => { this.setState({ RenderChart: true }); }} type="primary">Load Range Charts</Button>
            </Row>
          </Row>

          <Row>
            <Suspense fallback={<div>Loading...</div>}>
              {RenderChart !== false ? <Charts /> : ''}
            </Suspense>
          </Row>

          <Col style={{ marginTop: '24px' }}>
            <Suspense fallback={<div>Loading...</div>}>
              {/* {RenderMilestones !== false ? <Milestones /> : ''} */}
            </Suspense>

          </Col>
        </Content> : null}
        {role === 'organisation' ? <Statistics url="/api/v1/report/organisation" /> : null}
      </React.Fragment>
    )
  }
}


export default connect()(Dashboard)