import React from 'react';
import { Table , Tooltip, Tag} from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import '../styles.less';

class VenueList extends React.Component {
  state = {
    filteredInfo: {},
    sortedInfo: {},
    searchText: '',
    filtered: false,
    data: [],
    visibleAddVenue: false
  };
  isEllipsisActive = (e) => {
    if (e) {
      return (e.offsetWidth < e.scrollWidth)
    }
    else {
      return false
    }
  }
  columns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    let { offlineSupernovasColumn, countryFilters } = this.props;
    sortedInfo = sortedInfo || {};
    countryFilters = countryFilters || [];
    filteredInfo = filteredInfo || {};

    let venueTitleItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Location name</p>
          </div>
          )
      },
      dataIndex: 'title',
      key: 'title',
      width: 120,
      fixed: 'left',
      sorter: (a, b) => a.title > b.title ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`venueTitleText_${record.key}}`)) ? record.title : ''}>
            <Link to={`/venues/${record.uuid}`}> 
              <p id={`venueTitleText_${record.key}}`} style={{margin:0, width: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                {record.title}
              </p>
            </Link>
          </Tooltip>
        </div>
      )
    };
    let statusItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Status</p>
          </div>
          )
      },
      dataIndex: 'status',
      key: 'status',
      width: 86,
      filters: [
        { text: 'Active', value: 'Active' },
        { text: 'Demo', value: 'Demo' },
        { text: 'Inactive', value: 'Inactive' },
        { text: 'Suspended', value: 'Suspended' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => {
        return record.status.includes(value)
      },
      render: (text, record) => (
        <p style={{margin: 0, color: record.statusColor}}>{record.status}</p>
      ),
    };
    let electronItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Electrons</p>
          </div>
          )
      },
      dataIndex: 'electrons',
      key: 'electrons',
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      width: 100,
      sortOrder: sortedInfo.columnKey === 'electrons' && sortedInfo.order,
      sorter: (a, b) => a.electrons - b.electrons
    };
    let supernovasItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Supernovas</p>
          </div>
          )
      },
      dataIndex: 'supernovas',
      key: 'supernovas',
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      width: 130,
      sortOrder: sortedInfo.columnKey === 'supernovas' && sortedInfo.order,
      sorter: (a, b) => a.supernovas - b.supernovas,
      filters:  [
        { text: 'One or more offline', value: 'One or more offline' },
        { text: 'All online', value: 'All online' }
      ],
      filteredValue: filteredInfo.supernovas || null,
      onFilter: (value, record) => {
        if ((value === 'One or more offline' && record.supernovasOffline.length > 0) || (value === 'All online' && record.supernovasOffline.length === 0 && record.supernovas > 0)) {
          return true;
        }
      },
      render: (text, record) => (
        <div style={{whiteSpace: 'nowrap', display: 'inline-flex'}}>
          { record.supernovas > 0 ?
              <Link style={{display: 'inline-flex'}} to={`/venues/${record.uuid}?groups=1`}>
                <p style={{margin: 0}}>{record.supernovasOnline.length}</p>
                <p style={{margin: 0, color: 'red', display: record.supernovasOffline.length > 0 ? '' : 'none'}}>/{record.supernovasOffline.length}</p>
              </Link>
          : 0 } 
        </div>
      ),
    };
    let locationIdItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Location ID</p>
          </div>
          )
      },
      dataIndex: 'groupKeyId',
      key: 'groupKeyId',
      width: 130,
      render: (text, record) => (
        <Link to={`/venues/${record.uuid}?groups=1`}> 
          <Tag color={record.groupTagColor} style={{cursor: "pointer", display: record.groupKeyId ? '' : 'none'}}> 
            {record.groupKeyId}
          </Tag>
        </Link>
      ),
      align: 'center',
      filters:  [
        { text: 'Has loc ID', value: 'Has loc ID' },
        { text: 'No Loc ID', value: 'No Loc ID' }
      ],
      filteredValue: filteredInfo.groupKeyId || null,
      onFilter: (value, record) => {
        return (record.groupKeyId && value === 'Has loc ID') || (!record.groupKeyId && value === 'No Loc ID')
      }
    };
    let installationTypeItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Installation</p>
          </div>
          )
      },
      dataIndex: 'installationType',
      key: 'installationType',
      align: 'center',
      width: 110,
      filters:  [
        { text: 'Wall', value: 'Wall' },
        { text: 'Floor', value: 'Floor' },
        { text: 'Counter', value: 'Counter'}
      ],
      filteredValue: filteredInfo.installationType || null,
      onFilter: (value, record) => {
        return record.installationType.includes(value)
      }
    };
    let accessTypeItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: !offlineSupernovasColumn ? 'nowrap' : '', textAlign: 'center'}}>
            <p style={{margin: 0}}>Access type</p>
          </div>
          )
      },
      dataIndex: 'accessType',
      key: 'accessType',
      align: 'center',
      width: 120,
      filters:  [
        { text: 'Public', value: 'Public' },
        { text: 'Private', value: 'Private' }
      ],
      filteredValue: filteredInfo.accessType || null,
      onFilter: (value, record) => {
        return record.accessType.includes(value)
      }
    };
    let firstFreeItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>First free</p>
          </div>
          )
      },
      dataIndex: 'firstFree',
      key: 'firstFree',
      align: 'center',
      width: 100,
      filters:  [
        { text: 'On', value: 'On' },
        { text: 'Off', value: 'Off' }
      ],
      filteredValue: filteredInfo.firstFree || null,
      onFilter: (value, record) => {
        return record.firstFree === value
      },
      render: (text, record) => (
        <div style={{whiteSpace: 'nowrap'}}>
          <p style={{margin: 0, color: record.firstFree === 'On' ? 'green' : 'red'}}>{record.firstFree}</p>
        </div>
      )
    };
    let durationItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Duration</p>
          </div>
          )
      },
      dataIndex: 'duration',
      key: 'duration',
      align: 'center',
      width: 110,
      filters:  [
        { text: 'Permanent', value: 'Permanent' },
        { text: 'Temporary', value: 'Temporary' }
      ],
      filteredValue: filteredInfo.duration || null,
      onFilter: (value, record) => {
        return record.duration.includes(value)
      }
    };
    let offlineSupernovasItem = offlineSupernovasColumn ? {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Offline</p>
          </div>
          )
      },
      dataIndex: 'supernovasOffline',
      key: 'supernovasOffline',
      align: 'center',
      width: 110,
      filters:  [
        { text: 'Show offline', value: 'Show offline' }
      ],
      filteredValue: filteredInfo.supernovasOffline || null,
      onFilter: (value, record) => {
        return value === 'Show offline' && record.supernovasOffline && record.supernovasOffline.length > 0; 
      },
      render: (text, record) => (
        <div style={{whiteSpace: 'nowrap', fontSize: '12px'}}>
          <p style={{margin: 0, color: 'red'}}>{record.supernovasOffline && record.supernovasOffline.length > 0 ? record.supernovasOffline[0] : ''}</p>
          <p style={{margin: 0, display: record.supernovasOffline && record.supernovasOffline.length > 1 ? '' : 'none', color: 'red'}}>...</p>
        </div>
      )
    } : {};
    let whoPaysItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: !offlineSupernovasColumn ? 'nowrap' : '', textAlign: 'center'}}>
            <p style={{margin: 0}}>Who pays</p>
          </div>
          )
      },
      dataIndex: 'freeForUsers',
      key: 'freeForUsers',
      align: 'center',
      width: 120,
      filters:  [
        { text: 'Users', value: 'Users' },
        { text: 'Org', value: 'Org' }
      ],
      filteredValue: filteredInfo.freeForUsers || null,
      onFilter: (value, record) => {
        return record.freeForUsers && record.freeForUsers.includes(value)
      }
    };
    let addressItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Address</p>
          </div>
          )
      },
      dataIndex: 'address',
      key: 'address',
      width: 60,
      filters:  countryFilters,
      filteredValue: filteredInfo.address || null,
      onFilter: (value, record) => {
        return record.country ? record.country.includes(value) : null
      },
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`addressText_${record.key}}`)) ? record.address : ''}>
            <p id={`addressText_${record.key}}`} style={{margin:0, width: '60px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              {record.address}
            </p>
          </Tooltip> 
        </div>
      )
    };
    let contactsItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Contact</p>
          </div>
          )
      },
      dataIndex: 'contact',
      key: 'contact',
      width: 60,
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`contactText_${record.key}}`)) ? <ul style={{listStyle: 'decimal', margin: 0}}>{record.contactsList}</ul> : ''}>
            <p id={`contactText_${record.key}}`} style={{margin:0, width: '60px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              {record.contact}
            </p>
          </Tooltip>
        </div>
      )
    };
    let ticketsItem =    {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Tickets</p>
          </div>
        )
      },
      key: 'tickets',
      dataIndex: 'tickets',
      sortDirections: ['descend', 'ascend'],
      width: 100,
      align: 'center',
      sorter: (a, b) => a.tickets > b.tickets ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'tickets' && sortedInfo.order
    };

    const columns = [venueTitleItem];
    if (offlineSupernovasColumn) columns.push(offlineSupernovasItem)
    const generalColumns = [
      statusItem,
      locationIdItem,
      supernovasItem,
      electronItem,
      installationTypeItem,
      accessTypeItem,
      durationItem,
      whoPaysItem,
      firstFreeItem,
      ticketsItem,
      addressItem,
      contactsItem
    ];
    columns.push(...generalColumns);
    return columns;
  }
  getData = () => {
    const data = [];
    if (!this.props.list) return data;
    let venues = this.props.list;
    venues.forEach((venue, i) => {
      let contact = '';
      let contactsList = [];
      const newContactsList = [];
      if (venue.contacts_list && venue.contacts_list.length > 0) {
        let count = 1;
        venue.contacts_list.forEach((contact) => {
          const contactArr = [];
          if (contact.contact) {
            if (contact.contact.name) {
              contactArr.push(contact.contact.name);
            }
            if (contact.contact.phone) {
              contactArr.push(contact.contact.phone);
            }
            if (contact.contact.email) {
              contactArr.push(contact.contact.email);
            }
          }
          const fullContact = contactArr.join(', ');
          if (fullContact && newContactsList.indexOf(fullContact) === -1) {
            newContactsList.push(fullContact);
          }
        })
        if (newContactsList && newContactsList.length > 0) {
          contact = newContactsList[0];
          newContactsList.forEach((contact) => {
            contactsList.push(<li key={count}>{contact}</li>)
            count += 1;       
          })       
        }
      }
      let status = 'Active';
      let statusColor = 'green';
      if (venue.status === 0) {
        status = 'Inactive';
        statusColor = 'blue';
      } else if (venue.status === 2) {
        status = 'Suspended';
        statusColor = 'red';
      } else if (venue.status === 1 && venue.is_demo) {
        status = 'Demo';
      }
      let electronLists = [];
      let supernovasList = {
        online: [],
        offline: []
      };
      let groupTagColor = 'red';
      venue.groups.forEach(groupObj => {
        let supernovas = _.get(groupObj.group, 'supernovas');
        if (supernovas) {
          supernovas.forEach(supernovaObj => {
            let electrons = _.get(supernovaObj.supernova, 'electrons');
            const status = _.get(supernovaObj.supernova, 'status');
            const stringId = _.get(supernovaObj.supernova, 'string_id');
            if (status === 'online') {
              groupTagColor = 'green';
              supernovasList.online.push(stringId);
            }
            else {
              supernovasList.offline.push(stringId);
            }
            if (electrons) {
              electronLists.push(...electrons);
            }
          });
        }
      });
      if (groupTagColor === 'red' && venue.status !== 1) {
        groupTagColor = 'green';
      }
      const groupKeyId = _.get(venue, 'groups[0].group.key_id');
      const group_domestic_id = _.get(venue, 'groups[0].group_id');
      const type_of_instalation = _.get(venue, 'groups[0].group.type_of_instalation');
      let installationType = 'Other';
      if (type_of_instalation === 'wall' || type_of_instalation === 'direct_wall') {
        installationType = 'Wall';
      } else if (type_of_instalation === 'counter') {
        installationType = 'Counter';
      } else if (type_of_instalation === 'floor') {
        installationType = 'Floor';
      }
      
      data.push({
        key: i,
        id: venue.id,
        uuid: venue.uuid,   
        title: venue.title,
        address: venue.address,
        actual_status: venue.status,
        status: status,
        statusColor: statusColor,
        electrons: electronLists.length,
        groupKeyId: groupKeyId,
        installationType: installationType,
        groupTagColor,
        supernovas: supernovasList.online.length + supernovasList.offline.length,
        supernovasOnline: supernovasList.online,
        supernovasOffline: supernovasList.offline,
        accessType: venue.is_public ? 'Public' : 'Private',
        duration: venue.location_core === 'permanent' ? 'Permanent' : 'Temporary',
        freeForUsers: venue.free_for_users ?  'Org' : 'Users',
        paymentRequest: venue.request_payment_information ? 'On' : 'Off',
        tickets: venue.ticketsAmount,
        contact,
        contactsList,
        country: venue.address_components ? venue.address_components.country : '',
        group_domestic_id,
        firstFree: venue.first_time_free ? 'On' : 'Off'
      })
    });
    return data;
  }
  handleTableChange = (pagination, filters, sorter) => {
    console.log('handleTableChange', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  render() {
    const { isFetching, pagination } = this.props;
    return (
      <Table
        className="small-table-header"
        size="small"
        bordered={false}
        columns={ this.columns() }
        loading={ isFetching }
        dataSource={ this.getData() }
        pagination={ pagination }
        onChange={ this.handleTableChange }
        scroll={{x: 'max-content'}}
        />
    )
  }
};

export default VenueList;