import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Spin, Col, Button, message, Checkbox, Radio, Table, Switch, InputNumber } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

import './PricingSettings.less';

const FormItem = Form.Item;

class PricingSettings extends React.Component {
  state = {
    isFetching: true,
    settings: {
      first_time_free: 0,
      free_for_users: 0,
      request_payment_information: 1,
      type_of_pricing: 0
    },
    pricingPolicy: 0,
    chargePerFreeUse: 0,
    plans: {}
  }
  componentDidMount() {
    this.mounted = true;
    if (this.props.default) {
      const currencyCode = this.props.currencyCode || 'USD';
      this.getDefaultPricing(currencyCode);
    } else {
      this.getPricingSettings();
    }   
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getDefaultPricing = (currencyCode) => {
  if (!currencyCode) return;
  // console.log('getPricingSettings', currencyCode);
  fetchJSON(`/api/v1/organisation/defaultPricing/${currencyCode}`, {
    method: 'get'
  }).then(response => {
    console.log(response);
    if (this.mounted) {
      if (response.code === 200) {
        const setValues = {
          settings: response.data,
          isFetching: false,
          pricingPolicy: 0,
          chargePerFreeUse: 0,
          plans: response.data.plans,
          currencyCode
        };
        const { plans } = response.data;
        console.log(plans, 'plans')
        if (plans) {
          Object.keys(plans).forEach((key) => {
            setValues[`plans[${plans[key].key_id}].is_active`] = plans[key].is_active;
          });
        }
        if (response.data.first_time_free_no_payments) {
          setValues.settings.first_time_free = 2;
        }      
        this.setState(setValues);
      } else {
        this.setState({
          isFetching: false
        });
      }
    }
   }).catch(error => {
      console.log(error);
      if (this.mounted) {
        this.setState({
          isFetching: false
        })
      }
   });
  }
  getPricingSettings = () => {
    const { source, sourceId } = this.props; 
    this.setState({
      isFetching: true
    });
    fetchJSON(`/api/v1/${source}/pricing/${sourceId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.code === 200) {
        const setValues = {
          settings: response.data,
          isFetching: false,
          pricingPolicy: response.data.free_for_users,
          chargePerFreeUse: response.data.charge_per_free_use,
          plans: response.data.plans,
          currencyCode: response.data.currency_code
        };
        const { plans } = response.data;

        if (plans) {
          Object.keys(plans).forEach((key) => {
            setValues[`plans[${plans[key].key_id}].is_active`] = plans[key].is_active;
          });
        }
        if (response.data.first_time_free_no_payments) {
          setValues.settings.first_time_free = 2;
        }      

        this.setState(setValues);
      } else {
        this.setState({
          isFetching: false
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        })
     });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      //console.log('fieldsValue', fieldsValue);
      const { source, sourceId, relatedIds } = this.props; 
      const venueUuids = [];
      if (source === 'venue' && sourceId) {
        venueUuids.push(sourceId);
      }
      if (source === 'organisation' && relatedIds && relatedIds.length) {
        relatedIds.forEach((uuid) => {
          if (uuid && venueUuids.indexOf(uuid) === -1) {
            venueUuids.push(uuid);
          }
        })
      }
      console.log(venueUuids, 'venueUuids', relatedIds, 'relatedIds')
      if (venueUuids.length) {
        this.setState({
          isFetching: true
        });
        const promises = [];
        const responses = [];
        venueUuids.forEach((uuid) => {
          promises.push(new Promise((resolveLocal, reject) => {
            this.updatePricingVenue(uuid, fieldsValue, (response = false) => {
              if (response) {
                responses.push(response);
              }
              resolveLocal();
            })
          }));
        });
        Promise.all(promises).then((values) => {
          if (responses.indexOf(400) === -1) {
            message.success('Saved successfully!');
            this.setState({
              isFetching: false
            }, () => {
              if (this.props.handleTableUpdate) {
                this.props.handleTableUpdate();
              } else {
                this.getPricingSettings();
              }
            });
          } else {
            this.setState({
              isFetching: false
            })
            message.error('Something went wrong, please try again');  
          }
        })
      }
      return
    })
  }
  updatePricingVenue = (venueUuid, values, callback) => {
  fetchJSON(`/api/v1/venue/pricing/${venueUuid}`, {
    method: 'post',
    body: values
  }).then(response => {
    console.log(response);
    if (response.success) {
      callback(200);
    } else {
      callback(400);
    }
   }).catch(error => {
      callback(400);
      console.log(error);
   });
  }

  pricingPolicyChange = (e) => {
    const obj = {
      pricingPolicy: e.target.value
    };
    this.setState(obj);
  }
  columnsCustom = () => {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return [
      {
        title: 'Enable',
        dataIndex: 'is_active',
        render: (text, record, index) => {
          return getFieldDecorator(`plans[${record.id}].is_active`, {
            initialValue: text,
            valuePropName: "checked",
          })(
            <Checkbox onChange={(e) => this.changeFieldStateCheckbox(e, `plans[${record.id}].is_active`)} />
          )
        } 
      },
      {
        title: 'Plan',
        dataIndex: 'plan',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        render: (text, record, index) => {
          let maxPrice = 99999;
          // if (record.key_id === 'daily') {
          //   maxPrice = 10;
          // }
          // if (record.key_id === 'hourly_rate') {
          //   maxPrice = 10;
          // }
          if (['daily', 'hourly_rate', 'monthly', 'annual'].indexOf(record.key_id) === -1) return text;
          const disabled = this.state[`plans[${record.key_id}].is_active`];
          return <Form.Item style={{ marginBottom: "0px" }}>
          {getFieldDecorator(`plans[${record.id}].price`, {
            rules: [{ required: true, message: 'Please enter a price!' }],
            initialValue: text
          })(
            <InputNumber min={1} max={maxPrice} style={{ maxWidth: '80px' }} disabled={!disabled} />
          )}
          </Form.Item>
        } 
      }, {
        title: 'Currency',
        dataIndex: 'currencyCode'
      }
    ];
  }
  changeFieldStateCheckbox = (e, key) => {
    this.setState({
      [key]: e.target.checked
    });
  }
  changeFieldStateSwitch = (value, key) => {
    this.setState({
      [key]: value
    });
  }
  plansListData = () => {
    const { plans, currencyCode } = this.state;
    const data = [];
    if (!plans || (typeof plans === 'object' && !Object.keys(plans).length)) return data;

    Object.keys(plans).forEach((key, index) => {
      //console.log('plans[key]', plans[key]);
      data.push({
        key: index,
        id: key,
        plan_id: plans[key].plan_id,
        is_active: plans[key].is_active,
        plan: plans[key].view_label,
        price: plans[key].price,
        key_id: plans[key].key_id,
        currencyCode 
      });
    });

    return data;
  }
  render() {
    const {
      isFetching,
      settings,
      pricingPolicy,
      chargePerFreeUse
    } = this.state;

    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Spin spinning={isFetching}>
        <h1 className="table-title">Pricing settings</h1>
        <Col span={16}>
          <Form onSubmit={this.handleSubmit}>
            <FormItem style={{ marginBottom: '40px' }}>
              {getFieldDecorator('free_for_users', {
                initialValue: settings.free_for_users,
              })(
                <Radio.Group onChange={this.pricingPolicyChange} size="large">
                  <Radio.Button value={0}>Users pay</Radio.Button>
                  <Radio.Button value={1}>Free for users</Radio.Button>
                </Radio.Group>
              )}
            </FormItem>

            <FormItem style={{ 'display': pricingPolicy === 0 ? '' : 'none'}} >
              <label style={{ marginRight: '20px', }}>Free first use for new users</label>
              {getFieldDecorator('first_time_free', {
                initialValue: settings.first_time_free,
                //valuePropName: "checked",
              })(
                <Radio.Group>
                  <Radio.Button value={0}>Disabled</Radio.Button>
                  <Radio.Button value={1}>With payment</Radio.Button>
                  <Radio.Button value={2}>Without payment</Radio.Button>
                </Radio.Group>
              )}
            </FormItem>
            <div style={{
              display: pricingPolicy === 1 ? 'flex' : 'none',
              marginBottom: '12px',
              minHeight: '32px',
              alignItems: 'center',
            }}>
              <label style={{ marginRight: '20px', 'fontWeight': 'bold' }}>Paid by organisation</label>
              {getFieldDecorator('charge_per_free_use', {
                initialValue: settings.charge_per_free_use ? true : false,
                valuePropName: "checked",
              })(
                <Switch onChange={(e) => this.changeFieldStateSwitch(e, 'chargePerFreeUse')}/>
              )}
              <span style={{
                'display': pricingPolicy === 1 && chargePerFreeUse ? '' : 'none',
                'marginLeft': '15px'
              }}>
                <Form.Item style={{ marginBottom: "0px" }}>
                {getFieldDecorator('charge_per_free_use_amount', {
                  initialValue: settings.charge_per_free_use_amount,
                  rules: [{ required: pricingPolicy === 1 && chargePerFreeUse, message: 'Please enter an amount!' }],
                })(
                  <InputNumber style={{maxWidth: '100px'}} />
                )}
                </Form.Item>
              </span>
            </div>
            <FormItem style={{ 'display': pricingPolicy === 1 ? '' : 'none', marginTop: '20px' }}>
              <label style={{ 'display': 'block', 'fontWeight': 'bold' }}>The Electron return control</label>
              {getFieldDecorator('request_payment_information', {
                initialValue: settings.request_payment_information,
              })(
                <Radio.Group className="requestPayment">
                  <label className="requestPayment__box">
                    <span className="requestPayment__label">
                      Request Payment information
                      <span className="requestPayment__help">
                        User is responsible
                      </span>
                    </span>
                    <span className="requestPayment__input">
                      <Radio value={1}/>
                    </span>
                  </label>
                  <label className="requestPayment__box">
                    <span className="requestPayment__label">
                      Don’t request payment information
                      <span className="requestPayment__help requestPayment__help--red">
                        Responsibility on lost Electron will be <br/>on your organisation
                      </span>
                    </span>
                    <span className="requestPayment__input">
                      <Radio value={0}/>
                    </span>
                  </label>
                </Radio.Group>
              )}
            </FormItem>
            <FormItem style={{ 'display': pricingPolicy === 0 ? '' : 'none', 'marginTop': '20px '}} >
              <Col span={20}>
                <Table
                  columns={this.columnsCustom()} 
                  dataSource={this.plansListData()} 
                  bordered={true}
                  pagination={{
                    total: 10,
                    pageSize: 10,
                    hideOnSinglePage: true
                  }}
                  />
              </Col>
            </FormItem>
            <FormItem style={{ marginTop: '40px' }}>
              <Button type="primary" htmlType="submit">
                Save changes
              </Button>
              <Button onClick={this.props.handleClose} style={{display: this.props.handleClose ? '' : 'none', marginLeft: '16px'}}>
                Cancel
              </Button>
            </FormItem>
          </Form>
        </Col>
      </Spin>
    )
  }
}

export default Form.create()(PricingSettings);