import React from 'react';
import PricingSettings from 'screens/Dashboard/components/PricingSettings/PricingSettings';

class Pricing extends React.Component {
  
  render() {
    const {
      organisationId
    } = this.props;

    return (
      <PricingSettings source="organisation" sourceId={organisationId} />
    )
  }
};

export default Pricing;