import React from 'react';
import Settings from 'services/config/Settings';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Table,
  Layout,
  Input,
  DatePicker,
  Row,
  Col,
  Button,
  Card,
  Select,
  Tooltip,
  Tag,
  Modal,
  Icon
} from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { cleanEmpty, getTimeDistance } from 'services/utils/utils';
import fetchJSON from 'services/utils/fetchJSON';
import { Parser } from 'json2csv';
import SmsSender from 'screens/Dashboard/components/SmsSender/SmsSender';


import './styles.less';

require("moment-duration-format");
const Search = Input.Search;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;
const WAIT_INTERVAL = 1000

class Users extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filteredInfo: {},
      sortedInfo: {},
      searchText: '',
      filtered: false,
      data: [],
      totalNumberTable: 0,
      rangePickerValue: getTimeDistance('total'),
      rangePickerFilterValue: getTimeDistance('total'),
      detailedTable: false,
      searchParameter: false,
      currentDataSource: [],
      filteredBySearch: false,
      showDateFilter: false,
      businessTypesLoaded: false,
      typeFilter: [],
      business_types: {},
      pagination: {
        pageSizeOptions: ['10', '30', '50', '100']
      },
      usersList: [],
      isFetching: false,
      detailsFetching: false,
      dateFilterType: 'Filter by date',
      selectedRowKeys: [],
      selectedRowValues: [],
      visibleSmsSender: false,
      idsForAction: []
    };
    this.timeout = 0;
    this.totalNumberTable = 0;
    this.totalNumberInterval = null;
    this.isDev = process.env.NODE_ENV === 'development1';
  }


  componentDidMount() {
    document.title = `Users | ${Settings.title}`;
    this.tableFixed = false;
    this.getBusinessInfo();

    if (this.isDev) {
      this.setState({
        rangePickerValue: getTimeDistance('month')
      }, this.getUserList)
      //this.getUserList(true);
    }
    else {
      this.getUserList(true);
    }
    const { pagination } = this.state;
    this.setState({
      pagination: {
        ...pagination,
        showTotal: (total) => {
          return <p id="totalNumber" style={{ display: 'none' }}>{this.renderTotal(total)}</p>
        }
      }
    })
  }
  componentWillUnmount() {
    clearInterval(this.totalNumberInterval);
  }
  trackTotalNumberValue = () => {
    clearInterval(this.totalNumberInterval);
    const { totalNumberTable } = this.state;
    if (totalNumberTable !== this.totalNumberTable) {
      this.setState({
        totalNumberTable: this.totalNumberTable
      });
    }
    this.totalNumberInterval = null;
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      console.log(value);
      console.log(record);
      // record[dataIndex]
      //   .toString()
      //   .toLowerCase()
      //   .includes(value.toLowerCase())
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => text,
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  renderTotal = (total) => {
    this.totalNumberTable = total;
    const { totalNumberTable } = this.state;
    if (totalNumberTable !== this.totalNumberTable && !this.totalNumberInterval) {
      this.totalNumberInterval = setInterval(this.trackTotalNumberValue, 100);
    }
    return total;
  }
  getBusinessInfo = () => {
    fetchJSON('/api/v3/general/org-types', {
      method: 'get'
    }).then(response => {
      if (response.success) {
        let businessTypes = {};
        const typeFilter = [];
        const business_types = response.data.business_types;
        if (business_types) {
          business_types.forEach((type) => {
            businessTypes[type.id] = type.title;
            typeFilter.push({ text: type.title, value: type.title })
          })
        }
        this.setState({
          business_types: businessTypes,
          businessTypesLoaded: true,
          typeFilter
        });
      }
    }).catch(error => {
      //console.log(error);
    });
  }
  getUserList = (disableDates = false, filterType = 'regdate', pagination = false, userData = false) => {
    const { rangePickerValue } = this.state;
    let bodyObj = {};
    if (!disableDates) {
      bodyObj.dates = [rangePickerValue[0].format(), rangePickerValue[1].format()];
      bodyObj.filterType = filterType;
    }
    //console.log(userData);
    if (pagination) {
      bodyObj.pagination = pagination
    }
    if (userData) {
      bodyObj.userdata = userData
    }
    this.setState({
      isFetching: true
    })
    fetchJSON('/api/v1/user/list', {
      method: 'put',
      body: bodyObj
    }).then(response => {
      //console.log(response);
      if (response.success) {
        const referredToFilter = [];
        const storage = [];
        const countriesFilter = [];
        const storageCountries = [];
        const storageVenues = [];
        const venuesEnrollFilters = [];
        if (response.data) {
          response.data.forEach(user => {
            let organisationTitle = _.get(user, 'enroll_users.venue_groups.venue.organisation.organisation.name');
            let organisationId = _.get(user, 'enroll_users.venue_groups.venue.organisation.organisation.id');
            const country = _.get(user, 'enroll_users.venue_groups.venue.country');
            const venue_title = _.get(user, 'enroll_users.venue_groups.venue.title');
            const venue_id = _.get(user, 'enroll_users.venue_groups.venue.id');
            if (venue_title && venue_id && storageVenues.indexOf(venue_id) === -1) {
              storageVenues.push(venue_id);
              venuesEnrollFilters.push({
                text: venue_title, value: venue_id
              })
            }
            if (country && storageCountries.indexOf(country) === -1) {
              storageCountries.push(country);
              countriesFilter.push({
                text: country, value: country
              });
            }
            if (organisationId && organisationTitle) {
              if (storage.indexOf(organisationId) === -1) {
                storage.push(organisationId);
                referredToFilter.push({
                  text: organisationTitle, value: parseInt(organisationId, 10)
                });
              }
            }
          });
        }
        setTimeout(() => {
          if (!document.getElementById("totalNumber")) {
            this.setState({
              totalNumberTable: 0
            })
          }
        }, 100)
        this.setState({
          usersList: response.data,
          currencies: response.currencies,
          count: response.count,
          isFetching: false,
          referredToFilter,
          countriesFilter,
          venuesEnrollFilters
        }, () => this.getDetailedList(disableDates, filterType))
      }
    }).catch(error => {
      this.setState({
        isFetching: false
      })
      //console.log(error);
    });
  }
  getDetailedList = (disableDates = false, filterType = 'regdate') => {
    const { rangePickerValue } = this.state;
    let bodyObj = {};
    if (!disableDates) {
      bodyObj.dates = [rangePickerValue[0].format(), rangePickerValue[1].format()];
      bodyObj.filterType = filterType;
    }
    this.setState({
      detailsFetching: true
    })
    // fetchJSON('/api/v1/user/list/details', {
    //   method: 'put',
    //   body: bodyObj
    // }).then(response => {
    //   //console.log(response);
    //   if (response.success) {
    //     this.setState({
    //       detailsFetching: false,
    //       detailedInfo: response.data
    //     });
    //     setTimeout(() => {
    //       if (!document.getElementById("totalNumber")) {
    //         this.setState({
    //           totalNumberTable: 0
    //         })
    //       }
    //     }, 100)
    //   }
    // }).catch(error => {
    //   this.setState({
    //     detailsFetching: false
    //   })
    //   //console.log(error);
    // });
  }
  downloadFile = () => {
    const {
      detailedInfo,
      filtered,
      filteredBySearch,
      filteredList,
      rangePickerValue,
      currentDataSource
    } = this.state;
    let list = [];

    if (filteredBySearch) {
      list = filteredList;
    } else if (filtered) {
      list = currentDataSource;
    } else {
      list = detailedInfo;
    }

    const data = [];

    list.forEach(user => {
      let Plan = 'No';
      if (user.subscription) {
        const subscription = user.subscription;
        if (subscription.userOrder) {
          const order = subscription.userOrder.order;
          if (order.promo_id) {
            Plan = 'Promo ';
          } else {
            Plan = '';
          }
          const plan = subscription.plan;
          if (plan) {
            Plan += subscription.plan.view_label;
          }
        }
      }

      let organisationTitle = _.get(user, 'enroll_users.venue_groups.venue.organisation.organisation.name');
      let ReferredToOrg = organisationTitle || '';
      if (user.enroll) {
        ReferredToOrg = user.enroll;
      }
      if (user.plan) {
        Plan = user.plan;
      }

      let phone = '';

      if (user.phone) {
        phone = `**** ${user.phone.slice(-2)}`
      }
      let email = '';
      if (user.email) {
        email = `${user.email.slice(0, 2)}***${user.email.slice(-4)}`;
      }

      data.push({
        'ID': user.id,
        'Phone': phone,
        'Email': email,
        'Active Plan': Plan,
        'Number of sessions': user.electronSession ? user.electronSession.length : user.sessionCount,
        'Sign Up Date': moment.unix(user.regdate).tz('America/Los_Angeles').format('MM/DD/YY h:mm a'),
        'Referral Code': user.referral,
        'Annual Plans': user.annualPlans,
        'Monthly Plans': user.monthlyPlans,
        'Daily Plans': user.dailyPlans,
        'Promo Plans': user.promoPlans,
        'Refunded': user.refunded,
        'Referred To Org': ReferredToOrg
      })
    });

    const fields = [
      'ID',
      'Phone',
      'Email',
      'Active Plan',
      'Number of sessions',
      'Sign Up Date',
      'Referral Code',
      'Annual Plans',
      'Monthly Plans',
      'Daily Plans',
      'Promo Plans',
      'Refunded',
      'Referred To Org'
    ];

    const start = rangePickerValue[0].tz('America/Los_Angeles').format('MM/DD/YY');
    const end = rangePickerValue[1].tz('America/Los_Angeles').format('MM/DD/YY');
    var fileTitle = 'List of users period ' + start + '-' + end;

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(data);
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  isEllipsisActive = (e) => {
    if (e) { return (e.offsetWidth < e.scrollWidth) }
    else { return false }
  }
  columnTitle = (name) => {
    return name;
    // return (
    //   <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
    //     <p style={{margin: 0}}>{name}</p>
    //   </div>
    // )
  }
  getTimeDistanceUnix = (period = 'day') => {
    let start = moment().startOf(period);
    let end = moment().endOf(period);
    if (period === 'week') {
      start = start.add(1, 'd');
      end = end.add(1, 'd');
    }
    return [start.unix(), end.unix()]
  }
  columns = () => {
    let { sortedInfo, filteredInfo, detailedTable, typeFilter, referredToFilter, countriesFilter, venuesEnrollFilters } = this.state;

    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    const ages = [
      '18-20',
      '20-25',
      '25-30',
      '30-35',
      '35-40',
      '40-50',
      '50-60',
      '60-70',
      '70-80',
      '80-100'
    ];
    const agesFilter = [];
    ages.forEach((age) => {
      agesFilter.push({
        text: age, value: age
      });
    })
    const idItem = {
      title: () => this.columnTitle('ID'),
      dataIndex: 'id',
      key: 'id',
      width: 40,
      fixed: 'left',
      sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <span>
          <Link to={"/users/" + record.id}>
            {record.id}
          </Link>
        </span>
      )
    };
    const activeSessionItem = {
      title: () => this.columnTitle('Session'),
      dataIndex: 'activeSession',
      key: 'activeSession',
      width: 80,
      align: 'center',
      filters: [
        { text: 'Active', value: 'yes' },
        { text: 'No', value: 'no' }
      ],

      filteredValue: filteredInfo.activeSession || null,
      // onFilter: (value, record) => {
      //   if (value === 'yes') {
      //     return record.activeSession ? true : false
      //   } else {
      //     return record.activeSession ? false : true
      //   }
      // },
      render: (text, record) => (
        <span>
          {record.activeSession ?
            <Link to={"/sessions/" + record.activeSession} style={{ marginRight: '8px' }}>
              Active
            </Link> : <p style={{ margin: 0 }}>No</p>
          }
        </span>
      )
    };
    const sessionsHistoryItem = {
      title: () => this.columnTitle('Sessions'),
      dataIndex: 'sessionCount',
      key: 'sessionCount',
      align: 'center',
      width: 110,
      sortOrder: sortedInfo.columnKey === 'sessionCount' && sortedInfo.order,
      sorter: (a, b) => a.sessionCount - b.sessionCount,
      filters: [
        { text: '0', value: '0' },
        { text: '1', value: '1' },
        { text: '2', value: '-2' },
        { text: '3', value: '-3' },
        { text: '> 1', value: '2' },
        { text: '> 2', value: '3' },
        { text: '> 5', value: '6' },
        { text: '> 10', value: '11' },
        { text: '> 20', value: '21' }
      ],
      filteredValue: filteredInfo.sessionCount || null,
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   if (valueInt < 2) {
      //     return record.sessionCount === Math.abs(valueInt)
      //   } else {
      //     return record.sessionCount >= valueInt
      //   }
      // },
      render: (text, record) => (
        <span>
          {record.sessionCount > 0 ?
            <Link to={`/users/${record.id}?history=1`}>
              {record.sessionCount}
            </Link> : <p style={{ margin: 0 }}>0</p>
          }
        </span>
      )
    };
    const lastSessionDateItem = {
      title: 'Last session',
      dataIndex: 'lastSessionDate',
      key: 'lastSessionDate',
      align: 'center',
      width: 110,
      sortOrder: sortedInfo.columnKey === 'lastSessionDate' && sortedInfo.order,
      sorter: (a, b) => a.lastSessionOpenStamp - b.lastSessionOpenStamp,
      filters: [
        { text: 'Today', value: 'day' },
        { text: 'This week', value: 'week' },
        { text: 'This month', value: 'month' }
      ],
      filteredValue: filteredInfo.lastSessionDate || null,
      // onFilter: (value, record) => {
      //   if (record.lastSessionDate) {
      //     const period = this.getTimeDistanceUnix(value);
      //     return record.lastSessionOpenStamp > period[0] && record.lastSessionOpenStamp < period[1]
      //   }
      //   return;
      // }
    };
    const planItem = {
      title: () => this.columnTitle('Plan'),
      dataIndex: 'plan',
      key: 'plan',
      width: 100,
      filters: [
        { text: 'Hourly', value: 'Hourly' },
        { text: 'Daily', value: 'Daily' },
        { text: 'Monthly', value: 'Monthly' },
        { text: 'Annual', value: 'Annual' },
        { text: 'Free first time', value: 'Free first time' },
        { text: 'Promo daily', value: 'Promo daily' },
        { text: 'Promo monthly', value: 'Promo monthly' },
        { text: 'Promo semester', value: 'Promo semester' },
        { text: 'No', value: 'No' }
      ],
      filteredValue: filteredInfo.plan || null,
      // onFilter: (value, record) => {
      //   console.log(filteredInfo.plan);
      //   return record.plan.startsWith(value);
      // }
    };
    const npsItem = {
      title: () => this.columnTitle('NPS'),
      dataIndex: 'nps_score',
      key: 'nps_score',
      width: 100,
      filters: [
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '3', value: '3' },
        { text: '4', value: '4' },
        { text: '5', value: '5' },
        { text: '6', value: '6' },
        { text: '7', value: '7' },
        { text: '8', value: '8' },
        { text: '9', value: '9' },
        { text: '10', value: '10' }
      ],
      filteredValue: filteredInfo.nps_score || null,
      // onFilter: (value, record) => {
      //   return record.nps_score && record.nps_score.toString() === value;
      // }
    };
    const expirationItem = {
      title: 'Expiration',
      dataIndex: 'expirationDays',
      key: 'expirationDays',
      width: 120,
      sortDirections: ['ascend', 'descend'],
      filters: [
        { text: 'Today', value: '0' },
        { text: '1 day left', value: '1' },
        { text: '5 days left', value: '5' },
        { text: '> 7 days left', value: '7' }
      ],
      filteredValue: filteredInfo.expirationDays || null,
      // onFilter: (value, record) => {
      //   let valueInt = parseInt(value, 10);
      //   if (valueInt !== 7) {
      //     return record.expirationDays === valueInt
      //   } else {
      //     return record.expirationDays > 7 && record.expirationDays !== 9999
      //   }
      // },
      sortOrder: sortedInfo.columnKey === 'expirationDays' && sortedInfo.order,
      sorter: (a, b) => a.expirationDays - b.expirationDays,
      render: (text, record) => (
        <span>
          <p style={{ margin: 0, fontWeight: record.expirationTextDay === 'No' ? '' : 700 }}>{record.expirationTextDay}</p>
          <p style={{ margin: 0 }}>{record.expirationTextAt}</p>
        </span>
      )
    };
    const ratingItem = {
      title: () => this.columnTitle('Rating'),
      dataIndex: 'userRating',
      key: 'userRating',
      align: 'center',
      width: 100,
      sortOrder: sortedInfo.columnKey === 'userRating' && sortedInfo.order,
      sorter: (a, b) => a.userRating - b.userRating,
      filters: [
        { text: '5', value: '5' },
        { text: '4.9-4.5', value: '4.9-4.5' },
        { text: '4.5-4', value: '4.5-4' },
        { text: '4-3.5 ', value: '4-3.5' },
        { text: '3.5-3 ', value: '3.5-3' },
        { text: '3-2 ', value: '3-2' },
        { text: '1-0', value: '1-0' }
      ],
      filteredValue: filteredInfo.userRating || null,
      // onFilter: (value, record) => {
      //   let rate = parseFloat(value);
      //   switch (rate) {
      //     case 5:
      //       return parseInt(record.userRating) === 5;
      //     case 4.9:
      //       return record.userRating <= 4.9 && record.userRating >= 4.5;
      //     case 4.5:
      //       return record.userRating <= 4.5 && record.userRating >= 4;
      //     case 4:
      //       return record.userRating <= 4 && record.userRating >= 3.5;
      //     case 3.5:
      //       return record.userRating <= 3.5 && record.userRating >= 3;
      //     case 3:
      //       return record.userRating <= 3 && record.userRating >= 2;
      //     case 1:
      //       return record.userRating <= 1 && record.userRating >= 0;
      //     default:
      //       break;
      //   }
      // },
    };
    const phoneItem = {
      title: () => this.columnTitle('Phone'),
      dataIndex: 'phone',
      key: 'phone',
      width: 100,
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`phoneText_${record.key}}`)) ? record.phone : ''}>
            <p id={`phoneText_${record.key}}`} style={{ margin: 0, width: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {record.phone}
            </p>
          </Tooltip>
        </div>
      )
    };
    const emailItem = {
      title: () => this.columnTitle('Email'),
      dataIndex: 'email',
      key: 'email',
      width: 100,
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`emailText_${record.key}}`)) ? record.email : ''}>
            <p id={`emailText_${record.key}}`} style={{ margin: 0, width: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {record.email}
            </p>
          </Tooltip>
        </div>
      )
    };
    const genderItem = {
      title: () => this.columnTitle('Gender'),
      dataIndex: 'gender',
      width: 100,
      filters: [
        { text: 'Female', value: 'female' },
        { text: 'Male', value: 'male' },
        { text: 'Non binary', value: 'non_binary' },
      ],
      filteredValue: filteredInfo.gender || null,
      // onFilter: (value, record) => {
      //   return record.gender === value;
      // },
      render: (text, record) => (
        <div>
          {record.gender && record.gender === 'non_binary' ?
            <p className="grad-text">Non binary</p>
            : null}
          {record.gender && record.gender !== 'non_binary' ?
            <p style={{ margin: 0, color: record.gender === 'female' ? '#FE2E9A' : '#2E64FE' }}>{record.gender === 'female' ? 'Female' : 'Male'}</p>
            : null}
        </div>
      )
    };
    const ageItem = {
      title: () => this.columnTitle('Age'),
      dataIndex: 'age',
      align: 'center',
      width: 100,

      filters: agesFilter,
      filteredValue: filteredInfo.age || null,
      // onFilter: (value, record) => {
      //   return record.age === value;
      // }
    };
    const worryPhoneDyingItem = {
      title: 'Worry phone dying',
      dataIndex: 'worrySurveyText',
      key: 'worrySurveyText',
      align: 'center',
      width: 120,
      filters: [
        { text: 'Somewhat worry', value: 'Somewhat worry' },
        { text: 'Worry', value: 'Worry' },
        { text: 'Stressed', value: 'Stressed' },
      ],
      filteredValue: filteredInfo.worrySurveyText || null,
      // onFilter: (value, record) => {
      //   return record.worrySurveyText === value;
      // },
      render: (text, record) => (
        <div>
          {record.worrySurveyText ?
            <p style={{ margin: 0, color: record.worrySurveyColor ? record.worrySurveyColor : '' }}>{record.worrySurveyText}</p>
            : null}
        </div>
      )
    };
    const s_paid_servicesItem = {
      title: 'Paid services opinion',
      dataIndex: 's_paid_servicesText',
      key: 's_paid_servicesText',
      align: 'center',
      width: 120,
      filters: [
        { text: '24 hours usage fee ¥250', value: '24 hours usage fee ¥250' },
        { text: '24-hour usage fee ¥300', value: '24-hour usage fee ¥300' },
        { text: 'Monthly subscription Unlimited use fee ¥800', value: 'Monthly subscription Unlimited use fee ¥800' },
      ],
      filteredValue: filteredInfo.s_paid_servicesText || null,
      // onFilter: (value, record) => {
      //   return record.s_paid_servicesText === value;
      // },
      render: (text, record) => (
        <div>
          {record.s_paid_servicesText ?
            <p style={{ margin: 0 }}>{record.s_paid_servicesText}</p>
            : null}
        </div>
      )
    };
    const businessTypeItem = {
      title: 'Business type',
      dataIndex: 'businessType',
      key: 'businessType',
      width: 110,
      align: 'center',
      filters: typeFilter,
      filteredValue: filteredInfo.businessType || null,
      // onFilter: (value, record) => {
      //   return record.businessType === value
      // }
    };

    const signUpOrgItem = {
      title: 'Signup org',
      dataIndex: 'enroll',
      key: 'enroll',
      width: 100,
      // filters: referredToFilter,
      // filterSearch: true,
      // filteredValue: filteredInfo.enroll || null,
      ...this.getColumnSearchProps('Signup org'),
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   return record.organisationId === valueInt;
      // }
    };

    const signUpVenueItem = {
      title: 'Signup venue',
      dataIndex: 'enroll_venue_title',
      key: 'enroll_venue_title',
      width: 100,
      ...this.getColumnSearchProps('Signup venue'),
      // filters: venuesEnrollFilters,

      // filteredValue: filteredInfo.enroll_venue_title || null,
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   return record.enroll_venue === valueInt;
      // }
    };
    const countryItem = {
      title: () => this.columnTitle('Country'),
      dataIndex: 'country',
      key: 'country',
      align: 'center',
      width: 110,
      filters: countriesFilter,
      filteredValue: filteredInfo.country || null,
      onFilter: (value, record) => {
        //console.log('record.country', record.country, 'value', value);
        return record.country === value;
      }
    };
    const statusItem = {
      title: () => this.columnTitle('Status'),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      filters: [
        { text: 'Active', value: 'Active' },
        { text: 'Suspended', value: 'Suspended' },
      ],
      filteredValue: filteredInfo.status || null,
      // onFilter: (value, record) => {
      //   return record.status.includes(value)
      // },
      render: (text, record) => (
        <p style={{ margin: 0, color: text === 'Suspended' ? 'red' : '' }}>{text}</p>
      )
    };
    const typeItem = {
      title: () => this.columnTitle('Type'),
      dataIndex: 'type',
      key: 'type',
      width: 80,
      filters: [
        { text: 'New', value: 'New' },
        { text: 'Returned', value: 'Returned' }
      ],
      filteredValue: filteredInfo.type || null,
      // onFilter: (value, record) => {
      //   return record.type === value
      // }
    };
    const signUpDateItem = {
      title: 'Signup date',
      dataIndex: 'signUpDate',
      key: 'signUpDate',
      width: 90,
      sortOrder: sortedInfo.columnKey === 'signUpDate' && sortedInfo.order,
      sorter: (a, b) => a.regdate - b.regdate
    };
    // detailed columns
    const openAppDaysItem = {
      title: 'Opened app (days)',
      dataIndex: 'openedApp',
      key: 'openedApp',
      align: 'center',
      width: 140,
      sortOrder: sortedInfo.columnKey === 'openedApp' && sortedInfo.order,
      sorter: (a, b) => a.openedApp - b.openedApp,
      filters: [
        { text: '0', value: '0' },
        { text: '1', value: '1' },
        { text: '2', value: '-2' },
        { text: '3', value: '-3' },
        { text: '> 2', value: '2' },
        { text: '> 3', value: '3' },
        { text: '> 10', value: '10' },
        { text: '> 15', value: '15' }
      ],
      filteredValue: filteredInfo.openedApp || null,
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   if (valueInt < 2) {
      //     return record.openedApp === Math.abs(valueInt);
      //   }
      //   return record.openedApp > valueInt;
      // }
    };
    const lastVisitItem = {
      title: 'Last visit',
      dataIndex: 'lastVisit',
      key: 'lastVisit',
      width: 110,
      sortOrder: sortedInfo.columnKey === 'lastVisit' && sortedInfo.order,
      sorter: (a, b) => a.lastVisitNumber - b.lastVisitNumber
    };
    const usedFirstFree = {
      title: 'Used Free Day',
      dataIndex: 'everUsedFreeDay',
      key: 'everUsedFreeDay',
      align: 'center',
      width: 100,
      filters: [
        { text: 'Yes', value: '1' },
        { text: 'No', value: '0' }
      ],
      filteredValue: filteredInfo.everUsedFreeDay || null,
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   return record.everUsedFreeDayValue === valueInt;
      // }
    };
    const dailyItem = {
      title: () => this.columnTitle('Daily'),
      dataIndex: 'dailyPlans',
      key: 'dailyPlans',
      align: 'center',
      width: 110,
      sortOrder: sortedInfo.columnKey === 'dailyPlans' && sortedInfo.order,
      sorter: (a, b) => a.dailyPlans - b.dailyPlans,
      filters: [
        { text: '0', value: '0' },
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '> 2', value: '3' },
        { text: '> 10', value: '10' },
        { text: '> 15', value: '15' }
      ],
      filteredValue: filteredInfo.dailyPlans || null,
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   if (valueInt < 3) {
      //     return record.dailyPlans === valueInt;
      //   }
      //   return record.dailyPlans >= valueInt;
      // }
    };
    const monthlyItem = {
      title: () => this.columnTitle('Monthly'),
      dataIndex: 'monthlyPlans',
      key: 'monthlyPlans',
      align: 'center',
      width: 110,
      sortOrder: sortedInfo.columnKey === 'monthlyPlans' && sortedInfo.order,
      sorter: (a, b) => a.monthlyPlans - b.monthlyPlans,
      filters: [
        { text: '0', value: '0' },
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '> 2', value: '3' },
        { text: '> 10', value: '10' },
        { text: '> 15', value: '15' }
      ],
      filteredValue: filteredInfo.monthlyPlans || null,
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   if (valueInt < 3) {
      //     return record.monthlyPlans === valueInt;
      //   }
      //   return record.monthlyPlans >= valueInt;
      // }
    };
    const annualItem = {
      title: () => this.columnTitle('Annual'),
      dataIndex: 'annualPlans',
      key: 'annualPlans',
      align: 'center',
      width: 110,
      sortOrder: sortedInfo.columnKey === 'annualPlans' && sortedInfo.order,
      sorter: (a, b) => a.annualPlans - b.annualPlans,
      filters: [
        { text: '0', value: '0' },
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '> 2', value: '3' },
        { text: '> 10', value: '10' },
        { text: '> 15', value: '15' }
      ],
      filteredValue: filteredInfo.annualPlans || null,
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   if (valueInt < 3) {
      //     return record.annualPlans === valueInt;
      //   }
      //   return record.annualPlans >= valueInt;
      // }
    };
    const hourlyItem = {
      title: () => this.columnTitle('Hourly'),
      dataIndex: 'hourlyPlans',
      key: 'hourlyPlans',
      align: 'center',
      width: 110,
      sortOrder: sortedInfo.columnKey === 'hourlyPlans' && sortedInfo.order,
      sorter: (a, b) => a.hourlyPlans - b.hourlyPlans,
      filters: [
        { text: '0', value: '0' },
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '> 2', value: '3' },
        { text: '> 10', value: '10' },
        { text: '> 15', value: '15' }
      ],
      filteredValue: filteredInfo.hourlyPlans || null,
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   if (valueInt < 3) {
      //     return record.hourlyPlans === valueInt;
      //   }
      //   return record.hourlyPlans >= valueInt;
      // }
    };
    const freePrivateItem = {
      title: 'Free private',
      dataIndex: 'freePrivate',
      key: 'freePrivate',
      align: 'center',
      width: 110,
      sortOrder: sortedInfo.columnKey === 'freePrivate' && sortedInfo.order,
      sorter: (a, b) => a.freePrivate - b.freePrivate,
      filters: [
        { text: '0', value: '0' },
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '> 2', value: '3' },
        { text: '> 10', value: '10' },
        { text: '> 15', value: '15' }
      ],
      filteredValue: filteredInfo.freePrivate || null,
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   if (valueInt < 3) {
      //     return record.freePrivate === valueInt;
      //   }
      //   return record.freePrivate >= valueInt;
      // }
    };
    const promoItem = {
      title: () => this.columnTitle('Promo'),
      dataIndex: 'promoPlans',
      key: 'promoPlans',
      align: 'center',
      width: 110,
      sortOrder: sortedInfo.columnKey === 'promoPlans' && sortedInfo.order,
      sorter: (a, b) => a.promoPlans - b.promoPlans,
      filters: [
        { text: '0', value: '0' },
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '> 2', value: '3' },
        { text: '> 10', value: '10' },
        { text: '> 15', value: '15' }
      ],
      filteredValue: filteredInfo.promoPlans || null,
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   if (valueInt < 3) {
      //     return record.promoPlans === valueInt;
      //   }
      //   return record.promoPlans >= valueInt;
      // }
    };
    const refundedItem = {
      title: () => this.columnTitle('Refunded'),
      dataIndex: 'refunded',
      key: 'refunded',
      align: 'center',
      width: 110,
      sortOrder: sortedInfo.columnKey === 'refunded' && sortedInfo.order,
      sorter: (a, b) => a.refunded - b.refunded,
      filters: [
        { text: 'No', value: '0' },
        { text: '1 and more', value: '1' }
      ],
      filteredValue: filteredInfo.refunded || null,
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   if (valueInt === 0) {
      //     return record.refunded === valueInt;
      //   }
      //   return record.refunded > 0;
      // },
      render: (text, record) => (
        <p style={{ margin: 0 }}>{record.refundedText}</p>
      )
    };
    const referralItem = {
      title: 'Referral code',
      dataIndex: 'referral',
      key: 'referral',
      width: 80,
      render: (text, record) => (
        <p style={{ margin: 0 }}>{text}</p>
      )
    };
    const referralUsedItem = {
      title: 'Referral used',
      align: 'center',
      dataIndex: 'referralUsed',
      key: 'referralUsed',
      width: 110,
      sortOrder: sortedInfo.columnKey === 'referralUsed' && sortedInfo.order,
      sorter: (a, b) => a.referralUsed - b.referralUsed,
      filters: [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' },
        { text: '0', value: '0' },
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '> 2', value: '3' },
        { text: '> 10', value: '10' },
        { text: '> 15', value: '15' }
      ],
      filteredValue: filteredInfo.referralUsed || null,
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   if (!valueInt) {
      //     if (value === 'Yes') { return record.referralUsed > 0 }
      //     if (value === 'No') { return record.referralUsed === 0 }
      //   }
      //   if (valueInt < 3) {
      //     return record.referralUsed === valueInt;
      //   }
      //   return record.referralUsed >= valueInt;
      // },
      render: (text, record) => (
        <div>
          {record.referralUsed > 0 ?
            <button className="ant-btn button-link" onClick={() => this.filterById(record.id, record.invitedUsers)}>
              {this.state.filteredById && this.state.hostUserId === record.id ? 'Cancel' : text}
            </button> : <p style={{ margin: 0 }}>No</p>}
        </div>
      )
    };
    const paymentMethodItem = {
      title: 'Payment info',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      align: 'center',
      width: 90,
      filters: [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' }
      ],
      filteredValue: filteredInfo.paymentMethod || null,
      // onFilter: (value, record) => {
      //   return record.paymentMethod === value
      // }
    };
    const revenueItem = {
      title: () => this.columnTitle('Revenue'),
      dataIndex: 'revenue',
      key: 'revenue',
      width: 100,
      align: 'center',
      filters: [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' }
      ],
      filteredValue: filteredInfo.revenue || null,
      // onFilter: (value, record) => {
      //   if (value === 'Yes') {
      //     return record.revenue.length
      //   }
      //   else return !record.revenue.length
      // }
    }
    const cableTypesItem = {
      title: 'Type of cable',
      dataIndex: 'cableTypes',
      key: 'cableTypes',
      width: 100,
      filters: [
        { text: 'Lightning', value: 'lightning' },
        { text: 'Type-C', value: 'usb_c' },
        { text: 'Micro', value: 'micro_usb' },
        { text: 'Double', value: '2' },
        { text: 'Triple', value: '3' },
      ],
      filteredValue: filteredInfo.cableTypes || null,
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   if (!valueInt) {
      //     return (record.cableTypes.indexOf(value) > -1 && record.cableTypes.length === 1)
      //   } else {
      //     return (record.cableTypes.length === valueInt)
      //   }
      // },
      render: (text, record) => (
        <div>
          {record.cableTypesTags}
        </div>
      )
    };
    const multyLocationsItem = {
      title: 'Multi locations',
      key: 'multyLocations',
      dataIndex: 'multyLocations',
      align: 'center',
      width: 110,
      sortOrder: sortedInfo.columnKey === 'multyLocations' && sortedInfo.order,
      sorter: (a, b) => a.multyLocations - b.multyLocations,
      filters: [
        { text: '0', value: '0' },
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '3', value: '3' },
        { text: '> 3', value: '4' }
      ],
      filteredValue: filteredInfo.multyLocations || null,
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   if (valueInt < 4) {
      //     return record.multyLocations === valueInt;
      //   }
      //   return record.multyLocations >= valueInt;
      // },
      render: (text, record) => (
        <span>
          {record.multyLocations > 0 ?
            <Link to={`/users/${record.id}?history=1`}>
              {record.multyLocations}
            </Link> : <p style={{ margin: 0 }}>0</p>
          }
        </span>
      )
    }
    const sessionTimeItem = {
      title: 'Session time',
      key: 'midSessionTime',
      dataIndex: 'midSessionTime',
      width: 140,
      align: 'center',
      sortOrder: sortedInfo.columnKey === 'midSessionTime' && sortedInfo.order,
      sorter: (a, b) => a.midSessionTime - b.midSessionTime,
      filters: [
        { text: '< 5 min', value: '5' },
        { text: '< 10 min', value: '10' },
        { text: '< 30 min', value: '30' },
        { text: '< 60 min', value: '60' },
        { text: '> 60 min', value: '61' }
      ],
      filteredValue: filteredInfo.midSessionTime || null,
      // onFilter: (value, record) => {
      //   const valueInt = parseInt(value, 10);
      //   if (record.midSessionTime > 0 || record.avgSessionTime > 0) {
      //     if (valueInt < 61) {
      //       return record.midSessionTime < valueInt
      //     } else {
      //       return record.midSessionTime > 60
      //     }
      //   } else return false;
      // },
      render: (text, record) => (
        <span>
          {(record.midSessionTime > 0 || record.avgSessionTime > 0) && record.midSessionTime !== record.avgSessionTime ?
            <span>
              <div style={{ display: 'inline-flex', width: '-webkit-fill-available' }}>
                <p style={{ margin: '0px 2px', fontWeight: 600 }}>Mid:</p><p style={{ margin: 0 }}>
                  {moment.duration(record.midSessionTime, 'minutes').format('h [h] m [min]')}
                </p>
              </div>
              <div style={{ display: 'inline-flex', width: '-webkit-fill-available' }}>
                <p style={{ margin: '0px 2px', fontWeight: 600 }}>Avg:</p><p style={{ margin: 0 }}>
                  {moment.duration(record.avgSessionTime, 'minutes').format('h [h] m [min]')}
                </p>
              </div>
            </span> : <p style={{ margin: 0 }}>{moment.duration(record.midSessionTime, 'minutes').format('h [h] m [min]')}</p>}
        </span>
      )
    }

    let columns = [];
    const generalColumns = [idItem, activeSessionItem, planItem, expirationItem, revenueItem, sessionsHistoryItem, sessionTimeItem, lastSessionDateItem, ratingItem,
      genderItem, ageItem, worryPhoneDyingItem, npsItem, s_paid_servicesItem, businessTypeItem, signUpOrgItem, signUpVenueItem, countryItem, statusItem, typeItem, cableTypesItem, multyLocationsItem];
    const detailedColumns = [phoneItem, emailItem, openAppDaysItem, lastVisitItem, signUpDateItem, paymentMethodItem, usedFirstFree,
      hourlyItem, dailyItem, monthlyItem, annualItem, promoItem, freePrivateItem, refundedItem, referralItem, referralUsedItem];
    if (!detailedTable) {
      generalColumns.push(signUpDateItem)
      generalColumns.push(phoneItem)
      generalColumns.push(emailItem)
    }
    columns.push(...generalColumns);
    if (detailedTable) {
      columns.push(...detailedColumns);
    }
    return columns
  }
  filterById = (hostUserId, invitedUsers) => {
    this.setState({
      filteredInfo: {}
    })


    const { detailedInfo, filteredById } = this.state;
    if ((!filteredById || (filteredById && this.state.hostUserId !== hostUserId)) && detailedInfo) {
      const filteredList = [];
      let invitedRecords = [];
      let idsStorage = [hostUserId];
      detailedInfo.forEach((record) => {
        if (record.id === hostUserId) {
          filteredList.push(record);
        }
      })
      invitedRecords = detailedInfo.flatMap(record => {
        if (invitedUsers.indexOf(record.id) > -1 && idsStorage.indexOf(record.id) === -1) {
          idsStorage.push(record.id)
          return {
            ...record,
          };
        }
        else {
          return null
        }
      });
      filteredList.push(...invitedRecords);
      this.setState({
        filteredById: true,
        filteredBySearch: true,
        hostUserId: hostUserId,
        filteredList: filteredList.filter(record => !!record)
      });
    }
    else {
      this.setState({
        filteredBySearch: false,
        filteredById: false,
        hostUserId: 0
      })
    }
  }
  getRating(sessions) {
    let rating = {
      1: [], 2: [], 3: [], 4: [], 5: []
    };
    if (!sessions.length) return 5.00;
    sessions.forEach(session => {
      let sessionOpen = moment.unix(session.opentime);
      let sessionClose = moment();
      if (session.status === 'close') {
        sessionClose = moment.unix(session.closetime);
      }
      let ms = sessionClose.diff(sessionOpen);
      let _duration = moment.duration(ms);
      let hoursSpend = parseInt((_duration._milliseconds / (1000 * 60 * 60)), 10);
      if (session.status === 'lost') {
        rating['1'].push(hoursSpend);
      } else if (hoursSpend < 25) {
        rating['5'].push(hoursSpend);
      } else if (hoursSpend > 24 && hoursSpend < 59) {
        rating['4'].push(hoursSpend);
      } else if (hoursSpend > 58 && hoursSpend < 73) {
        rating['3'].push(hoursSpend);
      } else if (hoursSpend > 72) {
        rating['2'].push(hoursSpend);
      }
    })
    let top = 0;
    let bottom = 0;
    Object.keys(rating).forEach((key) => {
      bottom += rating[key].length;
      top += parseInt(rating[key].length * parseInt(key, 10), 10);
    });
    let stars = top / bottom;
    return stars.toFixed(2);
  }
  getCurrencyText = (code, price) => {
    const currencies = this.state.currencies;
    let result = '';
    currencies.forEach((currency) => {
      if (currency.code === code) {
        const template = currency.template;
        const grapheme = currency.grapheme;
        result = template.replace('$', grapheme).replace('1', parseFloat(price, 10));
      }
    })
    return result
  }
  getData = () => {
    const data = [];
    const { detailedTable, filteredBySearch, detailedInfo, usersList } = this.state;

    let users;
    if ((!detailedTable && (!usersList || !usersList.length)) || (detailedTable && (!detailedInfo || !detailedInfo.length))) return data;
    if (filteredBySearch) {
      users = this.state.filteredList;
    } else {
      if (detailedTable) {
        users = detailedInfo;
      }
      else {
        users = usersList;
      }
    }

    users.forEach((user, i) => {
      let sessionId;
      if (user.activeSession && Object.keys(user.activeSession).length) {
        sessionId = user.activeSession.id;
      }
      let revenueArr = [];
      let revenue = '';
      if (user.revenue && Object.keys(user.revenue).length) {
        Object.keys(user.revenue).forEach((key) => {
          revenueArr.push(this.getCurrencyText(key, user.revenue[key]));
        })
      }

      if (revenueArr.length) {
        revenue = revenueArr.join(', ');
      }
      let lastSessionDate = '';
      let lastSessionOpenStamp = -99999999999;
      if (user.electronSession && user.electronSession.length) {
        lastSessionOpenStamp = user.electronSession[0].opentime;
        let lastSessionOpenMoment = moment.unix(user.electronSession[0].opentime);
        lastSessionDate = lastSessionOpenMoment.format('DD.MM.YYYY');
      }
      const cableTypes = [];
      if (user.cables_use && user.cables_use.length) {
        if (user.cables_use.indexOf('micro_usb') > -1) cableTypes.push(<Tag key={`${user.id}mc`} color="green">Micro</Tag>)
        if (user.cables_use.indexOf('usb_c') > -1) cableTypes.push(<Tag key={`${user.id}tc`} color="green">Type-C</Tag>)
        if (user.cables_use.indexOf('lightning') > -1) cableTypes.push(<Tag key={`${user.id}lt`} color="green">Lightning</Tag>)
      }
      let Plan = 'No';
      let expirationTextDay = 'No';
      let expirationTextAt = '';
      let expirationDays;
      if (user.subscription) {
        const subscription = user.subscription;
        if (user.id === 22470) {
          console.log(user, plan);
        }
        if (subscription.userOrder) {
          const order = subscription.userOrder.order;
          if (this.isDev) {
            subscription.userOrder.first_time_free_no_payments = 1;
          }
          if (order.promo_id) {
            if ((order.promo_id === 1642 || order.promo_id === 1307) && subscription.userOrder.first_time_free_no_payments) {
              Plan = 'Free first time';
            }
            else { Plan = 'Promo '; }
          } else { Plan = ''; }

          const plan = subscription.plan;

          if (plan && Plan !== 'Free first time') {
            if (Plan && Plan.startsWith('Promo')) {
              Plan += subscription.plan.view_label.toLowerCase();
            }
            else {
              Plan += subscription.plan.view_label;
            }
          }
          let paiduntildate = moment.unix(subscription.paiduntildate);
          let now = moment();
          expirationDays = paiduntildate.diff(now, 'days');
          if (expirationDays === 0) {
            expirationTextDay = `Today`;
            expirationTextAt = ` (at ${paiduntildate.format('h:mm a')})`;
          } else if (expirationDays === 1) {
            expirationTextDay = "1 day";
            expirationTextAt = ` (at ${paiduntildate.format('DD.MM.YYYY, h:mm a')})`
          } else {
            expirationTextDay = `${expirationDays} days`;
            expirationTextAt = ` (at ${paiduntildate.format('DD.MM.YYYY, h:mm a')})`;
          }
        }
      }
      let organisationTitle = _.get(user, 'enroll_users.venue_groups.venue.organisation.organisation.name');
      let organisationId = _.get(user, 'enroll_users.venue_groups.venue.organisation.organisation.id');
      const country = _.get(user, 'enroll_users.venue_groups.venue.country');

      const venueBusinessTypeId = _.get(user, 'enroll_users.venue_groups.venue.business_type_id');
      let businessType = '';
      if (venueBusinessTypeId) {
        const { business_types } = this.state;
        businessType = business_types[venueBusinessTypeId];
      }
      const enroll_venue = _.get(user, 'enroll_users.venue_groups.venue.id');;
      const enroll_venue_title = _.get(user, 'enroll_users.venue_groups.venue.title');;

      let worrySurveyText = '';
      let worrySurveyColor = '';
      if (user.worry_your_phone_dying) {
        switch (user.worry_your_phone_dying) {
          case 'somewhat worry':
            worrySurveyColor = 'blue';
            worrySurveyText = 'Somewhat worry';
            break;
          case 'worry':
            worrySurveyText = 'Worry';
            worrySurveyColor = 'orange';
            break;
          case 'stressed':
            worrySurveyText = 'Stressed';
            worrySurveyColor = 'red';
            break;
          default:
            break;
        }
      }

      let s_paid_servicesText = '';
      if (user.s_paid_services) {
        switch (user.s_paid_services) {
          case '24hours_250':
            s_paid_servicesText = '24 hours usage fee ¥250';
            break;
          case '24hours_300':
            s_paid_servicesText = '24-hour usage fee ¥300';
            break;
          case 'monthly_800':
            s_paid_servicesText = 'Monthly subscription Unlimited use fee ¥800';
            break;
          default:
            break;
        }
      }

      const generalData = {
        key: i,
        id: user.id,
        phone: user.phone,
        type: user.type,
        plan: Plan,
        expirationTextDay,
        expirationTextAt,
        revenue,
        enroll_venue,
        enroll_venue_title,
        expirationDays: expirationDays !== undefined ? expirationDays : 9999,
        activeSession: user.activeSession ? sessionId : null,
        status: user.blocking_reason ? 'Suspended' : 'Active',
        sessionCount: user.electronSession.length,
        lastSessionDate,
        lastSessionOpenStamp,
        userRating: user.electronSession.length ? this.getRating(user.electronSession) : '5.00',
        regdate: user.regdate,
        email: user.email,
        signUpDate: moment.unix(user.regdate).tz('America/Los_Angeles').format('MM/DD/YY h:mm a'),
        age: user.age,
        nps_score: user.nps_score,
        gender: user.gender,
        worrySurveyText,
        worrySurveyColor,
        s_paid_services: user.s_paid_services,
        s_paid_servicesText,
        enroll: organisationTitle || '',
        organisationId: organisationId ? parseInt(organisationId, 10) : null,
        country: country,
        businessType,
        cableTypesTags: cableTypes,
        cableTypes: user.cables_use || [],
        multyLocations: user.venueIds ? user.venueIds.length : 0,
        avgSessionTime: user.avgSessionTime || 0,
        midSessionTime: user.midSessionTime || 0,
      };
      let detailedData = {};
      if (detailedTable) {
        let lastVisit = 0;
        let lastVisitNumber = -999;
        if (user.lastVisit) {
          let sessionOpen = moment(user.lastVisit);
          let sessionClose = moment();
          let ms = sessionClose.diff(sessionOpen);
          let _duration = moment.duration(ms);
          lastVisitNumber = _duration._milliseconds;
          lastVisit = _duration.format("d [d] h [h] m [min]");
        }
        let refundedText = 'No';
        if (user.refunded && user.refunded > 0) {
          refundedText = `${user.refunded} time(s)`;
          if (user.refundedObj && Object.keys(user.refundedObj).length) {
            let refundedArr = [];
            Object.keys(user.refundedObj).forEach((key) => {
              refundedArr.push(this.getCurrencyText(key, user.refundedObj[key]));
            })

            if (refundedArr.length) {
              refundedText = refundedArr.join(', ');
            }
          }
        }


        detailedData = {
          ...generalData,
          lastVisit,
          lastVisitNumber,
          referralUsed: user.invitedUsers ? user.invitedUsers.length : 0,
          invitedUsers: user.invitedUsers ? user.invitedUsers : [],
          paymentMethod: user.paymentMethod ? 'Yes' : 'No',
          everUsedFreeDay: user.everUsedFreeDay ? 'Yes' : 'No',
          everUsedFreeDayValue: user.everUsedFreeDay ? 1 : 0,
          openedApp: user.openedApp,
          referral: user.referral,
          hourlyPlans: user.hourlyPlans,
          freePrivate: user.freePrivate,
          annualPlans: user.annualPlans,
          dailyPlans: user.dailyPlans,
          monthlyPlans: user.monthlyPlans,
          promoPlans: user.promoPlans,
          refunded: user.refunded,
          refundedText
        };
      }
      if (!detailedTable) {
        data.push(generalData)
      }
      else {
        data.push(detailedData)
      }
    });

    return data;
  }
  handleTableChange = (pagination, filters, sorter, extra) => {
    console.log('filters', cleanEmpty(filters));
    let UserData = { 'filters': cleanEmpty(filters), 'sorter': sorter };
    let filtered = false;
    const { detailedTable, detailedInfo } = this.state;
    const data = this.state.usersList;
    if (extra.currentDataSource && detailedInfo) {
      if ((extra.currentDataSource.length !== data.length && !detailedTable) || (extra.currentDataSource.length !== detailedInfo.length && detailedTable)) {
        filtered = true;
      }
    }
    if (pagination.current > 0) {
      this.getUserList(false, 'regdate', pagination, UserData);
    }
    setTimeout(() => {
      if (!document.getElementById("totalNumber")) {
        this.setState({
          totalNumberTable: 0
        })
      }
    }, 100)
    // used for tooltips in cells (because it searchs for html element)
    setTimeout(() => {
      const { pagination } = this.state;
      this.setState({
        pagination: {
          ...pagination,
          update: !pagination.update
        }
      })
    }, 100);


    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      currentDataSource: extra.currentDataSource,
      filtered: filtered
    })
  }
  timer = 0

  onSearch = (e) => {
    console.log(e);
    this.setState({ searchText: e.target.value });
    let searchText = e.target.value;
    const filtertype = this.state.searchParameter;

    const { searchParameter, detailedTable, detailedInfo } = this.state;
    const reg = new RegExp(`${e.target.value.replace(new RegExp("[()+]", "g"), '')}`, 'gi');
    let data = this.state.usersList;
    if (detailedTable && detailedInfo) {
      data = detailedInfo;
    }
    // this.setState({
    //   filteredBySearch: !!searchText,
    //   filteredList: _.flatMap(data, record => {

    //     let number = searchText.replace(/[^\d]/g, '');
    //     let phoneMatch = false;
    //     let userIdMatch = false;
    //     if (number) {
    //       phoneMatch = record.phone ? record.phone.match(new RegExp(number, 'gi')) && (searchParameter === 'phone' || !searchParameter) : false;
    //       userIdMatch = record.id.toString().match(new RegExp(number, 'gi')) && (searchParameter === 'userId' || !searchParameter);
    //     }
    //     const emailMatch = record.email ? record.email.match(reg) && (searchParameter === 'email' || !searchParameter) : false;
    //     if (!phoneMatch && !userIdMatch && !emailMatch) {
    //       return null;
    //     }
    //     return {
    //       ...record,
    //     };
    //   }).filter(record => !!record),
    // });
    if (searchParameter) {
      let UserData = { 'filters': cleanEmpty({ typeFilter: searchParameter, valueData: searchText }), 'sorter': 'DESC' };

      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        // this.SearchData(UserData, this.state.searchText)
      }, 1000);
    }

    if (e.target.value === '') {
      // this.setState({
      //   data: data,
      //   filteredBySearch: false
      // });
    }
  }

  SearchData = (e) => {
    const { searchParameter, searchText } = this.state;
    console.log(searchText);
    let UserData = { 'filters': cleanEmpty({ typeFilter: searchParameter, valueData: searchText }), 'sorter': 'DESC' };
    if (searchText && searchParameter) {
      this.getUserList(false, 'regdate', false, UserData);
    }

  }

  emitEmpty = () => {
    this.setState({
      data: [],
      searchText: '',
      filtered: false,
      filteredBySearch: false
    });
  }
  isActive(type) {

    const { rangePickerValue } = this.state;
    const value = getTimeDistance(type);
    if (!rangePickerValue[0] || !rangePickerValue[1]) {
      return '';
    }
    if (
      rangePickerValue[0].isSame(value[0], 'day') &&
      rangePickerValue[1].isSame(value[1], 'day')
    ) {
      return 'ant-btn-primary';
    }

    return '';
  }
  handleRangePickerChange = (rangePickerValue) => {
    const { dateFilterType } = this.state;
    console.log('work');

    rangePickerValue[0].tz('America/Los_Angeles').startOf('day');
    rangePickerValue[1].tz('America/Los_Angeles').endOf('day');
    this.setState({
      rangePickerValue,
    }, () => this.getUserList(false, dateFilterType));
  }
  selectDate = (type) => {
    console.log('work');

    const { dateFilterType } = this.state;
    this.setState({
      rangePickerValue: getTimeDistance(type),
    }, () => this.getUserList(false, dateFilterType));
  }
  handleDateFilterChange = (value) => {
    const { showDateFilter, detailedTable } = this.state;

    this.setState({
      detailedTable: value === 'lastvisit' ? true : detailedTable,
      showDateFilter: true,
      dateFilterType: value
    }, () => {
      if (showDateFilter || (!showDateFilter && value !== 'regdate')) {
        if (value === 'lastvisit') {
          this.getDetailedList(false, value)
        } else {
          this.getUserList(false, value)
        }
      }
    })
  }
  changeSearchParameter = (value) => {
    const { searchText } = this.state;
    const searchObj = {
      target: {
        value: searchText
      }
    }
    this.setState({
      searchParameter: value
    }, () => this.onSearch(searchObj))
  }
  changeTableType = () => {
    const { detailedTable, dateFilterType } = this.state;

    if (detailedTable && dateFilterType === 'lastvisit') {
      Modal.confirm({
        title: 'Filter by date of the last visit is available only in detailed table. Are you sure you want to switch to general?',
        onOk: () => {
          const totalDistance = getTimeDistance('total');
          this.getDetailedList(true)
          this.setState({
            detailedTable: false,
            dateFilterType: 'Filter by date',
            rangePickerValue: totalDistance,
            showDateFilter: false
          })
        }
      })
    } else {
      this.setState({
        detailedTable: !detailedTable
      })
    }
  }
  resetFilters = () => {
    const { showDateFilter, rangePickerValue, dateFilterType } = this.state;
    this.emitEmpty();
    const totalDistance = getTimeDistance('total');
    const isTotalData = rangePickerValue[0].isSame(totalDistance[0]) && rangePickerValue[1].isSame(totalDistance[1]);
    if (showDateFilter && (!isTotalData || dateFilterType !== 'regdate')) {
      this.getUserList(true);
    }
    this.setState({
      filteredInfo: {},
      showDateFilter: false,
      rangePickerValue: totalDistance,
      dateFilterType: 'Filter by date'
    })
  }
  onRowSelect = (selectedRowKeys, selectedRowValues) => {

    this.setState({
      selectedRowKeys,
      selectedRowValues
    });
  }
  actions = (value) => {
    const { selectedRowValues } = this.state;
    if (!selectedRowValues || !selectedRowValues.length) {
      Modal.warning({
        title: 'Please, select at least one user for this action.',
        maskClosable: true
      })
      return;
    }
    if (value === 'sms') {
      const idsForAction = [];
      selectedRowValues.forEach((user) => {

        if (idsForAction.indexOf(user.id) === -1) {
          idsForAction.push(user.id);
        }
      })
      this.setState({
        visibleSmsSender: true,
        idsForAction
      })
      //console.log(idsForAction)
    }
  }
  hideSMSsender = () => {
    this.setState({
      visibleSmsSender: false,
      selectedRowKeys: [],
      selectedRowValues: []
    })
  }
  render() {

    const { isFetching, count, pagination, searchText, totalNumberTable, detailedTable, selectedRowKeys, visibleSmsSender,
      rangePickerValue, detailsFetching, showDateFilter, businessTypesLoaded, filteredInfo, dateFilterType, idsForAction
    } = this.state;
    let filtersActive = false;
    if (typeof filteredInfo === 'object' && Object.keys(filteredInfo).length > 0) {
      Object.keys(filteredInfo).forEach((key) => {
        if ((!Array.isArray(filteredInfo[key]) && filteredInfo[key]) || (Array.isArray(filteredInfo[key]) && filteredInfo[key].length)) {
          filtersActive = true;
        }
      })
    }
    const suffix = searchText ? <CloseCircleOutlined key="1" onClick={this.emitEmpty} /> : null;
    const searchBy = (
      <Select onChange={(value) => this.changeSearchParameter(value)} defaultValue="Search by" className="select-before">
        <Option value="userId">User ID</Option>
        <Option value="phone">Phone</Option>
        <Option value="email">Email</Option>
      </Select>
    );
    const searchFilter = (
      <div style={{ width: 'fit-content', marginLeft: 'auto' }}>
        <Button onClick={() => this.resetFilters()} style={{ display: filtersActive || showDateFilter ? '' : 'none', marginRight: '12px' }}>
          Reset filters
        </Button>
        <Select disabled={detailsFetching || isFetching ? true : false} loading={detailsFetching || isFetching ? true : false} style={{ marginRight: '12px' }} value={dateFilterType} onSelect={(value) => this.handleDateFilterChange(value)}>
          <Option value="regdate">Signup</Option>
          <Option value="lastvisit">Last visit</Option>
          <Option value="lastsession">Last session</Option>
        </Select>
        <Search
          style={{ marginBottom: '8px', width: '305px', marginLeft: 'auto' }}
          disabled={(isFetching && !detailedTable) || (detailedTable && detailsFetching)}
          onChange={this.onSearch}
          onSearch={this.SearchData}
          placeholder="Search users"
          allowClear
          addonBefore={searchBy}
        />
      </div>
    )
    const generalActions = (
      <div style={{ width: 'fit-content', display: 'inline-block' }}>
        <Select style={{ width: '120px' }} placeholder="Actions" onChange={(value) => this.actions(value)} value={'Actions'}>
          <Option value="sms">Send sms</Option>
        </Select>
        <Button onClick={() => this.changeTableType()} loading={detailsFetching || isFetching ? true : false} style={{ margin: 'auto 8px' }}>
          {detailedTable ? 'General' : 'Detailed'}
        </Button>
        <Button onClick={this.downloadFile} style={{ display: detailedTable ? '' : 'none' }} loading={detailsFetching ? true : false}>
          Download as a file
        </Button>
      </div>
    )
    const dateFilter = (
      <div className="usageElectronWrap" style={{ display: 'flex' }}>
        {generalActions}
        <div style={{ marginLeft: 'auto' }}>
          <div className="usageElectron">
            <button className={"ant-btn " + this.isActive('today')} onClick={() => this.selectDate('today')}>
              Today
            </button>
            <button style={{ height: '30px' }} className={"ant-btn " + this.isActive('week')} onClick={() => this.selectDate('week')}>
              Week
            </button>
            <button style={{ height: '30px' }} className={"ant-btn " + this.isActive('month')} onClick={() => this.selectDate('month')}>
              Month
            </button>
            <button style={{ height: '30px' }} className={"ant-btn " + this.isActive('year')} onClick={() => this.selectDate('year')}>
              Year
            </button>
            <button style={{ height: '30px' }} className={"ant-btn " + this.isActive('total')} onClick={() => this.selectDate('total')}>
              Total
            </button>
          </div>
          <RangePicker
            style={{ height: '30px' }}
            value={rangePickerValue}
            onChange={this.handleRangePickerChange}
          />
        </div>
      </div>
    );
    return (
      <Content className="fill-bg-table">
        <Row gutter={8}>
          <Col span={showDateFilter ? 12 : 24} style={{ display: 'inline-flex', marginTop: '-12px', marginBottom: '-16px' }}>
            <h1 className="table-title" style={{ marginTop: '4px' }}>
              Users
            </h1>
            <p style={{ margin: 'auto 8px', fontWeight: '500', fontSize: '15px' }}>
              Quantity: {totalNumberTable}
            </p>
          </Col>
          <Col style={{ marginTop: '-8px' }} span={showDateFilter ? 12 : 0}>
            {searchFilter}
          </Col>
        </Row>
        <Row style={{ marginTop: '18px', marginBottom: '8px', display: showDateFilter ? 'none' : 'inline-flex', width: '-webkit-fill-available' }}>
          {generalActions}
          {searchFilter}
        </Row>
        {visibleSmsSender ?
          <SmsSender
            visible={visibleSmsSender}
            handleClose={this.hideSMSsender}
            userId={idsForAction.length === 1 ? idsForAction[0] : null}
            userIds={idsForAction.length > 1 ? idsForAction : null}
          />
          : null}
        <Card
          bordered={false}
          className="removePadding extraUsers"
          extra={showDateFilter ? dateFilter : null}
        >
          <Table
            rowSelection={
              {
                selectedRowKeys: selectedRowKeys,
                onChange: this.onRowSelect
              }
            }
            className="scrollYTable"
            id="tableFixedHeaderWidthFix"
            loading={isFetching || (detailedTable && detailsFetching) || !businessTypesLoaded}
            columns={this.columns()}
            dataSource={businessTypesLoaded ? this.getData() : null}
            pagination={{
              ...pagination,
              total: count,
              showSizeChanger: true
            }}
            sortDirections={['descend', 'ascend']}
            size="middle"
            onChange={this.handleTableChange}
            scroll={{ x: 'max-content', y: '60vh' }}
            showSorterTooltip={false}
          />
        </Card>
      </Content>
    )
  }
}

export default Users