import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Input,
  Card,
  Button,
  Row,
  Alert,
  Col,
  Radio,
  Spin,
  Modal,
  Layout,
  DatePicker,
  Select,
  message,
  InputNumber,
} from 'antd';
import Notes from 'screens/Dashboard/components/Notes/Notes';
import fetchJSON from 'services/utils/fetchJSON';
import PromoHistory from './PromoHistory';
import qs from 'qs';

const FormItem = Form.Item;
const confirm = Modal.confirm;
const { RangePicker } = DatePicker;
const Option = Select.Option;
const moment = require('moment');
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const { Content } = Layout;

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const tabList = [{
  key: 'info',
  tab: 'Info',
}, {
  key: 'history',
  tab: 'History',
}];

class SinglePromo extends React.Component {
  state = {
    isFetching: false,
    errorMessage: '',
    visibleNotes: false,
    promocodeId: '',
    promocode: {
      transactions: []
    },
    visibleElectronHistory: false,
    fetchingStatus: false,
    activeTab: 'history',
    organisationsList: [],
    venueList: [],
    plansList: [],
    privateList: [],
    privateType: ''
  }
  componentDidMount() {
    const promocodeId = this.props.match.params.promocodeId;
    let paramFromUrl = {};
    if (window.location.search) {
      paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    }
    let history = paramFromUrl.history && paramFromUrl.history === "1" ? true : false;
    this.setState({
      promocodeId,
      activeTab: history ? 'history' : 'info'
    }, this.getSingle)
  }
  getSingle = () => {
    this.setState({
      isFetching: true
    })
    fetchJSON(`/api/v1/promocode/${this.state.promocodeId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.success) {
        if (response.data.promocode.private_type) {
          this.setState({
            isFetching: false,
            organisationsList: response.data.organisationsList,
            venueList: response.data.venueList,
            plansList: response.data.plansList
          }, () => {
            this.registerPrivateList(response.data.promocode.private_type);
            setTimeout(() => {
              this.setState({
                promocode: response.data.promocode,
                privateType:  response.data.promocode.private_type
              });
            }, 300)
          });
        } else {
          this.setState({
            isFetching: false,
            promocode: response.data.promocode,
            organisationsList: response.data.organisationsList,
            venueList: response.data.venueList,
            plansList: response.data.plansList
          });
        }
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  showNotes = () => {
    this.setState({
      visibleNotes: true
    })
  }
  hideNotes = () => {
    this.setState({
      visibleNotes: false
    })
  }
  showModal = () => {
    this.setState({
      visibleElectronHistory: true,
    });
  }
  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().subtract(2, 'd').endOf('day');
  }
  handleClose = () => {
    this.setState({ visibleElectronHistory: false });
  }
  onStatusChange = (e) => {
    const { promocodeId, promocode } = this.state;
    const status = e.target.value;
    promocode.status = status;
    confirm({
      title: `Change promocode status to "${status ? 'ON' : 'OFF'}"?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            fetchingStatus: true
          })
          fetchJSON(`/api/v1/promocode/updateStatus/${promocodeId}`, {
            method: 'put',
            body: {
              status: status
            }
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                fetchingStatus: false,
                promocode
              });
              resolve()
            } else {
              this.setState({
                fetchingStatus: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                fetchingStatus: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  onTabChange = (key, type) => {
    this.setState({ [type]: key });
  }
  registerPrivateList = (value) => {
    const { organisationsList, venueList } = this.state;
    let privateList = [];
    if (value === 'organisations') {
      organisationsList.forEach(organisation => {
        privateList.push({
          id: organisation.id,
          title: organisation.name
        })
      })
      console.log(privateList);
    }
    if (value === 'venues') {
      venueList.forEach(venue => {
        privateList.push({
          id: venue.id,
          title: venue.title
        })
      })
    }
    this.setState({
      privateList
    })
  }
  handleDependenciesChange = (value) => {
    const { organisationsList, venueList } = this.state;
    let privateList = [];
    if (value === 'organisations') {
      organisationsList.forEach(organisation => {
        privateList.push({
          id: organisation.id,
          title: organisation.name
        })
      })
      console.log(privateList);
    }
    if (value === 'venues') {
      venueList.forEach(venue => {
        privateList.push({
          id: venue.id,
          title: venue.title
        })
      })
    }
    this.props.form.setFields({
      private_id: {
        value: ''
      },
    });
    this.setState({
      privateList,
      privateType: value
    })
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({
        putREQUEST: true
      })
      const { promocodeId } = this.state;
      fetchJSON(`/api/v1/promocode/${promocodeId}`, {
        method: 'post',
        body: fieldsValue
      }).then(response => {
        // console.log(response);
        if (response.success) {
          message.success('Successfully updated');
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false
          });
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
          });
          message.error('Error while saving, or such promo code already exists');
        }
        setTimeout(() => {
          this.setState({
            errorMessage: ''
          });
        }, 3000)
       }).catch(error => {
          message.error('Error while saving, or such promo code already exists');
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });  
    })
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  render() {
    const {
      isFetching,
      errorMessage,
      visibleNotes,
      promocodeId,
      promocode,
      fetchingStatus,
      activeTab,
      privateList,
      plansList,
      privateType
    } = this.state;

    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Content className="fill-bg-table">
        <h2 className="table-title">Promotion code #{promocodeId} <Button onClick={  () => { this.modalVisible('visibleNotes', true) } } style={{float: 'right'}}>
          <QuestionCircleOutlined /> Notes
        </Button></h2>
        { visibleNotes ? <Notes visible={visibleNotes} handleClose={  () => { this.modalVisible('visibleNotes', false) }} sourceId={promocodeId} source="promocodes" /> : null }
        <Card
          loading={isFetching}
          tabList={tabList}
          activeTabKey={activeTab}
          onTabChange={(key) => { this.onTabChange(key, 'activeTab'); } }
          >
          { activeTab ==='info' ?
            <Form onSubmit={this.handleSubmit}>
              { errorMessage ? 
                <FormItem><Alert message={errorMessage} type="error"/></FormItem>
              : null }
              <Row gutter={8}>
                <Col span={5}>
                  <FormItem>
                    <Row>
                      <label className="form-label-custom">Promocode</label>
                    </Row>
                    <Row >
                      {getFieldDecorator('promocode_key', {
                        rules: [{ required: true, message: 'Please enter a promocode!' }],
                        initialValue: promocode.promocode_key
                      })(
                        <Input />
                      )}
                    </Row>
                  </FormItem>
                </Col>
                <Col span={7}>
                  <FormItem>
                    <Row>
                      <label className="form-label-custom">Valid dates from-to</label>
                    </Row>
                    <Row gutter={8}>
                      {getFieldDecorator('validDates', {
                        rules: [{ type: 'array', required: true, message: 'Please select time!' }],
                        initialValue: [moment(promocode.valid_from), moment(promocode.valid_to)]
                      })(
                        <RangePicker disabledDate={this.disabledDate} style={{width: '100%'}} />
                      )}
                    </Row>
                  </FormItem>
                </Col>
                <Col span={5}>
                  <FormItem>
                    <Row>
                      <label className="form-label-custom">Limit per user</label>
                    </Row>
                    <Row >
                      {getFieldDecorator('permitted_use_per_user', {
                        rules: [{ required: true, message: 'Please enter a permitted use per user!' }],
                        initialValue: promocode.permitted_use_per_user
                      })(
                        <Select>
                          <Option value='0'>Unlimit</Option>
                          <Option value="1">1</Option>
                          <Option value="2">2</Option>
                          <Option value="3">3</Option>
                          <Option value="4">4</Option>
                          <Option value="5">5</Option>
                          <Option value="6">6</Option>
                          <Option value="7">7</Option>
                          <Option value="8">8</Option>
                          <Option value="9">9</Option>
                          <Option value="10">10</Option>
                        </Select>
                      )}
                    </Row>
                  </FormItem>
                </Col>
                { false ?
                <Col span={2}>
                  <FormItem>
                    <Row>
                      <label className="form-label-custom">Use status</label>
                    </Row>
                    <Row>
                      <Input value={`${promocode.transactions.length} of ${promocode.quantity_limit}`} readOnly={true} />
                    </Row>
                  </FormItem>
                </Col> : null }
                <Col span={4}>
                  <FormItem>
                    <Row>
                      <label className="form-label-custom">Quantity limit</label>
                    </Row>
                    <Row >
                      {getFieldDecorator('quantity_limit', {
                        rules: [{ required: true, message: 'Please enter a quantity limit!' }],
                        initialValue: promocode.quantity_limit
                      })(
                        <InputNumber min={promocode.quantity_limit} style={{width: '100%'}} />
                      )}
                    </Row>
                  </FormItem>
                </Col>
                <Col span={3}>
                  <FormItem>
                    <Row>
                      <label className="form-label-custom">Status</label>
                    </Row>
                    <Spin spinning={ fetchingStatus }>
                      <RadioGroup onChange={this.onStatusChange} value={ promocode.status }>
                        <RadioButton value={1}>On</RadioButton>
                        <RadioButton value={0}>Off</RadioButton>
                      </RadioGroup>
                    </Spin>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={8}>
                  <FormItem
                      {...formItemLayout}
                    >
                      <Col span={24}>
                        <Row>
                          <label className="form-label-custom">Promo value: choose plan</label>
                        </Row>
                        <Row >
                          {getFieldDecorator('plan_id', {
                            rules: [{ required: true, message: 'Please choose plan!' }],
                            initialValue: promocode.plan_id
                          })(
                            <Select>
                              {plansList.map((plan, key) => <Option key={key} value={plan.id}>{plan.view_label} {plan.price} {plan.currency}</Option>) }
                            </Select>
                          )}
                        </Row>
                      </Col>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                      {...formItemLayout}
                    >
                      <Col span={24}>
                        <Row>
                          <label className="form-label-custom">Private dependencies</label>
                        </Row>
                        <Row >
                          {getFieldDecorator('private_type', {
                            rules: [{ required: false, message: 'Please choose plan!' }],
                            initialValue: promocode.private_type
                          })(
                            <Select onChange={this.handleDependenciesChange}>
                              <Option value=''>No dependencies</Option>
                              <Option value="organisations">Organisations</Option>
                              <Option value="venues">Venues</Option>
                              <Option value="user">User</Option>
                            </Select>
                          )}
                        </Row>
                      </Col>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                      {...formItemLayout}
                    >
                      <Col span={24}>
                        <Row>
                          <label className="form-label-custom">Dependency value</label>
                        </Row>
                        <Row>
                          {privateType !== 'user' ? 
                            <React.Fragment>
                              {getFieldDecorator('private_id', {
                                rules: [{ required: false, message: 'Please choose value!' }],
                                  initialValue: promocode.private_id
                              })(
                                <Select showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                  <Option key={100} value=''>No value</Option>
                                  {privateList.map((item, key) => <Option key={key} value={item.id}>{item.title}</Option>) }
                                </Select>
                              )}
                            </React.Fragment>
                            :
                            <React.Fragment>
                              {getFieldDecorator('private_id', {
                                rules: [{ required: true, message: 'Please enter user_id!' }],
                                initialValue: promocode.private_id
                              })(
                                <InputNumber style={{width: '100%'}} />
                              )}
                            </React.Fragment>
                            }
                        </Row>
                      </Col>
                  </FormItem>
                </Col>
              </Row>
              <FormItem >
                <Row style={{float: 'right'}}>
                  <Button type="primary" htmlType="submit">
                    Save changes
                  </Button>
                </Row>
              </FormItem>
            </Form> : null }

          { activeTab === 'history' ? 
            <div>
               <PromoHistory promocodeId={promocodeId} promocode={promocode} />
            </div>
          : null}
        </Card>
      </Content>
    );
  }
}

export default Form.create()(SinglePromo);
