import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Input, message, Spin } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const FormItem = Form.Item;

class SmsAuthenticationPrompt extends React.Component {
  state = {
    putREQUEST: false,
    putFAILURE: false,
    putSUCCESS: false,
    putMessage: '',
    getListRequest: false,
    listNotes: [],
    phone: '',
    code: '',
    stage: 1,
    showCode: false
  }
  componentDidMount() {
    
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      const { stage } = this.state;
      const { action, doAfter } = this.props;
      this.setState({
        putREQUEST: true
      });

      fetchJSON('/api/v1/auth/smsAuthentication', {
        method: 'POST',
        body: {
          phone: fieldsValue['phone'],
          code: fieldsValue['code'],
          stage: stage,
          action: action
        }
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false,
            stage: response.stage
          });
          message.success(response.message);
          
          if (response.stage === 3) {
            if (doAfter && typeof doAfter === 'function') {
              console.log('typeof doAfter',  typeof doAfter);
              doAfter();
              this.props.handleClose(false)
            }
          }
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
            stage: response.stage || 1
          });
          message.error(response.message);
        }
       }).catch(error => {
          console.log(error);
          message.error('Something wrong, try later');
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });
    })
  }
  render() {
    const {
      putREQUEST,
      phone,
      code,
      showCode,
      stage
    } = this.state;
    const { visible, form } = this.props;
    console.log('visible', visible);
    const { getFieldDecorator } = form;
    return (
      <Modal
        className="nocardPadding"
        style={{ top: 20 }}
        width={ 400 }
        visible={ visible }
        title='Prompt SMS authentication'
        onOk={() => this.props.handleClose(false)}
        onCancel={() => this.props.handleClose('cancel')}
        footer={false}
      >
        <Spin spinning={putREQUEST}>
        <Form layout="vertical" onSubmit={this.handleSubmit} style={{ padding: '20px' }}>
          <p>Enter phone number authorized for this operation</p>
          <FormItem label="Phone">
            {getFieldDecorator('phone', {
              rules: [{ required: true, message: 'Please enter a phone number!' }],
              initialValue: phone
            })(
              <Input disabled={showCode} name="phone" onChange={this.onChange}  />
            )}
          </FormItem>
          {stage === 2 ? <FormItem label="Confirmation code">
              {getFieldDecorator('code', {
                rules: [{ required: true, message: 'Please enter a code!' }],
                initialValue: code
              })(
                <Input name="code" onChange={this.onChange} />
              )}
            </FormItem> : null }
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form>
        </Spin>
      </Modal>
    )
  }
}
export default Form.create()(SmsAuthenticationPrompt);