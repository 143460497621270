import React from 'react';
import Settings from 'services/config/Settings';
import { Layout, Row, Col, Button } from 'antd';
import VenuesTable from './components/VenuesTable';
import AddVenue from './components/SingleVenue/AddVenue';

const { Content } = Layout;

class Venues extends React.Component {
  constructor(props) {
    super(props) 
    this.state = {
      totalNumberTable: 0,
      visibleAddVenue: false
    };
  } 
  componentDidMount() {
    document.title = `Locations | ${Settings.title}`;
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  eventCreated = (idVenue) => {
    this.props.history.push(`/venues/${idVenue}`);
  }
  getTotalNumberTable = () => {
    return this.state.totalNumberTable
  }
  updateState = (key, value) => {
    if (this.mounted) {
      this.setState({ [key]: value });
    }
  }
  render() {
    const { visibleAddVenue, totalNumberTable } = this.state;
    return (
      <Content>
        <Row>
          <Col>
            <h1 style={{display: 'inline-flex', margin: 0}}>
              Locations
              <Button type="primary" style={{margin: '6px 8px 0px 8px'}} size="small" onClick={ () => { this.updateState('visibleAddVenue', true) } }>
                Add Location
              </Button>
              <p style={{marginTop: '6px', fontWeight: '500', fontSize: '15px'}}>
                Quantity: {totalNumberTable}
              </p>
            </h1>
          </Col>
        </Row>
        <VenuesTable 
          history={this.props.history} 
          updateTotalNumberTable={ (quantity) => { this.updateState('totalNumberTable', quantity) }}
          getTotalNumberTable={this.getTotalNumberTable}
        />
        { visibleAddVenue ? 
          <AddVenue 
            visible={visibleAddVenue} 
            eventCreated={this.eventCreated} 
            history={this.props.history} 
            handleClose={ () => { this.updateState('visibleAddVenue', false) } } 
        /> : null }
      </Content>
    )
  }
};

export default Venues