import React from 'react';
import { Select } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const Option = Select.Option;

const getOrgList = function getOrgList(paramFromUrl = false) {
	this.setState({
		orgListFetching: true
	});
	fetchJSON('/api/v1/reports/orgVenuesList', {
		method: 'get'
	}).then(response => {
		if (this.mounted) {
			//console.log(response.dataByCategories, 'response.dataByCategories')
			const targetOrgId = response && Array.isArray(response) && response.length === 1 ? response[0].name : null;
			this.setState({
				orgList: response.data,
				businessInfoSorted: response.dataByCategories,
				orgListFetching: false,
				initialDataLoaded: true,
				targetOrgId
			}, () => {
				if (paramFromUrl) this.analiseUrlOnMount(paramFromUrl);
				if (targetOrgId && targetOrgId !== -1) this.sortVenuesList(targetOrgId)
			});
		}
	}).catch(error => {
		console.log(error);
		if (this.mounted) {
			this.setState({
				orgListFetching: true
			});
		}
	});
}
const analiseUrlOnMount = function analiseUrlOnMount(paramFromUrl) {
	if (paramFromUrl && typeof paramFromUrl === 'object' && Object.keys(paramFromUrl).length) {
		const paramsList = Object.keys(paramFromUrl);
		const filterByType = paramsList.indexOf('filterByType') > -1 && (paramFromUrl.filterByType === 'true');
		this.setState({
			filterByLocationType: filterByType
		}, () => {
			if (filterByType) {
				if (paramsList.indexOf('businessType') > -1) {
					const { businessInfoSorted } = this.state;
					let categoriesArr = [];  // eslint-disable-line
					let businessTypeId = null;
					let businessCategoryId = null;
					if (Array.isArray(businessInfoSorted)) {
						businessInfoSorted.forEach((business_type) => {
							if (business_type.uuid === paramFromUrl.businessType) {
								businessTypeId = business_type.id;
								categoriesArr = business_type.business_categories;
								if (paramsList.indexOf('businessCategory') > -1 && (business_type.categoriesUuids.indexOf(paramFromUrl.businessCategory) > -1 || paramFromUrl.businessCategory === 'all')) {
									if (paramFromUrl.businessCategory !== 'all') {
										business_type.business_categories.forEach((category) => {
											if (category.uuid === paramFromUrl.businessCategory) {
												businessCategoryId = category.id;
											}
										})
									} else {
										this.singleLocationMode = false;
										businessCategoryId = -1;
									}
								}
							}
						})
					}
					if ((paramsList.indexOf('businessType') > -1 && !businessTypeId) || (paramsList.indexOf('businessCategory') > -1 && !businessCategoryId)) {
						this.setNewUrlParams({ wrongParamsAfterCheck: true })
					} else {
						this.setState({
							targetBusinessTypeId: businessTypeId
						}, () => {
							return Promise.all([this.sortBusinessCategoriesList(businessTypeId)])
								.then(() => {
									if (businessCategoryId) {
										return Promise.all([this.sortVenuesByCategory(businessCategoryId)])
											.then(() => {
												this.setState({
													urlAnalized: true
												})
											})
									}
								})
						})
					}
				}
			} else {
				if (paramsList.indexOf('org') > -1) {
					const { orgList } = this.state;
					let venuesArr = [];
					let orgId = null;
					let venueId = null;
					if (paramFromUrl.org === 'all') {
						venueId = -1;
						orgId = -1;
						this.singleLocationMode = false;
						this.setState({
							targetOrgId: -1,
							targetVenueId: -1,
							urlAnalized: true
						})
					} else if (Array.isArray(orgList)) {
						orgList.forEach((organisation) => {
							if (organisation.uuid === paramFromUrl.org) {
								orgId = organisation.id;
								venuesArr = organisation.venues;
								if (paramsList.indexOf('venue') > -1 && (organisation.venueUuids.indexOf(paramFromUrl.venue) > -1 || paramFromUrl.venue === 'all')) {
									if (paramFromUrl.venue !== 'all') {
										organisation.venues.forEach((venue) => {
											if (venue.uuid === paramFromUrl.venue) {
												venueId = venue.id;
											}
										})
									} else {
										this.singleLocationMode = false;
										venueId = -1;
									}
								}
							}
						})
					}
					if ((paramsList.indexOf('org') > -1 && !orgId) || (paramsList.indexOf('venue') > -1 && !venueId)) {
						this.setNewUrlParams({ wrongParamsAfterCheck: true })
					} else {
						const stateUpdateObj = {
							venuesList: venuesArr,
							targetOrgId: orgId,
							urlAnalized: true
						};
						if (venueId) stateUpdateObj.targetVenueId = venueId;
						this.setState(stateUpdateObj);
					}
				}
			}
		})
	}
	this.setState({
		urlAnalized: true
	})
}
const renderOrganisationOptions = function renderOrganisationOptions(optionAll = true) {
	const { orgList } = this.state;
	const render = [];
	if (orgList) {
		if (orgList.length > 1 && optionAll) {
			render.push(<Option key={orgList.length} value={-1}>All</Option>)
		}
		orgList.forEach((org, i) => {
			render.push(<Option key={i} value={org.id}>{org.name}</Option>)
		});
	}
	return render;
}
const sortVenuesList = function sortVenuesList(targetOrgIdManual = null) {
	const { orgList, targetOrgId, orgsIds, overrideLocationChoiceGlobal } = this.state;
	if (orgList && Array.isArray(orgList)) {
		if (!overrideLocationChoiceGlobal) {
			orgList.forEach((org) => {
				if ((org.id === targetOrgId && !targetOrgIdManual) || (targetOrgIdManual && org.id === targetOrgIdManual)) {
					const newVenuesArr = org.venues;
					this.setState({
						venuesList: newVenuesArr
					}, () => {
						if (newVenuesArr.length === 1) {
							this.setState({ targetVenueId: newVenuesArr[0].id })
							this.setNewUrlParams({ venue: newVenuesArr[0].id })
						}
					})
				}
			})
		} else {
			console.log(orgsIds, overrideLocationChoiceGlobal, 'test overrideLocationChoiceGlobal')
			if (Array.isArray(orgsIds)) {
				const newVenuesArr = [];
				orgList.forEach((org) => {
					if (orgsIds.indexOf(org.id) > -1) {
						newVenuesArr.push(...org.venues);
					}
				})
				this.setState({
					venuesList: newVenuesArr
				})
			}
		}
	}
}
const renderVenuesOptions = function renderVenuesOptions() {
	const { venuesList } = this.state;
	const render = [];
	if (venuesList) {
		venuesList.forEach((venue, i) => {
			render.push(<Option key={i} value={venue.id}>{venue.title}</Option>)
		});
	}
	if (render.length > 1) {
		render.push(<Option key={render.length + 1} value={-1}>All</Option>)
	}
	return render;
}
const renderBusinessTypesOptions = function renderBusinessTypesOptions() {
	const { businessInfoSorted } = this.state;
	const render = [];
	if (businessInfoSorted) {
		businessInfoSorted.forEach((type, i) => {
			render.push(<Option key={i} value={type.id}>{type.title}</Option>)
		});
	}
	return render;
}
const renderBusinessCategoriesOptions = function renderBusinessCategoriesOptions() {
	const { businessCategoriesList } = this.state;
	const render = [];
	if (businessCategoriesList) {
		businessCategoriesList.forEach((category, i) => {
			render.push(<Option key={i} value={category.id}>{category.title}</Option>)
		});
	}
	if (render.length > 1) {
		render.push(<Option key={render.length + 1} value={-1}>All</Option>)
	}
	return render;
}
const sortBusinessCategoriesList = function sortBusinessCategoriesList(manualBusinessTypeId = null) {
	const { businessInfoSorted, targetBusinessTypeId } = this.state;
	if (businessInfoSorted) {
		businessInfoSorted.forEach((type) => {
			if ((!manualBusinessTypeId && type.id === targetBusinessTypeId) || (manualBusinessTypeId && type.id === manualBusinessTypeId)) {
				if (type.business_categories) {
					const businessCategoriesList = [];
					type.business_categories.forEach((category) => {
						businessCategoriesList.push(category);
					})
					this.setState({
						businessCategoriesList
					})
				}
			}
		})
	}
}
const sortVenuesByCategory = function sortVenuesByCategory(value) {
	const { businessCategoriesList } = this.state;
	let venuesCategoriesList = [];
	if (businessCategoriesList) {
		businessCategoriesList.forEach((category) => {
			if (category.id === value || value === -1) {
				venuesCategoriesList = venuesCategoriesList.concat(category.venues);
			}
		})
	}
	this.setState({
		venuesCategoriesList,
		targetBusinessCategoryId: value
	})
}

export {
	getOrgList,
	renderOrganisationOptions,
	renderVenuesOptions,
	sortVenuesList,
	renderBusinessTypesOptions,
	renderBusinessCategoriesOptions,
	sortBusinessCategoriesList,
	sortVenuesByCategory,
	analiseUrlOnMount
};