import React from 'react';
import { connect } from 'react-redux';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { CloseCircleOutlined, CloseOutlined, MobileOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Input, Card, Button, Row, message, Modal, Alert, Spin, Radio } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import fetchJSON from 'services/utils/fetchJSON';

import { fetchSecretQuote, closeSession } from './data/actions'
import SmsSender from 'screens/Dashboard/components/SmsSender/SmsSender';
import Notes from 'screens/Dashboard/components/Notes/Notes';

const FormItem = Form.Item;
const { TextArea } = Input;
const confirm = Modal.confirm;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class SingleSession extends React.Component {
  state = {
    visibleElectronHistory: false,
    visibleUserProfile: false,
    visibleSmsSender: false,
    visibleNotes: false,
    fetchingPenalty: false
  }
  componentDidMount() {
    const sessionId = this.props.match.params ? parseInt(this.props.match.params.sessionId, 10) : null;
    
    if (!sessionId) {
      return this.props.closeModal();
    }

    if (!this.props.session || this.lessThanAgo(this.props.createdFetch) || this.props.sessionIdFetch !== sessionId)
      this.letsFetch();
  }
  componentDidUpdate (prevProps, prevState) {
    if (this.props.isFetchingError) {
      this.props.closeModal();
    }
  }
  componentWillUnmount () {
    if (this.props.isFetchingError) {
      this.errorMessage();
    }
  }
  letsFetch = () => {
    const sessionId = this.props.match.params.sessionId;
    this.props.dispatch(fetchSecretQuote(`/session/${sessionId}`))
  }
  
  errorMessage = () => {
    message.error('Error getting session card');
  }
  lessThanAgo = (date) => {
    if (!date) return false;
    const _AGO = 1000 * 60 * 3;
    let anMinuteAgo = Date.now() - _AGO;
    return date < anMinuteAgo;
  }
  
  showModal = () => {
    this.setState({
      visibleElectronHistory: true,
    });
  }
  handleClose = () => {
    this.setState({ visibleElectronHistory: false });
  }
  showModalUserProfile = () => {
    this.setState({
      visibleUserProfile: true,
    });
  }
  handleCloseUserProfile = () => {
    this.setState({ visibleUserProfile: false });
  }
  closeSessionConfirm = () => {
    const { dispatch } = this.props;
    const { sessionId } = this.props.match.params;
    confirm({
      title: 'Do you want to close this session?',
      content: 'This action will send a notification to the user',
      onOk: () => dispatch(closeSession({ sessionId }, this.letsFetch)),
      onCancel() {},
    });
  }
  createSupportTicket = () => {
    confirm({
      title: 'Creating a support Ticket',
      content: 'Here will soon be the process of creating a ticket',
      onOk() {},
      onCancel() {},
    });
  }
  sendTemplateSMS = () => {
    this.setState({
      visibleSmsSender: true
    })
  }
  hideSMSsender = () => {
    this.setState({
      visibleSmsSender: false
    })
  }
  showNotes = () => {
    this.setState({
      visibleNotes: true
    })
  }
  hideNotes = () => {
    this.setState({
      visibleNotes: false
    })
  }
  onPenaltyChange = (e) => {
  const { sessionId } = this.props.match.params;
  const status = e.target.value;
  confirm({
    title: `Change penalty status to "${status ? 'ON' : 'HOLD'}"?`,
    onOk: () => {
      this.setState({
        fetchingPenalty: true
      })
      fetchJSON(`/api/v1/session/penalty/${sessionId}`, {
        method: 'PUT',
        body: {
          penalty_actions: status
        }
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            fetchingPenalty: false
          }, this.letsFetch);
        } else {
          this.setState({
            fetchingPenalty: false
          });
        }
        }).catch(error => {
          console.log(error);
          this.setState({
            fetchingPenalty: false
          });
        });
      },
      onCancel() {},
  });
  }
  render() {
    const penalty_actions = this.props.session.penalty_status;
    const { isFetching, session, errorMessage } = this.props;
    const { visibleSmsSender, visibleNotes, fetchingPenalty } = this.state;
    const formItemLayout = {
      // labelCol: {
      //   xs: { span: 24 },
      //   sm: { span: 6 },
      // },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    let sessionSingle = {
      id: null,
      electron_id: null,
      open_supernova: {
        venue: {}
      },
      electron: {
        string_id: ''
      },
      durationSession: '',
      user: {}
    }
    if (session) {
      let sessionOpen = moment.unix(session.opentime);
      let sessionClose;
      if (session.status === 'open') {
        sessionClose = moment();
      } else {
        sessionClose = moment.unix(session.closetime);
      }
      let ms = sessionClose.diff(sessionOpen);
      let _duration = moment.duration(ms);
      let durationSession = _duration.format("d [d] h [h] m [min]");
      let hoursSpend = parseInt((_duration._milliseconds/(1000*60*60)), 10);
      if (session.status === 'lost') {
        durationSession = 0;
        hoursSpend = 0;
      }
      sessionSingle = {
        id: session.id,
        electron_id: session.electron_id,
        open_supernova: session.open_supernova,
        durationSession: durationSession,
        hoursSpend: hoursSpend,
        status: session.status,
        user: session.user,
        electron: session.electron ? session.electron : { string_id: '' }
      }
    }

    let act = sessionSingle.status;
    let color;
    let icon;
    if (act === 'open') {
      act = "Opened";
      color = "#1890ff";
      icon = "clock-circle-o"
    } else if (act === 'close') {
      act = "Returned";
      color = "#52c41a";
      icon = "safety"
    } else if (act === 'lost') {
      act = "Lost";
      color = "#f5222d";
      icon = "exclamation-circle"
    } else if (act === 'closed_through_support'){
      act = "Closed through support";
      color = "#f5222d";
      icon = "close-circle"
    }
    let venueTitle = _.get(session.open_supernova, 'group.group.venue_groups.venue.title');
    let venueId = _.get(session.open_supernova, 'group.group.venue_groups.venue.id');
    let venueAddress = _.get(session.open_supernova, 'group.group.venue_groups.venue.address');

    return (
      <div className="modalWindow">
        <Card
          loading={isFetching}
          className="modalWindow__card"
          type="inner"
          title={ session ? <div style={{'position': 'relative', 'top': '5px' }}><LegacyIcon type={icon} style={{ fontSize: '16px', 'color': color }} /> { act } session #{session.id}</div> : null }
          extra={<Button onClick={this.props.closeModal} size="small"><CloseOutlined /></Button>} >
          <Form>
            { errorMessage ? 
                <FormItem><Alert message={errorMessage} type="error"/></FormItem>
              : null }
            <FormItem
              {...formItemLayout}
            >
              <Row>
                <label className="form-label-custom">Electron ID</label>
                <Link to={`/electrons/${sessionSingle.electron_id}?history=1`} target="_blank" className="ant-btn ant-btn-sm">Show history</Link>
              </Row>
              <Row >
                <Input defaultValue={sessionSingle.electron.string_id} readOnly={true} />
              </Row>
            </FormItem>
            <FormItem
              {...formItemLayout}
            >
              <Row>
                <label className="form-label-custom">Venue of release</label>
                {venueId ?<Link to={`/venues/${venueId}`} target="_blank" className="ant-btn ant-btn-sm">Look up</Link> : null }
              </Row>
              <Row >
                <TextArea defaultValue={`${venueTitle}, ${venueAddress}`} readOnly={true} />
              </Row>
            </FormItem>
            <FormItem
              {...formItemLayout}
            >
              <Row>
                <label className="form-label-custom">Duration of session</label>
              </Row>
              <Row >
                <Input defaultValue={sessionSingle.durationSession} readOnly={true} />
              </Row>
            </FormItem>
            <FormItem
              {...formItemLayout}
            >
              <Row>
                <label className="form-label-custom">User</label>
                <Link to={`/users/${sessionSingle.user.id}`} target="_blank" className="ant-btn ant-btn-sm">Show profile</Link>
              </Row>
              <Row >
                <Input defaultValue={`ID: ${sessionSingle.user.id}; Phone: ${sessionSingle.user.phone}`} readOnly={true} />
              </Row>
            </FormItem>
            <FormItem
            {...formItemLayout}
            >
              <Row>
                <label className="form-label-custom">Penalty membership</label>
              </Row>
              <Spin spinning={ fetchingPenalty }>
                <RadioGroup onChange={this.onPenaltyChange} value={ penalty_actions }>
                  <RadioButton value={1}>ON</RadioButton>
                  <RadioButton value={0}>HOLD</RadioButton>
                </RadioGroup>
              </Spin>
            </FormItem>
            <FormItem
              {...formItemLayout}
            >
              <Row>
                <label className="form-label-custom">Actions</label>
              </Row>
              <Row className="btn-group">
                <Button type="primary" onClick={ this.sendTemplateSMS }>
                  <MobileOutlined /> Send text to user
                </Button>
                { visibleSmsSender ? <SmsSender visible={visibleSmsSender} handleClose={this.hideSMSsender} userId={sessionSingle.user.id}  /> : null }
                <Button onClick={ this.showNotes }>
                  <QuestionCircleOutlined /> Notes
                </Button>
                { visibleNotes ? <Notes visible={visibleNotes} handleClose={this.hideNotes} sourceId={sessionSingle.id} source="session" /> : null }
                { sessionSingle.status === 'open' ? <Button type="danger" onClick={ this.closeSessionConfirm }>
                  <CloseCircleOutlined /> Close session
                </Button> : null }
              </Row>
            </FormItem>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) =>{
  const { singleSession, closeSession } = state;
  const { errorMessage } = closeSession;
  const { quote, isFetching, createdFetch, isFetchingError, sessionId } = singleSession;
  let session = '';
  const sessionIdFetch = sessionId;
  if (quote) {
    session = quote.success ? quote.data : '';
  }
  return {
    session,
    sessionIdFetch,
    isFetching,
    createdFetch,
    isFetchingError,
    errorMessage
  }
}

export default connect(mapStateToProps)(Form.create()(SingleSession))