import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Input, message, InputNumber, Spin } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const FormItem = Form.Item;

class NewSeries extends React.Component {
  state = {
    isFetching: false,
    seriesId: ''
  }
  
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      
      if (err) {
        return;
      }
      this.setState({
        isFetching: true
      })
      console.log(fieldsValue, err)
  
      fetchJSON(`/api/v1/group/generate`, {
        method: 'PUT',
        body: fieldsValue
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            isFetching: false,
            seriesId: response.seriesId
          }, this.goToSeries);
        } else {
          this.setState({
            isFetching: false,
          });
          message.error(response.message);
        }
        setTimeout(() => {
          this.setState({
            errorMessage: ''
          });
        }, 3000)
       }).catch(error => {
          console.log(error);
          this.setState({
            isFetching: false
          });
       });  
    })
  }
  
  goToSeries = () => {
    const { seriesId } = this.state;
    this.props.history.push(`/groups/generator/${seriesId}`);
  }

  render() {
    const {
     isFetching
    } = this.state;
    const { handleClose, visible, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        style={{ top: 20 }}
        visible={ visible }
        width={400}
        title="Generate new series of groups"
        onOk={ handleClose }
        onCancel={ handleClose }
        footer={false}
      >
        <Spin spinning={isFetching} >
          <Form layout="vertical" onSubmit={this.handleSubmit}>
            <FormItem label="Name for series">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Please enter a name!' }],
              })(
                <Input />
              )}
            </FormItem>
            <FormItem label="Number of groups">
              {getFieldDecorator('number', {
                rules: [{ required: true, message: 'Please enter number of groups!' }],
                initialValue: 10
              })(
                <InputNumber min={1} max={5000} style={{ width: '100%' }} />
              )}
            </FormItem>
            <Button type="primary" htmlType="submit">Submit</Button>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

export default Form.create()(NewSeries);
