import { CALL_API } from 'services/middleware/api'

export const QUOTE_REQUEST = 'organisations/QUOTE_REQUEST'
export const QUOTE_SUCCESS = 'organisations/QUOTE_SUCCESS'
export const QUOTE_FAILURE = 'organisations/QUOTE_FAILURE'


// Uses the API middlware to get a quote
export function fetchQuote(endpoint) {
  return {
    [CALL_API]: {
      endpoint: endpoint,
      types: [QUOTE_REQUEST, QUOTE_SUCCESS, QUOTE_FAILURE]
    }
  }
}

// Same API middlware is used to get a
// secret quote, but we set authenticated
// to true so that the auth header is sent
export function fetchSecretQuote(endpoint) {
  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [QUOTE_REQUEST, QUOTE_SUCCESS, QUOTE_FAILURE]
    }
  }
}