import React from 'react';
import { connect } from 'react-redux';
import { ModalRoute } from 'react-router-modal';
import Settings from 'services/config/Settings';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Table, Layout, Input, Row, Col } from 'antd';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { fetchSecretQuote } from './data/actions'
import moment from 'moment-timezone';

import SingleSession from './components/SingleSession/SingleSession';

import './styles.less';

require("moment-duration-format");
const Search = Input.Search;
const { Content } = Layout;

class Sessions extends React.Component {
  state = {
    filteredInfo: {},
    sortedInfo: {},
    searchText: '',
    filtered: false,
    data: []
  };
  componentDidMount() {
    document.title = `Sessions | ${Settings.title}`;
    this.props.dispatch(fetchSecretQuote('/session/list/open'))
  }

  columns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return [{
      title: '#',
      dataIndex: 'id',
      key: 'id',
      sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Electron ID',
      dataIndex: 'electronId',
      key: 'electronId',
      render: (text, record) => (
        <Link to={"/electrons/"+record.electron} target="_blank"> 
          {record.electronId}
        </Link>
      ),
    },
    {
      title: 'User ID',
      dataIndex: 'user_id',
      key: 'user_id',
      render: (text, record) => (
        <Link to={"/users/"+record.user_id} target="_blank"> 
          {record.user_id}
        </Link>
      ),
    },
    {
      title: 'User phone',
      dataIndex: 'user_phone',
      key: 'user_phone',
    },
    {
      title: 'Plan',
      dataIndex: 'user_plan',
      key: 'user_plan',
      filters: [
        { text: 'Annual', value: 'Annual' },
        { text: 'Daily', value: 'Daily' },
        { text: 'Monthly', value: 'Monthly' },
        { text: 'Promo', value: 'Promo' },
        { text: 'No', value: 'No' },
      ],
      filteredValue: filteredInfo.user_plan || null,
      onFilter: (value, record) => {
        return record.user_plan.startsWith(value);
      }
    },
    {
      title: 'Group ID',
      dataIndex: 'groupId',
      key: 'groupId',
      sortOrder: sortedInfo.columnKey === 'groupId' && sortedInfo.order,
      sorter: (a, b) => a.groupId - b.groupId
    },
    {
      title: 'Venue of release',
      dataIndex: 'venue_reliase',
      key: 'venue_reliase',
      render: (text, record) => (
        <div style={{margin: 0}}>
          { record.venue_reliase_id ? 
            <Link to={"/venues/"+record.venue_reliase_id} target="_blank">
              {record.venue_reliase}
            </Link> : 
            <p style={{margin: 0}}>{record.venue_reliase}</p>
          }
        </div>
      )
    },
    {
      title: 'Start date',
      dataIndex: 'startDate',
      key: 'startDate',
      sortOrder: sortedInfo.columnKey === 'startDate' && sortedInfo.order,
      sorter: (a, b) => a.opentime - b.opentime
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render: (text, record) => (
        <span style={{
          color: record.hoursSpend > 48 ? 'red' : 'black'
        }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Penalty',
      dataIndex: 'penaltyStatus',
      key: 'penaltyStatus',
      sortOrder: sortedInfo.columnKey === 'penaltyStatus' && sortedInfo.order,
      sorter: (a, b) => a.penaltyStatus - b.penaltyStatus
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Link to={"/sessions/"+record.id} className="ant-btn ant-btn-sm">
            Edit
          </Link>
        </span>
      ),
    }];
  }
  lessThanAgo = (date) => {
    if (!date) return false;
    const _AGO = 1000 * 60 * 3;
    let anMinuteAgo = Date.now() - _AGO;
    return date < anMinuteAgo;
  }
  getData = () => {
    const data = [];
    if (!this.props.quote) return data;

    let response = this.props.quote.data;
    let sessionOpen;
    let sessionClose;
    let ms;
    let _duration;
    let durationSession;
    let hoursSpend;

    if (this.state.filtered) {
      response = this.state.data;
    }

    response.forEach((session, i) => {
      if (session.status === 'open') {
        if (session.electron_id) {
          sessionOpen = moment.unix(session.opentime);
          let startDate = moment.unix(session.opentime).tz('America/Los_Angeles').format('MM/DD/YY h:mm a');
          let returnDate = '-';
          if (session.status === 'open') {
            sessionClose = moment();
          }

          ms = sessionClose.diff(sessionOpen);
          _duration = moment.duration(ms);
          durationSession = _duration.format("d [d] h [h] m [min]");
          hoursSpend = parseInt((_duration._milliseconds/(1000*60*60)), 10);

          console.log('session', session.user);

          let venueTitle;
          let venueUuid;
          let groupId = _.get(session.open_group, 'key_id');
          var reg = /^\d+$/;
          if (!reg.test(groupId)) {
            const groupIdtext = _.get(session.group, 'notes');
            if (groupIdtext) {
              groupId = groupIdtext.replace(/ex/gi, '').trim();
            }
          }
          const venue = _.get(session.open_group, 'venue_groups.venue');

          if (venue) {
            venueTitle = venue.title;
            if (!venue.is_deleted && !venue.unlinked) {
              venueUuid = venue.uuid;
            }
          }
          
          const user = session.user;
          let Plan = 'No';

          if (user.subscription) {
            const subscription = user.subscription;
            if (subscription.userOrder) {
              const order = subscription.userOrder.order;
              if (order.promo_id) {
                Plan = 'Promo ';
              } else {
                Plan = '';
              }
              const plan = subscription.plan;
              if (plan) {
                Plan += subscription.plan.view_label;
              }

            } 
          }
          let user_phone = '';
          if (session.user) {
            user_phone = session.user.phone ? `**${session.user.phone.slice(-4)}`: '';
          } 
          
          data.push({
            key: i,
            id: session.id,
            electron: session.electron_id,
            electronId: session.electron ? session.electron.string_id : null,
            user_id: session.user.id,
            user: session.user.phone,
            user_plan: Plan,
            groupId: groupId,
            venue_reliase_id: venueUuid,
            venue_reliase: venueTitle,
            status: session.status,
            duration: durationSession,
            hoursSpend: hoursSpend ? hoursSpend : 0,
            startDate: startDate,
            returnDate: returnDate,
            opentime: session.opentime,
            closetime: session.closetime ? session.closetime : false,
            user_phone: user_phone,
            penaltyStatus: session.penalty_status === 1 ? 'ON' : 'OFF'
          })
        }
      }
    })

    return data;
  }
  handleTableChange = (pagination, filters, sorter) => {
    console.log('handleTableChange', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  onSearch = (e) => {
    this.setState({ searchText: e.target.value });
    const searchText = e.target.value;
    const reg = new RegExp(e.target.value, 'gi');
    this.setState({
      filtered: !!searchText,
      data: _.flatMap(this.props.quote.data, record => {
        let electron = record.electron ? record.electron.string_id : false;
        let startDate = moment.unix(record.opentime).tz('America/Los_Angeles').format('MM/DD/YY h:mm a');
        const userMatch = record.user ? record.user.phone.match(reg) : false;
        const dateMatch = startDate ? startDate.match(reg) : false;
        const electronMatch = electron ? electron.match(reg) : false;
        if (!userMatch && !electronMatch && !dateMatch) {
          return null;
        }
        return {          
          ...record,
          };
      }).filter(record => !!record),
    });
    if (e.target.value === ''){
      this.setState({
        data: this.props.quote.data,
        filtered: false
      });
    }
  }
  emitEmpty = () => {
    this.setState({ 
      data: [],
      searchText: '',
      filtered: false
    });
  }
  render() {
    const { isFetching, pagination } = this.props;
    const { searchText } = this.state;
    const suffix = searchText ? <CloseCircleOutlined key="1" onClick={this.emitEmpty} /> : null;
    return (
      <Content className="fill-bg-table">
        <Row gutter={8}>
          <Col span={18}>
            <h1 className="table-title" style={{marginTop: '4px'}} >
              Open Sessions
              <Link to={"/sessions-closed"} className="ant-btn ant-btn-sm" style={{marginLeft: '8px'}}>
                Closed Sessions
              </Link>
              <Link to={"/sessions/statistics"} className="ant-btn ant-btn-sm" style={{marginLeft: '8px'}}>
                Statistics
              </Link>
            </h1>
          </Col>
          <Col span={6} style={{display: 'flex'}}>
           <Search 
            style={{width: '305px', marginLeft: 'auto'}} 
            disabled={isFetching} 
            onChange={this.onSearch} 
            placeholder="Search session"
            allowClear
           />
          </Col>
        </Row>
        <Table
          columns={ this.columns() }
          loading={ isFetching }
          dataSource={ this.getData() }
          pagination={ pagination }
          onChange={ this.handleTableChange }
          size="middle"
          style={{marginTop: '12px'}}
          />
        <ModalRoute 
          component={SingleSession} 
          path={"/sessions/:sessionId"} 
          parentPath={"/sessions"}
          className='modalWindow__container'
          />
      </Content>
    )
  }
}


const mapStateToProps = (state) =>{
  const { sessions } = state;
  const { quote, isFetching, createdFetch, pagination } = sessions;
  return {
    quote,
    isFetching,
    createdFetch,
    pagination
  }
}

export default connect(mapStateToProps)(Sessions)