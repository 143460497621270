import fetchJSON from 'services/utils/fetchJSON';

const getFullTrendsData = function getFullTrendsData() {
  const bodyObj = this.getBodyObjTrends();
  this.setState({
    personalInfoData: emptyPersonalInfoObj,
  }, () => {
    this.getPersonalInfoData(bodyObj);
    this.getGeneralUsagePatternsData(bodyObj);
    this.getTimePatternsUsage(bodyObj);
    this.getMembershipsPatternsUsage(bodyObj);
  })
}
const getBodyObjTrends = function getBodyObjTrends() {
  const {
    filterByLocationType,
    targetOrgId,
    targetVenueId,
    targetBusinessTypeId,
    targetBusinessCategoryId,
    // defaultRangePickerValue,
    venuesCategoriesList,
    fullVenuesList,
    general_date_type,
    // multilocations
    overrideLocationChoiceGlobal,
    orgsIds,
    venuesIds
  } = this.props;
  
  const bodyObj = {
    filter_by_id: !filterByLocationType,
    organisation_id: targetOrgId,
    venue_id: targetVenueId,
    business_type_id: targetBusinessTypeId,
    business_category_id: targetBusinessCategoryId,
    // dates: defaultRangePickerValue,  
    venues_list_category: venuesCategoriesList,
    full_venues_list: fullVenuesList,
    general_date_type,
    use_custom_locs_arr: overrideLocationChoiceGlobal,
    custom_org_ids: orgsIds,
    custom_venues_ids: venuesIds
  };
  return bodyObj
}
const getMembershipsPatternsUsage = function getMembershipsPatternsUsage(body) {
  const { membershipPatternsTimeOverride, membershipPatternsRange } = this.state;
  body.should_time_override = membershipPatternsTimeOverride;
  body.time_override = membershipPatternsRange;

  this.setState({
    isMembershipsPatternsUsageFetching: true
  });
  fetchJSON('/api/v1/reports/trends/getMembershipsPatternsUsage', {
    method: 'post',
    body
  }).then(response => {
    if (this.mounted) {
      this.setState({
        isMembershipsPatternsUsageFetching: false,
        membershipsPatternsData: response.data || emptyMembershipsPatternsUsageObj
      });
    }
  }).catch(error => {
    console.log(error);
    if (this.mounted) { 
      this.setState({
        isMembershipsPatternsUsageFetching: false,
        membershipsPatternsData: emptyMembershipsPatternsUsageObj
      });
    }
  });
};
const getPersonalInfoData = function getPersonalInfoData(body) {
  const { disableLocationChoicePersonalInfo } = this.state;
  body.location_disabled = disableLocationChoicePersonalInfo;
  this.setState({
    isPersonalInfoFetching: true
  });
  fetchJSON('/api/v1/reports/trends/getPersonalInfoData', {
    method: 'post',
    body
  }).then(response => {
    if (this.mounted) {
      let showEmpty = false;
      if (response.data) {
        const data = response.data;
        showEmpty = !data.gender.length && !data.ageToGender.length && !data.worry_your_phone_dying.total.length && !data.worry_your_phone_dying.female.length && !data.worry_your_phone_dying.male.length; 
      } else {
        showEmpty = true;
      }
      this.setState({
        isPersonalInfoFetching: false,
        personalInfoData: response.data || emptyPersonalInfoObj,
        showEmpty
      });
    }
  }).catch(error => {
    console.log(error);
    if (this.mounted) { 
      this.setState({
        isPersonalInfoFetching: false,
        personalInfoData: emptyPersonalInfoObj
      });
    }
  });
}
const getGeneralUsagePatternsData = function getGeneralUsagePatternsData(body) {
  const { usageGenderFilter, usageAgeFilter, usageCableFilter, disableLocationChoiceUsagePatterns } = this.state;
  body.gender_filters = usageGenderFilter;
  body.age_filters = usageAgeFilter;
  body.cable_filters = usageCableFilter;
  body.location_disabled = disableLocationChoiceUsagePatterns;
  this.setState({
    isGeneralUsagePatternsFetching: true
  });
  fetchJSON('/api/v1/reports/trends/getGeneralUsagePatternsData', {
    method: 'post',
    body
  }).then(response => {
    if (this.mounted) {
      this.setState({
        isGeneralUsagePatternsFetching: false,
        generalUsagePatternsData: response.data || emptyGeneralUsagePatternsObj
      });
    }
  }).catch(error => {
    console.log(error);
    if (this.mounted) { 
      this.setState({
        isGeneralUsagePatternsFetching: false,
        generalUsagePatternsData: emptyGeneralUsagePatternsObj
      });
    }
  });
}
const getTimePatternsUsage = function getTimePatternsUsage(body) {
  this.setState({
    isTimePatternsUsageFetching: true
  });
  fetchJSON('/api/v1/reports/trends/getTimePatternsUsage', {
    method: 'post',
    body
  }).then(response => {
    if (this.mounted) {
      this.setState({
        isTimePatternsUsageFetching: false,
        timePatternsData: response.data || emptyTimePatternsDataObj
      });
    }
  }).catch(error => {
    console.log(error);
    if (this.mounted) { 
      this.setState({
        isTimePatternsUsageFetching: false,
        timePatternsData: emptyTimePatternsDataObj
      });
    }
  });
};
const emptyMembershipsPatternsUsageObj = {
  graphWeeklyData: [],
  graphDailyData: [],
  pieChartData: [],
  membershipsAmount: 0,
  reference_lines_years_weeks: {},
  reference_lines_years_days: {}
}
const emptyPersonalInfoObj = {
  gender: [],
  ageToGender: [],
  multilocations_users_percentage: 0,
  charging_cables: [],
  max_amount_gender_age: 0
};
const emptyGeneralUsagePatternsObj = {
  avg_charging_time: 0,
  mid_charging_time: 0,
  super_users_bar_chart: [],
  is_super_users: false,
  super_users_amount: 0
}
const emptyTimePatternsDataObj = {
  hourly_usage: [
    { name: '06:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '07:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '08:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '09:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '10:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '11:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '12:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '13:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '14:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '15:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '16:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '17:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '18:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '19:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '20:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0  },
    { name: '21:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '22:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '23:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '23:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '24:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '01:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '02:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '03:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '04:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 },
    { name: '05:00', returns: 0, checkouts: 0, returns_percentage: 0, checkouts_percentage: 0 }
  ],
  weekday_usage: [
    { name: 'Monday', sessions: 0, sessions_percentage: 0, new_users: 0, new_users_percentage: 0 },
    { name: 'Tuesday', sessions: 0, sessions_percentage: 0, new_users: 0, new_users_percentage: 0 },
    { name: 'Wednesday', sessions: 0, sessions_percentage: 0, new_users: 0, new_users_percentage: 0 },
    { name: 'Thursday', sessions: 0, sessions_percentage: 0, new_users: 0, new_users_percentage: 0 },
    { name: 'Friday', sessions: 0, sessions_percentage: 0, new_users: 0, new_users_percentage: 0 },
    { name: 'Saturday', sessions: 0, sessions_percentage: 0, new_users: 0, new_users_percentage: 0 },
    { name: 'Sunday', sessions: 0, sessions_percentage: 0, new_users: 0, new_users_percentage: 0 },
  ]
};

export {
  getFullTrendsData,
	getBodyObjTrends,
	getPersonalInfoData,
  getGeneralUsagePatternsData,
  getTimePatternsUsage,
  getMembershipsPatternsUsage,
	//
	emptyPersonalInfoObj,
  emptyGeneralUsagePatternsObj,
  emptyTimePatternsDataObj,
  emptyMembershipsPatternsUsageObj
};