import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Button, Alert, Select, InputNumber } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const FormItem = Form.Item;
const { Option } = Select;

let id = 1;

class AddInvoice extends React.Component {
	state = {
    putREQUEST: false,
    putFAILURE: false,
    putSUCCESS: false,
    putMessage: '',
    currenciesList: ['USD', 'JPY', 'AUD', 'GBP', 'CAD', 'EUR']
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({
        putREQUEST: true
      })
      fetchJSON('/api/v1/organisation/invoicing', {
        method: 'PUT',
        body: fieldsValue
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false
          }, () => {
          	this.props.renderList();
          	this.props.handleClose();
          });
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false
          });
        }
       }).catch(error => {
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });
    })
  }

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  };
  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };



  render() {
    const { putFAILURE, currenciesList } = this.state; 
  	const { visible, handleClose, form, organisationId } = this.props;
  	const { getFieldDecorator, getFieldValue } = form;

  
    getFieldDecorator('keys', { initialValue: [0] });

    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => (
      <Form.Item
        label={index === 0 ? 'Order items' : ''}
        key={k}
      > 
        <div style={{ display: 'flex' }}>
          <Form.Item style={{ width: '70%', 'margin': 0 }}>{
            getFieldDecorator(`items[${k}].title`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                message: "Please enter item title!",
              },
            ],
            })(
            <Input placeholder="Title" style={{ width: '100%', marginRight: 8 }}/>
            )
          }</Form.Item>
          <Form.Item style={{ 'margin': 0 }}>{
            getFieldDecorator(`items[${k}].price`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                message: "Please enter item price!",
              },
            ],
            })(<InputNumber min={1} placeholder="Price" style={{ width: '60%', marginLeft: 8, marginRight: 8 }} />)
          }</Form.Item>
          {keys.length > 1 ? (
            <MinusCircleOutlined className="dynamic-delete-button" onClick={() => this.remove(k)} />
          ) : null}
        </div>
      </Form.Item>
    ));

    return (
      <Modal
      style={{ top: 20 }}
      visible={ visible }
      width={400}
      title="Add invoice"
      onOk={ handleClose }
      onCancel={ handleClose }
      footer={false}
    >
        <Form layout="vertical" onSubmit={this.handleSubmit}>
        { putFAILURE ? <FormItem>
          <Alert message="Something wrong" type="error" />
        </FormItem> : null }

          <div style={{'display': 'none'}}>
          {getFieldDecorator('organisationId', {
            rules: [{ required: true, message: 'Please enter a title!' }],
            initialValue: organisationId
          })(
            <Input />
          )}
        </div>
        <FormItem label="Currency">
          {getFieldDecorator('currency', {
            rules: [{ required: true, message: 'Please choose a currency!' }],
          })(
           <Select>
            {currenciesList.map((item, index) => (
              <Option key={item}>{item}</Option>
            ))}
           </Select>
          )}
        </FormItem>
        {formItems}
        <Form.Item>
          <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
            <PlusOutlined /> Add item
          </Button>
        </Form.Item>

        <Button type="primary" htmlType="submit" style={{marginTop: '20px'}}>Submit</Button>
      </Form>
    </Modal>
    );
  }
}

export default Form.create()(AddInvoice);
