import React from 'react';
import { connect } from 'react-redux';
import Settings from 'services/config/Settings';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Table, Layout, Input, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { fetchSecretQuote } from './data/actions'

const { Content } = Layout;
const Search = Input.Search;

const statuses = {
  'on_factory': 'On factory',
  'active': 'Active',
  'repair': 'Repair',
  'eol': 'EOL',
  'opened': 'Opened',
  'lost': 'Lost'
};

class Electrons extends React.Component {
  state = {
    filteredInfo: {},
    sortedInfo: {},
    searchText: '',
    filtered: false,
    data: [],
    groupFilter: []
  };
  componentDidMount() {
    document.title = `Electrons | ${Settings.title}`;
    this.props.dispatch(fetchSecretQuote('/electrons/list'))
  }
  lessThanAgo = (date) => {
    if (!date) return false;
    const _AGO = 1000 * 60 * 3;
    let anMinuteAgo = Date.now() - _AGO;
    return date < anMinuteAgo;
  }

  columns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return [{
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <span
          style={{ 
            fontWeight: 'bold',
            //color: record.status === 'OUT' ? 'green' : (record.status === 'IN' ? 'blue' : '#d73843' )
          }}
        >
        {record.status}</span>
      ),
      filters: [
        { text: 'IN', value: 'IN' },
        { text: 'OUT', value: 'OUT' },
        { text: 'LOST', value: 'LOST' },
        { text: 'STOLEN', value: 'STOLEN' },
        { text: 'WAREHOUSE', value: 'WAREHOUSE' },
        { text: 'LOST FOREVER', value: 'LOST FOREVER' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => {
        return record.status.includes(value)
      },
      sorter: (a, b) => a.status > b.status ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order
    },
    {
      title: 'ID',
      dataIndex: 'string_id',
      key: 'string_id',
      sortOrder: sortedInfo.columnKey === 'string_id' && sortedInfo.order,
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Release status',
      dataIndex: 'release_status',
      key: 'release_status',
      filters: [
        { text: 'Ok', value: 'ok' },
        { text: 'Stopped', value: 'stopped' }
      ],
      filteredValue: filteredInfo.release_status || null,
      onFilter: (value, record) => {
        return record.release_status.includes(value)
      },
      sorter: (a, b) => a.release_status > b.release_status ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'release_status' && sortedInfo.order,
    },
    {
      title: 'Actual status',
      dataIndex: 'actual_status',
      key: 'actual_status',
      filters: [
        { text: 'On factory', value: 'on_factory' },
        { text: 'Active', value: 'active' },
        { text: 'Repair', value: 'repair' },
        { text: 'EOL', value: 'eol' },
        { text: 'Opened', value: 'opened' },
        { text: 'Lost', value: 'lost' }
      ],
      filteredValue: filteredInfo.actual_status || null,
      onFilter: (value, record) => {
        return record.raw_actual_status ? record.raw_actual_status.includes(value) : false
      },
      sorter: (a, b) => a.actual_status > b.actual_status ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'actual_status' && sortedInfo.order,
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      filters: [
        { text: 'Supernova', value: 'IN' },
        { text: 'User', value: 'OUT' }
      ],
      filteredValue: filteredInfo.owner || null,
      onFilter: (value, record) => {
        return record.status.includes(value)
      },
      render: (text, record) => (
        record.owner_type ?
        <Link to={`${record.owner_type}/${record.owner_id}`} target="_blank"> 
          {record.owner}
        </Link> : null
      ),
    },
    {
      title: 'Group ID',
      dataIndex: 'group_id',
      key: 'group_id',
      filters: this.props.groupFilter,
      filteredValue: filteredInfo.group_id || null,
      onFilter: (value, record) => {
        return _.get(record, 'group_id') === value;
      }
    },
    {
      title: 'Session done',
      dataIndex: 'sessionCount',
      key: 'sessionCount',
      sortOrder: sortedInfo.columnKey === 'sessionCount' && sortedInfo.order,
      sorter: (a, b) => a.sessionCount - b.sessionCount
    },
    {
      title: 'Hardware revision',
      dataIndex: 'hardware_revision',
      key: 'hardware_revision',
      filters: this.hardwareRevisionFilter(),
      filteredValue: filteredInfo.hardware_revision || null,
      onFilter: (value, record) => {
        if (!record.hardware_revision) return false;
        return record.hardware_revision.includes(value);
      },
      sorter: (a, b) => a.hardware_revision > b.hardware_revision ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'hardware_revision' && sortedInfo.order,
    },
    {
      title: 'Cable types',
      dataIndex: 'cable_types',
      key: 'cable_types',
      filters: this.cableTypesFilter(),
      filteredValue: filteredInfo.cable_types || null,
      onFilter: (value, record) => {
        if (!record.cable_types) return false;
        return record.cable_types.includes(value);
      },
      sorter: (a, b) => a.cable_types > b.cable_types ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'cable_types' && sortedInfo.order,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Link to={"/electrons/"+record.id} className="ant-btn ant-btn-sm" style={{marginRight: '8px'}}>
            Edit
          </Link>
          <Link to={`/electrons/${record.id}?history=1`} className="ant-btn ant-btn-sm" style={{marginRight: '8px'}}>
            History
          </Link>
          <Link to={`/electrons/${record.id}?history_log=1`} className="ant-btn ant-btn-sm">
            Statistics
          </Link>
        </span>
      ),
    }];
  }
  cableTypesFilter = () => {
    const filter = [];
    if (!this.props.quote) return filter;
    let response = this.props.quote.data;
    
    const listTmp = [];
    response.forEach((electron, i) => {
      if (electron.cable_types) {
        if (listTmp.indexOf(electron.cable_types) === -1) {
          filter.push({
            text: electron.cable_types, value: electron.cable_types
          });
          listTmp.push(electron.cable_types);
        }
      }
      
    })
    return filter;
  } 
  hardwareRevisionFilter = () => {
    const filter = [];
    if (!this.props.quote) return filter;
    let response = this.props.quote.data;
    
    const listTmp = [];
    response.forEach((electron, i) => {
      if (electron.hardware_revision) {
        if (listTmp.indexOf(electron.hardware_revision) === -1) {
          filter.push({
            text: electron.hardware_revision, value: electron.hardware_revision
          });
          listTmp.push(electron.hardware_revision);
        }
      }
      
    })
    return filter;
  }
  getData = () => {
    const data = [];
    if (!this.props.quote) return data;

    let response = this.props.quote.data;

    if (this.state.filtered) {
      response = this.state.data;
    }
    
    response.forEach((electron, i) => {
      let owner = false;
      let status = '';
      let owner_type = false;
      let owner_id = '';

      if (electron.status.owner_type === 'supernova') {
        owner = 'Supernova ';
        status = 'IN';
        owner_type = 'supernovas';
        if (electron.supernovaId) {
          owner_id = electron.supernovaId;
        }
      }
      if (electron.status.owner_type === 'lost_from_supernova') {
        status = 'LOST';
        owner = 'Lost from ';
        owner_type = 'supernovas';
        if (electron.supernovaId) {
          owner_id = electron.supernovaId;
        }
      }
      if (electron.status.owner_type === 'lost_forever') {
        status = 'LOST FOREVER';
      }

      if (electron.status.owner_type === 'stolen_from_supernova') {
        status = 'STOLEN';
        owner = 'Stolen from ';
        owner_type = 'supernovas';
        if (electron.supernovaId) {
          owner_id = electron.supernovaId;
        }
      }
      if (electron.status.owner_type === 'storage') {
        status = 'WAREHOUSE';
        owner = 'Out of system';
        owner_type = null;
        owner_id = null;
      }

      if (electron.status.owner_type === 'user') {
        status = 'OUT';
        owner = 'User #';
        owner_type = 'users';
        owner_id = electron.status.owner_id;
      }
      data.push({
        key: i,
        id: electron.id,
        string_id: electron.string_id,
        release_status: electron.release_status,
        actual_status: statuses[electron.actual_status],
        raw_actual_status: electron.actual_status,
        status: status,
        owner: `${owner}${owner_id}`,
        owner_id: electron.status.owner_id,
        owner_type: owner_type,
        group_id: electron.group_id,
        sessionCount: parseInt(electron.sessionCount, 10) || 0,
        cable_types: electron.cable_types,
        hardware_revision: electron.hardware_revision,
      })
    })

    return data;
  }
  onSearch = (e) => {
    this.setState({ searchText: e.target.value });
    const searchText = e.target.value;
    const reg = new RegExp(e.target.value, 'gi');
    this.setState({
      filtered: !!searchText,
      data: _.flatMap(this.props.quote.data, record => {
        const supernovaIdMatch = record.supernovaId ? record.supernovaId.match(reg) : false;
        const idMatch = record.string_id.match(reg);
        if (!supernovaIdMatch && !idMatch) {
          return null;
        }
        return {          
          ...record,
          };
      }).filter(record => !!record),
    });
    if (e.target.value === ''){
      this.setState({
        data: this.props.quote.data,
        filtered: false
      });
    }
  }
  handleTableChange = (pagination, filters, sorter) => {
    console.log('handleTableChange', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  emitEmpty = () => {
    this.setState({ 
      data: [],
      searchText: '',
      filtered: false
    });
  }
  render() {
    const { isFetching, pagination } = this.props;
    const { searchText } = this.state;
    const suffix = searchText ? <CloseCircleOutlined key="1" onClick={this.emitEmpty} /> : null;
    return (
      <Content className="fill-bg-table">
        <Row>
          <Col span={18}>
            <h1 className="table-title" style={{marginTop: '4px'}}>Electrons</h1>
          </Col>
          <Col span={6} style={{display: 'flex'}}>
           <Search 
            style={{marginBottom: '8px', width: '305px', marginLeft: 'auto'}} 
            disabled={isFetching} 
            onChange={this.onSearch} 
            placeholder="Search electron"
            allowClear
           />
          </Col>
        </Row>
        <Table
          columns={ this.columns() }
          size="middle"
          loading={ isFetching }
          dataSource={ this.getData() }
          pagination={ pagination }
          onChange={ this.handleTableChange }
        />
      </Content>
    )
  }
}

const mapStateToProps = (state) =>{
  const { electrons } = state;
  const { quote, isFetching, createdFetch, pagination, groupFilter } = electrons;
  return {
    quote,
    isFetching,
    createdFetch,
    pagination,
    groupFilter
  }
}


export default connect(mapStateToProps)(Electrons)
