import React from 'react';
import { connect } from 'react-redux';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
  CloseCircleOutlined,
  CreditCardOutlined,
  MobileOutlined,
  QuestionCircleOutlined,
  UnlockOutlined,
} from '@ant-design/icons';

import { Button, Row, Col, Input, Table, Card, Modal, Radio, Spin } from 'antd';
import { fetchUser } from './modules/getSingleUser';
import moment from 'moment-timezone';
import qs from 'qs';
import { Link } from 'react-router-dom';
// import fetchJSON from 'services/utils/fetchJSON';
import _ from 'lodash';

import { changeUserStatus } from './modules/userStatus';
import SmsSender from 'screens/Dashboard/components/SmsSender/SmsSender';
import BatteryRelease from 'screens/Dashboard/components/BatteryRelease/BatteryRelease';
import Transactions from 'screens/Dashboard/components/Transactions/Transactions';
import Notes from 'screens/Dashboard/components/Notes/Notes';
import UserTransactions from './components/UserTransactions/UserTransactions';
import UserMessages from './components/UserMessages'
import DeletePersonalData from './components/DeletePersonalData';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const confirm = Modal.confirm;

require("moment-duration-format");
const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const tabList = [{
  key: 'info',
  tab: 'Info',
}, {
  key: 'history',
  tab: 'Charging history',
}, {
  key: 'transactions',
  tab: 'Transactions',
}, {
  key: 'messages',
  tab: 'Messages',
}];

class SingleUserCard extends React.Component {
  state = {
    chargingHistory: false,
    visibleSmsSender: false,
    visibleTransactions: false,
    visibleNotes: false,
    activeTab: 'info',
    visibleBatteryRelease: false,
    showDeletePersonalData: false
  }
  componentDidMount() {
    let paramFromUrl = {};
    if (window.location.search) {
      paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    }
    let activeTab = 'info';
    if (Object.keys(paramFromUrl).length) {
      Object.keys(paramFromUrl).forEach((param) => {
        if (paramFromUrl[param]) {
          activeTab = param;
        }
      });
    }
    this.setState({
      activeTab: activeTab
    }, this.letsFetch)
  }
  letsFetch = () => {
    const { dispatch, userId } = this.props;
    dispatch(fetchUser(`/user/${userId}`));
  }
  renderTable = () => {
    let sessions = this.props.user.electronSession;
    if (!sessions) return [];
    if (!sessions.length) return [];
    return sessions.map((item, index) => {
      let opentime = moment.unix(item.opentime).tz('America/Los_Angeles').format('DD MMM YYYY h:mm A');
      let closed_at = '';
      let color;
      let icon;
      let act = item.status;
      let sessionOpen = moment.unix(item.opentime);
      let sessionClose = moment();
      if (act === 'close') {
        sessionClose = moment.unix(item.closetime);
        closed_at = moment.unix(item.closetime).tz('America/Los_Angeles').format('DD MMM YYYY h:mm A');
      }
      let ms = sessionClose.diff(sessionOpen);
      let _duration = moment.duration(ms);
      let durationSession = _duration.format("d [d] h [h] m [min]");
      let openVenueId = _.get(item, 'open_group.venue_groups.venue_id');
      let closeVenueId = _.get(item, 'close_group.venue_groups.venue_id');
      if (act === 'lost') {
        durationSession = false;
      }
      if (act === 'open') {
        act = "Active";
        color = "blue";
        icon = "clock-circle-o"
      } else if (act === 'close') {
        act = "Completed";
        color = "green";
        icon = "safety"
      } else if (act === 'lost') {
        act = "Lost";
        color = "#f5222d";
        icon = "exclamation-circle"
      } else if (act === 'closed_through_support'){
        act = "Closed through support";
        color = "#f5222d";
        icon = "close-circle"
      }
      return {
        key: item.id,
        iconStatus: <LegacyIcon type={icon} style={{ fontSize: '16px' }} />,
        colorStatus: color,
        event: act,
        date: opentime,
        duration: durationSession,
        electron: item.electron_id,
        open_supernova_id: item.open_supernova_id ? item.open_supernova_id : null,
        close_supernova_id: item.close_supernova_id ? item.close_supernova_id : null,
        open_group_id: item.opening_group_id ? item.opening_group_id : null,
        close_group_id: item.closing_group_id ? item.closing_group_id: null,

        openSupernovaId: item.open_supernova ? item.open_supernova.string_id : null,
        closeSupernovaId: item.close_supernova ? item.close_supernova.string_id : null,
        electronId: item.electron ? item.electron.string_id : null,
        openGroupId: item.open_group ? item.open_group.key_id : null,
        closeGroupId: item.close_group ? item.close_group.key_id : null,
        openVenueId,
        closeVenueId,
        closed_at
      };
    });

  }
  columns = () => {
    return [{
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
      render: (text, record) => (
        <span style={{color: record.colorStatus }}>
          {record.iconStatus}  {record.event}
        </span>
      ),
    },
    {
      title: 'Started at',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Closed at',
      dataIndex: 'closed_at',
      key: 'closed_at',
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration'
    },
    {
      title: 'Electron',
      dataIndex: 'electronId',
      key: 'electronId',
      render: (text, record) => (
        <span>
          { record.electron ? 
            <Link to={`/electrons/${record.electron}`} target="_blank">{record.electronId}</Link>
            : null
          }
        </span>
      ),
    },
    {
      title: 'Open group',
      dataIndex: 'openGroupId',
      key: 'openGroupId',
      render: (text, record) => (
        <span>
          { (record.openVenueId && record.openGroupId) ? 
            <Link to={`/venues/${record.openVenueId}?groups=${record.openGroupId}`} target="_blank">{record.openGroupId}</Link>
            : null
          }
        </span>
      ),
    },
    {
      title: 'Close group',
      dataIndex: 'closeGroupId',
      key: 'closeGroupId',
      render: (text, record) => (
        <span>
          { (record.closeVenueId && record.closeGroupId) ? 
            <Link to={`/venues/${record.closeVenueId}?groups=${record.closeGroupId}`} target="_blank">{record.closeGroupId}</Link>
            : null
          }
        </span>
      ),
    },
    {
      title: 'Open supernova',
      dataIndex: 'openSupernovaId',
      key: 'openSupernovaId',
      render: (text, record) => (
        <span>
          { record.open_supernova_id ? 
            <Link to={`/supernovas/${record.openSupernovaId}`} target="_blank">{record.openSupernovaId}</Link>
            : null
          }
        </span>
      ),
    },
    {
      title: 'Close supernova',
      dataIndex: 'closeSupernovaId',
      key: 'closeSupernovaId',
      render: (text, record) => (
        <span>
          { record.close_supernova_id ? 
            <Link to={`/supernovas/${record.closeSupernovaId}`} target="_blank">{record.closeSupernovaId}</Link>
            : null
          }
        </span>
      ),
    }];
  }
  itemForm (label, value) {
    const formItemLayout = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    return (
      <FormItem
          {...formItemLayout}
        >
        <Row>
          <label className="form-label-custom">{label}</label>
        </Row>
        <Row >
          <Input value={ value } readOnly={true} />
        </Row>
      </FormItem>
    )
  }
  getRating (sessions) {
    let rating = {
      1: [], 2: [], 3: [], 4: [], 5: []
    };
    if (!sessions.length) return '5.00';

    sessions.forEach(session => {
      let sessionOpen = moment.unix(session.opentime);
      let sessionClose = moment();
      if (session.status === 'close') {
        sessionClose = moment.unix(session.closetime);
      }
      let ms = sessionClose.diff(sessionOpen);
      let _duration = moment.duration(ms);
      let hoursSpend = parseInt((_duration._milliseconds/(1000*60*60)), 10);
      if (session.status === 'lost') {
        rating['1'].push(hoursSpend);
      } else if (hoursSpend < 25) {
        rating['5'].push(hoursSpend);
      } else if (hoursSpend > 24 && hoursSpend < 59) {
        rating['4'].push(hoursSpend);
      } else if (hoursSpend > 58 && hoursSpend < 73) {
        rating['3'].push(hoursSpend);
      } else if (hoursSpend > 72) {
        rating['2'].push(hoursSpend);
      }
    })
    let top = 0;
    let bottom = 0;
    Object.keys(rating).forEach((key) => {
      bottom += rating[key].length;
      top += parseInt(rating[key].length*parseInt(key, 10), 10);
    });
    let stars = top/bottom;
    return stars.toFixed(2);
  };
  activeSub (subscription = []) {
    let Plan = 'No membership';
    if (subscription && subscription.length) {
      const activePlan = subscription[0];
      if (activePlan.userOrder) {
        const order = activePlan.userOrder.order;
        if (order.promo_id) {
          Plan = 'Promo ';
        } else {
          Plan = '';
        }
        const plan = activePlan.plan;
        if (plan) {
          Plan += activePlan.plan.view_label;
        }
      }
      if (subscription[0].paiduntildate > Math.floor(new Date().getTime()/1000)) {
        let until = subscription[0].paiduntildate ? moment.unix(subscription[0].paiduntildate).tz('America/Los_Angeles').format('DD MMM YYYY h:mm A') : false;
        if (until) Plan += ` expires ${until}`;
      }
    }
    return Plan;
  }
  renderActiveSession = (activeSession) => {
    if (activeSession && Object.keys(activeSession).length) {
      let sessionOpen = moment.unix(activeSession.opentime);
      let sessionClose = moment();
      let ms = sessionClose.diff(sessionOpen);
      let _duration = moment.duration(ms);
      let durationSession = _duration.format("d [d] h [h] m [min]");
      return durationSession;
    } else {
      return 0;
    }
  }
  onStatusChange = (e) => {
    const { dispatch, userId } = this.props;
    const status = e.target.value;
    confirm({
      title: `Change user status to "${status}"?`,
      onOk: () => dispatch(changeUserStatus({ status, userId }, this.letsFetch)),
      onCancel() {},
    });
  }
  createSupportTicket = () => {
    confirm({
      title: 'Creating a support Ticket',
      content: 'Here will soon be the process of creating a ticket',
      onOk() {},
      onCancel() {},
    });
  }
  openTransactions = () => {
    this.setState({
      visibleTransactions: true
    })
  }
  hideTransactions = () => {
    this.setState({
      visibleTransactions: false
    })
  }
  sendTemplateSMS = () => {
    this.setState({
      visibleSmsSender: true
    })
  }
  hideSMSsender = () => {
    this.setState({
      visibleSmsSender: false
    })
  }
  showNotes = () => {
    this.setState({
      visibleNotes: true
    })
  }
  hideNotes = () => {
    this.setState({
      visibleNotes: false
    })
  }
  showDPD = () => {
    this.setState({
      showDeletePersonalData: true
    })
  }
  hideDPD = () => {
    this.setState({
      showDeletePersonalData: false
    })
  }
  onTabChange = (key, type) => {
    this.setState({ [type]: key });
  }
  batteryRelease = () => {
    this.setState({
      visibleBatteryRelease: true
    })
  }
  hideRealiser = () => {
    this.setState({
      visibleBatteryRelease: false
    })
  }
  render() {
    const {
      phone,
      blocking_reason,
      id,
      regdate,
      subscription,
      electronSession,
      activeSession,
      email,
      zip,
      gender,
      age
    } = this.props.user;

    const { fetchingStatus } = this.props;
    let dateRegistration = regdate ? moment.unix(regdate).tz('America/Los_Angeles').format('DD MMM YYYY h:mm A') : null;

    const activeSub = this.activeSub(subscription);
    const { 
      visibleSmsSender,
      visibleTransactions,
      visibleNotes,
      activeTab,
      visibleBatteryRelease,
      showDeletePersonalData
    } = this.state;
    let activeSess = this.renderActiveSession(activeSession);    
    return (
      <Card
       loading = {this.props.isFetching}
       type="inner"
       title="User account"
       tabList={tabList}
       activeTabKey={activeTab}
       onTabChange={(key) => { this.onTabChange(key, 'activeTab'); } }
       >
       { activeTab ==='info' ?
         <Form>
           <FormItem
               {...formItemLayout}
             >
             <Row>
               <label className="form-label-custom">ID</label>
               <Button type="primary" onClick={ this.sendTemplateSMS }>
                 <MobileOutlined /> Send text to user
               </Button>
               <Button type="primary" onClick={ this.batteryRelease } style={{
                 marginLeft: '10px'
               }}>
                 <UnlockOutlined /> The battery release
               </Button>
               { visibleSmsSender ? <SmsSender visible={visibleSmsSender} handleClose={this.hideSMSsender} userId={id}  /> : null }
               { visibleBatteryRelease ? <BatteryRelease visible={visibleBatteryRelease} handleClose={this.hideRealiser} userId={id}  /> : null  }
             </Row>
             <Row >
               <Input value={ id } readOnly={true} />
             </Row>
           </FormItem>
           <FormItem
               {...formItemLayout}
             >
             <Row gutter={8}>
             <Col span={12}>
               <Row>
                 <label className="form-label-custom">Phone</label>
               </Row>
               <Row >
                 <Input value={ phone } readOnly={true} />
               </Row>
             </Col>
             { dateRegistration ? <Col span={12}>
               <Row>
                 <label className="form-label-custom">Date of registration</label>
               </Row>
               <Row >
                 <Input value={ dateRegistration } readOnly={true} />
               </Row>
             </Col>: null }
             </Row>
           </FormItem>
           <FormItem
               {...formItemLayout}
             >
             <Row gutter={8}>
             <Col span={12}>
               <Row>
                 <label className="form-label-custom">Email</label>
               </Row>
               <Row >
                 <Input value={ email } readOnly={true} />
               </Row>
             </Col>
             <Col span={12}>
               <Row>
                 <label className="form-label-custom">ZIP</label>
               </Row>
               <Row >
                 <Input value={ zip } readOnly={true} />
               </Row>
             </Col>
             </Row>
           </FormItem>
           <FormItem
               {...formItemLayout}
             >
             <Row gutter={8}>
             <Col span={12}>
               <Row>
                 <label className="form-label-custom">Rating</label>
               </Row>
               <Row >
                 <Input value={ electronSession && electronSession.length  ? this.getRating(electronSession) : '5.00'  } readOnly={true} />
               </Row>
             </Col>
             <Col span={12}>
               <Row>
                 <label className="form-label-custom">Number of session</label>
               </Row>
               <Row >
                 <Input value={ electronSession && electronSession.length ? electronSession.length : 0 } readOnly={true} />
               </Row>
             </Col>
             </Row>
           </FormItem>
           <FormItem
               {...formItemLayout}
             >
             <Row gutter={8}>
             <Col span={12}>
               <Row>
                 <label className="form-label-custom">Gender</label>
               </Row>
               <Row >
                 <Input value={ gender } readOnly={true} />
               </Row>
             </Col>
             <Col span={12}>
               <Row>
                 <label className="form-label-custom">Age</label>
               </Row>
               <Row >
                 <Input value={ age } readOnly={true} />
               </Row>
             </Col>
             </Row>
           </FormItem>
           <FormItem
               {...formItemLayout}
             >
             <Row gutter={8}>
             <Col span={activeSess ? 12 : 24}>
               <Row>
                 <label className="form-label-custom">Subscription</label>
               </Row>
               <Row >
                 <Input value={ activeSub } readOnly={true} />
               </Row>
             </Col>
             { activeSess ? <Col span={12}>
               <Row>
                 <label className="form-label-custom">Active session</label>
               </Row>
               <Row >
                 <Input value={ activeSess } readOnly={true} />
               </Row>
             </Col> : null }
             </Row>
           </FormItem>
           <FormItem
             {...formItemLayout}
             help={ blocking_reason ? 'Blocking reason: '+blocking_reason : ''}
           >
             <Row>
               <label className="form-label-custom">User status</label>
             </Row>
             <Spin spinning={ fetchingStatus }>
               <RadioGroup onChange={this.onStatusChange} value={ blocking_reason ? 'block' : 'active' }>
                 <RadioButton value={'active'}>Active</RadioButton>
                 <RadioButton value={'block'}>Block</RadioButton>
               </RadioGroup>
             </Spin>
           </FormItem>
           <FormItem
             {...formItemLayout}
             >
             <Row>
               <label className="form-label-custom">Actions</label>
             </Row>
             <Row className="btn-group">
               <Button type="primary" onClick={ this.openTransactions }>
                 <CreditCardOutlined /> Transactions
               </Button>
               { visibleTransactions ? <Transactions visible={visibleTransactions} handleClose={this.hideTransactions} userId={id} /> : null }
               <Button onClick={ this.showNotes }>
                 <QuestionCircleOutlined /> Notes
               </Button>
               { visibleNotes ? <Notes visible={visibleNotes} handleClose={this.hideNotes} sourceId={id} source="user" /> : null }
               <Button type="danger" onClick={ this.showDPD }>
                 <CloseCircleOutlined /> Delete personal data
               </Button>
               { showDeletePersonalData ? <DeletePersonalData
                 visible={showDeletePersonalData}
                 handleClose={this.hideDPD}
                 userId={id}
               /> : null }
             </Row>
           </FormItem>
         </Form>
         : null }

         { activeTab === 'history' ? 
           <div>
              <Table
               columns={ this.columns() }
               loading={ fetchingStatus }
               dataSource={ this.renderTable() }
               pagination={{ pageSize: 7 }}
               onChange={ this.handleTableChange }
             />
           </div>
         : null}
         { activeTab === 'transactions' ? 
           <UserTransactions userId={id} />
         : null}

         { activeTab === 'messages' ? 
           <UserMessages userId={id} />
         : null}

       </Card>
    );
  }
}


const mapStateToProps = (state) =>{
  const { singleUser, userStatus } = state;
  const fetchingStatus = userStatus.isFetching;
  const { quote, isFetching, createdFetch } = singleUser;
  const user = quote ? quote.success ? quote.data : {} : {};
  return {
    user,
    isFetching,
    createdFetch,
    fetchingStatus
  }
}

export default connect(mapStateToProps)(Form.create()(SingleUserCard))