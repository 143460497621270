import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Button, Radio } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

class SendCommand extends React.Component {
	state = {
    putREQUEST: false,
    putFAILURE: false,
    putSUCCESS: false,
    putMessage: '',
    value: 'v'
  }

  handleSubmit = e => {
    const { machineId } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({
        putREQUEST: true
      })
      let body = fieldsValue;
      if (body.command === 'SETGROUP') {
        body.command = `SETGROUP:${body.setgroup}`
      }

      if (body.command === 'SETGROUP') {
        body.command = `SETGROUP:${body.setgroup}`
      }
      if (body.command === 'custom') {
        body.command = body.custom
      }

      console.log(body);

      fetchJSON(`/api/v1/supernova/talk/${machineId}`, {
        method: 'PUT',
        body: body
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false
          }, () => {
          	this.props.successResult();
          	this.props.handleClose();
          });
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false
          });
        }
       }).catch(error => {
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });
    })
  }
  onChange = (e) => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  }
  render() {
  	const { visible, handleClose, form, machineId } = this.props;
  	const { getFieldDecorator } = form;
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    return (
    	<Modal
        style={{ top: 20 }}
        visible={ visible }
        width={400}
        title={`Send command to ${machineId}`}
        onOk={ handleClose }
        onCancel={ handleClose }
        footer={false}
      >
	      <Form layout="vertical" onSubmit={this.handleSubmit}>
          <FormItem label="Command">
            {getFieldDecorator('command', {
              rules: [{ required: true, message: 'Please enter a command!' }],
              initialValue: this.state.value
            })(
              <RadioGroup onChange={this.onChange}>
                <Radio style={radioStyle} value='v'>Voltage</Radio>
                <Radio style={radioStyle} value='HEAP'>Free memory</Radio>
                <Radio style={radioStyle} value='WIFIREBOOT'>Wifi reboot</Radio>
                <Radio style={radioStyle} value='SWITCHMQTT'>Switch to test server</Radio>
                <Radio style={radioStyle} value='c10'>All nest RED</Radio>
                <Radio style={radioStyle} value='c40'>All nest GREEN</Radio>
                <Radio style={radioStyle} value='c70'>All nest WHITE</Radio>
                <Radio style={radioStyle} value='SETGROUP'>
                  SETGROUP:XXXX
                  {this.state.value === 'SETGROUP' ? 
                  getFieldDecorator('setgroup', {
                      rules: [{ required: true, message: 'Please enter a group!' }],
                    })( <Input style={{ width: 100, marginLeft: 10 }} /> ) : null}
                </Radio>
                <Radio style={radioStyle} value='custom'>
                  Type command
                  {this.state.value === 'custom' ?
                  getFieldDecorator('custom', {
                      rules: [{ required: true, message: 'Please enter a command!' }],
                    })( <Input style={{ width: 100, marginLeft: 10 }} /> ) : null}
                </Radio>
              </RadioGroup>
            )}
          </FormItem>
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(SendCommand);
