import React from 'react';
import { DeleteOutlined, DisconnectOutlined, GlobalOutlined } from '@ant-design/icons';
import { Button, Col, Row, Modal, Radio, Divider, message } from 'antd';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import fetchJSON from 'services/utils/fetchJSON';

import GroupSetting from 'screens/Dashboard/components/GroupSetting/GroupSetting';
import SmsAuthenticationPrompt from 'screens/Dashboard/components/SmsAuthenticationPrompt/SmsAuthenticationPrompt';

import AddGroup from './AddGroup';
import AssignGroup from './AssignGroup';

import QrGenerator from './QrGenerator';
import { statuses } from '../../../../Supernovas/components/SupernovasTable.jsx';
import SupernovasTable from '../../../../Supernovas/components/SupernovasTable.jsx';
import AdaIcon from 'components/images/icon-wheelchair-symbol.svg';

import '../styles.less';

const instalationTypes = {
  'floor': 'Floor',
  'counter': 'Counter',
  'wall': 'Wall',
  'other': 'Other',
};
const confirm = Modal.confirm;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

class GroupSupernovas extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filteredInfo: {},
      sortedInfo: {},
      visibleGroupSettings: false,
      visibleAddGroup: false,
      visibleAssignGroup: false,
      editGroupId: null,
      generateQrId: null,
      visibleQrGenerator: false,
      visibleSmsAuthenticationPrompt: false,
      totalNumberTableObj: {}
    }
  }
  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  columns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    const signalStrengthList = [
      { text: 'Marginal', value: 'Marginal' },
      { text: 'OK', value: 'OK' },
      { text: 'Good', value: 'Good' },
      { text: 'Excellent', value: 'Excellent' },
      { text: 'WiFi', value: 'WiFi'}
    ];

    let LineItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap'}}>
            <p style={{margin: 0}}>Online</p>
          </div>
          )
      },
      dataIndex: 'internet',
      key: 'internet',
      fixed: 'left',
      render: (text, record) => (
        <span
          style={{ color: record.internet === 'online' ? 'green': 'red' }}
        ><GlobalOutlined style={{fontSize: '16px'}} /></span>
      ),
      filters: [
        { text: 'Online', value: 'online' },
        { text: 'Offline', value: 'offline'}
      ],
      align: 'center',
      filteredValue: filteredInfo.internet || null,
      onFilter: (value, record) => {
        return record.internet.includes(value)
      },
      sorter: (a, b) => a.internet > b.internet ? -1 : 1,
      sortOrder: sortedInfo.columnKey === 'internet' && sortedInfo.order,
      width: 100
    };
    let IdItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Supernova ID</p>
          </div>
          )
      },
      dataIndex: 'string_id',
      key: 'string_id',
      align: 'center',
      fixed: 'left',
      width: 130,
      render: (text, record) => (
        <Link to={`/supernovas/${record.string_id}`}> 
          {record.string_id}
        </Link>
    )};
    let ActualStatusItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Status</p>  
          </div>
          )
      },
      dataIndex: 'actual_status',
      key: 'actual_status',
      align: 'center',
      width: 138,
      filters: statuses,
      filteredValue: filteredInfo.actual_status || null,
      onFilter: (value, record) => {
        if (record.actual_status === value) return true;
      },
      sorter: (a, b) => a.actual_status > b.actual_status ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'actual_status' && sortedInfo.order,
      render: (text, record) => (
        <p style={{margin: 0}}>{capitalize(text)}</p>
      ),
    };
    let issuesItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Issues</p>  
          </div>
          )
      },
      key: 'issues',
      dataIndex: 'issues',
      align: 'center',
      width: 77,
      render: (text, record) => (
        <span
          style={{ color: '' }}
        >-</span>
      ),
    };
    let typeItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Type</p>  
          </div>
          )
      },
      key: 'type',
      dataIndex: 'type',
      align: 'center',
      width: 85,
      sorter: (a, b) => a.type > b.type ? 1 : -1,
      filters: [
        { text: 'Cellular', value: 'Cellular' },
        { text: 'WiFi', value: 'WiFi' }
      ],
      filteredValue: filteredInfo.type || null,
      onFilter: (value, record) => {
        return record.type.includes(value)
      },
      sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order
    }
    let signalStrengthItem = {
      title: () => {
        return (
          <div style={{display: 'inline-flex', whiteSpace: 'nowrap'}}>
             <p style={{margin: 0}}>Signal strength</p>
          </div>
          )
      },
      filters: signalStrengthList,
      filteredValue: filteredInfo.signalStrength || null,
      onFilter: (value, record) => {
        return record.signalStrength.includes(value)
      },
      key: 'signalStrength',
      dataIndex: 'signalStrength',
      align: 'center',
      width: 160
    }
    let covid19 =     {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
           <p style={{margin: 0}}>Quarantine</p>
          </div>
          )
      },
      key: 'quarantine',
      dataIndex: 'quarantine',
      align: 'center',
      width: 120,
      render: (text, record) => (
        <span
          style={{ color: record.covid_quarantine ? 'green': 'red' }}
        >{text}</span>
      ),
    }
    // let ticketsItem =    {
    //     title: () => {
    //       return (
    //         <div style={{ whiteSpace: 'nowrap' }}>
    //           <p style={{margin: 0}}>Tickets</p>
    //         </div>
    //         )
    //     },
    //     key: 'tickets',
    //     dataIndex: 'tickets',
    //     align: 'center',
    //     sortDirections: ['descend', 'ascend'],
    //     width: 80,
    //     sorter: (a, b) => a.tickets > b.tickets ? -1 : 1,
    //     sortOrder: sortedInfo.columnKey === 'tickets' && sortedInfo.order,
    //     render: (text, record) => (
    //       <div style={{whiteSpace: 'nowrap', margin: 0}}>
    //       { record.tickets > 0 ? 
    //         <Link to={`/tickets?spn=${record.id}`}>
    //           {record.tickets}
    //         </Link> : <p style={{margin: 0}}>0</p> }
    //       </div>
    //     )
    // };
    let regionItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
            <p style={{margin: 0}}>Region</p>
          </div>
          )
      },
      filters: this.props.regionFilter,
      filteredValue: filteredInfo.region || null,
      onFilter: (value, record) => {
        return record.region.includes(value)
      },
      align: 'center',
      key: 'region',
      dataIndex: 'region',
      width: 120
    }
    let sessionsItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{margin: 0}}>Sessions</p>
          </div>
          )
      },
      key: 'sessions',
      dataIndex: 'sessions',
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.sessions > b.sessions ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'sessions' && sortedInfo.order,
      width: 90
    }
    let electronsItem = {
      title: 'Electrons',
      dataIndex: 'electrons',
      key: 'electrons',
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      width: 93,
      sortOrder: sortedInfo.columnKey === 'electrons' && sortedInfo.order,
      sorter: (a, b) => a.electrons - b.electrons
    };
    let adaItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <img style={{ maxWidth: '18px' }} src={AdaIcon} alt="wheelchair symbol"/>
          </div>
          )
      },
      key: 'ada_status',
      align: 'center',
      dataIndex: 'ada_status',
      width: 90,
      render: (text, record) => (
        <span
          style={{ color: record.ada_status === 'On' ? 'green': 'red' }}
        >{text}</span>
      ),
    }
    let modelItem = {
      title: 'Model',
      dataIndex: 'model',
      align: 'center',
      key: 'model',
      width: 135,
      // filters: this.props.modelFilter,
      // filteredValue: filteredInfo.model || null,
      // onFilter: (value, record) => {
      //   return record.model.includes(value)
      // },
    };
    let warrantyItem = {
      title: 'Warranty',
      dataIndex: 'warranty',
      key: 'warranty',
      align: 'center',
      width: 120,
      render: (text, record) => (
          <p style={{color: record.warranty === 'Expired' ? 'red' : '', marginBottom: '0px'}}>{record.warranty}</p>
      ),
      filters:  [
        { text: 'Active', value: 'Active' },
        { text: 'Expired', value: 'Expired' }
      ],
      filteredValue: filteredInfo.warranty || null,
      onFilter: (value, record) => {
        if (record.warranty) {
          if (value === 'Expired') {
            return record.warranty.includes(value)
          }
          else return !record.warranty.includes(value) && record.warranty !== 'Expired'
        }
        return null
      }
    };
    let organisationItem = {
      title: 'Organization',
      key: 'organisation',
      dataIndex: 'organisation'
    }
    const columns = [];
    const generalColumns = [
      LineItem,     
      IdItem,
      signalStrengthItem,
      ActualStatusItem,
      electronsItem,
      sessionsItem,
      issuesItem,
      // ticketsItem,
      covid19,
      adaItem,
      typeItem,
      modelItem,
      regionItem,
      warrantyItem,
      organisationItem
    ];

    columns.push(...generalColumns);
    return columns;
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  modalVisibleSetting = (key, value, groupId) => {
    this.setState({ 
      [key]: value,
      editGroupId: groupId
    });
  }
  onDeleteGroup = (group_id) => {
    confirm({
      title: `Are you sure you want to delete this group?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            isFetching: true
          })
          fetchJSON(`/api/v1/group/delete/${group_id}`, {
            method: 'put',
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                isFetching: false,
              });
              this.props.renderVenue();
              resolve()
            } else {
              this.setState({
                isFetching: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                isFetching: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  changeTypeOfInstalation = (e, group_domestic_id) => {
     confirm({
      title: 'Are you sure you want change type of instalation for this location ID?',
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            isFetching: true
          })
          fetchJSON(`/api/v1/group/instalationType/${group_domestic_id}`, {
            method: 'post',
            body: {
              type_of_instalation: e.target.value
            }
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                isFetching: false,
              });
              message.success('Type of instalation changed');
              this.props.renderVenue();
              resolve()
            } else {
              throw new Error('Server error');
            }
           }).catch(error => {
              console.log(error);
              message.error('Something went wrong, please, try again');
              reject();
              this.setState({
                isFetching: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  releaseDigitalCode = (group_id, group_domestic_id) => {
    confirm({
      title: `Are you sure you want the release ${group_id} code from this location ID?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            isFetching: true
          })
          fetchJSON(`/api/v1/group/releaseDigitalCode/${group_domestic_id}`, {
            method: 'post'
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                isFetching: false,
              });
              message.success('Location ID successfully released');
              this.props.renderVenue();
              resolve()
            } else {
              throw new Error('Server error');
            }
           }).catch(error => {
              console.log(error);
              message.error('Something went wrong, please, try again');
              reject();
              this.setState({
                isFetching: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  releaseDigitalCodePrompt = (group_id, group_domestic_id) => {
    confirm({
      title: `Are you sure you want the release ${group_id} code from this location ID? This digital code will be free for assignment to the new venue.`,
      content: 'You can no longer use the QR code that is installed in the physical location. You will have to print a new one. Also you will need to confirm this action via SMS authentication.',
      onOk: () => {
        this.setState({
          visibleSmsAuthenticationPrompt: true,
          doAfterSmsAuthentication: () => {
            this.releaseDigitalCode(group_id, group_domestic_id);
          }
        });
      },
      onCancel() {},
    });
  }
  handleTableChange = (pagination, filters, sorter) => {
    //console.log('handleTableChange', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  getTotalNumberTable = (groupKeyId) => {
    return this.state.totalNumberTableObj[groupKeyId]
  }
  updateTotalNumberTable = (groupKeyId, value) => {
    if (this.mounted) {
      let { totalNumberTableObj } = this.state;
      totalNumberTableObj[groupKeyId] = value;
      this.setState({ totalNumberTableObj });
    }
  }
  renderGroups = () => {
    const { groups } = this.props;
    const { totalNumberTableObj } = this.state;
    if (groups) {
      let groupRender = [];
      groups.forEach(groupObj => {
        let tmp = {
          id: _.get(groupObj, 'group.id'),
          notes:  _.get(groupObj, 'group.notes'),
          key_id: _.get(groupObj, 'group.key_id'),
          type_of_instalation: _.get(groupObj, 'group.type_of_instalation'),
          supernovaIds: []
        };
        let supernovas = _.get(groupObj.group, 'supernovas');
        const supernovaIds = [];
        if (supernovas) {
          supernovas.forEach((supernovaObj, index) => {
            let supernova = supernovaObj.supernova;
            if (supernova.id && supernovaIds.indexOf(supernova.id) === -1) {
              supernovaIds.push(supernova.id);
            }
          })
          tmp.supernovaIds = supernovaIds;
        }
        groupRender.push(tmp);
      });
      return groupRender.map((group, key) => {
        return (
          <div key={key} style={{ marginBottom: '20px' }}>
            <Row gutter={8} style={{ marginBottom: '20px' }}>
              <Col span={24}>
                <h3 className="table-title">{group.key_id}
                  <Button style={{marginLeft:'8px'}} size="small" onClick={ () => { this.modalVisibleSetting('visibleQrGenerator', true, group.key_id) } }>
                    Get QR code
                  </Button>
                  <Button style={{marginLeft:'8px'}} type="danger" size="small" onClick={ () => { this.releaseDigitalCodePrompt(group.key_id, group.id) } }>
                    <DisconnectOutlined /> Release Location ID
                  </Button>
                  <Button style={{marginLeft:'8px'}} type="danger" size="small" onClick={ () => { this.onDeleteGroup(group.id) } }>
                    <DeleteOutlined />  Delete
                  </Button>
                </h3>
                <p style={{ marginTop: '15px' }}>
                  <b>Description:</b> {group.notes}
                  <Button style={{marginLeft:'8px'}} size="small" onClick={ () => { this.modalVisibleSetting('visibleGroupSettings', true, group.id) } }>
                    Change description
                  </Button>
                </p>
                <Row>
                  <b style={{ marginRight: '15px' }}>Type of instalation</b>
                  <RadioGroup value={group.type_of_instalation} onChange={(e) => this.changeTypeOfInstalation(e, group.id) }>
                    {Object.keys(instalationTypes).map((value, i) => {
                      return (<RadioButton key={i} value={value}>{instalationTypes[value]}</RadioButton>);
                    })}
                  </RadioGroup>
                </Row>
                <Row style={{marginTop: '15px'}}>
                  <b>Supernovas quantity: {totalNumberTableObj[group.key_id]}</b>
                </Row>
              </Col>
            </Row>
            <div>
              <SupernovasTable 
                history={this.props.history}
                updateTotalNumberTable={ (quantity) => { this.updateTotalNumberTable(group.key_id, quantity) }}
                getTotalNumberTable={() => this.getTotalNumberTable(group.key_id)}
                inGroup={true}
                supernovaIds={group.supernovaIds}
              />
            </div>
          </div>
        );
      });
    } else {
      return null;
    }
  }
  render() {
    const {
      visibleAddGroup,
      visibleGroupSettings,
      editGroupId,
      visibleQrGenerator,
      visibleAssignGroup,
      visibleSmsAuthenticationPrompt,
      doAfterSmsAuthentication
    } = this.state;
    return (
      <div>
        <div id="placeHolder"></div>
        <Row gutter={8}>
          <Col span={11}>
            <h1 className="table-title">Location IDs
              <Button style={{marginLeft:'8px'}} type="primary" size="small" onClick={ () => { this.modalVisible('visibleAddGroup', true) } }>
                Generate new location ID
              </Button>
              <Button style={{marginLeft:'8px'}} type="primary" size="small" onClick={ () => { this.modalVisible('visibleAssignGroup', true) } }>
                Assign location ID
              </Button>
            </h1>
          </Col>
          <Divider />
          <Col span={13}>
            { visibleAddGroup ? <AddGroup
              venueId={this.props.venueId}
              renderGroups={this.props.renderVenue}
              visible={visibleAddGroup}
              history={this.props.history}
              handleClose={ () => { this.modalVisible('visibleAddGroup', false) } } />
              : null
            }
            { visibleAssignGroup ? <AssignGroup
              venueId={this.props.venueId}
              renderGroups={this.props.renderVenue}
              visible={visibleAssignGroup}
              history={this.props.history}
              handleClose={ () => { this.modalVisible('visibleAssignGroup', false) } } />
              : null
            }
            { visibleSmsAuthenticationPrompt ? <SmsAuthenticationPrompt
              visible={visibleSmsAuthenticationPrompt}
              action="release_location_id"
              doAfter={ doAfterSmsAuthentication }
              handleClose={ () => { this.modalVisible('visibleSmsAuthenticationPrompt', false) } } />
              : null
            }
          </Col>
        </Row>
        { this.renderGroups() }
        { visibleQrGenerator ?
          <QrGenerator
            groupId={editGroupId}
            visible={visibleQrGenerator}
            history={this.props.history}
            handleClose={ () => { this.modalVisible('visibleQrGenerator', false) } }
          /> : null }
        { visibleGroupSettings ?
          <GroupSetting
            groupId={editGroupId}
            renderGroups={this.props.renderVenue}
            visible={visibleGroupSettings}
            history={this.props.history}
            handleClose={ () => { this.modalVisible('visibleGroupSettings', false) } }
          /> : null }
      </div>
    )
  }
}

export default GroupSupernovas;
