import React from 'react';
import axios from 'axios'
import Auth from 'services/utils/Auth';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Modal, message } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const confirm = Modal.confirm;

class VenueImages extends React.Component {
	state = {
		previewImage: '',
		previewVisible: false,
		fileList: []
	}
	componentDidMount() {
		if (this.props.images && this.props.images.length) {
			let fileList = this.props.images.map((obj, key) => {
				return {
					uid: obj.image.id,
					status: 'done',
					url: `/api/v1/images/origin/${obj.image.file}`
				}
			})
			this.setState({
				fileList
			})
		}
	}
	handleDrop = data => {
		// console.log(data);
		const file = data.file;
		if (file) {
			if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
				if (file.size > 5242880) {
					let errorLoadMessage = 'This image is too big! Max image file size is 5 mb.';
					message.error(errorLoadMessage);
					data.onError();
					return false;
				}
			} else {
				let errorLoadMessage = 'Wrong file type! file type must be png, jpg or jpeg!';
				message.error(errorLoadMessage);
				data.onError();
				return false;
			}
		} else {
			let errorLoadMessage = "File was null!";
			message.error(errorLoadMessage);
			data.onError();
			return false;
		}

		// Initial FormData
		const formData = new FormData();
		formData.append("file", file);
		const config = {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
				"Authorization": `bearer ${Auth.getToken()}`
			},
			onUploadProgress: data.onProgress
		};
		return axios.put(`/api/v1/venue/image/${this.props.venueId}`, formData, config)
			.then(response => {
				// console.log(response);
				data.onSuccess(response);
				const { fileList } = this.state;
				fileList.map(file => {
					if (file.uid === data.file.uid) {
						file.uid = response.data.uid;
					}
					return file;
				});
				this.setState({ fileList })
			})
			.catch(error => {
				console.log(error);
				let errorLoadMessage = 'Could not upload file. Please try again!';
				message.error(errorLoadMessage);
				data.onError();
			})
	}

	handleCancel = () => this.setState({ previewVisible: false })
	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	}
	handleChange = ({ fileList }) => this.setState({ fileList })
	onRemove = params => {
		return new Promise((resolve, reject) => {
			confirm({
				title: 'Do you want to delete this image?',
				onOk() {
					fetchJSON(`/api/v1/venue/image/${params.uid}`, {
						method: 'DELETE'
					}).then(response => {
						if (response.success) {
							message.success("File deleted");
							resolve()
						} else {
							reject()
						}
					}).catch(error => {
						console.log(error);
						reject()
					})
				},
				onCancel() {

				},
			});

		})

	}
	render() {
		const { previewVisible, previewImage, fileList } = this.state;
		const uploadButton = (
			<div>
				<PlusOutlined />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<div>
				<h3>Images</h3>
				<p>Minimum requirements: width - 1800px, height - 800px, maximum size - 5mb</p>
				<div className="clearfix">
					<Upload
						action="/api/v1/venue/upload"
						multiple={true}
						customRequest={this.handleDrop}
						listType="picture-card"
						fileList={fileList}
						onPreview={this.handlePreview}
						onChange={this.handleChange}
						onRemove={this.onRemove}
					>
						{fileList.length >= 5 ? null : uploadButton}
					</Upload>
					<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
						<img alt="example" style={{ width: '100%' }} src={previewImage} />
					</Modal>
				</div>
			</div>
		)
	}
}

export default VenueImages;
