import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Input, Row, Col, Spin, message, Select, Steps } from 'antd';
// import AddressField from './components/AddressField';
import AddressField from './components/AddressField/AddressField';
import fetchJSON from 'services/utils/fetchJSON';

import AddVenue from '../../../Venues/components/SingleVenue/AddVenue';
import VenueList from './components/VenueList';

const FormItem = Form.Item;
const Option = Select.Option;
const Step = Steps.Step;

const steps = [{
  title: 'Create organisation',
  content: 'organisation-step',
}, {
  title: 'Add venue',
  content: 'venues-step',
}];

class AddOrg extends React.Component {
  state = {
    organisation: {
      title: '',
      address: '',
      description: '',
      delay_notifications: 1,
      contacts: {},
      addressComponents: {}
    },
    types: {
    	business_types: [],
    	business_categories: [],
    	partnership_plans: [],
    	partner_types: [],
    	customer_sources: [],
      business_subcategories: []
    },
    business_categories: [],
    putREQUEST: false,
    putFAILURE: false,
    putSUCCESS: false,
    currentStep: 0,
    organisationId: null,
    visibleAddVenue: false,
    venueList: []
  }
  componentDidMount() {
    this.getTypeFields();
  }
  getTypeFields = () => {
    this.setState({
      putREQUEST: true
    })
    fetchJSON('/api/v1/organisation/types', {
      method: 'get'
    }).then(response => {
      //console.log(response);
      if (response.success) {
        this.setState({
          putREQUEST: false,
          types: response.data
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          putREQUEST: false
        });
     });
  }

  getVenueList = () => {
    this.setState({
      putREQUEST: true
    })
    const { organisationId } = this.state;
    fetchJSON(`/api/v1/organisation/venueList/${organisationId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          putREQUEST: false,
          venueList: response.data
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          putREQUEST: false
        });
     });
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      console.log(fieldsValue, err)
      if (err) {
        return;
      }
      this.setState({
        putREQUEST: true
      })
      fetchJSON(`/api/v1/organisation/create`, {
        method: 'PUT',
        body: fieldsValue
      }).then(response => {
        console.log(response);
        if (response.success) {
          message.success('Successfully created');
          let { currentStep } = this.state;
          currentStep = currentStep + 1;
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false,
            organisationId: response.data.id,
            currentStep
          });
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
          });
          message.error(response.message);
        }
        setTimeout(() => {
          this.setState({
            errorMessage: ''
          });
        }, 3000)
       }).catch(error => {
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });  
    })
  }
  checkAddress = (rule, value, callback) => {
  	callback();
    return;
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  eventCreated = () => {
    this.modalVisible('visibleAddVenue', false)
    this.getVenueList();
  }
  goToOrg = () => {
    const { organisationId } = this.state;
    this.props.history.push(`/organisations/${organisationId}`);
  }

  render() {
    const {
      putREQUEST,
      organisation,
      types,
      currentStep, 
      visibleAddVenue,
      venueList,
      organisationId
    } = this.state;
    const { handleClose, visible, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        style={{ top: 20 }}
        visible={ visible }
        width={900}
        title="Add organisation"
        onOk={ handleClose }
        onCancel={ handleClose }
        footer={false}
      >
        <Steps current={currentStep}>
          {steps.map(item => <Step key={item.title} title={item.title} />)}
        </Steps>
        <div className="steps-action" style={{ marginTop: '30px' }}>
          { currentStep === 0 ?
            <Form style={{minHeight: '300px'}} onSubmit={this.handleSubmit}>
              <Spin spinning={putREQUEST} >
                <Row gutter={8}>
                  <Col span={8}>
                    <FormItem>
                      <Row>
                        <label className="form-label-custom">Name</label>
                      </Row>
                      <Row >
                        {getFieldDecorator('name', {
                          rules: [{ required: true, message: 'Please enter a name!' }],
                        })(
                          <Input />
                        )}
                      </Row>
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem>
                      <Row>
                        <label className="form-label-custom">Business name</label>
                      </Row>
                      <Row >
                        {getFieldDecorator('business_name', {
                          rules: [{ required: true, message: 'Please enter a business name!' }],
                        })(
                          <Input />
                        )}
                      </Row>
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem>
                      <Row>
                        <label className="form-label-custom">Type of privacy</label>
                      </Row>
                      <Row >
                        {getFieldDecorator('is_public', {
                          rules: [{ required: true, message: 'Please choose privacy type!' }],
                        })(
                          <Select>
                            <Option value={1}>Public</Option>
                            <Option value={0}>Private</Option>
                          </Select>
                        )}
                      </Row>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={12}>
                    <FormItem>
                      <Row>
                        <label className="form-label-custom">Type of partner</label>
                      </Row>
                      <Row >
                        {getFieldDecorator('partner_type_id', {
                          rules: [{ required: true, message: 'Please choose type!' }],
                        })(
                          <Select>
    							          {types.partner_types.map((partner_type, key) => <Option key={key} value={partner_type.id}>{partner_type.title}</Option>) }
    							        </Select>
                        )}
                      </Row>
                    </FormItem>
                  </Col>
                  
                  <Col span={12}>
                    <FormItem>
                      <Row>
                        <label className="form-label-custom">How did they find us?</label>
                      </Row>
                      <Row >
                        {getFieldDecorator('customer_source_id', {
                          rules: [{ required: true, message: 'Please choose the way' }],
                        })(
                          <Select>
    							          {types.customer_sources.map((customer_source, key) => <Option key={key} value={customer_source.id}>{customer_source.title}</Option>) }
    							        </Select>
                        )}
                      </Row>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={12}>
                    <FormItem>
                      <Row>
                        <label className="form-label-custom">Delay notifications</label>
                      </Row>
                      <Row >
                        {getFieldDecorator('delay_notifications', {
                          rules: [{ required: true, message: '' }], initialValue: 1
                        })(
                          <Select>
                            <Option value={1} style={{color: 'green'}}>On</Option>
                            <Option value={0} style={{color: 'red'}}>Off</Option>
                          </Select>
                        )}
                      </Row>
                    </FormItem>
                  </Col>
                </Row>
                <FormItem>
                  <Row gutter={8}>
                    <Col span={24}>
                      <Row>
                        <label className="form-label-custom">Address</label>
                      </Row>
                      <Row>
                        {getFieldDecorator('addressGroup', {
                          initialValue: { address: organisation.address, addressComponents: organisation.address_components, coordinates: organisation.coordinates, correct: true },
                          rules: [{ validator: this.checkAddress }]
                        })(
                          <AddressField />
                        )}
                      </Row>
                    </Col>
                  </Row>
                </FormItem>
                <FormItem>
                  <Row gutter={8}>
                    <Col span={24}>
                      <Row>
                        <label className="form-label-custom">Contacts</label>
                      </Row>
                      <Row>
                        <Row gutter={8}>
                          <Col span={8}>
                            <Row>
                              <FormItem>
                                {getFieldDecorator(`contacts.name`, {
                                  validateTrigger: ['onChange', 'onBlur'],
                                  rules: [{
                                    required: false
                                  }],
                                })(
                                  <Input placeholder="Name" />
                                )}
                                </FormItem>
                            </Row>
                          </Col>
                          <Col span={8}>
                            <Row>
                              <FormItem>
                              {getFieldDecorator(`contacts.phone`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [{
                                  required: false
                                }],
                              })(
                                <Input type="tel"  placeholder="Phone" />
                              )}
                              </FormItem>
                            </Row>
                          </Col>
                          <Col span={8}>
                            <Row>
                              <FormItem>
                                {getFieldDecorator(`contacts.email`, {
                                  validateTrigger: ['onChange', 'onBlur'],
                                  rules: [{
                                    required: false
                                  }],
                                })(
                                  <Input type="email" placeholder="Email"/>
                                )}
                                </FormItem>
                            </Row>
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </FormItem>
                <FormItem >
                  <Row style={{float: 'right'}}>
                    <Button type="primary" htmlType="submit">
                      Add venue
                    </Button>
                  </Row>
                </FormItem>
              </Spin>
            </Form>
          : null }
          {
            currentStep === 1 ? 
            <div>
              <Row gutter={8}>
                <Col span={5}>
                  <h1 className="table-title">Add venues</h1>
                </Col>
                <Col span={16}>
                  <Button type="primary" size="large" onClick={ () => { this.modalVisible('visibleAddVenue', true) } }>
                    Add venue
                  </Button>
                  { visibleAddVenue ? <AddVenue organisationId={organisationId} eventCreated={this.eventCreated} visible={visibleAddVenue} history={this.props.history} handleClose={ () => { this.modalVisible('visibleAddVenue', false) } } /> : null }
                </Col>
              </Row>
              <Row>
                <VenueList list={venueList} />
              </Row>
              <Row>
                <div style={{float: 'right'}}>
                  <Button type="primary" onClick={this.goToOrg} >
                    Finish
                  </Button>
                </div>
              </Row>
            </div>
            : null 
          }
        </div>
      </Modal>
    )
  }
}

export default Form.create()(AddOrg);
