import React from 'react';
import Tabletop from 'tabletop';
import _ from 'lodash';
import { Layout, Button } from 'antd';

const { Content } = Layout;

class UserApp extends React.Component {
  state = {
    dictionary: '',
    loading: true,
    dictionaryJA: ''
  }
  componentDidMount() {
    document.title = 'User App';
    this.getDictionary()
  }
  
  getDictionary() {
    // 1JyAQODb1xxNjIBWMydwadR6PudiIVfVK6zqXlSHgysc
    const spreadsheetId = '1JyAQODb1xxNjIBWMydwadR6PudiIVfVK6zqXlSHgysc';
    Tabletop.init({
      key: spreadsheetId, 
      callback: (data, tabletop) => {
        const dataDictionary = data;
        console.log('dataDictionary', dataDictionary);
        //const sheetName = Object.keys(dataDictionary)[0];
        const sheet = dataDictionary['dictionary'];
        const groupByKey = _.chain(sheet.elements)
          .groupBy('key')
          .value();
        let dictionary = {}; 
        Object.keys(groupByKey).forEach(key => {
          dictionary[key] = groupByKey[key][0].value;
        })
        console.log('dictionary', dictionary);
        dictionary = JSON.stringify(dictionary, null, 2);
        
        const sheet1 = dataDictionary['ja'];
        const groupByKey1 = _.chain(sheet1.elements)
          .groupBy('key')
          .value();
        let dictionaryJA = {}; 
        Object.keys(groupByKey1).forEach(key => {
          dictionaryJA[key] = groupByKey1[key][0].value;
        })
        console.log('dictionaryJA', dictionaryJA);
        //this.downloadDictionaryJP(dictionaryJA);

        dictionaryJA = JSON.stringify(dictionaryJA, null, 2);
        
        this.setState({
          dictionary,
          loading: false,
          dictionaryJA
        })
      }
    });
  }
  downloadDictionaryJP = (dictionary) => {
    const a = document.createElement("a");
    let source = dictionary;
    const content = `const dictionary = ${source};\nexport default dictionary;`;
    const file = new Blob([content], {type: 'application/javascript'});
    a.href = URL.createObjectURL(file);
    const currentdate = new Date(); 
    const datetime = currentdate.getDate() + "/"
                    + (currentdate.getMonth()+1)  + "/" 
                    + currentdate.getFullYear() + " @ "  
                    + currentdate.getHours() + ":"  
                    + currentdate.getMinutes() + ":" 
                    + currentdate.getSeconds();

    a.download = 'dictionary '+datetime+'.js';
    a.click();
  }
  downloadDictionary = (locale) => {
    const { dictionary, dictionaryJA } = this.state;
    const a = document.createElement("a");
    let source = dictionary;
    if (locale === 'ja') {
      source = dictionaryJA;
    }
    const content = `const dictionary = ${source};\nexport default dictionary;`;
    const file = new Blob([content], {type: 'application/javascript'});
    a.href = URL.createObjectURL(file);
    const currentdate = new Date(); 
    const datetime = currentdate.getDate() + "/"
                    + (currentdate.getMonth()+1)  + "/" 
                    + currentdate.getFullYear() + " @ "  
                    + currentdate.getHours() + ":"  
                    + currentdate.getMinutes() + ":" 
                    + currentdate.getSeconds();

    a.download = 'dictionary '+datetime+'.js';
    a.click();
  }
  render() {
    const { loading } = this.state;
    return (
      <Content className="fill-bg-table">
        <Button disabled={loading} onClick={() => this.downloadDictionary('en')}>Download dictionary EN</Button>
        <Button disabled={loading} onClick={() => this.downloadDictionary('ja')}>Download dictionary JA</Button>
      </Content>
    )
  }
};

export default UserApp;
