import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Button, Alert, Select, message, Row, Col } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const FormItem = Form.Item;
const { Option } = Select;

class AddUser extends React.Component {
	state = {
    putREQUEST: false,
    putFAILURE: false,
    putSUCCESS: false,
    putMessage: '',
    warehousesList: []
  }
  componentDidMount() {
    this.getWarehousesList();
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({
        putREQUEST: true
      })
      fetchJSON('/api/v1/organisation/createUser', {
        method: 'PUT',
        body: fieldsValue
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false
          }, () => {
          	this.props.renderList();
          	this.props.handleClose();
          });
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false
          });
        }
       }).catch(error => {
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });
    })
  }
  getWarehousesList = () => {
    const { organisationId } = this.props;
    fetchJSON(`/api/v1/organisation/warehouses/${organisationId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      this.setState({
        warehousesList: response.data.warehouses,
        isFetching: false
      });
      if (!response.data.warehouses || !response.data.warehouses.length) {
        message.error("The organization doesn't have warehouses. Create one first on tab 'Warehouses'")
      }
    }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false
      });
    });
  }
  render() {
    const { putFAILURE, warehousesList } = this.state; 
  	const { visible, handleClose, form, organisationId, platform } = this.props;
  	const { getFieldDecorator } = form;
    return (
    	<Modal
        style={{ top: 20 }}
        visible={ visible }
        width={600}
        title="Add user"
        onOk={ handleClose }
        onCancel={ handleClose }
        footer={false}
      >
	      <Form layout="vertical" onSubmit={this.handleSubmit}>
          <FormItem>
          { putFAILURE ? <Alert message="This user already exists." type="error" /> : null }
          </FormItem>
	      	<div style={{'display': 'none'}}>
            {getFieldDecorator('organisationId', {
              rules: [{ required: false, message: 'Please enter a title!' }],
              initialValue: organisationId
            })(
              <Input />
            )}
          </div> 
          <div style={{'display': 'none'}}>
            {getFieldDecorator('platform', {
              rules: [{ required: false, message: 'Please enter a title!' }],
              initialValue: platform
            })(
              <Input />
            )}
          </div> 
          <Row gutter={8}>
            <Col sm={12}>
              <FormItem label="Name">
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Please enter a name!' }],
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col sm={12}>
              <FormItem label="Phone">
                {getFieldDecorator('phone', {
                  rules: [{ required: false, message: 'Please enter a phone!' }],
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
          <FormItem label="Email">
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please enter an email!' }],
            })(
              <Input />
            )}
          </FormItem>
          <FormItem label="Warehouse">
            {getFieldDecorator('directoryId', {
              rules: [{ required: true, message: 'Please choose a warehouse!' }],
            })(
             <Select>
              {warehousesList.map(item => (
                <Option key={item.id}>{item.title}</Option>
              ))}
             </Select>
            )}
          </FormItem>
          { platform === 'nebula' ?
            <Row gutter={8}>
              <Col span={8}>
                <FormItem label="Surveys reports">
                  {getFieldDecorator('reports_surveys', {
                    rules: [{ required: false, message: '' }],
                    initialValue: 0
                  })(
                    <Select
                      style={{ width: '100%' }}
                      placeholder="Please select"
                    >
                      <Option key={0} value={1} style={{color: 'green'}}>On</Option>
                      <Option key={1} value={0} style={{color: 'red'}}>Off</Option>
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="Trends reports">
                  {getFieldDecorator('reports_trends', {
                    rules: [{ required: false, message: '' }],
                    initialValue: 0
                  })(
                    <Select
                      style={{ width: '100%' }}
                      placeholder="Please select"
                    >
                      <Option key={0} value={1} style={{color: 'green'}}>On</Option>
                      <Option key={1} value={0} style={{color: 'red'}}>Off</Option>
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="Users section">
                  {getFieldDecorator('show_users_tab', {
                    rules: [{ required: false, message: '' }],
                    initialValue: 0
                  })(
                    <Select
                      style={{ width: '100%' }}
                      placeholder="Please select"
                    >
                      <Option key={0} value={1} style={{color: 'green'}}>On</Option>
                      <Option key={1} value={0} style={{color: 'red'}}>Off</Option>
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row> : null }
          { !warehousesList.length ? <Alert message="The organization doesn't have warehouses. Create one first on tab 'Warehouses'" type="error" /> : null }
          <Button type="primary" htmlType="submit" style={{marginTop: '20px'}}>Submit</Button>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(AddUser);
