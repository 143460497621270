import React from 'react';

import {
  ArrowDownOutlined,
  DeleteOutlined,
  GoogleOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
  Layout,
  Input,
  Card,
  Button,
  Row,
  Alert,
  Col,
  Select,
  Modal,
  message,
  Radio,
  DatePicker,
  Spin,
} from 'antd';
import Notes from 'screens/Dashboard/components/Notes/Notes';
import fetchJSON from 'services/utils/fetchJSON';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import AddressField from './components/AddressField/AddressField';
import GroupSupernovas from './components/GroupSupernovas';
import VenueImages from './components/VenueImages';
import WorkingHours from './components/WorkingHours';
import Contacts from './components/Contacts';
import Pricing from './components/Pricing';
import NetworkProfiles from 'screens/Dashboard/components/NetworkProfiles/NetworkProfiles';
import qs from 'qs';
import moment from 'moment';
import * as _sharedModules from './modules/_modules';

import './styles.less';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Content } = Layout;
const Option = Select.Option;
const confirm = Modal.confirm;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const { RangePicker } = DatePicker;

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const tabList = [{
  key: 'info',
  tab: 'Info',
}, {
  key: 'groups',
  tab: 'Location IDs',
}, {
  key: 'network_settings',
  tab: 'Network profiles',
}, {
  key: 'pricing_features',
  tab: 'Pricing',
}];

class SingleVenue extends React.Component {
  constructor() {
    super();
    this.state = {
      isFetching: false,
      errorMessage: '',
      visibleNotes: false,
      visibleContacts: false,
      ignoredContacts: [],
      venueUuid: '',
      venue: {
        working_hours: {},
        timezone: '',
        place_id: '',
        currency_code: '',
        opening_hours: {},
        country_code: '',
        is_demo: 0,
        supernovas_lights: 1
      },
      contacts_list: [],
      all_contacts: [],
      putREQUEST: false,
      putFAILURE: false,
      putSUCCESS: false,
      activeTab: '',
      currentStep: 0,
      orgList: [],
      businessHours: [],
      opening_hours: {},
      formatted_address: '',
      venue_id: null,
      location_core: 'permanent',
      temporaryCoreSettings: [],
      types: [],
      business_categories: [],
      business_info: [],
      showSubcategory: false
    }
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
  }
  componentDidMount() {
    this.getOrgList();
    this.getBusinessTypes();
    const venueUuid = this.props.match.params.venueUuid;

    let activeTab = 'info';
    let paramFromUrl = {};
    if (window.location.search) {
      paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    }
    if (Object.keys(paramFromUrl).length) {
      Object.keys(paramFromUrl).forEach((param) => {
        if (paramFromUrl[param]) {
          activeTab = param;
        }
      });
    }

    this.setState({
      activeTab: activeTab,
      venueUuid
    }, this.getSingleVenue)

  }
  getOrgList = () => {
    this.setState({
      isFetching: true
    })
    fetchJSON('/api/v1/organisation/list', {
      method: 'get'
    }).then(response => {
      // console.log(response);
      if (response.success) {
        this.setState({
          isFetching: false,
          orgList: response.data
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  getSingleVenue = () => {
    this.setState({
      isFetching: true
    })
    const { venueUuid } = this.state;
    fetchJSON(`/api/v1/venue/${venueUuid}`, {
      method: 'get'
    }).then(response => {
      //console.log(response);
      if (response.success && response.data) {
        const is_ID = /^\d+$/.test(venueUuid);
        if (is_ID) {
          // redirect to uuid now!
          this.setState({
            venueUuid: response.data.uuid
          });
          this.props.history.push(`/venues/${response.data.uuid}`);
        }
        const temporaryCoreSettings = [];
        if (response.data.temporaryCoreSettings && response.data.temporaryCoreSettings.length) {
          response.data.temporaryCoreSettings.forEach((item) => {
            temporaryCoreSettings.push({ id: item.id, dates: [moment(item.valid_from), moment(item.valid_to)] })
          });
        }
        this.setState({
          isFetching: false,
          venue: response.data,
          venue_id: response.data.id,
          opening_hours: response.data.opening_hours || {},
          location_core: response.data.location_core,
          temporaryCoreSettings: temporaryCoreSettings,
          formatted_address: response.data.formatted_address
        }, () => {
          // businessHours: response.data.working_hours
          this.getContactsList();
          if (response.data.working_hours) {
            if (Array.isArray(response.data.working_hours)) {
              this.setState({
                businessHours: response.data.working_hours
              });
            }
          }
          if (response.data.contacts_list) {
            if (response.data.contacts_list.length) {
              const contacts_list = response.data.contacts_list.map(item => {
                return item.contact;
              });
              if (contacts_list.length) {
                this.setState({
                  contacts_list
                })
              }
            }
          }
          setTimeout(() => {
            if (this.props.form && response.data.business_category_id && Object.keys(this.state.business_info).length) {
              this.sortBusinessCategories(response.data.business_type_id, response.data.business_category_id, response.data.business_subcategory_id)
            }
          }, 500)
        });
      } else {
        window.location.href = "/venues";
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  showNotes = () => {
    this.setState({
      visibleNotes: true
    })
  }
  hideNotes = () => {
    this.setState({
      visibleNotes: false
    })
  }
  checkAddress = (rule, value, callback) => {
    console.log('checkAddress', value);

    if (!value.correct) {
      callback('Select correct address!');
      return;
    }

    if (value.address) {
      const opening_hours = _.get(value, 'opening_hours');
      this.adjustGoogleHours(opening_hours);
      this.setState({
        formatted_address: value.formatted_address
      })
      callback();
      return;
    }
    callback('Please enter address!');
  }
  timeStringToDate = (timeString) => {
    const result = {};
    if (timeString.length === 9) {
      const times = timeString.split('-');
      const start = moment(times[0], 'HHmm');
      const end = moment(times[1], 'HHmm');
      result.startObj = start;
      result.endObj = end;
      result.start = start.format('hh:mm A');
      result.end = end.format('hh:mm A');
    }
    return result;
  }
  adjustGoogleHours = (opening_hours) => {
    this.setState({
      opening_hours
    });
    if (!opening_hours) {
      return;
    }
    if (typeof opening_hours === 'object') {
      if (Object.keys(opening_hours).indexOf('periods') > -1) {
        const { periods } = opening_hours;
        console.log('adjustGoogleHours', periods);
        if (Array.isArray(periods) && periods.length) {
          const state = {
            week: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
            availableWeekDays: [],
            slotTemplate: {
              working_days: [],
              working_hours: {
                start: '9:00 AM',
                end: '6:00 PM'
              },
              break_hours: {
                start: '12:00 PM',
                end: '1:00 PM'
              },
              break_hours_enabled: 0,
              always_open: false
            }
          };
          const businessHours = [];
          if (periods.length === 1) {
            const day = _.get(periods, '0.open.day');
            const open = _.get(periods, '0.open.time');
            const close = _.get(periods, '0.close.time');
            if (day === 0 && open === '0000' && !close) {
              businessHours.push({
                working_days: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
                working_hours: {
                  start: '12:00 AM',
                  end: '11:59 PM'
                },
                break_hours: {
                  start: '12:00 PM',
                  end: '1:00 PM'
                },
                break_hours_enabled: 0,
                always_open: true
              });
            }
          } else {
            const listOfDays = {};
            periods.forEach(period => {
              const day = _.get(period, 'open.day');
              const open = _.get(period, 'open.time');
              const close = _.get(period, 'close.time');
              if (open && close) {
                if (Object.keys(listOfDays).indexOf(day.toString()) === -1) {
                  listOfDays[day.toString()] = [];
                }
                listOfDays[day.toString()].push(`${open}-${close}`);
              }
            });
            let listOfTimes = {};

            Object.keys(listOfDays).forEach((key) => {
              if (listOfDays[key].length === 2) {
                const i = `${listOfDays[key][0]}=${listOfDays[key][1]}`
                if (Object.keys(listOfTimes).indexOf(i) === -1) {
                  listOfTimes[i] = [];
                }
                listOfTimes[i].push(parseInt(key, 10));
                delete listOfDays[key];
              }
            });
            Object.keys(listOfTimes).forEach(key => {
              if (key.length === 19 && listOfTimes[key].length) {
                const period = key.split('=');
                const time1 = this.timeStringToDate(period[0]);
                const time2 = this.timeStringToDate(period[1]);
                const newSlot = JSON.parse(JSON.stringify(state.slotTemplate));

                newSlot.working_hours.start = time1.start;
                newSlot.working_hours.end = time2.end;
                newSlot.break_hours.start = time1.end;
                newSlot.break_hours.end = time2.start;
                newSlot.break_hours_enabled = true;

                listOfTimes[key].forEach((day) => {
                  newSlot.working_days.push(state.week[day]);
                });
                businessHours.push(newSlot);
              }
            });            
            listOfTimes = {};
            Object.keys(listOfDays).forEach((key) => {
              if (listOfDays[key].length === 1) {
                if (Object.keys(listOfTimes).indexOf(listOfDays[key][0]) === -1) {
                  listOfTimes[listOfDays[key][0]] = [];
                }
                listOfTimes[listOfDays[key][0]].push(parseInt(key, 10));
                delete listOfDays[key];
              }
            });
            Object.keys(listOfTimes).forEach(key => {
              if (key.length === 9 && listOfTimes[key].length) {
                const time = this.timeStringToDate(key);
                const newSlot = JSON.parse(JSON.stringify(state.slotTemplate));
                newSlot.working_hours.start = time.start;
                newSlot.working_hours.end = time.end;
                listOfTimes[key].forEach((day) => {
                  newSlot.working_days.push(state.week[day]);
                });
                businessHours.push(newSlot);
              }
            });
          }
          if (businessHours.length) {
            console.log('businessHours', businessHours);
            this.setState({
              businessHours
            });
          }
        }
      }
    }
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      const { showSubcategory } = this.state;
      if (!showSubcategory) {
        fieldsValue.business_subcategory_id = null;
      }
      fieldsValue.working_hours = this.state.businessHours;
      fieldsValue.formatted_address = this.state.formatted_address;

      if (fieldsValue.temporaryCoreSettings) {
        fieldsValue.temporaryCoreSettings.forEach((time) => {
          time.valid_from = time.dates[0].startOf('day').format('YYYY-MM-DD');
          time.valid_to = time.dates[1].endOf('day').format('YYYY-MM-DD');
        });
      } 

      console.log('fieldsValue', fieldsValue);

      this.setState({
        putREQUEST: true
      });
      fetchJSON(`/api/v1/venue/${this.state.venueUuid}`, {
        method: 'post',
        body: fieldsValue
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false,
            //errorMessage: 'Saved successfully'
          }, this.getSingleVenue);
          message.success('Saved successfully');
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
            //errorMessage: response.message
          });
          message.error(response.message);
        }
       }).catch(error => {
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
          message.error('ERROR');
       });
    })
  }
  onTabChange = (key, type) => {
    const { venueUuid } = this.state;
    this.props.history.push(`/venues/${venueUuid}?${key}=1`);
    this.setState({ [type]: key });
  }
  deleteVenue = () => {
    const { venue_id } = this.state;
    confirm({
      title: 'Are you sure you want to delete this venue?',
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            fetchingStatus: true
          })
          fetchJSON(`/api/v1/venue/${venue_id}`, {
            method: 'delete'
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                fetchingStatus: false,
              });
              window.location.href = "/venues";
              resolve();
            } else {
              this.setState({
                fetchingStatus: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                fetchingStatus: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  removeContact = (index) => {
    const { contacts_list } = this.state;
    const newList = contacts_list.filter((contact, i) => {
      return i !== index;
    });

    this.setState({
      contacts_list: newList
    });
  }
  deleteContact = (index, contact_id) => {
    confirm({
      title: 'Are you sure you want to delete contact?',
      onOk: () => {
        return new Promise((resolve, reject) => {
          const { contacts_list } = this.state;
          const item = contacts_list[index];
          if (item.id === 'new' || (typeof item.id === 'string' && item.id.indexOf('from_book_') > -1)) {
            if (item.id.indexOf('from_book_') > -1) {
              let { ignoredContacts } = this.state;
              const idIndex = ignoredContacts.indexOf(Number(item.id.substr(10)));
              if (idIndex > -1) {
                ignoredContacts.splice(idIndex, 1);
                this.setState({
                  ignoredContacts
                })
              }
            }
            this.removeContact(index);
            resolve();
            return;
          };

          this.setState({
            fetchingStatus: true
          })
          fetchJSON(`/api/v1/contact/${contact_id}`, {
            method: 'delete'
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                fetchingStatus: false,
              });
              this.removeContact(index);
              resolve();
            } else {
              this.setState({
                fetchingStatus: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                fetchingStatus: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  addContact = () => {
    const { contacts_list } = this.state;
    const newItem = {id: 'new', name: '', phone: '', email: '', note: '', office_phone: '', tag:'' };
    contacts_list.push(newItem);
    this.setState({
      contacts_list
    });
  }
  addContactFromBook = (id, name, phone, email, note, office_phone, tag) => {
    const { contacts_list, ignoredContacts } = this.state;
    const newItem = {id: `from_book_${id}`, name: name, phone: phone, email: email, note: note, office_phone: office_phone, tag: tag};
    contacts_list.push(newItem);
    ignoredContacts.push(id)
    this.setState({
      contacts_list,
      ignoredContacts
    });
    this.hideContacts();
  }
  getContactsList = () => {
    this.setState({
      isFetching: true
    })
    fetchJSON(`/api/v1/contact/list/${this.state.venue.organisation.organisation_id}`, {
      method: 'put',
      body: {
        venueId: this.state.venue_id
      }
    }).then(response => {
      //console.log(response);
      if (response.success && response.data) {
        this.setState({
          isFetching: false,
          all_contacts: response.data
        })
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  showContacts = () => {
    this.setState({
      visibleContacts: true
    })
  }
  hideContacts = () => {
    this.setState({
      visibleContacts: false
    })
  }
  setBusinessHours = (params, callback = () => {}) => {
    this.setState(params, callback);
  }
  addNewTimeSpot = () => {
    const { temporaryCoreSettings } = this.state;

    const copyIt = [...temporaryCoreSettings];
    copyIt.push({ id: 'new', 'dates': [] });

    this.setState({
      temporaryCoreSettings: copyIt
    });
  }
  handleCoreChange = (value) => {
    this.setState({
      location_core: value.target.value
    }, () => {
      const { temporaryCoreSettings } = this.state;
      if (!temporaryCoreSettings.length) {
        this.addNewTimeSpot();
      }
    });
  }
  disabledDate = (current, current_index) => {
    const { form } = this.props;
    const temporaryCoreSettings = form.getFieldValue('temporaryCoreSettings');
    if (temporaryCoreSettings && Array.isArray(temporaryCoreSettings) && temporaryCoreSettings.length) {
      let notValid = false;
      for (let i = 0; i < temporaryCoreSettings.length; i++) {
        let item = temporaryCoreSettings[i];
        if (i !== current_index) {
          if (item.dates && Array.isArray(item.dates) && item.dates.length === 2) {
            if (current && current.isBetween(item.dates[0].startOf('day'), item.dates[1].endOf('day'))) {
              notValid = true;
              break;
            }
          }
        }
      }
      if (notValid) {
        return notValid;
      }
    }
    
    // Can not select days before today and today
    return current && current < moment().subtract(2, 'd').endOf('day');
  }
  
  render() {
    const {
      isFetching,
      errorMessage,
      visibleNotes,
      venue,
      venue_id,
      putFAILURE,
      activeTab,
      orgList,
      contacts_list,
      businessHours,
      opening_hours,
      visibleContacts,
      all_contacts,
      ignoredContacts,
      location_core,
      temporaryCoreSettings,
      venueUuid,
      showSubcategory
    } = this.state;
    const { form } = this.props;
    
    const { getFieldDecorator } = form;
    const isContactsBook = (all_contacts.organisationContacts && all_contacts.organisationContacts.length) || ( all_contacts.venuesContacts && all_contacts.venuesContacts.length);
    const canUseGoogleOpenHours = _.get(opening_hours, 'can_use_google');
    return (
      <Content className="fill-bg-table">
        { visibleNotes ? <Notes visible={visibleNotes} handleClose={this.hideNotes} sourceId={venue_id} source="venue" /> : null }
        <Card
          title={<h3 style={{marginBottom: '0px'}}>{venue.title}</h3>}
          tabList={tabList}
          activeTabKey={activeTab}
          onTabChange={(key) => { this.onTabChange(key, 'activeTab'); }}
          extra={
            <div>
              <Button onClick={ this.showNotes } style={{'marginRight': '10px'}}>
                <QuestionCircleOutlined /> Notes
              </Button>
              <Button style={{'marginRight': '10px'}} type="danger" onClick={ this.deleteVenue } >
                <DeleteOutlined /> Delete
              </Button>
            </div>
          }
          >
          <Spin spinning={isFetching}>
          { activeTab === 'info' ?
          <Form style={{minHeight: '300px'}} onSubmit={this.handleSubmit}>
            { errorMessage ? 
              <FormItem><Alert message={errorMessage ? errorMessage : 'Something went wrong, repeat'} type={ putFAILURE ? "error" : "success"}/></FormItem>
            : null }
            <Row gutter={8}>
              <Col span={12}>
                <FormItem {...formItemLayout}>
                  <Row>
                    <label className="form-label-custom">Name</label>
                  </Row>
                  <Row>
                    {getFieldDecorator('title', {
                      rules: [{ required: true, message: 'Please enter a title!' }],
                      initialValue: venue.title
                    })(
                      <Input />
                    )}
                  </Row>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem {...formItemLayout}>
                <Row>
                  <label className="form-label-custom">Status</label>
                </Row>
                <Row >
                  {getFieldDecorator('status', {
                    rules: [{ required: true, message: 'Please enter status of venue!' }],
                    initialValue: venue.status+''
                  })(
                     <Select>
                        <Option value="1">Launched</Option>
                        <Option value="0" style={{color: 'blue'}}>Inactive</Option>
                        <Option value="2" style={{color: 'red'}}>Blocked</Option>
                    </Select>
                  )}
                </Row>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={6}>
                <FormItem {...formItemLayout}>
                <Row>
                  <label className="form-label-custom">Demo Lights</label>
                </Row>
                <Row>
                  {getFieldDecorator('supernovas_lights', {
                    rules: [{ required: true, message: 'Please enter demo lights of venue!' }],
                    initialValue: venue.supernovas_lights+''
                  })(
                     <Select>
                        <Option value="1">On</Option>
                        <Option value="0">Off</Option>
                    </Select>
                  )}
                </Row>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem {...formItemLayout}>
                <Row>
                  <label className="form-label-custom">Lights interval</label>
                </Row>
                <Row>
                  {getFieldDecorator('is_demo', {
                    rules: [{ required: true, message: 'Please enter demo lights of venue!' }],
                    initialValue: venue.is_demo+''
                  })(
                     <Select>
                        <Option value="1">Small</Option>
                        <Option value="0">Regular</Option>
                    </Select>
                  )}
                </Row>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8} style={{display: 'none'}}>
              <Col span={24}>
                <FormItem
                  {...formItemLayout}
                >
                  <Row>
                    <label className="form-label-custom">Description</label>
                  </Row>
                  <Row >
                    {getFieldDecorator('description', {
                      rules: [{ required: false, message: 'Please enter a description!' }],
                      initialValue: venue.description
                    })(
                      <TextArea autosize={{ minRows: 1, maxRows: 6 }} />
                    )}
                  </Row>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <FormItem>
                  <Row>
                    <label className="form-label-custom">Organization</label>
                    {venue.organisation ? <Link to={`/organisations/${venue.organisation.organisation_id}`} target="_blank" className="ant-btn ant-btn-sm">
                      Open in new window
                    </Link> : null }
                  </Row>
                  <Row >
                    {getFieldDecorator('organisationId', {
                      rules: [{ required: true, message: 'Please choose a organisation!' }],
                      initialValue: venue.organisation ? venue.organisation.organisation_id : ''
                    })(
                      <Select disabled = {true}>
                        { orgList.map((org, key) => <Option key={key} value={org.id}>{org.name}</Option>) }
                      </Select>
                    )}
                  </Row>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem >
                  <Row>
                    <label className="form-label-custom">Type of venue</label>
                  </Row>
                    {getFieldDecorator('is_public', {
                      rules: [{ required: true, message: 'Please choose type of venue!' }],
                      initialValue: venue.is_public
                    })(
                      <Select>
                        <Option value={1}>Public</Option>
                        <Option value={0}>Private</Option>
                      </Select>
                    )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                <FormItem >
                  <Row>
                    <label className="form-label-custom">Type of business</label>
                  </Row>
                    {getFieldDecorator('business_type_id', {
                      rules: [{ required: true, message: 'Please choose business type!' }],
                      initialValue: venue.business_type_id
                    })(
                      <Select placeholder="Select location type" onChange={this.sortBusinessCategories}>
                        {this.renderBusinessTypesList()}
                      </Select>
                    )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem >
                  <Row>
                    <label className="form-label-custom">Type of location</label>
                  </Row>
                    {getFieldDecorator('business_category_id', {
                      rules: [{ required: true, message: 'Please choose business category!' }],
                      initialValue: venue.business_category_id
                    })(
                      <Select placeholder="Select location category">
                        {this.renderBusinessCategoriesList()}
                      </Select>
                    )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem style={{display: showSubcategory ? '' : 'none'}}>
                  <Row>
                    <label className="form-label-custom">Subtype of location</label>
                  </Row>
                    {getFieldDecorator('business_subcategory_id', {
                      rules: [{ required: showSubcategory ? true : false, message: 'Please choose business subcategory!' }],
                      initialValue: venue.business_subcategory_id
                    })(
                      <Select placeholder="Select location subcategory">
                        {this.renderBusinessSubcategoriesList()}
                      </Select>
                    )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <FormItem >
                  <Row>
                    <label className="form-label-custom">Venue state</label>
                  </Row>
                    {getFieldDecorator('location_core', {
                      rules: [{ required: true, message: 'Please choose state of venue!' }],
                      initialValue: venue.location_core
                    })(
                      <RadioGroup onChange={this.handleCoreChange}>
                        <RadioButton value='permanent'>Permanent</RadioButton>
                        <RadioButton value='temporary'>Temporary</RadioButton>
                      </RadioGroup>
                    )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              { location_core === 'temporary' ? 
                <React.Fragment>
                  <Col span={7}>
                    {temporaryCoreSettings.length ? 
                      <React.Fragment>
                        <Row>
                          <label className="form-label-custom">Valid dates from-to</label>
                        </Row>
                        {temporaryCoreSettings.map((item, index) => <React.Fragment key={index}>
                          <FormItem>
                            <div style={{display: 'none'}}>
                              {getFieldDecorator(`temporaryCoreSettings[${index}].id`, {
                                initialValue: item.id
                              })(
                                <Input />
                              )}
                            </div>
                          </FormItem>
                          <FormItem>
                            <Row gutter={8}>
                              {getFieldDecorator(`temporaryCoreSettings[${index}].dates`, {
                                rules: [{ type: 'array', required: true, message: 'Please select dates!' }],
                                initialValue: item.dates //moment(venue.valid_from), moment(venue.valid_to)
                              })(
                                <RangePicker disabledDate={(current) => this.disabledDate(current, index)} />
                              )}
                              <Button onClick={this.addNewTimeSpot} style={{
                                display: 'none', //temporaryCoreSettings.length === (index+1) ? null : 'none',
                                marginLeft: '8px'
                              }}>Add</Button>
                            </Row>
                          </FormItem>
                        </React.Fragment>)}
                      </React.Fragment>
                    : null }
                  </Col>
                </React.Fragment>
              : null }
            </Row>
            <FormItem
                {...formItemLayout}
              >
              <Row gutter={8}>
                <Col span={24}>
                  <Row>
                    <label className="form-label-custom">Address</label>
                  </Row>
                  { venue.coordinates ? <Row >
                    {getFieldDecorator('addressGroup', {
                      initialValue: {
                        address: venue.address,
                        addressComponents: venue.address_components,
                        coordinates: venue.coordinates,
                        timezone: venue.timezone,
                        place_id: venue.place_id,
                        currency_code: venue.currency_code,
                        opening_hours: venue.opening_hours,
                        country_code: venue.country,
                        correct: true,
                        formatted_address: venue.formatted_address
                      },
                      rules: [{ validator: this.checkAddress }]
                    })(
                      <AddressField />
                    )}
                  </Row> : null }
                </Col>
              </Row>
            </FormItem>
            <div>
              <Row gutter={8}>
                <Col span={10}>
                  <Row>
                    <h3>Business hours {canUseGoogleOpenHours ? <span style={{fontSize: '14px', color:'green','paddingLeft': '10px'}}><GoogleOutlined /> From Google Places</span> : null}</h3>
                  </Row>
                  <Row>
                    <WorkingHours
                      businessHours={businessHours}
                      setBusinessHours={this.setBusinessHours}
                    />
                  </Row>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Row>
                    <h3>Contacts</h3>
                  </Row>
                  <Row>
                    { contacts_list.length ? contacts_list.map((contact, index) => {
                      return (
                        <Row gutter={8} key={index}>
                          <Col span={4}>
                            <Row>
                              <div style={{display: 'none'}}>
                                {getFieldDecorator(`contacts_list[${index}].contact_id`, {
                                  validateTrigger: ['onChange', 'onBlur'],
                                  rules: [{
                                    required: true
                                  }],
                                  initialValue: contact.id
                                })(
                                  <Input placeholder="Contact ID" />
                                )}
                              </div>
                              <FormItem
                                  {...formItemLayout}
                                >
                                {getFieldDecorator(`contacts_list[${index}].name`, {
                                  validateTrigger: ['onChange', 'onBlur'],
                                  rules: [{
                                    required: false
                                  }],
                                  initialValue: contact.name
                                })(
                                  <Input placeholder="Name" />
                                )}
                                </FormItem>
                            </Row>
                          </Col>
                          <Col span={3}>
                            <Row>
                              <FormItem
                                  {...formItemLayout}
                                >
                              {getFieldDecorator(`contacts_list[${index}].phone`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [{
                                  required: false
                                }],
                                initialValue: contact.phone
                              })(
                                <Input type="tel"  placeholder="Phone" />
                              )}
                              </FormItem>
                            </Row>
                          </Col>
                          <Col span={3}>
                            <Row>
                              <FormItem
                                  {...formItemLayout}
                                >
                              {getFieldDecorator(`contacts_list[${index}].office_phone`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [{
                                  required: false
                                }],
                                initialValue: contact.office_phone
                              })(
                                <Input type="tel"  placeholder="Office phone" />
                              )}
                              </FormItem>
                            </Row>
                          </Col>
                          <Col span={4}>
                            <Row>
                              <FormItem
                                {...formItemLayout}
                              >
                                {getFieldDecorator(`contacts_list[${index}].email`, {
                                  validateTrigger: ['onChange', 'onBlur'],
                                  rules: [{
                                    required: false
                                  }],
                                  initialValue: contact.email
                                })(
                                  <Input type="email" placeholder="Email"/>
                                )}
                                </FormItem>
                            </Row>
                          </Col>
                          <Col span={2}>
                            <Row>
                              <FormItem
                                  {...formItemLayout}
                                >
                              {getFieldDecorator(`contacts_list[${index}].tag`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [{
                                  required: false
                                }],
                                initialValue: contact.tag
                              })(
                                <Input placeholder="Tag" />
                              )}
                              </FormItem>
                            </Row>
                          </Col>
                          <Col span={2}>
                            <Row>
                              <FormItem
                                  {...formItemLayout}
                                >
                                {getFieldDecorator(`contacts_list[${index}].note`, {
                                  validateTrigger: ['onChange', 'onBlur'],
                                  rules: [{
                                    required: false
                                  }],
                                  initialValue: contact.note
                                })(
                                  <Input placeholder="Note" />
                                )}
                                </FormItem>
                            </Row>
                          </Col>
                          <Col span={6}>
                            <Row>
                              {index + 1 === contacts_list.length && isContactsBook ? <Button style={{float: 'right'}} onClick={ this.showContacts } >
                                <ArrowDownOutlined />Import
                              </Button> : null }
                              {index + 1 === contacts_list.length ? <Button onClick={ this.addContact } style={{float: 'right', marginRight: isContactsBook ? '10px': 0}}>
                                <PlusCircleOutlined /> Add New
                              </Button> : null }
                                                   
                              <Button onClick={ () => this.deleteContact(index, contact.id) } style={{
                                float: 'right',
                                'marginRight': index + 1 === contacts_list.length ? '10px' : 0
                              }} type="danger">
                                <DeleteOutlined /> Delete
                              </Button>

                            </Row>
                          </Col>
                        </Row>
                      );
                    }) : <Row> 
                           <Button onClick={ this.addContact } style={{marginBottom: '20px'}}>
                             <PlusCircleOutlined /> Add New
                           </Button> { isContactsBook ? <Button style={{marginBottom: '20px'}} onClick={ this.showContacts } >
                             <ArrowDownOutlined />Import
                           </Button> : null} 
                         </Row>}
                    { visibleContacts ? <Contacts visible={visibleContacts} handleClose={this.hideContacts} contacts={all_contacts} organisation={this.state.venue.organisation} ignoredContacts={ignoredContacts} addContact={this.addContactFromBook} /> : null }
                  </Row>
                </Col>
              </Row>
            </div>
            { typeof venue.images === 'object' ? <VenueImages images={venue.images} venueId={venue_id} /> : null }
            <FormItem
              {...formItemLayout}
            >
              <Row>
                <Button type="primary" htmlType="submit">
                  Save changes
                </Button>
              </Row>
            </FormItem>
          </Form>
          : null }
          { activeTab === 'groups' ?
            <div>
              <GroupSupernovas
                venueId = {venue_id}
                history={this.props.history}
                renderVenue={this.getSingleVenue}
                orgName={venue.organisation && venue.organisation.organisation ? venue.organisation.organisation.name : ''}
                groups={venue.groups} />
            </div>
           : null }
           { activeTab === 'network_settings' ?
              <NetworkProfiles
                venueId={venue_id}
                groups={venue.groups}
                mode="venue"
              />
           : null }

          { activeTab === 'marketing' ? <p>Marketing settings will be here</p> : null }

           { activeTab === 'analytics' ?
            <p>Analytics on the venue will be here</p>
           : null }
        
          { activeTab === 'pricing_features' ? <Pricing venueUuid={venueUuid} /> : null }
           </Spin>

        </Card>
      </Content>
    );
  }
}

export default Form.create()(SingleVenue);