import React from 'react';
import { DeleteOutlined, QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Input,
  Card,
  Button,
  Row,
  Alert,
  Col,
  Radio,
  Spin,
  Modal,
  Table,
  message,
  Popconfirm,
} from 'antd';
import qs from 'qs';
import Notes from 'screens/Dashboard/components/Notes/Notes';
import fetchJSON from 'services/utils/fetchJSON';
import { Link } from 'react-router-dom';
import NetworkProfiles from 'screens/Dashboard/components/NetworkProfiles/NetworkProfiles';
import _ from 'lodash';
import SendCommand from './SendCommand';
import AssignToGroup from './AssignToGroup';

import HistoryLog from 'screens/Dashboard/components/HistoryLog/HistoryLog';
import { statuses } from './SupernovasTable.jsx';


const FormItem = Form.Item;
const confirm = Modal.confirm;

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};


const tabList = [{
  key: 'supernova',
  tab: 'Supernova',
}, {
  key: 'electrons',
  tab: 'Electrons',
}, {
  key: 'network_settings',
  tab: 'Network profiles',
}, {
  key: 'history_log',
  tab: 'History Log',
}];
class SingleSupernovas extends React.Component {
  state = {
    isFetching: false,
    errorMessage: '',
    visibleNotes: false,
    supernovaId: false,
    supernova: {
      id: '',
      string_id: '',
      supernova: {},
      electrons: [],
      status: '',
      esp_version: '',
      mac_address: '',
      ap_ssid: '',
      ap_pwd: '',
      ada_status: null,
      nest_queue_state: {
        1: 'on',
        2: 'on',
        3: 'on',
        4: 'on',
        5: 'on',
        6: 'on',
        7: 'on'
      }
    },
    chooseVenueVisible: false,
    fetchingStatus: false,
    activeTab: 'supernova',
    rebootLoading: false,
    updateLoading: false,
    machineIdToSend: '',
    visibleShowCommand: false,
    visibleAssignGroup: false
  }
  componentDidMount() {
    const supernovaId = this.props.match.params.supernovaId;
    let activeTab = 'supernova';
    let paramFromUrl = {};
    if (window.location.search) {
      paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    }
    if (Object.keys(paramFromUrl).length) {
      Object.keys(paramFromUrl).forEach((param) => {
        if (paramFromUrl[param]) {
          activeTab = param;
        }
      });
    }
    this.setState({
      activeTab: activeTab,
      supernovaId
    }, this.getSingleSupernova)
  }
  toStringId = (internalId) => {
    fetchJSON(`/api/v1/supernova/convert/${internalId}`, {
      method: 'get'
    }).then(response => {
      console.log('internalId', internalId, 'toStringId RESULT', response);
      if (response.success && response.data) {
        this.props.history.push(`/supernovas/${response.data}`);
        this.setState({
          supernovaId: response.data
        }, this.getSingleSupernova)
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  getSingleSupernova = () => {
    this.setState({
      isFetching: true
    });
    const { supernovaId } = this.state;
    let isStringId = false;
    if (supernovaId.length > 3) {
      isStringId = supernovaId.substring(0, 2) === 'SN';
    }
    if (!isStringId) {
      let checkId = parseInt(supernovaId, 10);
      if (!isNaN(checkId)) {
        return this.toStringId(checkId);
      }
    }
    fetchJSON(`/api/v1/supernova/${this.state.supernovaId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.success && response.data) {
        this.setState({
          isFetching: false,
          supernova: response.data
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  showNotes = () => {
    this.setState({
      visibleNotes: true
    })
  }
  hideNotes = () => {
    this.setState({
      visibleNotes: false
    })
  }
  connectToVenue = () => {
    console.log('connectToVenue');
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  showCommandDialog = (key, value, machineId) => {
    this.setState({
      [key]: value,
      machineIdToSend: machineId
    });
  }
  onStatusChange = (e) => {
    const { supernova } = this.state;
    const status = e.target.value;
    supernova.actual_status = status;
    confirm({
      title: `Change supernova status to "${status}"?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            fetchingStatus: true
          })
          fetchJSON(`/api/v1/supernova/updateStatus/${supernova.id}`, {
            method: 'put',
            body: {
              actual_status: status
            }
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                fetchingStatus: false,
                supernova
              });
              resolve()
            } else {
              this.setState({
                fetchingStatus: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                fetchingStatus: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  onDelete = () => {
    const { supernova } = this.state;
    confirm({
      title: `Are you sure you want to delete this supernova?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            fetchingStatus: true
          })
          fetchJSON(`/api/v1/supernova/delete/${supernova.id}`, {
            method: 'put'
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                fetchingStatus: false,
              });
              window.location.href = "/supernovas";
              resolve()
            } else {
              this.setState({
                fetchingStatus: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                fetchingStatus: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  onADAChange = (e) => {
    const { supernova } = this.state;
    const status = e.target.value;
    supernova.ada_status = status;
    confirm({
      title: `Change ADA status to "${status ? 'ON' : 'OFF'}"?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            fetchingStatus: true
          })
          fetchJSON(`/api/v1/supernova/adaStatus/${supernova.id}`, {
            method: 'put',
            body: {
              ada_status: status
            }
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                fetchingStatus: false,
                supernova
              });
              resolve()
            } else {
              this.setState({
                fetchingStatus: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                fetchingStatus: false
              });
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
    });
  }
  onQueueStatusChange = (e, nestId) => {
    const { supernova } = this.state;
    const state = e.target.value;
    supernova.nest_queue_state[nestId] = state;
    console.log('nestId', nestId, 'state', state);
    confirm({
      title: `Change nest #${nestId} queue state to "${state}"?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            fetchingStatus: true
          });
          fetchJSON(`/api/v1/supernova/updateQueueStatus/${supernova.id}`, {
            method: 'put',
            body: {
              nest_queue_state: supernova.nest_queue_state
            }
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                fetchingStatus: false,
                supernova
              });
              resolve()
            } else {
              this.setState({
                fetchingStatus: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                fetchingStatus: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  onTabChange = (key, type) => {
    const { supernovaId } = this.state;
    this.props.history.push(`/supernovas/${supernovaId}?${key}=1`);
    this.setState({ [type]: key });
  }
  columns = () => {
    return [{
      title: 'ID',
      dataIndex: 'string_id',
      key: 'string_id'
    },
    {
      title: 'Actual status',
      dataIndex: 'actual_status',
      key: 'actual_status',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Link to={"/electrons/"+record.id} target="_blank" className="ant-btn ant-btn-sm" style={{marginRight: '8px'}}>
            Edit
          </Link>
          <Link to={`/electrons/${record.id}?history=1`} target="_blank" className="ant-btn ant-btn-sm">
            History
          </Link>
        </span>
      ),
    }];
  }
  renderElectronsTable = () => {
    const { electrons } = this.state.supernova;
    if (!electrons.length) return [];

    const statusesElectron = {
      'on_factory': 'On factory',
      'active': 'Active',
      'repair': 'Repair',
      'eol': 'EOL',
      'opened': 'Opened',
      'lost': 'Lost'
    };

    return electrons.map((electronObj, index) => {
      let electron = electronObj.electron;
      console.log(electron);
      return {
        key: index,
        id: electron.id,
        actual_status: statusesElectron[electron.actual_status],
        string_id: electron.string_id
      };
    });
  }
  reboot = () => {
    const { supernova } = this.state;
    const command = 'WIFIREBOOT';
    confirm({
      title: `Reboot supernova "${supernova.string_id}"?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            rebootLoading: true
          })
          fetchJSON(`/api/v1/supernova/talk/${supernova.string_id}`, {
            method: 'put',
            body: {
              command
            }
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                rebootLoading: false,
                supernova
              });
              resolve()
            } else {
              this.setState({
                rebootLoading: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                rebootLoading: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  updateIt = () => {
    const { supernova } = this.state;
    const command = 'UPDATE';
    confirm({
      title: `Send commant ${command} to ${supernova.string_id}?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            updateLoading: true
          })
          fetchJSON(`/api/v1/supernova/talk/${supernova.string_id}`, {
            method: 'put',
            body: {
              command
            }
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                updateLoading: false,
                supernova
              });
              resolve()
            } else {
              this.setState({
                updateLoading: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                updateLoading: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  successResultCommand = () => {
    message.success('Command successfully sent');
  }
  unlinkGroupId = () => {
    this.setState({
      isFetching: true
    });
    const { supernova } = this.state;
    fetchJSON(`/api/v1/supernova/unlinkGroup/${supernova.id}`, {
      method: 'put',
      body: {
        machineId: supernova.string_id
      }
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          isFetching: false
        }, this.getSingleSupernova);
        message.success('Success. Group ID is disconnected')
      } else {
        this.setState({
          isFetching: false
        });
        message.error('Error, try again.')
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
        message.error('Error, try again.')
     });
  }
  render() {
    const {
      isFetching,
      errorMessage,
      visibleNotes,
      supernova,
      fetchingStatus,
      activeTab,
      rebootLoading,
      updateLoading,
      visibleShowCommand,
      visibleAssignGroup
    } = this.state;
    const venueTitle = _.get(supernova, 'group.group.venue_groups.venue.title');
    const venueId = _.get(supernova, 'group.group.venue_groups.venue.id');
    const groupId = _.get(supernova, 'group.group.key_id');
    const groupIdNum = _.get(supernova, 'group.group.id');
    const nests = [1,2,3,4,5,6,7];
    const nestsState = [{value: 'on', text: 'OK'}, {value: 'off', text: 'OFF the queue'}];
    return (
      <div className="modalWindow">
        <Card
          loading={isFetching}
          type="inner"
          title={"Supernova "+supernova.string_id}
          tabList={tabList}
          activeTabKey={activeTab}
          onTabChange={(key) => { this.onTabChange(key, 'activeTab'); } }
          extra={<Button type="danger" onClick={this.onDelete} style={{float: 'right'}} >
                  <DeleteOutlined /> Delete
                </Button>}
          >
          { activeTab ==='supernova' ?
            <Form>
              { errorMessage ? 
                <FormItem><Alert message={errorMessage} type="error"/></FormItem>
              : null }
              <FormItem
                  {...formItemLayout}
                >
                <Row gutter={8}>
                  <Col span={8}>
                    <Row>
                      <label className="form-label-custom">String ID</label>
                      <Button className="ant-btn ant-btn-sm" onClick={ () => { this.showCommandDialog('visibleShowCommand', true, supernova.string_id) } }>
                        Send command
                      </Button>
                      <Link className="ant-btn ant-btn-sm" to={`/monitor/${supernova.string_id}`} style={{
                        marginLeft: '8px'
                      }}>
                        Monitor
                      </Link>
                      { visibleShowCommand ?
                        <SendCommand 
                          visible={visibleShowCommand}
                          successResult={this.successResultCommand}
                          machineId={this.state.machineIdToSend}
                          handleClose={ () => { this.modalVisible('visibleShowCommand', false) } }
                          /> : null }
                    </Row>
                    <Row >
                      <Input value={ supernova.string_id } readOnly={true} />
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <label className="form-label-custom">System ID</label>
                    </Row>
                    <Row >
                      <Input value={ supernova.id } readOnly={true} />
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <label className="form-label-custom">Group ID</label>
                      {groupId ? <Button className="ant-btn ant-btn-sm" onClick={ () => { this.showCommandDialog('visibleShowCommand', true, groupId) } }>
                        Send command
                      </Button> : <Button type="primary" size="small" onClick={ () => { this.modalVisible('visibleAssignGroup', true) } }>
                        Assign to the location ID
                      </Button> }
                      { groupId ? <Popconfirm title="Are you sure that you want unlink the connection of the group and supernova?" onConfirm={this.unlinkGroupId} okText="Yes" cancelText="No">
                        <Button className="ant-btn ant-btn-sm" style={{
                          marginLeft: '8px'
                        }}>
                          Unlink Group ID
                        </Button>
                      </Popconfirm> : null }

                      { visibleAssignGroup ? <AssignToGroup
                        supernovaId={supernova.id}
                        renderGroups={this.getSingleSupernova}
                        visible={visibleAssignGroup}
                        history={this.props.history}
                        handleClose={ () => { this.modalVisible('visibleAssignGroup', false) } } />
                        : null
                      }
                    </Row>
                    <Row >
                      <Input readOnly={true} value={groupId} />
                    </Row>
                  </Col>
                </Row>
              </FormItem>
              <FormItem
                  {...formItemLayout}
                >
                <Row gutter={8}>
                  <Col span={12}>
                    <Row>
                      <label className="form-label-custom">Firmware</label>
                      <Button loading={updateLoading} onClick={ this.updateIt } className="ant-btn ant-btn-sm">
                        Look for updates
                      </Button>
                    </Row>
                    <Row >
                      <Input value={ supernova.esp_version } readOnly={true} />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <label className="form-label-custom">Mac address</label>
                    </Row>
                    <Row >
                      <Input value={ supernova.mac_address } readOnly={true} />
                    </Row>
                  </Col>
                </Row>
              </FormItem>
              <FormItem
                  {...formItemLayout}
                >
                <Row gutter={8}>
                  <Col span={12}>
                    <Row>
                      <label className="form-label-custom">Access Point SSID</label>
                    </Row>
                    <Row >
                      <Input value={ supernova.ap_ssid } readOnly={true} />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <label className="form-label-custom">Access Point Password</label>
                    </Row>
                    <Row >
                      <Input value={ supernova.ap_pwd } readOnly={true} />
                    </Row>
                  </Col>
                </Row>
              </FormItem>
              <FormItem
                  {...formItemLayout}
                >
                <Row gutter={8}>
                  <Col span={24}>
                    <Row>
                      <label className="form-label-custom">Venue</label>
                      <Link to={`/venues/${venueId}`} target="_blank" className="ant-btn ant-btn-sm">
                        Look up
                      </Link>
                    </Row>
                    <Row >
                      <Input value={ venueTitle } readOnly={true} />
                    </Row>
                  </Col>
                </Row>
              </FormItem>
               <FormItem
              {...formItemLayout}
              >
                <Row>
                  <label className="form-label-custom">Status</label>
                </Row>
                <Spin spinning={ fetchingStatus }>
                  <RadioGroup onChange={this.onStatusChange} value={ supernova.actual_status }>
                    { statuses.map(status => <RadioButton key={status.value} value={status.value}>{status.text}</RadioButton>) }
                  </RadioGroup>
                </Spin>
              </FormItem>
              <FormItem {...formItemLayout} >
                <Row gutter={8}>
                  <Col span={24}>
                    <Row>
                      <label className="form-label-custom">Status internet</label>
                    </Row>
                    <Row >
                      <Input value={ supernova.status } readOnly={true} />
                    </Row>
                  </Col>
                </Row>
              </FormItem>
              <FormItem {...formItemLayout} >
                <Row gutter={8}>
                  <Col span={24}>
                    <Row>
                      <label className="form-label-custom">State of nests (off - disables participation in the electron queue)</label>
                    </Row>
                  </Col>
                  { nests.map(nestId => <Col span={3} key={nestId}>
                    <Row>
                      <label className="form-label-custom">Nests {nestId}</label>
                    </Row>
                    <Row>
                      <RadioGroup onChange={e => this.onQueueStatusChange(e, nestId)} value={ supernova.nest_queue_state[nestId] }>
                        { nestsState.map(state => <RadioButton key={state.value} value={state.value}>{state.text}</RadioButton>) }
                      </RadioGroup>
                    </Row>
                  </Col>) }
                </Row>
              </FormItem>
              <FormItem {...formItemLayout} >
                <Row>
                  <label className="form-label-custom">ADA status</label>
                </Row>
                <Spin spinning={ fetchingStatus }>
                  <RadioGroup onChange={this.onADAChange} value={ supernova.ada_status }>
                    <RadioButton value={1}>ON</RadioButton>
                    <RadioButton value={0}>OFF</RadioButton>
                  </RadioGroup>
                </Spin>
              </FormItem>
              <FormItem
                {...formItemLayout}
              >
                <Row>
                  <label className="form-label-custom">Actions</label>
                </Row>
                <Row className="btn-group">
                  <Button loading={rebootLoading} type="primary" onClick={ this.reboot }>
                    <ReloadOutlined /> Reboot
                  </Button>
                  <Button onClick={ this.showNotes }>
                    <QuestionCircleOutlined /> Notes
                  </Button>
                  { visibleNotes ? <Notes visible={visibleNotes} handleClose={this.hideNotes} sourceId={supernova.id} source="supernova" /> : null }
                </Row>
              </FormItem>
            </Form> : null }
            { activeTab ==='electrons' ?
              <div>
                <Table
                  columns={ this.columns() }
                  loading={ isFetching }
                  dataSource={ this.renderElectronsTable() }
                  pagination={{ pageSize: 7 }}
                  onChange={ this.handleTableChange }
                />
              </div>
            : null }
            { activeTab === 'network_settings' ?
              <div>
              { groupId ? <NetworkProfiles venueId = {venueId} mode="supernova" groupId={groupIdNum} /> : 
                "Supernova is not attached to the venue"
              } 
              </div>
           : null }
           { activeTab === 'history_log' ?
              <React.Fragment> {supernova.id ? <HistoryLog source="supernova" sourceId={supernova.id} /> : null }</React.Fragment>
           : null }
        </Card>
      </div>
    );
  }
}

export default SingleSupernovas;
