import React from 'react';
import { getTimeDistance } from 'services/utils/utils';
import { DatePicker, Input, Row, Col, Button, Table } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { Parser } from 'json2csv';

import './styles.less';

const { RangePicker } = DatePicker;

class HistoryLog extends React.Component {
  state = {
    rangePickerValue: getTimeDistance('week'),
    isFetching: false,
    log: [],
    messageContains: '',
    filteredInfo: {},
    sortedInfo: {}
  }
  componentDidMount() {
    document.title = 'History Log';
    this.getLog();
  }
  getLog = () => {
    const { source, sourceId } = this.props;
    const { messageContains, rangePickerValue } = this.state;

    this.setState({
      isFetching: true
    });

    fetchJSON('/api/v1/statistic/history_log', {
      method: 'post',
      body: {
        dates: [rangePickerValue[0].format(), rangePickerValue[1].format()],
        messageContains: messageContains,
        source: source,
        sourceId: sourceId
      }
    }).then(response => {
      console.log(response);
      this.setState({
        log: response.data,
        isFetching: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false
      });
    });
  }
  isActive(type) {
    const { rangePickerValue } = this.state;
    const value = getTimeDistance(type);
    if (!rangePickerValue[0] || !rangePickerValue[1]) {
      return '';
    }
    if (
      rangePickerValue[0].isSame(value[0], 'day') &&
      rangePickerValue[1].isSame(value[1], 'day')
    ) {
      return 'ant-btn-primary';
    }

    return '';
  }
  handleRangePickerChange = (rangePickerValue) => {
    console.log('rangePickerValue', rangePickerValue);
    rangePickerValue[0].tz('America/Los_Angeles').startOf('day');
    rangePickerValue[1].tz('America/Los_Angeles').endOf('day');
    this.setState({
      rangePickerValue,
    }, this.getLog);
  }
  selectDate = (type) => {
    this.setState({
      rangePickerValue: getTimeDistance(type),
    }, this.getLog);
  }
  messageContains = (e) => {
    this.setState({ messageContains: e.target.value });
  }
  handleTableChange = (pagination, filters, sorter) => {
    console.log('handleTableChange', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    })
  }
  columns = () => {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const { source } = this.props;
    if (source === 'supernova') {
      return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
        sorter: (a, b) =>  a.id - b.id
      },
      {
        title: 'Message',
        dataIndex: 'message',
        key: 'message'
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
        sorter: (a, b) => a.timestamp - b.timestamp
      }];
    }
    if (source === 'electron') {
      return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
        sorter: (a, b) =>  a.id - b.id
      },
      {
        title: 'Voltage',
        dataIndex: 'voltage',
        key: 'voltage',
        sortOrder: sortedInfo.columnKey === 'voltage' && sortedInfo.order,
        sorter: (a, b) =>  a.voltage - b.voltage
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
      },
      {
        title: 'Nest ID',
        dataIndex: 'nest_id',
        key: 'nest_id'
      },
      {
        title: 'Supernova',
        dataIndex: 'supernova',
        key: 'supernova',
        render: (text, record) => (
          <span>
            <Link to={"/supernovas/"+record.supernova} className="ant-btn ant-btn-sm">
              {record.supernova}
            </Link>
          </span>
        ),
      },
      {
        title: 'Group ID',
        dataIndex: 'group_id',
        key: 'group_id'
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
        sorter: (a, b) => a.timestamp - b.timestamp
      }];
    }
  }
  getData = () => {
    const { source } = this.props;
    const { log } = this.state;
    const data = [];

    if (!log.length) return data;

    if (source === 'supernova') {
      log.forEach(item => {
        data.push({
          key: item.id,
          id: item.id,
          message: item.message,
          date: moment.utc(item.created_at).tz('America/Los_Angeles').format('MM/DD/YY h:mm:ss a'),
          timestamp: moment(item.created_at).unix()
        });
      });
    }
    if (source === 'electron') {
      log.forEach(item => {
        data.push({
          key: item.id,
          id: item.id,
          voltage: item.voltage,
          supernova_id: item.supernova_id,
          supernova: item.supernova_key,
          group_id: item.group_id,
          nest_id: item.nest_id,
          status: item.status ? item.status.CHRG_STAT : '',
          date: moment.utc(item.created_at).tz('America/Los_Angeles').format('MM/DD/YY h:mm:ss a'),
          timestamp: moment(item.created_at).unix()
        });
      });
    }
    return data;
  }
  downloadFile = () => {
    const { log, rangePickerValue } = this.state;
    const { sourceId } = this.props;
    const data = [];

    log.forEach(item => {
      data.push({
        id: item.id,
        voltage: item.voltage,
        gauge_voltage: item.gauge_voltage,
        supernova_id: item.supernova_id,
        supernova_string_id: item.supernova_key,
        group_id: item.group_id,
        nest_id: item.nest_id,
        temperature: item.temperature,
        soc: item.soc,
        CHRG_STAT: item.status ? item.status.CHRG_STAT : '',
        DPM: item.status ? item.status.DPM : '',
        PG_STAT: item.status ? item.status.PG_STAT : '',
        BAT_FAULT: item.status ? item.status.BAT_FAULT : '',
        HOOK_STAT: item.status ? item.status.HOOK_STAT : '',
        NTC_FAULT: item.status ? item.status.NTC_FAULT : '',
        VSYS_STAT: item.status ? item.status.VSYS_STAT : '',
        CHRG_FAULT: item.status ? item.status.CHRG_FAULT : '',
        THERM_STAT: item.status ? item.status.THERM_STAT : '',
        date: moment.utc(item.created_at).tz('America/Los_Angeles').format('MM/DD/YY h:mm:ss a')
      });
    });

    const fields = [
      'id',
      'voltage',
      'gauge_voltage',
      'temperature',
      'soc',
      'CHRG_STAT',
      'DPM',
      'PG_STAT',
      'BAT_FAULT',
      'HOOK_STAT',
      'NTC_FAULT',
      'VSYS_STAT',
      'CHRG_FAULT',
      'THERM_STAT',
      'supernova_id',
      'supernova_string_id',
      'group_id',
      'nest_id',
      'date'
    ];
    const start = rangePickerValue[0].tz('America/Los_Angeles').format('MM/DD/YY');
    const end = rangePickerValue[1].tz('America/Los_Angeles').format('MM/DD/YY');
    var fileTitle = 'Electron #'+sourceId+' period '+start+'-'+end;

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(data);
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

  }
  render() {
    const { source } = this.props;
    const { rangePickerValue, isFetching, messageContains } = this.state;
    return (
      <div className="historyLog">
          <div className="usageElectronWrap">
            <Row gutter={8}>
              <Col span={8}>
                { source === 'supernova' ? 
                <Input placeholder="Message contains" onChange={this.messageContains} value={messageContains} />
                : <Button onClick={this.downloadFile} type="primary">Download as a File</Button>
                }
              </Col>
              <Col span={14} className="textAlignRight">
                <div className="usageElectron">
                  <button className={"ant-btn "+this.isActive('today')} onClick={() => this.selectDate('today')}>
                    Today
                  </button>
                  <button className={"ant-btn "+this.isActive('week')} onClick={() => this.selectDate('week')}>
                    Week
                  </button>
                  <button className={"ant-btn "+this.isActive('month')} onClick={() => this.selectDate('month')}>
                    Month
                  </button>
                </div>
                <RangePicker
                  value={rangePickerValue}
                  onChange={this.handleRangePickerChange}
                />
              </Col>
              <Col span={2} className="textAlignRight">
                <Button onClick={this.getLog} type="primary">Go</Button>
              </Col>
            </Row>
          </div>
          <Table
            className="tableTop"
            columns={ this.columns() }
            loading={ isFetching }
            dataSource={ this.getData() }
            pagination={{ pageSize: 40 }}
            onChange={ this.handleTableChange }
          />
      </div>
    )
  }
};

export default HistoryLog;