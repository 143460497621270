import React from 'react';
import Settings from 'services/config/Settings';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Table, Layout, Input, Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import fetchJSON from 'services/utils/fetchJSON';
import NewSeries from './components/NewSeries';

const { Content } = Layout;
const Search = Input.Search;

class Groups extends React.Component {
  state = {
    filteredInfo: {},
    sortedInfo: {},
    searchText: '',
    filtered: false,
    quote: [],
    data: [],
    isFetching: false,
    pagination: {
      pageSize: 30
    },
    visibleNewSeries: false
  };
  componentDidMount() {
    document.title = `Groups | ${Settings.title}`;
    this.getGroupsList();
  }
  getGroupsList = () => {
    this.setState({
      isFetching: true
    });
    //var t0 = performance.now()
    fetchJSON(`/api/v1/group/list`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.success && response.data) {
        this.setState({
          isFetching: false,
          quote: response.data
        });
        // var t1 = performance.now()
        // console.log("Call to doSomething took " + ((t1 - t0) / 1000).toFixed(2) + " milliseconds.")
      } else {
        this.setState({
          isFetching: false
        });
      }
    }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false
      });
    });
  }
  columns = () => {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    //filteredInfo = filteredInfo || {};
    return [{
      title: 'ID',
      dataIndex: 'key_id',
      key: 'key_id',
      sortOrder: sortedInfo.columnKey === 'key_id' && sortedInfo.order,
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Venue',
      dataIndex: 'venue',
      key: 'venue',
      render: (text, record) => (
        <Link to={`/venues/${record.venue_id}`} target="_blank">
          {record.venue}
        </Link>
      ),
      sortOrder: sortedInfo.columnKey === 'venue' && sortedInfo.order,
      sorter: (a, b) => a.venue_id - b.venue_id
    },
    {
      title: 'Organisation',
      dataIndex: 'organisation',
      key: 'organisation',
      render: (text, record) => (
        <Link to={`/organisations/${record.organisation_id}`} target="_blank">
          {record.organisation}
        </Link>
      ),
      sortOrder: sortedInfo.columnKey === 'organisation' && sortedInfo.order,
      sorter: (a, b) => a.organisation_id - b.organisation_id
    },
    {
      title: 'Supernovas',
      dataIndex: 'supernovas_in',
      key: 'supernovas_in',
      sortOrder: sortedInfo.columnKey === 'supernovas_in' && sortedInfo.order,
      sorter: (a, b) => a.supernovas_in - b.supernovas_in
    },
    // {
    //   title: 'Electrons on',
    //   dataIndex: 'electrons_on',
    //   key: 'electrons_on',
    //   sortOrder: sortedInfo.columnKey === 'electrons_on' && sortedInfo.order,
    //   sorter: (a, b) => a.electrons_on - b.electrons_on
    // },
    // {
    //   title: 'Enroll Users',
    //   dataIndex: 'get_users',
    //   key: 'get_users',
    //   sortOrder: sortedInfo.columnKey === 'get_users' && sortedInfo.order,
    //   sorter: (a, b) => a.get_users - b.get_users
    // },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Link to={"/monitor/" + record.key_id} className="ant-btn ant-btn-sm" style={{ marginRight: '8px' }}>
            Open Log
          </Link>
        </span>
      ),
    }];
  }
  getData = () => {
    const data = [];
    if (!this.state.quote.length) return data;

    let response = this.state.quote;

    if (this.state.filtered) {
      response = this.state.data;
    }
    response.forEach((group, i) => {
      let venueTitle = _.get(group, 'venue_groups.venue.title');
      let venueId = _.get(group, 'venue_groups.venue.id');
      let organisationTitle = _.get(group, 'venue_groups.venue.organisation.organisation.name');
      let organisationId = _.get(group, 'venue_groups.venue.organisation.organisation.id');
      let supernovas_in = 0;
      let electrons_on = 0;
      let supernovas = _.get(group, 'supernovas');
      let get_users = 0;
      if (supernovas && supernovas.length) {
        supernovas_in = supernovas.length;
        supernovas.forEach(supernova => {
          let electrons = _.get(supernova, 'supernova.electrons');
          if (electrons) {
            if (electrons.length) {
              electrons_on += electrons.length;
            }
          }
        });
      }
      let enroll_users = _.get(group, 'enroll_users');
      if (enroll_users) {
        if (enroll_users.length) {
          get_users = enroll_users.length;
        }
      }
      data.push({
        key: i,
        id: group.id,
        key_id: group.key_id,
        venue: venueTitle,
        venue_id: venueId,
        organisation: organisationTitle,
        organisation_id: organisationId,
        supernovas_in: supernovas_in,
        electrons_on: electrons_on,
        get_users: get_users
      })
    });

    return data;
  }
  onSearch = (e) => {
    this.setState({ searchText: e.target.value });
    const searchText = e.target.value;
    const reg = new RegExp(e.target.value, 'gi');
    this.setState({
      filtered: !!searchText,
      data: _.flatMap(this.state.quote, record => {
        const organisationTitle = _.get(record, 'venue_groups.venue.organisation.organisation.name')
        const venueTitle = _.get(record, 'venue_groups.venue.title');

        const idMatch = record.key_id.match(reg);
        const orgMatch = organisationTitle ? organisationTitle.match(reg) : false;
        const venueMatch = venueTitle ? venueTitle.match(reg) : false;

        if (!idMatch && !venueMatch && !orgMatch) {
          return null;
        }
        return {
          ...record,
        };
      }).filter(record => !!record),
    });
    if (e.target.value === '') {
      this.setState({
        data: this.state.quote.data,
        filtered: false
      });
    }
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  emitEmpty = () => {
    this.setState({
      data: [],
      searchText: '',
      filtered: false
    });
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  render() {
    const { isFetching, pagination, visibleNewSeries, searchText } = this.state;
    const suffix = searchText ? <CloseCircleOutlined key="1" onClick={this.emitEmpty} /> : null;
    return (
      <Content className="fill-bg-table">
        <Row gutter={8}>
          <Col span={18}>
            <h1 className="table-title" style={{ marginTop: '4px' }}>
              Groups
              <Link to={"/groups/generator"} className="ant-btn ant-btn-primary ant-btn-sm" style={{ marginLeft: '8px' }}>
                Groups generator list
              </Link>
              <Button type="primary" size="small" style={{ marginLeft: '8px' }} onClick={() => { this.modalVisible('visibleNewSeries', true) }}>
                Generate new series of groups
              </Button>
            </h1>
          </Col>
          <Col span={6}>
            <Search style={{ marginBottom: '8px' }}
              size="large"
               disabled={isFetching}
              ref={ele => this.searchText = ele}
              // suffix={suffix} 
              onChange={this.onSearch}
              placeholder="Search"
              onPressEnter={this.onSearch} />
          </Col>
        </Row>
        {visibleNewSeries ? <NewSeries visible={visibleNewSeries} history={this.props.history} handleClose={() => { this.modalVisible('visibleNewSeries', false) }} /> : null}
        <Table
          columns={this.columns()}
          loading={isFetching}
          dataSource={this.getData()}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </Content>
    )
  }
}

export default Groups
