import React from 'react';
import { Select } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const Option = Select.Option;
const renderBusinessTypesList = function renderBusinessTypesList() {
  const { types } = this.state;
  let options = [];
  types.forEach(type => {
    options.push(<Option key={type.id} value={type.id}>{type.title}</Option>)
  })
  return (options)
}
const renderBusinessCategoriesList = function renderBusinessCategoriesList() {
  const { business_categories } = this.state;
  let options = [];
  business_categories.forEach(type => {
    options.push(<Option key={type.id} value={type.id}>{type.title}</Option>)
  })
  return (options)
}
const renderBusinessSubcategoriesList = function renderBusinessSubcategoriesList() {
  const { business_subcategories } = this.state;
  let options = [];
  //let businessType = this.formRef.current.getFieldsValue(['business_type_id']);
  if (business_subcategories) {
    business_subcategories.forEach((subcategory) => {
      // console.log(subcategory, 'subcategory')
      options.push(<Option key={subcategory.id} value={subcategory.id}>{subcategory.title}</Option>)
    })
  }
  return (options)
}
const renderPartnerTypes = function renderPartnerTypes() {
  const { partner_types } = this.state;
  let options = [];
  partner_types.forEach(type => {
    if (type.title !== 'Partner') {
      options.push(<Option key={type.id} value={type.id}>{type.title}</Option>)
    }
  })
  return (options)
}
 
const setBusinessCategories = function setBusinessCategories() {
  const { business_info } = this.state;
  let business_categories = [];
  let push = false;
  business_info.business_categories.forEach(business_category => {
    push = true;
    if (business_category.title === 'Other' && business_category.id !== 52)
      push = false;

    if (push)
      business_categories.push(business_category);
  })
  if (this.mounted) {
    this.setState({
      business_categories,
    })
  }
}
const sortBusinessSubcategories = function sortBusinessSubcategories(type_id, business_subcategory_id = '') {
  let showSubcategory = false;
  const { business_info } = this.state;
  //for add location
  this.props.form.resetFields(['businessSubcategory']);
  if (typeof business_subcategory_id !== 'number') this.props.form.setFieldsValue({business_subcategory_id: undefined});
  let business_subcategories = [];
  business_info.business_subcategories.forEach(business_subcategory => {
    if (business_subcategory.business_types_id === type_id) {
      business_subcategories.push(business_subcategory);
      showSubcategory = true;
    }
  })
  this.setState({
    business_subcategories,
    showSubcategory
  })
}

const sortBusinessCategories = function sortBusinessCategories(type_id, business_category_id = '',  business_subcategory_id = '') {
  const { business_info } = this.state;
  this.sortBusinessSubcategories(type_id, business_subcategory_id);
  this.props.form.resetFields(['businessCategory']);
  if (typeof business_category_id !== 'number') this.props.form.setFieldsValue({business_category_id: undefined});
  let business_categories = [];
  business_info.business_categories.forEach(business_category => {
    if (business_category.business_types_id === type_id) business_categories.push(business_category);
  })
  if (business_categories.length === 1) {
    this.props.form.setFieldsValue({
      businessCategory: business_categories[0].id,
      business_category_id: business_categories[0].id
    })
  }
  this.setState({
    business_categories,
    noBusinessType: false
  })
}

const getBusinessTypes = function getOrgType() {
  fetchJSON('/api/v1/organisation/types', {
    method: 'get'
  }).then(response => {
    //console.log('response orgtype', response);
    if (typeof this.mounted !== 'undefined' && !this.mounted) return false;
    if (response.success) {
      this.setState({
        business_info: response.data,
        types: response.data.business_types,
        //customer_sources: response.data.customer_sources,
        //partner_types: response.data.partner_types,
        business_subcategories: response.data.business_subcategories,
        business_categories: response.data.business_categories
      }, this.setBusinessCategories);
    }
   }).catch(error => {
      console.log(error);
   });
}  
export {
	renderBusinessTypesList,
	renderBusinessCategoriesList,
	setBusinessCategories,
	sortBusinessCategories,
  getBusinessTypes,
  renderPartnerTypes,
  renderBusinessSubcategoriesList,
  sortBusinessSubcategories
};