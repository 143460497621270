import Auth from 'services/utils/Auth'
import { CALL_API } from 'services/middleware/api'

export const QUOTE_REQUEST = 'singleSession/QUOTE_REQUEST'
export const QUOTE_SUCCESS = 'singleSession/QUOTE_SUCCESS'
export const QUOTE_FAILURE = 'singleSession/QUOTE_FAILURE'

// Same API middlware is used to get a
// secret quote, but we set authenticated
// to true so that the auth header is sent
export function fetchSecretQuote(endpoint) {
  return {
    [CALL_API]: {
      endpoint: endpoint,
      authenticated: true,
      types: [QUOTE_REQUEST, QUOTE_SUCCESS, QUOTE_FAILURE]
    }
  }
}

export const CLOSE_SESSION_REQUEST = 'singleSession/CLOSE_SESSION_REQUEST'
export const CLOSE_SESSION_SUCCESS = 'singleSession/CLOSE_SESSION_SUCCESS'
export const CLOSE_SESSION_FAILURE = 'singleSession/CLOSE_SESSION_FAILURE'


const requestCloseSession = (params) => {
  return {
    type: CLOSE_SESSION_REQUEST,
    isFetching: true,
    params
  }
}

const receiveCloseSession = (response) => {
  return {
    type: CLOSE_SESSION_SUCCESS,
    isFetching: false,
    response
  }
}

const closeSessionError = (message) => {
  return {
    type: CLOSE_SESSION_FAILURE,
    isFetching: false,
    message: message
  }
}

export const closeSession = (params, successAction) => {
  let token = Auth.getToken() || null
  let config = {
    method: 'PUT',
    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type':'application/x-www-form-urlencoded' }
  }
  return dispatch => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestCloseSession(params))
    return fetch(`/api/v1/session/close/${params.sessionId}`, config)
      .then(response => response.json())
      .then(response =>  {
        if (!response.success) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(closeSessionError(response.message))
          return Promise.reject(response)
        } else {
          // Dispatch the success action
          successAction();
          dispatch(receiveCloseSession(response))
        }
      }).catch(err => console.log("Error: ", err))
  }
}
