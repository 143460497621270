import React from 'react';
import { connect } from 'react-redux';
import SingleElectronCard from 'screens/Dashboard/components/SingleElectronCard/SingleElectron';

class SingleElectron extends React.Component {
  state = {
    electronId: null
  }
  componentDidMount() {
    const electronId = this.props.match.params ? parseInt(this.props.match.params.electronId, 10) : null;
    this.setState({
      electronId
    });
  }

  render() {
    const { electronId } = this.state;

    return (
      <React.Fragment>
        { electronId ? <SingleElectronCard electronId={electronId} modal={false} closeModal={this.props.closeModal} history={this.props.history} /> : null }
      </React.Fragment>
    )
  }
};

export default connect()(SingleElectron)
