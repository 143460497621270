import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Layout, Card, message } from 'antd';
import QcUserForm from './QcUserForm';
import fetchJSON from 'services/utils/fetchJSON';

const { Content } = Layout;
const fields = ['name', 'description', 'phone', 'role', 'status', 'access', 'group_assignment', 'access_to', 'access_label', 'login_expiration'];

class SingleQcUser extends React.Component {
  state = {
  	isFetching: false,
  	data: {},
  	qcuser: {},
    listSupernovasIds: [],
    supernovas: []
  }
  componentDidMount() {
    this.getSingleQcUser();
    this.getListSupernovas();
  }
  getSingleQcUser = () => {
  	const qcUserId = this.props.match.params.qcUserId;
    this.setState({
      isFetching: true
    })
    fetchJSON(`/api/v1/qcusers/${qcUserId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.success && response.data) {
        this.setState({
          isFetching: false,
          qcuser: response.data
        }, this.setFields )
      } else {
        this.setState({
          isFetching: false
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  getListSupernovas = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        isFetching: true
        })
      fetchJSON('/api/v1/supernova/list', {
        method: 'get'
      }).then(response => {
        if (response.success) {
          this.setState({
            isFetching: false,
            supernovas: response.data
          }, this.getListSupernovasIds ); 
        resolve();
        } else {
            this.setState({
              isFetching: false
            });
          }
      }).catch(error => {
        console.log(error);
          reject();
          this.setState({
            isFetching: false
          });
        })
      }).catch(() => console.log('Oops errors!'));
  }

  getListSupernovasIds = () => {
    this.setState({
      isFetching: true
    })
    const supernovas = this.state.supernovas;
    const stringsSupernovas = [];
    for (let i = 0; i < supernovas.length; i++) {
      if (stringsSupernovas.indexOf(supernovas[i].string_id) === -1) {
        stringsSupernovas.push(supernovas[i].string_id)
      }
    }
    this.setState({
      listSupernovasIds: stringsSupernovas,
      isFetching: false
    })
  }

  setFields = () => {
    let fieldsData = {}
    const qcuser = this.state.qcuser;
    let access_label = '';
    let login_expiration = null;

    if (qcuser.access_to !== null) {
      access_label = 'Limited by supernova status' }
  
    if (qcuser.access_to === null && (!Array.isArray(qcuser.access) || !qcuser.access.length)) {
          access_label = 'Any non-production supernova' }

    if (qcuser.access_to === null && (Array.isArray(qcuser.access) && qcuser.access.length > 0)) {
          access_label = 'Limited by supernova ID' }
    if (qcuser.session_duration) {
      login_expiration = Math.floor(qcuser.session_duration/8) }
    
    fieldsData = {
      name: qcuser.name,
      description: qcuser.description,
      phone: qcuser.phone,
      role: qcuser.role,
      status: qcuser.status,
      access: qcuser.access,
      group_assignment: qcuser.group_assignment,
      access_to: qcuser.access_to,
      access_label: access_label,
      login_expiration: login_expiration
    }
    if (fieldsData && typeof fieldsData == 'object') {
      Object.keys(fieldsData).forEach((key) => {
          this.props.form.setFieldsValue({[`${key}`]: fieldsData[key]});
      })
    }
  }
  onSubmit = () => {
    this.props.form.validateFields(fields)
      .then(values => {
        let data = this.props.form.getFieldsValue(fields);
        if (data && typeof data == 'object') {
          if (data.access_label === 'Limited by supernova ID') {
            if (!Array.isArray(data.access) || !data.access.length) {
              throw new Error('Please select supernovas for access');
            }
            data.access_to = null;
            this.props.form.setFieldsValue({'access_to': null});
          }
          else if (data.access_label === 'Limited by supernova status') {
            if (data.access_to == null) {
              throw new Error(`Please select status for access`);
            }
            data.access = [];
            this.props.form.setFieldsValue({'access': [] })
          }
          else if (data.access_label === 'Any non-production supernova') {
            data.access = [];
            data.access_to = null;
            this.props.form.setFieldsValue({'access': [] })
            this.props.form.setFieldsValue({'access_to': null})
          }
          if (Number(data.phone)) { 
            data.phone = Number(data.phone) 
          } else {
            throw new Error('Please enter correct phone')
          }
          if (Number(data.login_expiration)) {
            data.session_duration = Number(data.login_expiration) * 8;
          } else {
            throw new Error('Login expiration must be a number')
          }
          return data
        }
        else {
          throw new Error('Please try again')
        }
     })
     .then(data => {
        if (data && typeof data == 'object') {
          return new Promise((resolve, reject) => {
            fetchJSON(`/api/v1/qcusers/update/${this.props.match.params.qcUserId}`, {
              method: 'post',
              body: {
                name: data.name,
                description: data.description,
                phone: data.phone,
                role: data.role,
                status: data.status,
                group_assignment: data.group_assignment,
                access: data.access,
                access_to: data.access_to,
                session_duration: data.session_duration
              }
            }).then(response => {
              if (response.success) {
                this.setState({
                  isFetching: false
                }, message.success('User succesfully updated')); 
              resolve();
              } else {
                this.setState({
                  isFetching: false
                });
              }
            }).catch(error => {
              console.log(error);
              this.setState({
                isFetching: false
              }, message.error('Please try again'));
              reject();
            })
          }).catch(() => console.log('Oops errors!'));
        }
        else {
          throw new Error('Please try again')
        }
      })
      .catch(error => {
        const errors = ['Please select supernovas for access', `Please select status for access`, 'Please enter correct phone', 'Login expiration must be a number', 'Please try again']
        const index = errors.indexOf(error.message)
        if (index > -1 ) {
          message.error(errors[index])
        }
        else {
          message.error('Please fill in all fields');
        }

     }) 
  }


  render() {

  	const isFetching = this.state.isFetching;
    const form = this.props.form;

  	return (
  	  <Content className="fill-bg-table">
  		<h2 className="table-title">QC User </h2>
  		  <Card
          loading={isFetching}
          >
          <QcUserForm form={form} onSubmit={this.onSubmit} listSupernovasIds={this.state.listSupernovasIds}
          	/>
          
        </Card>
      </Content>
    )
  }
}



export default Form.create()(SingleQcUser);