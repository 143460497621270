import React from 'react';
import { Provider } from 'react-redux'
import { TrackJS } from 'trackjs';

// import { ConnectedRouter } from 'react-router-redux'
import { ConnectedRouter } from 'connected-react-router'

import { ModalContainer } from 'react-router-modal';
import { Switch, Route } from 'react-router-dom'
import store, { history } from './store'

import Track from '../utils/withTracker';
import Settings from 'services/config/Settings';
// import DashboardWrapper from 'components/DashboardWrapper/DashboardWrapper';
import Dashboard from 'screens/Dashboard/Dashboard';

import Supernovas from 'screens/Dashboard/screens/Supernovas/Supernovas';
import SingleSupernovas from 'screens/Dashboard/screens/Supernovas/components/SingleSupernovas';

import SupernovasMonitor from 'screens/Dashboard/screens/Supernovas/SupernovasMonitor';
import SupernovasLog from 'screens/Dashboard/screens/Supernovas/SupernovasLog';
import Electrons from 'screens/Dashboard/screens/Electrons/Electrons';
import SingleElectron from 'screens/Dashboard/screens/Electrons/components/SingleElectron';

import Groups from 'screens/Dashboard/screens/Groups/Groups';
import GroupGenerator from 'screens/Dashboard/screens/Groups/components/GroupGenerator';
import SingleGroupSeries from 'screens/Dashboard/screens/Groups/components/SingleGroupSeries';

import QcUsers from 'screens/Dashboard/screens/QcUsers/QcUsers';
import SingleQcUser from 'screens/Dashboard/screens/QcUsers/components/SingleQcUser';

import Users from 'screens/Dashboard/screens/Users/Users';
import SingleUser from 'screens/Dashboard/screens/Users/components/SingleUser';

import UserApp from 'screens/Dashboard/screens/UserApp/UserApp';
import Sessions from 'screens/Dashboard/screens/Sessions/Sessions';
import ClosedSessions from 'screens/Dashboard/screens/Sessions/CloseSessions';
import SessionsStatistics from 'screens/Dashboard/screens/Sessions/components/Statistics/Statistics';

import Venues from 'screens/Dashboard/screens/Venues/Venues';
import SingleVenue from 'screens/Dashboard/screens/Venues/components/SingleVenue/SingleVenue';
import Transactions from 'screens/Dashboard/screens/Transactions/Transactions';
import Tickets from 'screens/Dashboard/screens/Tickets/Tickets';
import SingleTicket from 'screens/Dashboard/screens/Tickets/components/SingleTicket';

import Organisations from 'screens/Dashboard/screens/Organisations/Organisations';
import GlobalOrgStatistics from 'screens/Dashboard/screens/Organisations/components/GlobalOrgStatistics/GlobalOrgStatistics';
import SingleOrg from 'screens/Dashboard/screens/Organisations/components/SingleOrg/SingleOrg';
import Promocodes from 'screens/Dashboard/screens/Promocodes/Promocodes';
import UsersPromocodes from 'screens/Dashboard/screens/Promocodes/UsersPromocodes';
import SinglePromo from 'screens/Dashboard/screens/Promocodes/components/SinglePromo';

import Login from 'screens/Login/Login';
import LogOut from 'screens/LogOut';

import Reports from 'screens/Dashboard/screens/Reports/Reports';


// General screens part
import NotFound from 'components/NotFound';

// utils for check authorization status
import { checkLogin, checkCredentials } from 'services/utils/requireAuth';

if (Settings.trackJsEnable) {
	TrackJS.install(Settings.trackJS);
}

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, isAuthenticated, redirectTo, ...rest}) => (
	<Route
		{...rest}
		render = { props => (
			isAuthenticated(redirectTo, () => {
				return ( <Component {...props} /> );
			})
		)}
	/>
);
// eslint-disable-next-line react/prop-types
const WrapperRoute = ({ component: Component, ...rest}) => (
	<Route
		{...rest}
		render = { props => (
			<Component {...props} />
		)}
	/>
);

const router = () => (
	<Provider store={store}>
    <ConnectedRouter history={history}>
			<div className="wrapper">
				<Switch>
					<WrapperRoute exact path='/' component={checkCredentials(['admin', 'organisation'], Track(Dashboard))} />
					<WrapperRoute exact path='/supernovas' component={checkCredentials(['admin'], Track(Supernovas))} />
					<WrapperRoute exact path='/supernovas/:supernovaId' component={checkCredentials(['admin'], Track(SingleSupernovas))} />
					<WrapperRoute exact path='/monitor' component={checkCredentials(['admin'], Track(SupernovasMonitor))} />
					<WrapperRoute exact path='/monitor/:logSource' component={checkCredentials(['admin'], Track(SupernovasLog))} />
					<WrapperRoute exact path='/electrons' component={checkCredentials(['admin'], Track(Electrons))} />
					<WrapperRoute exact path='/electrons/:electronId' component={checkCredentials(['admin'], Track(SingleElectron))} />
					<WrapperRoute exact path='/users' component={checkCredentials(['admin'], Track(Users))} />
					<WrapperRoute exact path='/users/:userId' component={checkCredentials(['admin'], Track(SingleUser))} />
					<WrapperRoute path='/sessions-closed' component={checkCredentials(['admin'], Track(ClosedSessions))} />
					<WrapperRoute path='/sessions/statistics' component={checkCredentials(['admin'], Track(SessionsStatistics))} />
					<WrapperRoute path='/sessions' component={checkCredentials(['admin'], Track(Sessions))} />
					<WrapperRoute exact path='/venues' component={checkCredentials(['admin'], Track(Venues))} />
					<WrapperRoute path='/venues/:venueUuid' component={checkCredentials(['admin'], Track(SingleVenue))} />
					<WrapperRoute exact path='/groups' component={checkCredentials(['admin'], Track(Groups))} />
					<WrapperRoute exact path='/groups/generator' component={checkCredentials(['admin'], Track(GroupGenerator))} />
					<WrapperRoute exact path='/groups/generator/:seriesId' component={checkCredentials(['admin'], Track(SingleGroupSeries))} />	
					<WrapperRoute exact path='/qcusers' component={checkCredentials(['admin'], Track(QcUsers))} />
					<WrapperRoute exact path='/qcusers/:qcUserId' component={checkCredentials(['admin'], Track(SingleQcUser))} />
					<WrapperRoute path='/transactions' component={checkCredentials(['admin'], Track(Transactions))} />
					<WrapperRoute exact path='/tickets' component={checkCredentials(['admin'], Track(Tickets))} />
					<WrapperRoute exact path='/tickets/:ticketId' component={checkCredentials(['admin'], Track(SingleTicket))} />
					<WrapperRoute exact path='/organisations' component={checkCredentials(['admin'], Track(Organisations))} />
					<WrapperRoute exact path='/organisations/statistics' component={checkCredentials(['admin'], Track(GlobalOrgStatistics))} />
					<WrapperRoute path='/organisations/:organisationId' component={checkCredentials(['admin'], Track(SingleOrg))} />
					<WrapperRoute exact path='/promocodes' component={checkCredentials(['admin'], Track(Promocodes))} />
					<WrapperRoute exact path='/promocodes/users' component={checkCredentials(['admin'], Track(UsersPromocodes))} />
					<WrapperRoute exact path='/promocodes/:promocodeId' component={checkCredentials(['admin'], Track(SinglePromo))} />
					<WrapperRoute exact path='/reports' component={checkCredentials(['admin'], Track(Reports))} />
					<PrivateRoute exact path="/login" component={Track(Login)} isAuthenticated={checkLogin} redirectTo='/' />
					<WrapperRoute path='/userapp' component={checkCredentials(['admin'], Track(UserApp))} />
					<Route exact path="/logout" component={Track(LogOut)} />
					<Route path="*" component={Track(NotFound)} />
				</Switch>
				<ModalContainer />
			</div>
    </ConnectedRouter>
  </Provider>
);

export default router;
