import React from 'react';
import { connect } from 'react-redux';
import Settings from 'services/config/Settings';
import { Table, Layout, Button, Modal, Col} from 'antd';
import { Link } from 'react-router-dom';
import { fetchSecretQuote } from './data/actions'
import moment from 'moment-timezone';
import fetchJSON from 'services/utils/fetchJSON';
import qs from 'qs';
// import Board from 'react-trello'

import './styles.less';

const { Content } = Layout;
const confirm = Modal.confirm;

class Tickets extends React.Component {
  state = {
    typeFilter: 'support',
    filteredInfo: {
      stage: ['pending']
    },
    sortedInfo: {},
  };
  componentDidMount() {
    document.title = `Tickets | ${Settings.title}`;
    this.props.dispatch(fetchSecretQuote('/note/list'))
    let paramFromUrl = {};
    if (window.location.search) {
      paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    }
    if (paramFromUrl.org) {
      this.props.history.push(`/tickets`);
      this.setState({
        filteredInfo: {
          source: ['organisation'],
          source_id: [parseInt(paramFromUrl.org)]
        },
        typeFilter: 'all'
      })
    }
    if (paramFromUrl.spn) {
      this.props.history.push(`/tickets`);
      this.setState({
        filteredInfo: {
          source: ['supernova'],
          source_id: [parseInt(paramFromUrl.spn)]
        },
        typeFilter: 'all'
      })
    }
  }
  lessThanAgo = (date) => {
    if (!date) return false;
    const _AGO = 1000 * 60 * 3;
    let anMinuteAgo = Date.now() - _AGO;
    return date < anMinuteAgo;
  }
  columns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
      sorter: (a, b) => a.id - b.id,
      filteredValue: filteredInfo.source_id || null,
      onFilter: (value, record) => {
        return record.source_id === value
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => (
        <span
          style={{ 
            fontWeight: 'bold',
          }}
        >
        {record.type}</span>
      ),
      sorter: (a, b) => a.type > b.type ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      sortOrder: sortedInfo.columnKey === 'source' && sortedInfo.order,
      sorter: (a, b) => a.source > b.source ? 1 : -1,
      filters: [
        { text: 'Supernova', value: 'supernova' },
        { text: 'Session', value: 'session' },
        { text: 'User', value: 'user' },
        { text: 'Electron', value: 'electron' },
        { text: 'Organization', value: 'organisation' }
      ],
      filteredValue: filteredInfo.source || null,
      onFilter: (value, record) => {
        return record.source.includes(value)
      },
      render: (text, record) => (
        record.owner_type ?
        <Link to={`${record.owner_type}/${record.owner_id}`} target="_blank"> 
          {record.owner}
        </Link> : null
      ),
    },
    {
      title: 'Nest ID',
      dataIndex: 'nest_id',
      key: 'nest_id',
    },
    {
      title: 'Stage',
      dataIndex: 'stage',
      key: 'stage',
      filters: [
        { text: 'Pending', value: 'pending' },
        { text: 'Active', value: 'active' },
        { text: 'Resolved', value: 'resolved' }
      ],
      filteredValue: filteredInfo.stage || null,
      onFilter: (value, record) => {
        return record.stage.includes(value)
      },
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Link to={"/tickets/"+record.id} className="ant-btn ant-btn-sm">
            Show
          </Link>
          {record.source === 'electron' ? 
          <span style={{marginLeft: '4px'}}>
            <Link to={`/electrons/${record.owner_id}?history_log=1`} target="_blank" className="ant-btn ant-btn-sm">
              Statistics
            </Link>
          </span>
          : null }
          <Button style={record.source === 'electron' ? {marginTop: '5px'} : {marginLeft: '4px'}} onClick={() => this.resolveTicket(record.id, 'resolved')} className="ant-btn ant-btn-sm">
            Resolve
          </Button>
        </span>
      ),
    }
    ];
  }
  resolveTicket = (ticketId, stage) => {
    console.log('resolveTicket', ticketId);
    confirm({
      title: `Change ticket stage to "${stage}"?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            fetchingStatus: true
          })
          fetchJSON(`/api/v1/note/updateStage/${ticketId}`, {
            method: 'put',
            body: {
              stage: stage
            }
          }).then(response => {
            if (response.success) {
              this.setState({
                fetchingStatus: false
              });
              this.props.dispatch(fetchSecretQuote('/note/list'))
              resolve()
            } else {
              this.setState({
                fetchingStatus: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                fetchingStatus: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  getData = () => {
    const data = [];
    if (!this.props.quote) return data;
    let response = this.props.quote.data;
    const typeFilter = this.state.typeFilter;
    let noFilter = false;
    if (typeFilter === 'all') {
      noFilter = true;
    }
    if (response.length) {
      response.forEach((ticket, i) => {
        if (ticket.type === typeFilter || noFilter) {
          const created = moment(ticket.createdAt).tz('America/Los_Angeles').format("DD MMM YY, h:mm a");

          let owner_type = false;
          let owner_id = '';
          let owner_string_id = '';
          let owner = false;

          if (ticket.source === 'supernova') {
            owner = 'Supernova #';
            owner_type = 'supernovas';
            if (ticket.source_string_id) {
              owner_string_id = ticket.source_string_id;
            }          
          }
          if (ticket.source === 'user') {
            owner = 'User #';
            owner_type = 'users';
            owner_string_id = ticket.source_id;
          }

          if (ticket.source === 'session') {
            owner = 'Session #';
            owner_type = 'sessions';
            owner_string_id = ticket.source_id;
          }

          if (ticket.source === 'electron') {
            owner = 'Electron #';
            owner_type = 'electrons';
            if (ticket.source_string_id) {
              owner_string_id = ticket.source_string_id;
            }
          }
          if (ticket.source === 'venue') {
            owner = 'Venue #';
            owner_type = 'venues';
            owner_string_id = ticket.source_id;
          }
          if (ticket.source === 'organisation') {
            owner = 'Organization #';
            owner_type = 'organisations';
            owner_string_id = ticket.source_id;
          }

          if (ticket.source_id) {
            owner_id = ticket.source_id;
          }

          data.push({
            key: i,
            id: ticket.id,
            type: ticket.type,
            stage: ticket.stage,
            created: created,
            author: ticket.author,
            source: ticket.source,
            source_id: ticket.source_id,
            value: ticket.value,
            owner: `${owner}${owner_string_id}`,
            owner_type: owner_type,
            owner_id: owner_id,
            nest_id: ticket.nest_id
          })
        }
      });
    }
    //console.log('data', data);
    return data;
  }
  handleTableChange = (pagination, filters, sorter) => {
    console.log('handleTableChange', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    })
  }
  isActive(type) {
    const { typeFilter } = this.state;
    if (
      typeFilter === type
    ) {
      return 'ant-btn-primary';
    }

    return '';
  }
  selectType = (type) => {
    this.setState({
      typeFilter: type
    })
  }

  render() {
    const { isFetching, pagination } = this.props;
    // const data = {
    //   lanes: [
    //     {
    //       id: 'lane1',
    //       title: 'Pending',
    //       cards: [
    //         {id: 'Card1', title: 'Write Blog', description: 'Can AI make memes', label: '30 mins'},
    //         {id: 'Card2', title: 'Pay Rent', description: 'Transfer via NEFT', label: '5 mins', metadata: {sha: 'be312a1'}}
    //       ]
    //     },
    //     {
    //       id: 'lane2',
    //       title: 'Doing',
    //       cards: [
    //         {id: 'Card3', title: 'Write Blog', description: 'Can AI make memes', label: '30 mins'},
    //         {id: 'Card4', title: 'Pay Rent', description: 'Transfer via NEFT', label: '5 mins', metadata: {sha: 'be312a1'}}
    //       ]
    //     },
    //     {
    //       id: 'lane3',
    //       title: 'Done',
    //       cards: [
    //         {id: 'Card5', title: 'Write Blog', description: 'Can AI make memes', label: '30 mins'},
    //         {id: 'Card6', title: 'Pay Rent', description: 'Transfer via NEFT', label: '5 mins', metadata: {sha: 'be312a1'}}
    //       ]
    //     },
    //   ]
    // }
    // <Board data={data} draggable laneDraggable={false} />

    return (
      <Content className="fill-bg-table">
      <Col span={2}>
        <h1 className="table-title">Tickets</h1>
      </Col>
        <div className="tickets-filter">
          <button className={"ant-btn " +this.isActive('support')} onClick={() => this.selectType('support')} >
            Support
          </button>
          <button className={"ant-btn " +this.isActive('info')} onClick={() => this.selectType('info')} >
            Info
          </button>
          <button className={"ant-btn " +this.isActive('testing')} onClick={() => this.selectType('testing')} >
            Testing
          </button>
          <button className={"ant-btn " +this.isActive('all')} onClick={() => this.selectType('all')} >
            All
          </button>
        </div>
        <Table
          columns={ this.columns() }
          loading={ isFetching }
          dataSource={ this.getData() }
          pagination={ pagination }
          onChange={ this.handleTableChange }
        />
      </Content>
    )
  }
};


const mapStateToProps = (state) =>{
  const { tickets } = state;
  const { quote, isFetching, createdFetch, pagination } = tickets;
  return {
    quote,
    isFetching,
    createdFetch,
    pagination
  }
}


export default connect(mapStateToProps)(Tickets)
