import fetchJSON from 'services/utils/fetchJSON';

const getFullSurveyData = function getFullSurveyData() {
  const bodyObj = this.getBodyObjSurvey();
  this.setState({
    surveyData: emptySurveyObj,
  }, () => {
    this.getSurveyData(bodyObj);
    this.getNpsData(bodyObj);
  })
}
const getBodyObjSurvey = function getBodyObjSurvey() {
  const {
    filterByLocationType,
    targetOrgId,
    targetVenueId,
    targetBusinessTypeId,
    targetBusinessCategoryId,
    // defaultRangePickerValue,
    venuesCategoriesList,
    fullVenuesList,
    general_date_type,
    // multilocations
    overrideLocationChoiceGlobal,
    orgsIds,
    venuesIds
  } = this.props;
  const { overrideLocationChoice } = this.state;
  const bodyObj = {
    filter_by_id: !filterByLocationType,
    organisation_id: targetOrgId,
    venue_id: targetVenueId,
    business_type_id: targetBusinessTypeId,
    business_category_id: targetBusinessCategoryId,
    // dates: defaultRangePickerValue,  
    venues_list_category: venuesCategoriesList,
    full_venues_list: fullVenuesList,
    general_date_type,
    location_disabled: overrideLocationChoice,
    use_custom_locs_arr: overrideLocationChoiceGlobal,
    custom_org_ids: orgsIds,
    custom_venues_ids: venuesIds
  };
  return bodyObj
}
const getNpsData = function getNpsData(body) {
  const { 
    npsAgeFilter,
    npsCableFilter,
    npsGenderFilter 
  } = this.state;
  
  body.gender_filters = npsGenderFilter;
  body.age_filters = npsAgeFilter;
  body.cable_filters = npsCableFilter;

  this.setState({
    isNpsFetching: true
  });
  fetchJSON('/api/v1/reports/survey/getNpsData', {
    method: 'post',
    body
  }).then(response => {
    if (this.mounted) {
      let showEmptyNps = !response.data.length;
      this.setState({
        isNpsFetching: false,
        npsData: response.data || emptyNpsObj,
        showEmptyNps
      });
    }
  }).catch(error => {
    console.log(error);
    if (this.mounted) { 
      this.setState({
        isNpsFetching: false
      });
    }
  });
}
const getSurveyData = function getSurveyData(body) {
  this.setState({
    isSurveyFetching: true
  });
  fetchJSON('/api/v1/reports/survey/getData', {
    method: 'post',
    body
  }).then(response => {
    if (this.mounted) {
      let showEmptyGeneralSurvey = false;
      if (response.data) {
        const data = response.data;
        showEmptyGeneralSurvey = !data.gender.length && !data.ageToGender.length && !data.worry_your_phone_dying.total.length && !data.worry_your_phone_dying.female.length && !data.worry_your_phone_dying.male.length; 
      } else {
        showEmptyGeneralSurvey = true;
      }
      this.setState({
        isSurveyFetching: false,
        surveyData: response.data || emptySurveyObj,
        showEmptyGeneralSurvey
      });
    }
  }).catch(error => {
    console.log(error);
    if (this.mounted) { 
      this.setState({
        isSurveyFetching: false
      });
    }
  });
}
const emptySurveyObj = {
  gender: [],
  ageToGender: [],
  worry_your_phone_dying: {
    total: [],
    female: [],
    male: []
  },
  nps_score: [],
  max_amount_gender_age: 0
};
const emptyNpsObj = {
  nps_score: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => { return { name: value.toString(), count: 0, percentage: 0 } }),
  female_score: 0,
  male_score: 0,
  total_score: 0
};
export {
  getFullSurveyData,
	getBodyObjSurvey,
	getSurveyData,
  getNpsData,
	//
	emptySurveyObj,
  emptyNpsObj
};