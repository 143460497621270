import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';

const { WeekPicker } = DatePicker;

class ControlledRangePicker extends React.Component {
  constructor(props) {
    super(props);
    const daysShift = !this.props.daily ? 7 * 1000 * 60 * 60 * 24 : 0;
    this.state = {
      startValue: null,
      endValue: null,
      endOpen: false,
      daysShift,
      fullValue: [null, null],
      shouldParentUpdate: false
    };
  }

  disabledStartDate = startValue => {
    const { endValue, daysShift } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf() - daysShift;
  };

  disabledEndDate = endValue => {
    const { startValue, daysShift } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf() + daysShift;
  };

  onChange = (field, value) => {
    const { fullValue } = this.state;
    const newFullValue = [];
    newFullValue[field === 'startValue' ? 0 : 1] = value;
    newFullValue[field === 'startValue' ? 1 : 0] = fullValue[field === 'startValue' ? 1 : 0];
    this.setState({
      [field]: value,
      fullValue: newFullValue
    }, () => {
      const newValueValid = moment.isMoment(newFullValue[0]) && moment.isMoment(newFullValue[1]);
      const oldValueValid = moment.isMoment(fullValue[0]) && moment.isMoment(fullValue[1]);
      let shouldUpdate = false;
      if (newValueValid) {
        if (oldValueValid) {
          if (this.props.daily) shouldUpdate = !newFullValue[0].isSame(fullValue[0]) || !newFullValue[1].isSame(fullValue[1]);
          else shouldUpdate = newFullValue[0].week() !== fullValue[0].week() || newFullValue[1].week() !== fullValue[1].week();
        } else shouldUpdate = true;
      }
      if (shouldUpdate) {
        this.setState({
          shouldParentUpdate: true
        })
      }
    });
  };

  onStartChange = value => {
    this.onChange('startValue', value);
  };

  onEndChange = value => {
    this.onChange('endValue', value);
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open },
    () => {
      if (!open) setTimeout(this.checkUpdate, 0);
    });
  };
  checkUpdate = () => {
    const { shouldParentUpdate, fullValue } = this.state;
    if (shouldParentUpdate) {
      this.setState({
        shouldParentUpdate: false
      }, () => this.props.onChange(fullValue))
    }
  }
  render() {
    const { startValue, endValue, endOpen } = this.state;
    const componentStartParams = {
      disabledDate: this.disabledStartDate,
      format: this.props.daily ? "YYYY-MM-DD": "YYYY wo",
      value: startValue,
      placeholder: this.props.daily ? "Start date" : "Start week",
      onChange: this.onStartChange,
      onOpenChange: this.handleStartOpenChange,
      allowClear: false  
    }
    const componentEndParams = {
      style: { marginLeft: '8px' },
      disabledDate: this.disabledEndDate,
      format: this.props.daily ? "YYYY-MM-DD": "YYYY wo",
      value: endValue,
      placeholder: this.props.daily ? 'End date' : "End week",
      onChange: this.onEndChange,
      open: endOpen,
      onOpenChange: this.handleEndOpenChange,
      allowClear: false
    }
    return (
      <div>
        { !this.props.daily ? 
          <span>
            <WeekPicker { ...componentStartParams} />
            <WeekPicker {...componentEndParams} />
          </span> : 
          <span>
            <DatePicker { ...componentStartParams} />
            <DatePicker {...componentEndParams} />
          </span>}

      </div>
    );
  }
}

export default ControlledRangePicker;