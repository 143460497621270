import React from 'react';
import moment from 'moment';
import fetchJSON from 'services/utils/fetchJSON';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Card, Button, Popconfirm } from 'antd';
import Refund from '../Refund';
import './styles.less';

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};
const FormItem = Form.Item;

class UserTransactions extends React.Component {
  state = {
    isFetching: false,
    transactions: [],
    totalPaid: 0,
    promoPlans: 0,
    paidPlans: 0,
    goodStatuses: ['authorized','submitted_for_settlement','settling','settlement_pending','settled', 'authorization_expired'],
    showRefund: false,
    refundObj: {}
  }
  componentDidMount() {
    this.getUserTransactions();
  }
  getUserTransactions = () => {
    const { userId } = this.props;
    if (!userId) return false;
    this.setState({
      isFetching: true
    });
    fetchJSON(`/api/v1/user/transaction/detailed_list/${userId}`, {
      method: 'get'
    }).then(response => {
      console.log('response', response);
      this.setState({
        transactions: response.data,
        isFetching: false
      }, this.countTransaction);
    }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false
      });
    });
  }
  countTransaction = () => {
    const { transactions } = this.state;
    if (transactions.length) {
      let totalPaid = 0;
      let promoPlans = 0;
      let paidPlans = 0;

      transactions.forEach((item, index) => {
        const { goodStatuses } = this.state;
        if (goodStatuses.indexOf(item.status) > -1) {
          totalPaid += item.amount;
        }
        if (item.plan) {
          if (item.promo) {
            promoPlans += 1;
          } else {
            paidPlans += 1;
          }
        }
      });

      this.setState({
        totalPaid,
        promoPlans,
        paidPlans
      })
    }
  }
  makeRefund = (refundObj) => {
    console.log('makeRefund', refundObj);
    this.setState({
      showRefund: true,
      refundObj 
    })
  }
  transactionsHistory = () => {
    if (this.state.transactions.length) {
      return this.state.transactions.map((item, index) => {
        let type = item.payment_instrument_type ? item.payment_instrument_type.replace('_',' ') : '';
        type = type.charAt(0).toUpperCase() + type.slice(1);
        let time = moment(item.created_at).format("DD MMM YY, h:mm a");
        let transactionPurpose = 'Transaction';
        let status = item.status;
        const { goodStatuses } = this.state;
        let refundAvailable = false;

        if (goodStatuses.indexOf(status) > -1) {
          status = 'Paid';
          refundAvailable = true;
        }
        
        if (item.plan) {
          transactionPurpose = `${item.plan.view_label} `;
          if (item.promo) {
            transactionPurpose += 'promo'
          } else {
            transactionPurpose += 'plan'
          }
        } else {
          refundAvailable = false;
        }
        let orderStatus = false;
        if (item.order_id) {
          if (item.order) {
            orderStatus = item.amount !== 0 ? item.order.status : 'Applied';
          }
        }
        let planStatus = false;
        if (item.subscription) {
          planStatus = item.subscription.status;
          if (planStatus === 'Single') planStatus = 'Active';
        }

        if (planStatus === 'Refunded') {
          refundAvailable = false;
        }
        
        let paymentOrderStatus = status;
        if (orderStatus) {
          if (orderStatus === 'paid') {
            if (status !== 'Paid' && planStatus !== 'Refunded') {
              if (status === 'voided') {
                paymentOrderStatus = `Applied`;
              }
            }
          }          
        }

        return (
          <div key={ index } className="payment-history__history-info">
            <div className="payment-history__colum-left">
              <div className="payment-history__title">{ transactionPurpose }</div>
              <div className="payment-history__text">Date: {time}</div>
              {planStatus ? <div className="payment-history__text">Plan status: {planStatus}</div> : null}
              {item.order_id ? <div className="payment-history__text">Order ID: {item.order_id}</div> : null}
              {orderStatus ? <div className="payment-history__text">Order status: {orderStatus}</div> : null}
              <div className="payment-history__text">Order amount: { !item.promo ? `${item.amount} ${item.currency}` : '0' }</div>
              { type ?
              <div className="payment-history__text">Payment method: {`${type} (${ item.last_4 })`}</div> : null }
              {item.amount !== 0 ?
                <div className="payment-history__text">Payment status: {!orderStatus ? status : paymentOrderStatus }</div> : null}

              { refundAvailable ? <Popconfirm title="Are you sure that you want to make refund?" onConfirm={e => this.makeRefund(item)} okText="Yes" cancelText="No">
                  <Button>Make refund</Button>
                </Popconfirm> : null }
            </div>
          </div>
        )
      })
    } else {
      return (
        <div className="wallet__sub-name" style={{'paddingTop': '20px'}}>User has no active transactions.</div>
      );
    }
  }
  hideRefund = () => {
    this.setState({
      showRefund: false
    })
  }
  render() {
    const { userId } = this.props;
    const {
      isFetching,
      totalPaid,
      promoPlans,
      paidPlans,
      showRefund,
      refundObj
    } = this.state;

    return (
      <Card
      loading = {isFetching}
      >
      <FormItem
        {...formItemLayout}
      >
        <Row gutter={8}>
          <Col span={8}>
            <Row>
              <label className="form-label-custom">Total paid</label>
            </Row>
            <Row >
              <Input value={ `$${totalPaid}` } readOnly={true} />
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <label className="form-label-custom">Paid plans</label>
            </Row>
            <Row >
              <Input value={paidPlans} readOnly={true} />
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <label className="form-label-custom">Promo plans</label>
            </Row>
            <Row >
              <Input value={promoPlans} readOnly={true} />
            </Row>
          </Col>
        </Row>
      </FormItem>
      <React.Fragment>
        { this.transactionsHistory() }
      </React.Fragment>
      { showRefund ? <Refund visible={showRefund} handleClose={this.hideRefund} userId={userId} refundFor={refundObj} getUserTransactions={this.getUserTransactions} /> : null }
      </Card>
    )
  }
}

export default UserTransactions;
