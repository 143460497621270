import React from 'react';
import { Row, Button, Col, Table } from 'antd';
import AddProfile from './components/AddProfile';
import fetchJSON from 'services/utils/fetchJSON';
import EditProfile from './components/EditProfile';

class NetworkProfiles extends React.Component {
  state = {
    isFetching: false,
    visibleAddProfile: false,
    visibleEditProfile: false,
    listProfiles: [],
    editProfile: {}
  }
  componentDidMount() {
    this.getProfiles();
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  editVisible = (key, value, editProfileId) => {
    const { listProfiles } = this.state;
    let editProfile = {};
    listProfiles.forEach(profile => {
      if (profile.id === editProfileId) editProfile = profile;
    })
    this.setState({
      [key]: value,
      editProfile
    });
  }
  getProfiles = () => {
    const { venueId } = this.props;
    if (!venueId) return false;
    this.setState({
      isFetching: true
    })
    fetchJSON(`/api/v1/venue/networkProfiles/list/${venueId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          isFetching: false,
          listProfiles: response.data
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  columns = () => {
    return [
    {
      title: 'SSID',
      dataIndex: 'ssid',
      key: 'ssid'
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password'
    },
    {
      title: 'Group ID',
      dataIndex: 'group_id',
      key: 'group_id'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Button onClick={ () => { this.editVisible('visibleEditProfile', true, record.id) } }>
          Edit
        </Button>
      ),
    }];
  }
  getData = () => {
    const { listProfiles } = this.state;
    if (!listProfiles.length) return [];
    return listProfiles.filter(listProfile => {
      if (this.props.mode === 'venue') return true;
      let group_id = listProfile.venue.group ? listProfile.venue.group.id : null;
      if (!group_id) return true;
      if (group_id === this.props.groupId) return true;
      return false;
    }).map((listProfile, index) => {
      let groupKey = listProfile.venue.group ? listProfile.venue.group.key_id : null;
      return {
        key: index,
        id: listProfile.id,
        ssid: listProfile.ssid,
        group_id: groupKey,
        password: listProfile.password
      }
    });
  }
  render() {
    const { visibleAddProfile, visibleEditProfile, editProfile } = this.state;
    const { venueId } = this.props;
    return (
      <div>
        <Row gutter={8}>
          <Col span={5}>
            <h1 className="table-title">Network Profiles
              <Button style={{marginLeft:'8px'}} type="primary" size="small" onClick={ () => { this.modalVisible('visibleAddProfile', true) } }>
                Add profile
              </Button>
            </h1>
          </Col>
          <Col span={10}>
            { visibleAddProfile ?
              <AddProfile 
                visible={visibleAddProfile}
                renderProfiles={this.getProfiles}
                venueId={venueId}
                groups={this.props.groups}
                groupId={this.props.groupId}
                mode={this.props.mode}
                handleClose={ () => { this.modalVisible('visibleAddProfile', false) } }
                /> : null }
          </Col>
        </Row>
        <Row>
          <Table columns={this.columns()} dataSource={this.getData()} />
        </Row>
        { visibleEditProfile ?
          <EditProfile 
            visible={visibleEditProfile}
            renderProfiles={this.getProfiles}
            profile={editProfile}
            venueId={venueId}
            groups={this.props.groups}
            groupId={this.props.groupId}
            mode={this.props.mode}
            handleClose={ () => { this.modalVisible('visibleEditProfile', false) } }
            /> : null }
      </div>
    )
  }
};

export default NetworkProfiles;
