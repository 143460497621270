import {
  QUOTE_REQUEST, QUOTE_SUCCESS, QUOTE_FAILURE
} from './actions'

const initialState = {
  isFetching: false,
  quote: '',
  authenticated: false,
  createdFetch: null,
  pagination: {
    pageSizeOptions: ['10', '30', '50', '100'],
  },
  totalNumberTable: 0
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case QUOTE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        createdFetch: null
      })
    case QUOTE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        quote: action.response,
        authenticated: action.authenticated || false,
        createdFetch: Date.now(),
        totalNumberTable: action.response.data.length
      })
    case QUOTE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        createdFetch: null
      })
    default:
      return state
   }
}
