import React from 'react';
import { Modal, Button, Card, Input, Alert, Spin } from 'antd';
import io from 'socket.io-client';
import fetchJSON from 'services/utils/fetchJSON';

class BatteryRelease extends React.Component {
	state = {
		isFetching: false,
		stationId: '',
		message: '',
		successSend: false,
		token: '',
		releaseInProgress: false
	}
	componentDidMount() {
		const { userId } = this.props;
		this.getUserToken(userId);
  }
  getUserToken = (userId) => {
  	this.setState({
			isFetching: true
		})
    fetchJSON(`/api/v1/user/releaseToken/${userId}`, {
			method: 'get'
		}).then(response => {
			if (response.success) {
				this.setState({
					token: response.token
				}, () => this.connectSocket(response.token));
			}
     }).catch(error => {
				console.log(error);
				this.setState({
					isFetching: false
				});
     });
  }
  connectSocket = (token) => {
  	const socketUrl = process.env.NODE_ENV === 'development' ? 'https://supernovas.toool.co' : 'https://supernovas.cloud';
  	this.socket = io(socketUrl, {
      'query': 'token=' + token,
      'timeout': '5000'
    });
    this.id = this.socket.id;
    this.socket.on('connect', () => {
      this.subscribeToEvents();
      this.setState({
				isFetching: false
			});
    });
    
    this.socket.on('error', (err) => {
      console.log('error', err);
      this.setState({
				isFetching: false,
				message: "Can't connect to the supernovas"
			});
    });
  }
  subscribeToEvents = () => {
		this.socket.on('electron', (response) => {
			console.log('electron feedback', response);
			if (response.code === 200) {
				console.log('SUCCESS');
				this.setState({
					message: 'SUCCESS',
					stationId: '',
					successSend: true,
					releaseInProgress: false
				})
				return false;
			}
			// if user not first in queue
			if (response.code === 301) {
				this.setState({
					message: 'ERROR - there\'s a queue',
					successSend: false,
					releaseInProgress: false
				})
				return false;
			}
			// if user did't take a battery
			if (response.code === 302) {
				this.setState({
					message: 'User didn\'t take battery',
					successSend: false,
					releaseInProgress: false
				})
				return false;
			}
			// if battery is not available
			if (response.code === 303) {
				this.setState({
					message: 'Station not ready for battery release',
					successSend: false,
					releaseInProgress: false
				})
				return false;
			}
			this.setState({
				message: 'Something went wrong',
				successSend: false,
				releaseInProgress: false
			})
		});
  }
  changeStation = e => {
  	this.setState({
      stationId: e.target.value
    });
  }
  releaseBattery = (e) => {
  	e.preventDefault();
  	const { stationId } = this.state;
  	if (!stationId) {
  		this.setState({
  			message: 'Please type station ID'
  		});
  		return false;
  	}
  	this.socket.emit('get_electron', { groupId: stationId });
  	this.setState({
  		releaseInProgress: true,
  		message: ''
  	});
  }
  componentWillUnmount = () => {
  	this.socket.disconnect();
  }
  render() {
  	const { isFetching, stationId, message, successSend, releaseInProgress } = this.state;
    return (
      <Modal
      	className="nocardPadding"
        style={{ top: 20 }}
        visible={this.props.visible}
        title={"Release battery to #"+this.props.userId}
        onOk={this.props.handleClose}
        onCancel={this.props.handleClose}
        footer={[
          <Button key="submit" type="primary" loading= {isFetching} onClick={this.props.handleClose}>
            Close
          </Button>
        ]}
      >
				<Card loading = { isFetching }>
					<Spin spinning={releaseInProgress}>
						<form onSubmit={this.releaseBattery}>
							{ message.length ? 
			      		<div style={{marginBottom: '20px'}}><Alert message={message} type={ successSend ? "success" : "error" } /></div>
			      	: null }
				      <div><Input placeholder="Station ID" value={stationId} disabled={releaseInProgress} onChange={this.changeStation} /></div>
				      <div style={{'padding': '20px 0'}}>
					      <Button key="submit" disabled={releaseInProgress} type="primary">
			            Release battery
			          </Button>
		          </div>
	          </form>
          </Spin>
	      </Card>
      </Modal>
    )
  }
};

export default BatteryRelease;
