import React from 'react';
import qs from 'qs';

import {
  ArrowDownOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import '@ant-design/compatible/assets/index.css';

import { Layout, Button, Input, Row, Col, Spin, message, Select, Modal, Card, Form, Skeleton } from 'antd';
import AddressField from './components/AddressField/AddressField';
import fetchJSON from 'services/utils/fetchJSON';
import Notes from 'screens/Dashboard/components/Notes/Notes';
import AddVenue from '../../../Venues/components/SingleVenue/AddVenue';
import VenuesTable from '../../../Venues/components/VenuesTable';
import Statistics from '../Statistics/Statistics';
import AppsUsers from './components/AppsUsers';
import Contacts from './components/Contacts'
import Pricing from './components/Pricing';
import Warehouses from './components/Warehouses';
import Survey from './components/Survey';
import Invoicing from './components/Invoicing/Invoicing';
import _ from 'lodash';

const FormItem = Form.Item;
const Option = Select.Option;
const { Content } = Layout;
const confirm = Modal.confirm;

const tabList = [{
  key: 'info',
  tab: 'Info',
}, {
  key: 'venues',
  tab: 'Locations',
}, {
  key: 'statistics',
  tab: 'Statistics',
}, {
  key: 'appsUsers',
  tab: 'Apps Users',
}, {
  key: 'warehouses',
  tab: 'Warehouses',
}, {
  key: 'survey',
  tab: 'Survey',
}, {
  key: 'invoicing',
  tab: 'Invoicing',
}];

class SingleOrg extends React.Component {
  constructor() {
    super();
    this.formRef = React.createRef();
    this.state = {
      organisation: {
        title: '',
        address: '',
        description: '',
        contacts: {},
        address_components: {},
        active_while_corona: 0
      },
      types: {
        business_types: [],
        business_categories: [],
        partnership_plans: [],
        partner_types: [],
        customer_sources: [],
        business_subcategories: []
      },
      putREQUEST: false,
      putFAILURE: false,
      putSUCCESS: false,
      organisationId: '',
      visibleNotes: false,
      activeTab: 'info',
      visibleAddVenue: false,
      contacts_list: [],
      visibleContacts: false,
      ignoredContacts: [],
      all_contacts: [],
      totalNumberTable: 0,
      venueIds: [],
      initialValues: {}
    }
  }

  componentDidMount() {
    const organisationId = this.props.match.params.organisationId;

		let activeTab = 'info';
    let paramFromUrl = {};
    if (window.location.search) {
      paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    }
    if (Object.keys(paramFromUrl).length) {
      Object.keys(paramFromUrl).forEach((param) => {
        if (paramFromUrl[param]) {
          activeTab = param;
        }
      });
    }
    this.setState({
    	activeTab: activeTab,
      organisationId
    }, () => {
    	if (activeTab !== 'statistics') {
    		this.getTypeFields(() => {
	    		this.getSingleOrganisation()
	    	});
    	}
    })
  }
  getTypeFields = (next = false) => {
    fetchJSON('/api/v1/organisation/types', {
      method: 'get'
    }).then(response => {
      if (response.success) {
        this.setState({
          types: response.data
        }, () => {
          next();
        });
      }
     }).catch(error => {
        console.log(error);
     });
  }

  getSingleOrganisation = () => {
  	this.setState({
      putREQUEST: true
    })
    const { organisationId } = this.state;
    fetchJSON(`/api/v1/organisation/${organisationId}`, {
      method: 'get'
    }).then(response => {
      if (response.success) {
        const venueIds = [];
        if (response.data.venues) {
          const venues = response.data.venues;
          if (venues) {
            venues.forEach((venue) => {
              const venueId = _.get(venue, 'venues.id');
              if (venueId && venueIds.indexOf(venueId) === -1) {
                venueIds.push(venueId);
              }
            })
          }
        }
        const organisation = response.data || {};
        const addressGroup = {
          address: organisation.address,
          addressComponents: organisation.address_components,
          coordinates: organisation.coordinates,
          correct: true
        };
        const initialValues = {
          name: organisation.name,
          business_name: organisation.business_name,
          is_public: organisation.is_public,
          status: organisation.status,
          partner_type_id: organisation.partner_type_id,
          customer_source_id: organisation.customer_source_id,
          active_while_corona: organisation.active_while_corona,
          addressInput: organisation.address,
          delay_notifications: organisation.delay_notifications
        };
      	this.setState({
          putREQUEST: false,
          organisation,
          venueIds,
          initialValues,
          addressGroup,
          addressInput: organisation.address
        }, () => {
          const { activeTab } = this.state;
          
          if (activeTab === 'venues' || activeTab === 'info') {
            this.getContactsList();
          }
          if (response.data.contacts_list) {
            if (response.data.contacts_list.length) {
              const contacts_list = response.data.contacts_list.map(item => {
                return item.contact;
              });
              if (contacts_list.length) {
                this.setState({
                  contacts_list
                })
              }
            }
          }
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          putREQUEST: false
        });
     });
  }
  handleSubmit = (values) => {
    this.setState({
      putREQUEST: true
    })
    const { organisationId, addressGroup } = this.state;
    addressGroup.address = addressGroup.formatted_address;
    values.addressGroup = addressGroup;
    console.log(values, addressGroup, 'addressGroup values')
    fetchJSON(`/api/v1/organisation/${organisationId}`, {
      method: 'post',
      body: values
    }).then(response => {
      // console.log(response);
      if (response.success) {
        message.success('Successfully updated');
        this.setState({
          putREQUEST: false,
          putSUCCESS: true,
          putFAILURE: false
        }, this.getSingleOrganisation);
      } else {
        this.setState({
          putREQUEST: false,
          putFAILURE: true,
          putSUCCESS: false,
        });
        message.error(response.message);
      }
      setTimeout(() => {
        this.setState({
          errorMessage: ''
        });
      }, 3000)
    })
    .catch(error => {
      //console.log(error);
      this.setState({
        putSUCCESS: false,
        putREQUEST: false,
        putFAILURE: true,
      });
    });  
  }
  deleteOrganisation = () => {
    confirm({
      title: `Are you sure you want to delete this organisation?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            putREQUEST: true
            })
          const organisationId = this.state.organisationId;
          fetchJSON(`/api/v1/organisation/delete/${organisationId}`, {
            method: 'put',
          }).then(response => {
            console.log(response);
            if (response.success) {
              message.success('Successfully updated');
              this.setState({
                putREQUEST: false,
                putSUCCESS: true,
                putFAILURE: false
              });
              window.location.href = "/organisations";
              resolve();
            } else {
                this.setState({
                  putREQUEST: false,
                  putFAILURE: true,
                  putSUCCESS: false,
                });
                message.error(response.message);
              }
          }).catch(error => {
            console.log(error);
              reject();
              this.setState({
                putSUCCESS: false,
                putREQUEST: false,
                putFAILURE: true,
              });
            })
          }).catch(() => console.log('errors'));
        },
      onCancel() {},
    });
  }
  checkAddress = (rule, value, callback) => {
  	callback();
    return;
  }
  updateState = (key, value) => {
    this.setState({ [key]: value });
  }
  onTabChange = (key, type) => {
    const { organisationId } = this.state;
    this.props.history.push(`/organisations/${organisationId}?${key}=1`);
    this.setState({ [type]: key });

    if (key === 'venues' || key === 'info') {
  		this.getTypeFields(() => {
    		this.getSingleOrganisation()
    	});
  	}
  }
  eventCreated = (idVenue) => {
    const newVenuesIds = [].concat(this.state['venueIds']);
    if (newVenuesIds.indexOf(idVenue) === -1) {
      newVenuesIds.push(idVenue)
    }
    this.setState({
      venueIds: newVenuesIds,
      visibleAddVenue: false
    })
  }

  getContactsList = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        putREQUEST: true
        })
      const organisationId = this.state.organisationId;
      fetchJSON(`/api/v1/contact/list/${organisationId}`, {
        method: 'put',
      }).then(response => {
        if (response.success) {
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false,
            all_contacts: response.data
          });
          resolve();
        } else {
            this.setState({
              putREQUEST: false,
              putFAILURE: true,
              putSUCCESS: false,
            });
          }
      }).catch(error => {
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
          reject();
        })
      }).catch(() => console.log('errors'));
  }
  showContacts = () => {
    this.setState({
      visibleContacts: true
    })
  }
  hideContacts = () => {
    this.setState({
      visibleContacts: false
    })
  }
  addContactFromBook = (id, name, phone, email, note, office_phone, tag) => {
    const { contacts_list, ignoredContacts } = this.state;
    const newItem = {id: `from_book_${id}`, name: name, phone: phone, email: email, note: note, office_phone: office_phone, tag: tag};
    contacts_list.push(newItem);
    ignoredContacts.push(id)
    this.setState({
      contacts_list,
      ignoredContacts
    });
    this.hideContacts();
  }
  removeContact = (index) => {
    const { contacts_list } = this.state;
    const newList = contacts_list.filter((contact, i) => {
      return i !== index;
    });

    this.setState({
      contacts_list: newList
    });
  }
  deleteContact = (index, contact_id) => {
    confirm({
      title: 'Are you sure you want to delete contact?',
      onOk: () => {
        return new Promise((resolve, reject) => {
          const { contacts_list } = this.state;
          const item = contacts_list[index];
          if (item.id === 'new' || (typeof item.id === 'string' && item.id.indexOf('from_book_') > -1)) {
            if (item.id.indexOf('from_book_') > -1) {
              let { ignoredContacts } = this.state;
              const idIndex = ignoredContacts.indexOf(Number(item.id.substr(10)));
              if (idIndex > -1) {
                ignoredContacts.splice(idIndex, 1);
                this.setState({
                  ignoredContacts
                })
              }
            }
            this.removeContact(index);
            resolve();
            return;
          };

          this.setState({
            fetchingStatus: true
          })
          fetchJSON(`/api/v1/contact/${contact_id}`, {
            method: 'delete'
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                fetchingStatus: false,
              });
              this.removeContact(index);
              resolve();
            } else {
              this.setState({
                fetchingStatus: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                fetchingStatus: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  addContact = () => {
    const { contacts_list } = this.state;
    const newItem = {id: 'new', name: '', phone: '', email: '', note: '', office_phone: '', tag: ''};
    contacts_list.push(newItem);
    this.setState({
      contacts_list
    });
  }
  getTotalNumberTable = () => {
    return this.state.totalNumberTable
  }
  triggerAddressChanges = (value) => {
    this.formRef.current.setFieldsValue({
      addressInput: value.formatted_address
    });
    this.setState({
      addressGroup: value,
      addressInput: value.formatted_address,
      formatted_address: value.formatted_address
    });
  }
  render() {
    const {
    	putREQUEST,
    	organisation,
    	types,
    	organisationId,
    	visibleNotes,
    	activeTab,
    	visibleAddVenue,
      contacts_list,
      visibleContacts,
      all_contacts,
      ignoredContacts,
      totalNumberTable,
      venueIds,
      initialValues,
      addressError,
      addressGroup,
      addressInput
    } = this.state;

    const isContactsBook = all_contacts.venuesContacts && all_contacts.venuesContacts.length;

    return (
      <Content className="fill-bg-table">
        <h2 className="table-title">
          Organisation "{organisation.name}" 
          <Button onClick={  () => { this.updateState('visibleNotes', true) } } style={{float: 'right'}}>
            <QuestionCircleOutlined /> Notes
          </Button>
          <Button type="danger" style={{float: 'right', 'marginRight': '10px'}} onClick={this.deleteOrganisation} >
              <DeleteOutlined /> Delete 
          </Button>
        </h2>
        { visibleNotes ? 
          <Notes 
            visible={visibleNotes} 
            handleClose={() => { this.updateState('visibleNotes', false) }} 
            sourceId={organisationId} source="organisation" 
          /> : null }
        <Card
        	tabList={tabList}
        	activeTabKey={activeTab}
        	onTabChange={(key) => { this.onTabChange(key, 'activeTab'); }}
        	>
	        { activeTab === 'info' ?
            <div>
              { Object.keys(initialValues).length ? 
              <Form 
                style={{minHeight: '300px'}} 
                onFinish={this.handleSubmit} 
                ref={this.formRef} 
                initialValues={initialValues}
                >
                <Spin spinning={putREQUEST} >
                  <Row>
                    <Col span={8}>
                      <FormItem>
                        <Row>
                          <label className="form-label-custom">Name</label>
                        </Row>
                        <Row>
                          <FormItem name="name" rules={[{ required: true, message: 'Please enter a name!' }]}>
                            <Input placeholder="Name"/>
                          </FormItem>
                        </Row>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem>
                        <Row>
                          <label className="form-label-custom">Business name</label>
                        </Row>
                        <Row>
                          <FormItem style={{width: '100%'}} name="business_name" rules={[{ required: true, message: 'Please enter a business name!' }]}>
                            <Input placeholder="Business name"/>
                          </FormItem>
                        </Row>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem>
                        <Row>
                          <label className="form-label-custom">Type of privacy</label>
                        </Row>
                        <Row>
                          <Form.Item
                            name="is_public"
                            rules={[{ required: true, message: 'Please choose type of privacy!' }]}
                            noStyle
                            >
                             <Select>
                              <Option value={1} style={{color: 'green'}}>Public</Option>
                              <Option value={0} style={{color: 'blue'}}>Private</Option>
                            </Select>
                          </Form.Item>
                        </Row>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <FormItem>
                        <Row>
                          <label className="form-label-custom">Organisation status</label>
                        </Row>
                        <Row>
                          <Form.Item
                            name="status"
                            rules={[{ required: true, message: 'Please choose a status!' }]}
                            noStyle
                            >
                             <Select>
                              <Option value={1}>Active</Option>
                              <Option value={0} style={{color: 'blue'}}>Inactive</Option>
                              <Option value={2} style={{color: 'red'}}>Suspended</Option>
                              <Option value={3}>Onboarding</Option>
                            </Select>
                          </Form.Item>
                        </Row>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem>
                        <Row>
                          <label className="form-label-custom">Delay notifications</label>
                        </Row>
                        <Row>
                          <Form.Item
                            name="delay_notifications"
                            rules={[{ required: true, message: '' }]}
                            noStyle
                            >
                             <Select>
                              <Option value={1} style={{color: 'green'}}>On</Option>
                              <Option value={0} style={{color: 'red'}}>Off</Option>
                            </Select>
                          </Form.Item>
                        </Row>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={8}>
                      <FormItem>
                        <Row>
                          <label className="form-label-custom">Type of partner</label>
                        </Row>
                        <Row>
                          <FormItem
                            name="partner_type_id"
                            rules={[{ required: true, message: 'Please choose type!' }]}
                            noStyle
                          >
                            <Select>
                              {types.partner_types.map((partner_type, key) => <Option key={key} value={partner_type.id}>{partner_type.title}</Option>) }
                            </Select>
                          </FormItem>
                        </Row>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem>
                        <Row>
                          <label className="form-label-custom">How did they find us?</label>
                        </Row>
                        <Row>
                          <FormItem
                            name="customer_source_id"
                            rules={[{ required: true, message: 'Please choose the way!' }]}
                            noStyle
                          >
                            <Select>
                              {types.customer_sources.map((customer_source, key) => <Option key={key} value={customer_source.id}>{customer_source.title}</Option>) }
                            </Select>
                          </FormItem>
                        </Row>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem>
                        <Row>
                          <label className="form-label-custom">Active during coronavirus</label>
                        </Row>
                        <Row>
                          <FormItem
                            name="active_while_corona"
                            rules={[{ required: true, message: 'Please choose status!' }]}
                            noStyle
                          >
                            <Select>
                              <Option value={1}>Yes</Option>
                              <Option value={0}>No</Option>                          
                            </Select>
                          </FormItem>
                        </Row>
                      </FormItem>
                    </Col>
                  </Row>
                  <FormItem>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <label className="form-label-custom">Address</label>
                        </Row>
                        <Row>
                         <AddressField
                            value={addressGroup}
                            wrapIntoFormItem={true}
                            triggerChanges={(addressGroup) => this.triggerAddressChanges(addressGroup)}
                          />
                        </Row>
                        { addressError ? 
                          <div style={{marginTop: '20px'}}><Alert message={addressError} type="error" /></div>
                        : null }
                      </Col>
                    </Row>
                  </FormItem>

                  <FormItem >
                    <Row style={{float: 'right', marginTop: '12px', width: '100%'}}>
                      <Button type="primary" htmlType="submit">
                        Save changes
                      </Button>
                    </Row>
                  </FormItem>
                </Spin>
              </Form> : 
              <Skeleton
                loading={true}
              /> }
            </div>
        	: null }
        	{ activeTab === 'venues' ?
        		<div style={{marginTop: '-16px'}}>
        			<Row style={{display: 'inline-flex'}}>
                <Button size="small" type="primary" onClick={ () => { this.updateState('visibleAddVenue', true) } }>
                  Add Location
                </Button>
                <p style={{fontWeight: '500', fontSize: '15px', marginLeft: '10px'}}>
                  Quantity: {totalNumberTable}
                </p>
              </Row>
              <Row>
                <VenuesTable 
                  history={this.props.history}
                  updateTotalNumberTable={ (quantity) => { this.updateState('totalNumberTable', quantity) }}
                  getTotalNumberTable={this.getTotalNumberTable}
                  venueIds={venueIds}
                  inOrg={true}
                />
              </Row>
              { visibleAddVenue ? 
                <AddVenue 
                  organisationId={organisationId} 
                  eventCreated={this.eventCreated} 
                  visible={visibleAddVenue} 
                  history={this.props.history} 
                  handleClose={ () => { this.updateState('visibleAddVenue', false) } } /> 
              : null }
        		</div>
        	: null }
        	{ activeTab === 'statistics' ?
        		<Statistics source='organisation' sourceId={organisationId} />
        	: null }
          { activeTab === 'appsUsers' ?
            <AppsUsers source='organisation' organisationId={organisationId} />
          : null }

          { activeTab === 'pricing_features' ? <Pricing organisationId={organisationId} /> : null }
          { activeTab === 'warehouses' ? <Warehouses organisationId={organisationId} /> : null }
          { activeTab === 'survey' ?
            <Survey organisationId={organisationId} />
          : null }
          { activeTab === 'invoicing' ?
            <Invoicing organisationId={organisationId} />
          : null }

      	</Card>
      </Content>
    );
  }
}

export default SingleOrg;
