import React from 'react';
import moment from 'moment';
import { ClockCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Row, Select, DatePicker, Tooltip } from 'antd';
import ControlledRangePicker from './components/ControlledRangePicker';

import 'moment/locale/en-gb';
import locale from 'antd/es/date-picker/locale/en_GB';

import './styles.less';

const Option = Select.Option;
const { MonthPicker, WeekPicker } = DatePicker;

class DateSelectChoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'lifetime',
      dateFilterType: null,
      dateValue: null
    };
  }
  componentDidMount() {
    this.mounted = true;
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.shouldUpdateTurnOffOverrides) {
      this.filterTypeDeselected(true);
      this.props.turnOffShouldUpdate()
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  filterTypeDeselected = (fromOutside = false) => {
    this.setState({
      dateFilterType: null,
      dateValue: null
    }, () => {
      if (!fromOutside) {
        this.props.updateDataOnTimeChange(false, null)
      } 
    })
  }
  handleDateChange = (value, type) => {
    const { dateValue } = this.state;
    let shouldUpdate = false;
    let typeDates = type;
    if (moment.isMoment(value) || type === 'range') {
      switch (type) {
        case 'day':
          shouldUpdate =  !value.isSame(dateValue);
          break;
        case 'week':
          shouldUpdate = !moment.isMoment(dateValue) || (dateValue.isoWeek() !== value.isoWeek());
          break;
        case 'month':
          shouldUpdate = !moment.isMoment(dateValue) || (dateValue.month() !== value.month());
          break;
        case 'year':
          shouldUpdate = !moment.isMoment(dateValue) || (dateValue.year() !== value.year());
          break;
        case 'range':
          typeDates = this.props.daily ? 'range_daily' : 'range_weekly';
          shouldUpdate = true;
          break;
        default:
          break;
      }
    }
    this.setState({
      dateValue: value
    })
    if (shouldUpdate) {
      this.props.updateDataOnTimeChange(true, value, typeDates);
    }  
  }
  posibbleChoices = () => {
    const allOptions = [{
        value: 'week', 
        name: 'Week'
      }, {
        value: 'month', 
        name: 'Month'
      }, {
        value: 'year', 
        name: 'Year'   
      }, 
      // {
      //   value: 'custom', 
      //   name: 'Custom'
      // }
    ];
    return this.props.daily ? [{
        value: 'day', 
        name: 'Day'
      }, ...allOptions] : allOptions
    // return this.props.daily ? [{
    //     value: 'day', 
    //     name: 'Day'
    //   }, ...allOptions] : allOptions
  }
  getYearsOptions = () => {
    const years = [];
    for (let i = moment().year(); i >= 2017; i--) {
      years.push(i)
    }
    return years.map((year, i) => <Option key={i} value={year}>{year}</Option>)
  }

  render() {
    const { dateFilterType } = this.state;
    return (
      <div>
        <Row>
          <h3>Customize time period</h3>
          { this.props.incorrectDataWarning ? 
            <Tooltip title="Please note that data may be slightly inaccurate in case of several locations with different timezones choosen.">
            <QuestionCircleOutlined
              style={{color: '#5856d6', fontSize: '16px', marginLeft: '8px', lineHeight: '1.8'}} /></Tooltip>
            : null }
        </Row>
        <Row style={{marginBottom: '20px', display: 'flex'}}>   
          <Select 
            suffixIcon={<ClockCircleOutlined />}
            allowClear={true} style={{width: '200px'}} 
            placeholder="Select time range"
            value={dateFilterType || "Select time range"}
            onSelect={(value) => {
              this.setState({ dateFilterType: value, dateValue: null });
              if (dateFilterType) {
                this.props.updateDataOnTimeChange(false, null)
              }   
            }}
            onChange={(value) => {
              if (!value) this.filterTypeDeselected()
            }}
          >
            { this.posibbleChoices().map((option, i) => 
              <Option key={i} value={option.value}>{option.name}</Option>
            )}
          </Select>
          <div style={{marginLeft: '8px'}}>
            { dateFilterType === 'day' &&
              <DatePicker
                onChange={(value) => this.handleDateChange(value, dateFilterType)}
                allowClear={false}
                />
            }
            { dateFilterType === 'week' && 
              <WeekPicker
                placeholder={`Select week`}
                allowClear={false}
                locale={locale}
                onChange={(value) => this.handleDateChange(value, dateFilterType)}
              /> }
              { dateFilterType === 'month' &&
               <MonthPicker
                placeholder="Select month"
                allowClear={false}
                onChange={(value) => this.handleDateChange(value, dateFilterType)}
                /> }
              { dateFilterType === 'year' &&
               <Select
                placeholder="Select year"
                allowClear={false} style={{width: '200px'}} 
                onSelect={(year) => { 
                  const moment_obj = moment().year(year); 
                  this.handleDateChange(moment_obj, dateFilterType) 
                }}
                >
                  { this.getYearsOptions() }
                </Select> 
              }
            { dateFilterType === 'custom' && 
              <ControlledRangePicker
                daily={this.props.daily}
                onChange={(value) => this.handleDateChange(value, 'range')}
              />
            }
          </div>
        </Row>
      </div>
    );
  }
}

export default DateSelectChoice;