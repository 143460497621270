import React from 'react';
import Settings from 'services/config/Settings';
import { Layout, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import SupernovasTable from './components/SupernovasTable';

const { Content } = Layout;

class Supernovas extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalNumberTable: 0
    }
  }
  componentDidMount() {
    document.title = `Supernovas | ${Settings.title}`;
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getTotalNumberTable = () => {
    return this.state.totalNumberTable
  }
  updateState = (key, value) => {
    if (this.mounted) {
      this.setState({ [key]: value });
    }
  }
  render() {
    const { totalNumberTable } = this.state;
    return (
      <Content className="fill-bg-table">
        <Row gutter={8} style={{marginTop: '-16px'}}>
          <Col span={12}>
            <h1 className="table-title" style={{marginTop: '4px', display: 'inline-flex'}}>
              Supernovas
              <Link to={"/monitor"} className="ant-btn ant-btn-sm" style={{margin: '6px 0px 0px 8px'}}>
                Monitor
              </Link>
              <p style={{margin: '6px 0px 0px 8px', fontWeight: '500', fontSize: '15px'}}>
                Quantity: {totalNumberTable}
              </p>
            </h1>
          </Col>
        </Row>
        <SupernovasTable 
          history={this.props.history}
          updateTotalNumberTable={ (quantity) => { this.updateState('totalNumberTable', quantity) }}
          getTotalNumberTable={this.getTotalNumberTable}
        />
      </Content>
    )
  }
};

export default Supernovas
