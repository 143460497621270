const Settings = {
	// branding name for our app
	title: 'Electron-to-Go Control Panel',
	analytics: {
		//null
	},
	trackJS: {
	  token: "5366b72791944ae1bb547dc90956ce46",
    application: "electron-to-go-prod"
	},
	trackJsEnable: false,
	googleApiKey: process.env.REACT_APP_GMAPS
};
 
if (process.env.NODE_ENV !== "development") {
	Settings.trackJsEnable = true;
}

export default Settings;
