import React from 'react';
import { TableOutlined } from '@ant-design/icons';
import { Table, Button, Col, Row, Modal } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import AddWarehouse from './AddWarehouse';
import AddSupernovasToWarehouse from './AddSupernovasToWarehouse';
import RemoveSupernovasFromWarehouse from './RemoveSupernovasFromWarehouse';

const { confirm } = Modal;


class Warehouses extends React.Component {
  state = {
    filteredInfo: {},
    sortedInfo: {},
    searchText: '',
    filtered: false,
    list: [],
    supernovasSource: [],
    isFetching: false,
    visibleAddUser: false,
    directoryId: '',
    visibleAddSupernovas: false,
    visibleRemoveSupernovas: false
  };
  componentDidMount() {
    document.title = 'Warehouses';
    this.getList();
  }
  getList = () => {
    const { organisationId } = this.props;
    fetchJSON(`/api/v1/organisation/warehouses/${organisationId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      this.setState({
        list: response.data.warehouses,
        supernovasSource: response.data.resources ? response.data.resources.supernovas : [],
        isFetching: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false
      });
    });
  }
  columns = () => {
    // let { sortedInfo, filteredInfo } = this.state;
    // sortedInfo = sortedInfo || {};
    // filteredInfo = filteredInfo || {};
    return [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Location IDs',
      dataIndex: 'groups',
      key: 'groups',
    },
    {
      title: 'Supernovas',
      dataIndex: 'supernovas',
      key: 'supernovas',
      render: (text, record) => {
        return (
          <React.Fragment>
            {
              record.supernovas ? 
              <Button onClick={() => this.openSupernovasModal(record.supernovasList)} className="ant-btn ant-btn-sm">
                {record.supernovas}
              </Button>
              :
              '0'
            }
          </React.Fragment>
        )
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <React.Fragment>
             <span>
              <Button onClick={() => this.openAddSupernovas(record.id)} className="ant-btn ant-btn-sm">
                Add supernovas
              </Button>
              <Button onClick={() => this.openRemoveSupernovas(record.id)} className="ant-btn ant-btn-sm" style={{marginLeft: '8px'}}>
                Remove supernovas
              </Button>
            </span>
          </React.Fragment>
        )
      }
    }];
  }
  
  getData = () => {
    const data = [];
    const { list } = this.state;
    if (!list) return data;

    list.forEach((item, i) => {
      console.log('item.resourses.supernovas', item.resourses.supernovas);
      data.push({
        key: i,
        id: item.id,
        name: item.title,
        groups: item.resourses.groups.length,
        supernovas: item.resourses.supernovas.length,
        supernovasList: item.resourses.supernovas
      });
    });
    return data;
  }
  handleTableChange = (pagination, filters, sorter) => {
    console.log('handleTableChange', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  openModalAddWarehouse = (platform) => {
   this.setState({
      visibleAddWarehouse: true
    })
  }
  openAddSupernovas = (directoryId) => {
    this.setState({
      directoryId
    }, () => {
      this.setState({
        visibleAddSupernovas: true
      })
    })
  }
  openRemoveSupernovas = (directoryId) => {
    this.setState({
      directoryId
    }, () => {
      this.setState({
        visibleRemoveSupernovas: true
      })
    })
  }
  openSupernovasModal = (list) => {
    //console.log('list', list);
    const { supernovasSource } = this.state;
    //console.log('supernovasSource', supernovasSource);
    const content = [];

    list.forEach((snId) => {
      supernovasSource.forEach((supernova) => {
        if (supernova.id === snId) {
          if (content.indexOf(supernova.string_id) === -1) {
            content.push(supernova.string_id);
          }
        }
      });
    });
    const listResult = <ol>
    {content.map((stringId, i) => <li key={i}>{stringId}</li>)}
    </ol>;
    confirm({
      title: 'Supernovas',
      icon: <TableOutlined />,
      content: listResult,
      cancelButtonProps: {style: {display: 'none'}},
      onOk() {
        console.log('OK');
      },
      closable: true,
      maskClosable: true
    });
  }
  render() {
    const { visibleAddWarehouse, directoryId, visibleAddSupernovas, visibleRemoveSupernovas } = this.state;
    const { isFetching, pagination, organisationId } = this.props;
    return (
      <React.Fragment>
         <React.Fragment>
            <Row gutter={8} style={{marginBottom: '15px'}}>
              <Col span={8}>
                <h1 className="table-title">Warehouses
                  <Button style={{marginLeft:'8px'}} type="primary" size="small" onClick={ () => { this.openModalAddWarehouse() } }>
                    Add warehouse
                  </Button>
                </h1>
              </Col>
              <Col span={16}>
              </Col>
            </Row>
            <Table
              columns={ this.columns() }
              loading={ isFetching }
              dataSource={ this.getData('nebula') }
              pagination={ pagination }
              onChange={ this.handleTableChange }
            />
          </React.Fragment>
          { visibleAddWarehouse ? <AddWarehouse
            organisationId={organisationId}
            visible={visibleAddWarehouse}
            renderList = { this.getList }
            handleClose={ () => { this.modalVisible('visibleAddWarehouse', false) } } />
            : null
          }
          { visibleAddSupernovas ? <AddSupernovasToWarehouse
            organisationId={organisationId}
            visible={visibleAddSupernovas}
            directoryId={directoryId}
            renderList = { this.getList }
            handleClose={ () => { this.modalVisible('visibleAddSupernovas', false) } } />
            : null
          }
          { visibleRemoveSupernovas ? <RemoveSupernovasFromWarehouse
            organisationId={organisationId}
            visible={visibleRemoveSupernovas}
            directoryId={directoryId}
            renderList = { this.getList }
            handleClose={ () => { this.modalVisible('visibleRemoveSupernovas', false) } } />
            : null
          }
      </React.Fragment>
    )
  }
}

export default Warehouses;