import React from 'react';
import { Col, Row, Spin, Switch, Modal } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const confirm = Modal.confirm;


class Survey extends React.Component {
  state = {
    isFetching: false,
    survey_before_release: false,
    hide_email_field: false,
    show_second_question_jp: false
  };
  componentDidMount() {
    document.title = 'Survey';
    this.getSurveySettings();
  }
  getSurveySettings = () => {
    this.setState({
      isFetching: true
    });
    const { organisationId } = this.props;
    fetchJSON(`/api/v1/organisation/survey/${organisationId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      let hide_email_field = false;
      let show_second_question_jp = false;

      if (response.data) {
        const { survey_hide_fields } = response.data;
        if (survey_hide_fields && Array.isArray(survey_hide_fields) && survey_hide_fields.length) {
          if (survey_hide_fields.indexOf('email') > -1) {
            hide_email_field = true;
          }
        }
        const { survey_show_fields_second_step } = response.data;
        if (survey_show_fields_second_step && Array.isArray(survey_show_fields_second_step) && survey_show_fields_second_step.length) {
          if (survey_show_fields_second_step.indexOf('s_paid_services') > -1) {
            show_second_question_jp = true;
          }
        }
      }
      this.setState({
        survey_before_release: response.data.survey_before_release ? true : false,
        hide_email_field,
        show_second_question_jp,
        isFetching: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false
      });
    });
  }
  onStatusChange = (checked, fieldName) => {
    const { organisationId } = this.props;
    console.log('fieldName', fieldName, 'checked', checked)
    confirm({
      title: `Change survey status to ${checked ? 'Enabled' : 'Disabled'}?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            isFetching: true
          })
          fetchJSON(`/api/v1/organisation/survey/${organisationId}`, {
            method: 'post',
            body: {
              [fieldName]: checked
            }
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                isFetching: false,
                [fieldName]: checked
              });
              this.getSurveySettings();
              resolve()
            } else {
              this.setState({
                isFetching: false,
                [fieldName]: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                isFetching: false,
                [fieldName]: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  render() {
    const { 
      isFetching,
      survey_before_release,
      hide_email_field,
      show_second_question_jp
    } = this.state;
    return (
      <React.Fragment>
        <Spin spinning={isFetching}>
          <Row gutter={8} style={{marginBottom: '15px'}}>
            <Col span={8}>
              <h1 className="table-title">Survey settings</h1>
            </Col>
          </Row>
          <Row gutter={8} style={{marginBottom: '15px'}}>
            <Col span={8}>
              <Row>
                <label className="form-label-custom">Activate Survey</label>
                <Switch checked={survey_before_release} onChange={(e) => this.onStatusChange(e, 'survey_before_release')} />
              </Row>
            </Col>
          </Row>
          {survey_before_release ? 
            <React.Fragment>
              <Row gutter={8} style={{marginBottom: '15px'}}>
                <Col span={8}>
                  <Row>
                    <label className="form-label-custom">Hide email field</label>
                    <Switch checked={hide_email_field} onChange={(e) => this.onStatusChange(e, 'hide_email_field')} />
                  </Row>
                </Col>
              </Row>
              <Row gutter={8} style={{marginBottom: '15px'}}>
                <Col span={8}>
                  <Row>
                    <label className="form-label-custom">Show second question for Japan</label>
                    <Switch checked={show_second_question_jp} onChange={(e) => this.onStatusChange(e, 'show_second_question_jp')} />
                  </Row>
                </Col>
              </Row>
            </React.Fragment>
            : null
          }
        </Spin>
      </React.Fragment>
    )
  }
};

export default Survey;