import React from 'react';
import { Card, Timeline, Spin } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import moment from 'moment-timezone';

class UserMessages extends React.Component {
	state = {
		isFetching: false,
		listMessages: [],
		timelineFetching: false
	}


  getListMessages = () => {
  	this.setState({
			timelineFetching: true
		})
    fetchJSON(`/api/v1/user/sms/list/${this.props.userId}`, {
			method: 'get'
		}).then(response => {
			console.log(response);
			if (response.success) {
				this.setState({
					timelineFetching: false,
					listMessages: response.data
				});
			}
     }).catch(error => {
				console.log(error);
				this.setState({
					timelineFetching: false
				});
     });
  }
   componentDidMount() {
  	this.getListMessages();
  }

  renderTimeline = () => {
  	const { listMessages } = this.state;
  	if (!listMessages.length) return null;
  	return listMessages.map((item, index) => {
  		let senddate = moment.unix(item.senddate).tz('America/Los_Angeles').format('DD MMM YYYY h:mm A');
  		return (
  			<Timeline.Item key={index}>
  				<p><b>Text:</b> { item.template ? item.template.value : item.text }</p>
  				<p><b>Date:</b> { senddate }</p>
  				<p><b>Delivery status:</b> { item.delivery_status ? item.delivery_status : 'unknown' }</p>
  			</Timeline.Item>
			)
  	})

  }
  render() {
  	const { isFetching, listMessages, timelineFetching } = this.state;
    return (
				<Card loading = { isFetching } bordered={false}>
          { listMessages.length ?			    
				    <Spin spinning={timelineFetching}>
				        <Timeline >
							    { this.renderTimeline() }
						</Timeline> 
					</Spin> : null }
	      </Card>
    )
  }
};

export default UserMessages;
