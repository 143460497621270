import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import $ from 'jquery';
import "./WorkingHours.less";
import _ from 'lodash';

require('../libs/datepair');
require('timepicker');

const confirm = Modal.confirm;

class WorkingHours extends React.Component {
	state = {
		week: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
		availableWeekDays: [],
		slotTemplate: {
			working_days: [],
			working_hours: {
				start: '9:00 AM',
				end: '6:00 PM'
			},
			break_hours: {
				start: '12:00 PM',
				end: '1:00 PM'
			},
			break_hours_enabled: 0,
			always_open: false
		}
	}
	componentDidMount() {
		this.checkBuzyDays();
		this.initDatepicker();
	}
	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.businessHours.length === 0 && this.props.businessHours.length > 0) {
			this.checkBuzyDays();
			this.initDatepicker();
		} else if (!_.isEqual(prevProps.businessHours, this.props.businessHours)) {
			setTimeout(() => {
				this.initDatepicker();
				this.checkBuzyDays();
			}, 5);
		}
	}
	initDatepicker() {
		var workingTimeSlots = $('.working-time-slot:not(.initialized)');

		$.each(workingTimeSlots, (key, slot) => {
			var workingHours = $(slot).find('.datepair-working-hours'),
				workingTimes = workingHours.find('.time');

			workingTimes.timepicker({
				'timeFormat': 'g:i A'
			});

			workingHours.datepair();

			var breakingHours = $(slot).find('.datepair-break-hours');
			var breakOption = {
				timeFormat: 'g:i A'
			};

			var startWorking = workingHours.find('.time.start');
			var endWorking = workingHours.find('.time.end');

			var startBreak = breakingHours.find('.time.start');
			var endBreak = breakingHours.find('.time.end');

			if (startWorking && startWorking.val()) {
				breakOption.minTime = startWorking.val();
			}
			if (endWorking && endWorking.val()) {
				breakOption.maxTime = endWorking.val();
			}

			breakingHours.find('.time').timepicker(breakOption);
			breakingHours.datepair();

			$(startWorking).on('change', (e) => {
				this.jqToReactChange(e);
				var value = e.currentTarget.value;
				if (value) {
					breakingHours.find('.time').timepicker('option', 'minTime', value);
					setTimeout(() => {
						this.checkPartnerValue(endWorking);
					}, 10);
				}
			});
			$(endWorking).on('change', (e) => {
				this.jqToReactChange(e);
				var value = e.currentTarget.value;
				if (value) breakingHours.find('.time').timepicker('option', 'maxTime', value);
				setTimeout(() => {
					this.checkPartnerValue(startWorking);
				}, 10);
			});

			$(startBreak).on('change', (e) => {
				this.jqToReactChange(e);
				setTimeout(() => {
					this.checkPartnerValue(endBreak);
				}, 10);
			});
			$(endBreak).on('change', (e) => {
				this.jqToReactChange(e);
				setTimeout(() => {
					this.checkPartnerValue(startBreak);
				}, 10);
			});

			$(slot).addClass('active initialized');
		});
	}
	checkPartnerValue = (partner) => {
		const partnerValue = partner.val(),
			slotIndex = partner.data('slotIndex'),
			listName = partner.data('listName'),
			key = partner.data('key');

		const { businessHours, setBusinessHours } = this.props;

		if (!businessHours[slotIndex][listName]) return;
		if (!businessHours[slotIndex][listName][key]) return;

		if (businessHours[slotIndex][listName][key] !== partnerValue) {
			const newSlots = [...businessHours];
			newSlots[slotIndex][listName][key] = partnerValue;
			setBusinessHours({
				businessHours: newSlots
			});
		}
	}
	componentWillUnmount = () => {
		$(".working-time-slot").find(".time").each(function () {
			$(this).off("change");
		});
	}
	jqToReactChange = (e) => {
		var target = $(e.currentTarget),
			slotIndex = target.data('slotIndex'),
			listName = target.data('listName'),
			key = target.data('key');

		this.changeInput(e, slotIndex, listName, key);
	}
	checkBuzyDays = () => {
		const { week } = this.state;
		const { businessHours } = this.props;
		const buzyDays = [];
		businessHours.forEach(timeSlot => {
			if (timeSlot.working_days && timeSlot.working_days.length) {
				buzyDays.push(...timeSlot.working_days);
			}
		});

		const availableWeekDays = week.filter(day => {
			return buzyDays.indexOf(day) === -1;
		});
		this.setState({
			availableWeekDays
		})
	}
	changeWeekDay = (e, weekDay, slotIndex) => {
		const { availableWeekDays } = this.state;
		const { businessHours, setBusinessHours } = this.props;
		const newSlots = [...businessHours];
		let availableList = [];

		const checked = e.currentTarget.checked;
		if (!newSlots[slotIndex]) return;

		if (checked) {
			newSlots[slotIndex].working_days.push(weekDay);
			availableList = availableWeekDays.filter(day => day !== weekDay);
		} else {
			const newWorkingDaysList = newSlots[slotIndex].working_days.filter(item => {
				return item !== weekDay;
			})
			newSlots[slotIndex].working_days = newWorkingDaysList;
			if (availableWeekDays.indexOf(weekDay) === -1) {
				availableList = [...availableWeekDays];
				availableList.push(weekDay);
			}
		}

		this.setState({
			availableWeekDays: availableList
		});

		setBusinessHours({
			businessHours: newSlots
		})
	}
	changeBreakTime = (e, slotIndex) => {
		const { businessHours, setBusinessHours } = this.props;
		const newSlots = [...businessHours];

		const checked = e.currentTarget.checked;
		if (!newSlots[slotIndex]) return;

		newSlots[slotIndex].break_hours_enabled = checked;

		setBusinessHours({
			businessHours: newSlots
		});
	}
	changeInput = (e, slotIndex, listName, key) => {
		const { businessHours, setBusinessHours } = this.props;
		const newSlots = [...businessHours];

		if (!newSlots[slotIndex][listName]) return;
		if (!newSlots[slotIndex][listName][key]) return;

		const value = e.currentTarget.value;
		newSlots[slotIndex][listName][key] = value;

		setBusinessHours({
			businessHours: newSlots
		});

	}
	addSlot = () => {
		const { businessHours, setBusinessHours } = this.props;
		const { slotTemplate } = this.state;
		const newSlots = [...businessHours];
		const newSlot = JSON.parse(JSON.stringify(slotTemplate));
		newSlots.push(newSlot);

		setBusinessHours({
			businessHours: newSlots
		});

		setTimeout(() => {
			this.initDatepicker();
			this.checkBuzyDays();
		}, 5)
	}
	deleteSlot = (slotIndex) => {
		confirm({
			title: 'Are you sure you want to delete business hours slot?',
			onOk: () => {
				return new Promise((resolve, reject) => {
					const { businessHours, setBusinessHours } = this.props;
					const businessHoursCopy = [...businessHours];

					if (businessHoursCopy[slotIndex]) {
						delete businessHoursCopy[slotIndex];
					}

					const newSlots = businessHoursCopy.filter((item) => {
						if (item) return true;
						return false;
					});

					setBusinessHours({
						businessHours: newSlots
					}, this.checkBuzyDays);
					resolve();
				}).catch(() => console.log('Oops errors!'));
			},
			onCancel() { },
		});
	}
	getShortLabel = (label) => {
		if (!label.length) return label;
		let newLabel = label;
		newLabel = newLabel.slice(0, 3);
		return newLabel.charAt(0).toUpperCase() + newLabel.slice(1);
	}
	renderSlot = (timeSlot, slotIndex) => {
		const { week, availableWeekDays } = this.state;
		return (
			<div className="time-block__input-group working-time-slot active" key={slotIndex} style={{ margin: '0 0 20px 0' }}>
				<span className="time-block__title">Days</span>
				<button type="button" onClick={() => this.deleteSlot(slotIndex)} className="time-block__delete">Delete</button>
				<div className="time-block__week-group">
					{week.map((weekDay, index) => {
						const activeDay = timeSlot.working_days.indexOf(weekDay) > -1;
						return (
							<React.Fragment key={index}>
								<input
									type="checkbox"
									id={`working_hours_${slotIndex}_${weekDay}`}
									onChange={e => this.changeWeekDay(e, weekDay, slotIndex)}
									name={`working_hours__${weekDay}`}
									checked={activeDay ? true : false}
									disabled={activeDay ? false : (availableWeekDays.indexOf(weekDay) > -1 ? false : true)}
									value="1"
									className={"time-block__checkbox workingTimeDay " + weekDay} />
								<label htmlFor={`working_hours_${slotIndex}_${weekDay}`} className={`time-block__label-days ${weekDay}`}>{this.getShortLabel(weekDay)}</label>
							</React.Fragment>
						);
					})}
				</div>
				<div className="time-block__input-group-hours">
					<div className="time-block__column">
						<span className="time-block__title">Open hours</span>
						<span className="time-block__input-wrapper datepair-working-hours">
							<input
								type="text"
								required
								value={timeSlot.working_hours.start}
								data-slot-index={slotIndex}
								data-list-name="working_hours"
								data-key="start"
								onChange={e => this.changeInput(e, slotIndex, 'working_hours', 'start')}
								name="working_hours__start time start"
								className="time-block__input time start"
							/>
							<input
								type="text"
								required
								value={timeSlot.working_hours.end}
								onChange={e => this.changeInput(e, slotIndex, 'working_hours', 'end')}
								data-slot-index={slotIndex}
								data-list-name="working_hours"
								data-key="end"
								name="working_hours__end time end"
								className="time-block__input time end"
							/>
						</span>
					</div>
					<div className="time-block__column">
						<span className="time-block__title">Break</span>
						<input type="checkbox" onChange={e => this.changeBreakTime(e, slotIndex)} checked={timeSlot.break_hours_enabled ? true : false} className="time-block__breakenabler" id={`break_hours_enabled_${slotIndex}`} name="break_hours_enabled" value="1" />
						<div className="breakTimeRange">
							<label className="time-block__clearBreak" htmlFor={`break_hours_enabled_${slotIndex}`}>Clear</label>
							<span className="time-block__input-wrapper datepair-break-hours">
								<input
									type="text"
									value={timeSlot.break_hours.start}
									onChange={e => this.changeInput(e, slotIndex, 'break_hours', 'start')}
									name="break_hours__start time start"
									className="time-block__input time start"
									data-slot-index={slotIndex}
									data-list-name="break_hours"
									data-key="start"
								/>
								<input
									type="text"
									value={timeSlot.break_hours.end}
									onChange={e => this.changeInput(e, slotIndex, 'break_hours', 'end')}
									name="break_hours__end time end"
									className="time-block__input time end"
									data-slot-index={slotIndex}
									data-list-name="break_hours"
									data-key="end"
								/>
							</span>
						</div>
						<label htmlFor={`break_hours_enabled_${slotIndex}`} className="time-block__addBreak breakTimeAdd">
							Add break
						</label>
					</div>
				</div>
			</div>
		);
	}
	render() {
		const { businessHours } = this.props;
		const { availableWeekDays } = this.state;
		return (
			<div className="time-block__container">
				{businessHours.map((timeSlot, i) => {
					return this.renderSlot(timeSlot, i);
				})}
				{availableWeekDays && availableWeekDays.length ? <Button onClick={this.addSlot} style={{ margin: '0 0 20px 0' }}>
					<PlusCircleOutlined /> Add new time slot
				</Button> : null}
			</div>
		);
	}
}

export default WorkingHours;