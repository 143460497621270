import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Button, Alert } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const FormItem = Form.Item;

class AddWarehouse extends React.Component {
	state = {
    putREQUEST: false,
    putFAILURE: false,
    putSUCCESS: false,
    putMessage: ''
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({
        putREQUEST: true
      })
      fetchJSON('/api/v1/organisation/warehouses/create', {
        method: 'PUT',
        body: fieldsValue
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false
          }, () => {
          	this.props.renderList();
          	this.props.handleClose();
          });
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false
          });
        }
       }).catch(error => {
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });
    })
  }
  render() {
    const { putFAILURE } = this.state; 

  	const { visible, handleClose, form, organisationId } = this.props;
  	const { getFieldDecorator } = form;
    return (
    	<Modal
        style={{ top: 20 }}
        visible={ visible }
        width={400}
        title="Add warehouse"
        onOk={ handleClose }
        onCancel={ handleClose }
        footer={false}
      >
	      <Form layout="vertical" onSubmit={this.handleSubmit}>
          <FormItem>
          { putFAILURE ? <Alert message="This user already exists." type="error" /> : null }
          </FormItem>
	      	<div style={{'display': 'none'}}>
            {getFieldDecorator('organisationId', {
              rules: [{ required: false, message: 'Please enter a title!' }],
              initialValue: organisationId
            })(
              <Input />
            )}
          </div>
          <FormItem label="Warehouse Name">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please enter a name!' }],
            })(
              <Input />
            )}
          </FormItem>
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(AddWarehouse);
