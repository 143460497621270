import React from 'react';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Card, Button, Row, Col, Radio, Spin, Modal, Timeline } from 'antd';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import Notes from 'screens/Dashboard/components/Notes/Notes';
import fetchJSON from 'services/utils/fetchJSON';
import SingleElectron from 'screens/Dashboard/components/SingleElectronCard/SingleElectron';

const FormItem = Form.Item;
const confirm = Modal.confirm;
const { TextArea } = Input;

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};
const statuses = [
  { text: 'Pending', value: 'pending' },
  { text: 'Active', value: 'active' },
  { text: 'Resolved', value: 'resolved' }
];

class SingleTicket extends React.Component {
  state = {
    isFetching: false,
    errorMessage: '',
    visibleNotes: false,
    ticketId: '',
    note: {
      author: "",
      createdAt: "",
      event: null,
      source: "",
      source_id: null,
      stage: "",
      suspect: {},
      type: "",
      updatedAt: "",
      value: "",
      comments: []
    },
    chooseVenueVisible: false,
    fetchingStatus: false,
    activeTab: 'supernova',
    rebootLoading: false,
    updateLoading: false,
    machineIdToSend: '',
    visibleShowCommand: false
  }
  componentDidMount() {
    const ticketId = this.props.match.params.ticketId;
    this.setState({
      ticketId
    }, this.getSingleTicket)
  }
  getSingleTicket = () => {
    this.setState({
      isFetching: true
    })
    fetchJSON(`/api/v1/note/${this.state.ticketId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          isFetching: false,
          note: response.data
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  showNotes = () => {
    this.setState({
      visibleNotes: true
    })
  }
  hideNotes = () => {
    this.setState({
      visibleNotes: false
    })
  }
  onStatusChange = (e) => {
    const { ticketId, note } = this.state;
    const stage = e.target.value;
    note.stage = stage;
    confirm({
      title: `Change ticket stage to "${stage}"?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            fetchingStatus: true
          })
          fetchJSON(`/api/v1/note/updateStage/${ticketId}`, {
            method: 'put',
            body: {
              stage: stage
            }
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                fetchingStatus: false,
                note
              });
              resolve()
            } else {
              this.setState({
                fetchingStatus: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                fetchingStatus: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  onDeleteNote = () => {
    const ticketId = this.state.ticketId;
    confirm({
      title: `Are you sure you want to delete this ticket?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            fetchingStatus: true
          })
          fetchJSON(`/api/v1/note/delete/${ticketId}`, {
            method: 'put'
          }).then(response => {
            console.log(response);
            if (response.success) {
              this.setState({
                fetchingStatus: false,
              });
              window.location.href = "/tickets";
              resolve()
            } else {
              this.setState({
                fetchingStatus: false
              });
            }
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                fetchingStatus: false
              });
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  renderTimeline = (comments) => {
    if (!comments.length) return null;
    return comments.map((note, index) => {
      let time = moment(note.createdAt).tz('America/Los_Angeles').format("DD MMM YY, h:mm a");
      return (
        <Timeline.Item key={index} color={note.type === 'info' ? 'green' : 'blue'}>
          <p>{note.value}</p>
          <small>{ note.type } / {time}</small>
        </Timeline.Item>
      )
    })
  }
  render() {
    const {
      isFetching,
      visibleNotes,
      note,
      ticketId,
      fetchingStatus,
    } = this.state;

    const createdAt = note.createdAt ? moment.utc(note.createdAt).tz('America/Los_Angeles').format("DD MMM YY, h:mm a") : '';
    const updatedAt = note.updatedAt ? moment.utc(note.updatedAt).tz('America/Los_Angeles').format("DD MMM YY, h:mm a") : '';
    let owner_type = false;
    let owner_id = '';
    let owner = false;

    if (note.source === 'supernova') {
      owner = 'Supernova ';
      owner_type = 'supernovas';
      if (note.suspect) {
        owner_id = note.suspect.string_id;
      }
      note.source_id = owner_id;
    }
    if (note.source === 'user') {
      owner = 'User #';
      owner_type = 'users';
      if (note.suspect) {
        owner_id = note.suspect.id;
      }
    }

    if (note.source === 'session') {
      owner = 'Session #';
      owner_type = 'sessions';
      if (note.suspect) {
        owner_id = note.suspect.id;
      }
    }

    if (note.source === 'electron') {
      owner = 'Electron ';
      owner_type = 'electrons';
      if (note.suspect) {
        owner_id = note.suspect.string_id;
      }
    }

    return (
      <div className="modalWindow">
        <Card
          loading={isFetching}
          type="inner"
          title={"Ticket "+ticketId}
          extra={<Button type="danger" onClick={this.onDeleteNote} style={{float: 'right'}} >
                  <DeleteOutlined /> Delete
                </Button>}
          >
            <Form>
              <FormItem
                  {...formItemLayout}
                >
                <Row gutter={8}>
                  <Col span={6}>
                    <Row>
                      <label className="form-label-custom">Source</label>
                      <Link to={`/${owner_type}/${note.source_id}`} target="_blank" className="ant-btn ant-btn-sm">
                        Look up
                      </Link>
                    </Row>
                    <Row >
                      <Input value={ `${owner}${owner_id}` } readOnly={true} />
                    </Row>
                  </Col>
                  <Col span={3}>
                    <Row>
                      <label className="form-label-custom">Nest ID</label>
                    </Row>
                    <Row >
                      <Input value={ note.nest_id } readOnly={true} />
                    </Row>
                  </Col>
                  <Col span={3}>
                    <Row>
                      <label className="form-label-custom">Author</label>
                    </Row>
                    <Row >
                      <Input value={ note.author } readOnly={true} />
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <label className="form-label-custom">Created</label>
                    </Row>
                    <Row >
                      <Input value={ createdAt } readOnly={true} />
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <label className="form-label-custom">Updated</label>
                    </Row>
                    <Row >
                      <Input value={ updatedAt } readOnly={true} />
                    </Row>
                  </Col>
                </Row>
              </FormItem>
                                       
              <FormItem
              {...formItemLayout}
              > 
                <Row>
                  <label className="form-label-custom">Note</label>
                </Row>
                <Row>
                  <TextArea value={ note.value } autosize={{ minRows: 2, maxRows: 6 }} readOnly={true} />
                </Row>
              </FormItem>
              <FormItem
              {...formItemLayout}
              > 
                <Row>
                  <label className="form-label-custom">Comments</label>
                </Row>
                <Row>
                  <Timeline style={{
                    'padding': '5px 0 0 5px'
                  }}>
                    { this.renderTimeline(note.comments) }
                  </Timeline>
                </Row>
              </FormItem>
               <FormItem
              {...formItemLayout}
              >
                <Row>
                  <label className="form-label-custom">Stage</label>
                </Row>
                <Spin spinning={ fetchingStatus }>
                  <RadioGroup onChange={this.onStatusChange} value={ note.stage }>
                    { statuses.map(status => <RadioButton key={status.value} value={status.value}>{status.text}</RadioButton>) }
                  </RadioGroup>
                </Spin>
              </FormItem>
              <FormItem
                {...formItemLayout}
              >
                <Row>
                  <label className="form-label-custom">Actions</label>
                </Row>
                <Row className="btn-group">
                  <Button onClick={ this.showNotes }>
                    <QuestionCircleOutlined /> Add comment
                  </Button>
                  { visibleNotes ? <Notes visible={visibleNotes} handleClose={this.hideNotes} sourceId={ticketId} source="note" onlyForm={true} /> : null }
                </Row>
              </FormItem>
            </Form>
            { owner_type === 'electrons' ? 
                  <SingleElectron electronId={note.source_id} isExternal={true} />
                  : null} 
        </Card>
      </div>
    );
  }
}

export default SingleTicket;
