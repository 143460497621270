import React from 'react';
import Settings from 'services/config/Settings';
import { Layout, Button, Table } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import NewSeries from './NewSeries';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

const { Content } = Layout;

class GroupGenerator extends React.Component {
  state = {
  	isFetching: false,
  	data: {},
    visibleNewSeries: false,
    pagination: {
      pageSize: 30
    },
    quote: []
  }
  componentDidMount() {
    document.title = `Group Generator | ${Settings.title}`;
    this.getGroupsSeriesList();
  }
  getGroupsSeriesList = () => {
    this.setState({
      isFetching: true
    })
    fetchJSON(`/api/v1/group/series`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.success && response.data) {
        this.setState({
          isFetching: false,
          quote: response.data
        });
      } else {
        this.setState({
          isFetching: false
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  columns = () => {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    //filteredInfo = filteredInfo || {};
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at'
    },
    {
      title: 'Number of groups',
      dataIndex: 'number_of_groups',
      key: 'number_of_groups',
      sortOrder: sortedInfo.columnKey === 'number_of_groups' && sortedInfo.order,
      sorter: (a, b) => a.number_of_groups - b.number_of_groups
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Link to={"/groups/generator/"+record.key_id} className="ant-btn ant-btn-sm" style={{marginRight: '8px'}}>
            Open 
          </Link>
        </span>
      ),
    }];
  }
  getData = () => {
    const data = [];
    if (!this.state.quote.length) return data;

    let response = this.state.quote;

    if (this.state.filtered) {
      response = this.state.data;
    }
    response.forEach((series, i) => {    
      console.log('series', series);
      data.push({
        key: i,
        id: series.id,
        key_id: series.key_id,
        name: series.name,
        created_at: moment.utc(series.created_at).tz('America/Los_Angeles').format('MM/DD/YY h:mm:ss a'),
        number_of_groups: series.list.length
      })
    });

    return data;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  render() {
    const {
      visibleNewSeries,
      isFetching,
      pagination
    } = this.state;
  	return (
  	  <Content className="fill-bg-table">
  		  <h2 className="table-title">
          Group generator
          <Button type="primary" size="small" style={{marginLeft: '8px'}} onClick={ () => { this.modalVisible('visibleNewSeries', true) } }>
            New series of groups
          </Button>
        </h2>
  		  { visibleNewSeries ? <NewSeries visible={visibleNewSeries} history={this.props.history} handleClose={ () => { this.modalVisible('visibleNewSeries', false) } } /> : null }
        <Table
          columns={ this.columns() }
          loading={ isFetching }
          dataSource={ this.getData() }
          pagination={ pagination }
          onChange={ this.handleTableChange }
        />
      </Content>
    )
  }
}



export default GroupGenerator;