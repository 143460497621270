import Auth from 'services/utils/Auth'

const BASE_URL = '/api/v1'

function callApi(endpoint, authenticated) {

  let token = Auth.getToken() || null
  let config = {}

  if (authenticated) {
    if (token) {
      config = {
        headers: { 'Authorization': `Bearer ${token}` }
      }
    } else {
    	throw new Error("No token saved!")
    }
  }

  return fetch(BASE_URL + endpoint, config)
    .then(response =>
      response.json()
    ).then(response => {
      if (!response.success) {
        return Promise.reject(response)
      }
      return response;
    }).catch(err => {
      console.log(err)
      return Promise.reject(err)
    })
}

export const CALL_API = Symbol('Call API')

export default store => next => action => {

  const callAPI = action[CALL_API]
  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  let { endpoint, types, authenticated } = callAPI

  const [ requestType, successType, errorType ] = types

  next({ type: requestType })
  // Passing the authenticated boolean back in our data will let us distinguish between normal and secret quotes
  return callApi(endpoint, authenticated).then(
    response => {
      console.log('response', response);
      return next({
        response,
        authenticated,
        type: successType
      });
    },
    error => next({
      error: error || 'There was an error.',
      type: errorType
    })
  )
}