import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Layout, Card, Alert, Spin } from 'antd';
import qs from 'qs';
import Settings from 'services/config/Settings';
import { loginUser } from './data/actions';

import './styles.less';

const FormItem = Form.Item;

class Login extends React.Component {
  state = {
    redirectUrl: '/'
  }
  componentDidMount() {
    document.title = `Login | ${Settings.title}`;
    if (window.location.search) {
      let param = window.location.search;
      let paramFromUrl = qs.parse(param, { ignoreQueryPrefix: true });
      let redirect = paramFromUrl.redirect ? paramFromUrl.redirect : '/';
      this.setState({
        redirectUrl: redirect
      })
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    // eslint-disable-next-line react/prop-types
    this.props.form.validateFields((err, creds) => {
      if (!err) {
        this.props.dispatch(loginUser(creds));
      }
    });
  }
  render() {
    const { errorMessage, isAuthenticated, isFetching } = this.props;
    const { getFieldDecorator } = this.props.form;
    let styleLayout = {
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center'
    };
    return (
      <Layout style={styleLayout}>
        <Spin spinning={isFetching} >
          <Card title={Settings.title} style={{ width: 350, margin: 'auto' }}>
            <Form onSubmit={this.handleSubmit} className="login-form">
              {errorMessage ?
                <FormItem><Alert message={errorMessage} type={isAuthenticated ? "success" : "error"} /></FormItem>
                : null}
              <FormItem>
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: 'Please input your email!' }],
                })(
                  <Input type="email" prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Please input your Password!' }],
                })(
                  <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                )}
              </FormItem>
              <FormItem>
                <Button type="primary" htmlType="submit" className="login-form-button">
                  Log in
                </Button>
              </FormItem>
            </Form>
          </Card>
        </Spin>
        {isAuthenticated ? <Redirect to={this.state.redirectUrl ? this.state.redirectUrl : '/'} /> : null}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  const { errorMessage, isAuthenticated, isFetching } = auth;
  return {
    errorMessage,
    isAuthenticated,
    isFetching
  }
}

export default connect(mapStateToProps)(Form.create()(Login));