import React from 'react';
import { DollarOutlined, UserOutlined, SmileOutlined, ThunderboltOutlined} from '@ant-design/icons';
import { Card, Statistic, Row, Col } from 'antd';

import './styles.less';

class LifetimeTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'lifetime'
    };
    this.memberhips_types = {
      'hourly_subs': 'Hourly subscriptions',
      'daily_subs': 'Daily subscriptions',
      'monthly_subs': 'Monthly subscriptions',
      'semester_subs': 'Semester subscriptions',
      'annual_subs': 'Annual subscriptions'
    };
  }
  componentDidMount() {
    this.mounted = true;
    this.singleLocationMode = this.props.singleLocationMode;
  }
  componentWillUnmount(){
    this.mounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    const singleLocationModeChange = typeof this.props.singleLocationMode !== 'undefined' && this.props.singleLocationMode !== prevProps.singleLocationMode;
    if (singleLocationModeChange) {
      this.singleLocationMode = this.props.singleLocationMode;
    }
  }
  onTabChange = (key, type) => {
    this.setState({
      [type]: key,
    })
  }
  getTabList = () => {
    return !this.singleLocationMode ? [] : [
      { key: 'lifetime', tab: 'Overall' },
      { key: 'best_day', tab: 'Best day' }
    ]
  }
  getSubscriptionsBlock = (type) => {
    const { data } = this.props;
    const {
      lifetime,
      best_day
    } = data;

    let render_cols;

    if (type === 'best_day') {
      render_cols = Object.keys(this.memberhips_types).reduce((prev, curr) => {
        if (best_day[curr] && typeof best_day[curr] === 'object' && typeof best_day[curr].amount === 'number' && best_day[curr].amount > 0) {
          return [...prev, 
            <Col key={curr} sm={5} xs={12}>
              <Statistic suffix={<span style={{marginLeft: '6px'}}>{`/ ${best_day[curr].day}`}</span>} title={this.memberhips_types[curr]} value={best_day[curr].amount}/>
            </Col>
          ]
        }
        return [...prev];
      }, []);
    }
    if (type === 'lifetime') {
      render_cols = Object.keys(this.memberhips_types).reduce((prev, curr) => {
        if (typeof lifetime[curr] === 'number' && lifetime[curr] > 0) {
          return [...prev, 
            <Col key={curr} sm={5} xs={12}>
              <Statistic title={this.memberhips_types[curr]} value={lifetime[curr]}/>
            </Col>
          ]
        }
        return [...prev];
      }, []);    
    }
    if (!render_cols.length) return;

    const final_render = [];
    for (let i = 0; i <= Math.ceil(render_cols.length/3); i++) {
      const trio_cols = [];
      for (let j = 0; j <= 2; j ++) {
        if (i * 3 + j <= render_cols.length - 1) {
          trio_cols.push(render_cols[i * 3 + j]);
        }
      }
      final_render.push(
        <Row className="top-margin" key={i}>
          { trio_cols.map((el) => el ) }
        </Row>
      )
    }
    return final_render
  }

  render() {
    const { 
      activeTab
    } = this.state;
    const { data } = this.props;
    const {
      lifetime,
      best_day
    } = data;

    return (
      <Card
        className="milestones-card"
        tabList={this.getTabList()}
        activeTabKey={activeTab}
        onTabChange={(key) => { this.onTabChange(key, 'activeTab'); }}
        bordered={false}
      >
        { activeTab === 'lifetime' ?
        <div>
          <Row>
            { this.singleLocationMode ? 
            <Col sm={5} xs={12}>
              <Statistic title="Amount of users" value={lifetime.users} prefix={<UserOutlined style={{marginRight: '4px'}} />}/>
            </Col> : null }
            <Col sm={5} xs={12}>
              <Statistic title="Amount of sessions" value={lifetime.sessions} />
            </Col>
            <Col sm={5} xs={24}>
              <Statistic title="Total payment" value={lifetime.payment} prefix={<DollarOutlined style={{marginRight: '4px'}} />}/>
            </Col>
          </Row>
          <Row className="top-margin">
            <Col sm={5} xs={24}>
              <Statistic title="Electron in use" value={lifetime.charging_time} prefix={<SmileOutlined style={{marginRight: '4px'}} />}/>
            </Col>
            <Col sm={6} xs={24}>
              <Statistic title="Duration of charging on average" value={lifetime.charging_avg} prefix={<ThunderboltOutlined style={{marginRight: '4px'}} />}/>
            </Col>
          </Row>
          { this.getSubscriptionsBlock('lifetime') }
        </div> : null }
        { activeTab === 'best_day' && 
        <div>
          <Row>
            { best_day.users && typeof best_day.users === 'object' ?
            <Col sm={5} xs={12}>
              <Statistic title="Amount of users" value={best_day.users.amount} suffix={<span style={{marginLeft: '6px'}}>{`/ ${best_day.users.day}`}</span>} prefix={<UserOutlined style={{marginRight: '4px'}} />}/>
            </Col> : null }
            { best_day.sessions && typeof best_day.sessions === 'object' ?
            <Col sm={5} xs={12}>
              <Statistic title="Sessions" value={best_day.sessions.amount} suffix={<span style={{marginLeft: '6px'}}>{`/ ${best_day.sessions.day}`}</span>} prefix={<UserOutlined style={{marginRight: '4px'}} />}/>
            </Col> : null }
            { best_day.payment && typeof best_day.payment === 'object' && false ?
            <Col sm={5} xs={24}>
              <Statistic title="Total payment" value={best_day.payment.amount} suffix={<span style={{marginLeft: '6px'}}>{`/ ${best_day.payment.day}`}</span>} prefix={<DollarOutlined style={{marginRight: '4px'}} />}/>
            </Col> : null }
          </Row>
          { this.getSubscriptionsBlock('best_day') }
        </div> }
      </Card>
    );
  }
}

export default LifetimeTable;