import React from 'react';
import { connect } from 'react-redux';
import Settings from 'services/config/Settings';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Table, Layout, Input, Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { fetchSecretQuote } from './data/actions'

import AddPromo from './components/AddPromo';

const { Content } = Layout;
const Search = Input.Search;

class Promocodes extends React.Component {
  state = {
    filteredInfo: {},
    sortedInfo: {},
    searchText: '',
    filtered: false,
    data: [],
    visibleAddPromo: false
  };
  componentDidMount() {
    document.title = `Promotion codes | ${Settings.title}`;
    this.props.dispatch(fetchSecretQuote('/promocode/usersList'))
  }
  columns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return [{
      title: 'Use status',
      dataIndex: 'use_status',
      key: 'use_status',
      render: (text, record) => (
        <span
          style={{ color: record.transactions > 0 ? 'green' : 'blue' }}
        >
        <LegacyIcon type={record.transactions > 0  ? "check-circle" : "poweroff"} /> {record.use_status} </span>
      ),
      filters: [
        { text: 'Not Used', value: 0 },
        { text: 'Used', value: 1 }
      ],
      filteredValue: filteredInfo.use_status || null,
      onFilter: (value, record) => {
        return value ? record.use_status > value : record.use_status === value;
      },
      sorter: (a, b) => a.use_status > b.use_status ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'use_status' && sortedInfo.order
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Quantity limit',
      dataIndex: 'quantity_limit',
      key: 'quantity_limit',
      sorter: (a, b) => a.quantity_limit > b.quantity_limit ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'quantity_limit' && sortedInfo.order,
    },
    {
      title: 'Dependencies',
      dataIndex: 'dependencies',
      key: 'dependencies',
      filters: [
        { text: 'Organisation', value: 'organisations' },
        { text: 'Venue', value: 'venue' },
        { text: 'No dependencies', value: '-' }
      ],
      filteredValue: filteredInfo.dependencies || null,
      onFilter: (value, record) => {
        return record.dependencies.includes(value)
      },
      render: (text, record) => (
        record.private_type ? 
        <Link to={`users/${record.private_id}`} target="_blank"> 
          {record.dependencies}
        </Link> : <span>{record.dependencies}</span>
      ),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Link to={"/promocodes/"+record.id} className="ant-btn ant-btn-sm" style={{marginRight: '8px'}}>
            Edit
          </Link>
          <Link to={`/promocodes/${record.id}?history=1`} className="ant-btn ant-btn-sm">
            History
          </Link>
        </span>
      ),
    }];
  }
  getData = () => {
    const data = [];
    if (!this.props.quote) return data;

    let response = this.props.quote.data;

    if (this.state.filtered) {
      response = this.state.data;
    }

    response.forEach((item, i) => {
      let dependencies = '-';
      if (item.private_type) {
        dependencies = `${item.private_type} #${item.private_id}`
      }
      let use_status = `${item.transactions.length} of ${item.quantity_limit}`;

      data.push({
        key: i,
        id: item.id,
        use_status: use_status,
        transactions: item.transactions.length,
        code: item.promocode_key,
        quantity_limit: item.quantity_limit,
        dependencies: dependencies,
        status: item.status ? 'On' : 'Off',
        private_type: item.private_type,
        private_id: item.private_id,
        value: item.plan_id ? `Plan - ${item.plan.title}` : '-',
      })
    })

    return data;
  }
  onSearch = (e) => {
    this.setState({ searchText: e.target.value });
    const searchText = e.target.value;
    const reg = new RegExp(e.target.value, 'gi');
    this.setState({
      filtered: !!searchText,
      data: _.flatMap(this.props.quote.data, record => {
        const groupidMatch = record.groupid ? record.groupid.match(reg) : false;
        const idMatch = record.string_id ? record.string_id.match(reg) : false;
        if (!groupidMatch && !idMatch) {
          return null;
        }
        return {          
          ...record,
          };
      }).filter(record => !!record),
    });
    if (e.target.value === ''){
      this.setState({
        data: this.props.quote.data,
        filtered: false
      });
    }
  }
  handleTableChange = (pagination, filters, sorter) => {
    console.log('handleTableChange', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  emitEmpty = () => {
    this.setState({ 
      data: [],
      searchText: '',
      filtered: false
    });
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  render() {
    const { isFetching, pagination } = this.props;
    const { searchText, visibleAddPromo } = this.state;
    const suffix = searchText ? <CloseCircleOutlined key="1" onClick={this.emitEmpty} /> : null;
    return (
      <Content className="fill-bg-table">
        <Row gutter={8}>
          <Col span={10}>
            <h1 className="table-title" style={{marginTop:'4px'}}>Users promotion
              <Button style={{marginLeft:'8px'}} type="primary" size="small" onClick={ () => { this.modalVisible('visibleAddPromo', true) } }>
                Add code
              </Button>
            </h1>
          </Col>
          <Col span={8}>
            { visibleAddPromo ? <AddPromo visible={visibleAddPromo} history={this.props.history} handleClose={ () => { this.modalVisible('visibleAddPromo', false) } } /> : null }
          </Col>
          <Col span={6}>
           <Search style={{marginBottom:'8px'}} size="large" disabled={isFetching} ref={ele => this.searchText = ele} suffix={suffix} onChange={this.onSearch} placeholder="Search" value={searchText} 
           onPressEnter={this.onSearch} />
          </Col>
        </Row>
        <Table
          columns={ this.columns() }
          loading={ isFetching }
          dataSource={ this.getData() }
          pagination={ pagination }
          onChange={ this.handleTableChange }
        />
        
      </Content>
    )
  }
}

const mapStateToProps = (state) =>{
  const { promocodes } = state;
  const { quote, isFetching, createdFetch, pagination } = promocodes;
  return {
    quote,
    isFetching,
    createdFetch,
    pagination
  }
}


export default connect(mapStateToProps)(Promocodes)
