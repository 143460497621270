import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Layout } from 'antd';

import Breadcrumbs, { MenuContainer } from './components/Breadcrumb';

import './styles.less';

import Logo from 'components/images/logo.svg';

const { Header, Content, Footer } = Layout;

class DashboardWrapper extends React.Component {
  render() {
    const { isAuthenticated, role } = this.props;
    let menu = {};
    let routes = {};

    if (role === 'admin') {
      menu = {
        '/': 'Dashboard',
        '/organisations': 'Organisations',
        '/venues': 'Locations',
        '/groups': 'Groups',
        '/supernovas': 'Supernovas',
        '/electrons': 'Electrons',
        '/users': 'Users',
        '/sessions': 'Sessions',  
        '/promocodes': 'Promo',
        '/tickets': 'Tickets',
        // '/qcusers': 'QC Users',
        '/reports': 'Reports'
      };
      routes = {
        ...menu,
        '/users/:userId': params => params.userId,
        '/electrons/:electronId': params => params.electronId,
        '/supernovas/:supernovaId': params => params.supernovaId,
        //'/venues/:venueId': params => params.venueId,
        //'/organisations/:organisationId': params => params.organisationId,
        '/sessions/:sessionId': params => params.sessionId,
        '/promocodes/:promocodeId': params => params.promocodeId,
        '/tickets/:ticketId': params => params.ticketId,
        '/qcusers/:qcUserId': params => params.qcUserId,
        '/groups/generator/:seriesId': params => params.seriesId,
         '/groups/generator': 'Group generator',
      };
    }
    if (role === 'organisation') {
      menu = {
        '/': 'Dashboard',
        '/logout': 'Logout'
      };
      routes = {
        ...menu,
      };
    }
    return (
      <div>
    	{ isAuthenticated ? <Layout className="layout">
        <Header>
          <div className="header-logo">
            <img className="header-logo-img" src={Logo} alt="Logo" />
          </div>
          <MenuContainer role={role} routes={menu} />
        </Header>
        <Content style={{ padding: '0 50px' }}>
          <Breadcrumbs routes={routes} />
          { this.props.children }
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Electron-to-Go Control Panel
        </Footer>
      </Layout> : <Redirect to='/login' /> }
      </div>
    )
  }
};

const mapStateToProps = (state) => {
  const { auth } = state;
  const { isAuthenticated } = auth;
  return {
    isAuthenticated
  }
}

export default connect(mapStateToProps)(DashboardWrapper);