import React from 'react';
import Settings from 'services/config/Settings';
import { Table, Layout, Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import fetchJSON from 'services/utils/fetchJSON';

import AddQcUser from './components/AddQcUser';

const { Content } = Layout;

class QcUsers extends React.Component {
  state = {
    filteredInfo: {},
    sortedInfo: {},
    quote: [],
    data: [],
    isFetching: false,
    visibleAddUser: false,
    pagination: {
      pageSize: 30
    }
  }
  componentDidMount() {
    document.title = `QC Users | ${Settings.title}`;
    this.getQcUsersList();
  }
  getQcUsersList = () => {
    this.setState({
      isFetching: true
    })
    fetchJSON(`/api/v1/qcusers/list`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.success && response.data) {
        this.setState({
          isFetching: false,
          quote: response.data
        });
      } else {
        this.setState({
          isFetching: false
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  columns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      filters: [
        { text: 'tester', value: 'tester' },
        { text: 'installer', value: 'installer' },
      ],
      filteredValue: filteredInfo.role || null,
      onFilter: (value, record) => {
        return record.role.includes(value)
      }
    },
    {
      title: 'Group assignment',
      dataIndex: 'group_assignment',
      key: 'group_assignment'
    },
    {
      title: 'Access',
      dataIndex: 'access_to',
      key: 'access_to'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
          <Link to={"/qcusers/"+record.id} className="ant-btn ant-btn-sm">
            Edit
          </Link>
      ),
    }];
  }
  getData = () => {
    const data = [];
    if (!this.state.quote.length) return data;

    let qcusers = this.state.quote;
    
    if (this.state.filtered) {
      qcusers =  this.state.data;
    }

    qcusers.forEach((qcuser, i) => {
      let access_to = null
      if (qcuser.access_to === null && (!Array.isArray(qcuser.access) || !qcuser.access.length)) {
        access_to = 'Any non-prod supernovas'
      }
      else {
        access_to = 'Limited'
      }

      data.push({
        key: i,
        id: qcuser.id,
        name: qcuser.name,
        phone: qcuser.phone,
        role: qcuser.role,
        group_assignment: qcuser.group_assignment,
        access_to: access_to
      })
    });

    return data;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  emitEmpty = () => {
    this.setState({ 
      data: [],
      filtered: null
    });
  }

  render() {
    const { isFetching, pagination, visibleAddUser } = this.state;
    return (
      <Content className="fill-bg-table">
        <Row gutter={8} >
          <Col span={7}>
            <h1 className="table-title">QC Users
              <Button type="primary" size="small" style={{marginLeft: '8px'}} onClick={ () => { this.modalVisible('visibleAddUser', true) } } >
                Add QC User
              </Button>
            </h1>
          </Col>
            { visibleAddUser ? <AddQcUser onClose={ () => { this.getQcUsersList() } } visible={visibleAddUser} handleClose={ () => { this.modalVisible('visibleAddUser', false) } } /> : null }
        </Row>
        <Table
          columns={ this.columns() }
          loading={ isFetching }
          dataSource={ this.getData() }
          pagination={ pagination }
          onChange={ this.handleTableChange }
        />
      </Content>
    )
  }
};

export default QcUsers

