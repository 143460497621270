import {
  QUOTE_REQUEST, QUOTE_SUCCESS, QUOTE_FAILURE,
  CLOSE_SESSION_REQUEST, CLOSE_SESSION_SUCCESS, CLOSE_SESSION_FAILURE
} from './actions'

const initialState = {
  isFetching: false,
  quote: '',
  authenticated: false,
  createdFetch: null,
  isFetchingError: false,
  sessionId: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case QUOTE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        quote: '',
        isFetchingError: false,
        sessionId: false
      })
    case QUOTE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        quote: action.response,
        sessionId: action.response.sessionId,
        authenticated: action.authenticated || false,
        createdFetch: Date.now(),
        isFetchingError: false
      })
    case QUOTE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingError: true,
        sessionId: false
      })
    default:
      return state
   }
}


export const closeSessionReducer = (state = {
    isFetching: false,
    quote: ''
  }, action) => {
  switch (action.type) {
    case CLOSE_SESSION_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case CLOSE_SESSION_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: '',
        quote: action.response
      })
    case CLOSE_SESSION_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message
      })
    default:
      return state
  }
}