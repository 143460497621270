import React from 'react';
import PricingSettings from 'screens/Dashboard/components/PricingSettings/PricingSettings';

class Pricing extends React.Component {
  render() {
    const {
      venueUuid
    } = this.props;

    return (
      <PricingSettings source="venue" sourceId={venueUuid} default={false}/>
    );
  }
};

export default Pricing;