import React from 'react';
import { connect } from 'react-redux';
import SingleUserCard from 'screens/Dashboard/components/SingleUserCard/SingleUserCard';

class SingleUser extends React.Component {
  state = {
    usedId: null
  }
  componentDidMount() {
    const userId = this.props.match.params ? parseInt(this.props.match.params.userId, 10) : null;
    if (userId) {
      this.setState({
        userId
      });
    } else {
      this.props.history.push("/users")
    }
  }

  render() {
    const { userId } = this.state;
    return (
      <React.Fragment>
        { userId ? <SingleUserCard userId={userId} modal={false} closeModal={this.props.closeModal} /> : null }
      </React.Fragment>
    )
  }
};

export default connect()(SingleUser)