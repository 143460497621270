import React from 'react';
import { getTimeDistance } from 'services/utils/utils';
import { DatePicker, Row, Col, Button, Spin, Card } from 'antd';
import FontAwesome from 'react-fontawesome';
import fetchJSON from 'services/utils/fetchJSON';
import Auth from 'services/utils/Auth';
import { saveAs } from 'file-saver';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Pie,
  PieChart,
  Cell,
  LineChart,
  Line
} from 'recharts';
import axios from 'axios';

import './styles.less';

const tooltipStyles = {
  margin: '0px',
  padding: '20px',
  backgroundColor: 'rgb(255, 255, 255)',
  border: '1px solid rgb(204, 204, 204)',
  whiteSpace: 'nowrap'
}

class SubscriptionsTooltip extends React.Component {
  render() {
    const { active } = this.props;
    if (active) {
      const { payload } = this.props;
      return (
        <div className="recharts-default-tooltip" style={tooltipStyles}>
          {`${payload[0].name}: ${payload[0].payload.percent} (${payload[0].payload.count})`}
        </div>
      );
    }
    return null;
  }
}
const { RangePicker } = DatePicker;

class GlobalOrgStatistics extends React.Component {
  state = {
    rangePickerValue: getTimeDistance('sixmonths'),
    isFetching: false,
    statistics: [],
    filteredInfo: {},
    sortedInfo: {},
    userTransactions: [],
    orgTitle: '',
    sourceId: 'total'
  }
  componentDidMount() {
    document.title = 'Global Org Statistics';
    this.getStatistics();
  }
  getStatistics = () => {
    const { rangePickerValue } = this.state;
    
    let defaultUrl = '/api/v1/statistic/details';

    this.setState({
      isFetching: true
    });
  
    fetchJSON(defaultUrl, {
      method: 'post',
      body: {
        dates: [rangePickerValue[0].format(), rangePickerValue[1].format()],
        source: 'organisation',
        sourceId: 'total'
      }
    }).then(response => {
      console.log(response);
      this.setState({
        statistics: response.data,
        isFetching: false
      }, this.setUsersTransactionList);
    }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false
      });
    });
  }

  setUsersTransactionList = () => {
    const { statistics } = this.state;

    const org = statistics.filter(item => item.type === 'organisation');
    if (org && org.length) {
      
      if (org[0]) {
        let userTransactionsList = [];
        if (org[0].statistics) {
          const { userTransactions } = org[0].statistics;
          if (userTransactions) {
            userTransactionsList = userTransactions;
          }
        }
        
        this.setState({
          userTransactions: userTransactionsList,
          orgTitle: org[0].title
        })
      }
    }
  }
  downloadPDF = () => {
    console.log('downloadPDF');
  }
  downloadUsersList_v2 = () => {
    console.log('downloadUsersList_v2');
    const { rangePickerValue, orgTitle } = this.state;
    const { sourceId } = this.state;
    const url = '/api/v1/organisation/getDetailedStatistics';

    this.setState({
      isFetching: true
    });

    const config = {
      headers: {
        "Authorization" : `bearer ${Auth.getToken()}`
      },
      responseType: 'blob'
    };
    const body = {
      dates: [rangePickerValue[0].format(), rangePickerValue[1].format()],
      sourceId: sourceId
    };

    return axios.post(url, body, config)
      .then(response => {
        const start = rangePickerValue[0].tz('America/Los_Angeles').format('MM/DD/YY');
        const end = rangePickerValue[1].tz('America/Los_Angeles').format('MM/DD/YY');
        var fileTitle = `User transactions within the organization ${orgTitle} from ${start} to ${end}`;
        if (sourceId === 'total') {
          fileTitle = `User transactions within ${orgTitle} from ${start} to ${end}`;
        }
        
        var exportedFileName = fileTitle + '.xlsx' || 'export.xlsx';
        saveAs(response.data, exportedFileName);
        this.setState({
          isFetching: false
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
    })

  }
  isActive(type) {
    const { rangePickerValue } = this.state;
    const value = getTimeDistance(type);
    if (!rangePickerValue[0] || !rangePickerValue[1]) {
      return '';
    }
    if (
      rangePickerValue[0].isSame(value[0], 'day') &&
      rangePickerValue[1].isSame(value[1], 'day')
    ) {
      return 'ant-btn-primary';
    }

    return '';
  }
  handleRangePickerChange = (rangePickerValue) => {
    if (rangePickerValue) {
      rangePickerValue[0].tz('America/Los_Angeles').startOf('day');
      rangePickerValue[1].tz('America/Los_Angeles').endOf('day');
      this.setState({
        rangePickerValue,
      }, this.getStatistics);
    }
  }
  selectDate = (type) => {
    this.setState({
      rangePickerValue: getTimeDistance(type),
    }, this.getStatistics);
  }
  messageContains = (e) => {
    this.setState({ messageContains: e.target.value });
  }
  handleTableChange = (pagination, filters, sorter) => {
    console.log('handleTableChange', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    })
  }
  generateUsersAchievements = (overview) => {
    const { isFetching } = this.state;
    if (!overview.usersAchievements) return null;
    if (!Object.keys(overview.usersAchievements).length) return null;
    
    return Object.keys(overview.usersAchievements).map((key, index) => {
      const result = key.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return (<Col span={8} key={index}>
        <Spin spinning={isFetching}>
          <div className="widget">
            <div className={"widget-body " + (!(index % 2) ? 'success' : '') }>
              <div className="widget-body-inner">
                <div className="widget-title">
                  {finalResult}
                </div>
                <div className="widget-count">
                  {overview.usersAchievements[key]}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Col>);
    });
  }
  generateFacts = (overview) => {
    const { isFetching } = this.state;
    if (!overview.facts) return null;
    if (!Object.keys(overview.facts).length) return null;
    
    return Object.keys(overview.facts).map((key, index) => {
      // const result = key.replace(/([A-Z])/g, " $1");
      // const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return (<Col span={8} key={index}>
        <Spin spinning={isFetching}>
          <div className="widget">
            <div className={"widget-body " + (!(index % 2) ? 'success' : '') }>
              <div className="widget-body-inner">
                <div className="widget-title">
                  {key}
                </div>
                <div className="widget-count">
                  {overview.facts[key]}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Col>);
    });
  }
  renderStatistics = () => {
    const { statistics, isFetching } = this.state;
    if (!statistics.length) return null;
    //const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#ffeb3b', '#d4fc79', '#fda085', '#fbc2eb'];

    var distinctColors = require('distinct-colors');
  
    var number_of_colors = 100;
    var COLORSs = distinctColors({count: number_of_colors});


    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#ffeb3b', '#d4fc79', '#fda085', '#fbc2eb'];
    COLORSs.forEach(color => {
      COLORS.push(`rgba(${color._rgb[0]}, ${color._rgb[1]}, ${color._rgb[2]}, ${color._rgb[3]})`);
    });

    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }

    return statistics.map((item, index) => {
      const overview = item.statistics;
      return (
        <div key={index}>
          <h2>{capitalize(item.type)}: {item.title}</h2>
          <p>{item.address}</p>
          {item.type === 'organisation' ? 
            <React.Fragment>
            <Row gutter={8}>
              <Col span={8}>
                <Spin spinning={isFetching}>
                  <div className="widget">
                    <div className="widget-body">
                      <div className="widget-body-inner">
                        <div className="widget-title">
                          Average charging time
                        </div>
                        <div className="widget-count">
                          {overview.averageChargingDuration}
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </Col>
              <Col span={8}>
                <Spin spinning={isFetching}>
                  <div className="widget">
                    <div className="widget-body success">
                      <div className="widget-body-inner">
                        <div className="widget-title">
                          Total payments
                        </div>
                        <FontAwesome 
                          className="widget-icon"
                          name='dollar-sign' />
                        <div className="widget-count">
                          ${overview.payments}
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </Col>
              <Col span={8}>
                <Spin spinning={isFetching}>
                  <div className="widget">
                    <div className="widget-body">
                      <div className="widget-body-inner">
                        <div className="widget-title">
                          Active Memberships
                        </div>
                        <div className="widget-count">
                          {overview.activeMemberships}
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </Col>
            </Row>
            <Row gutter={8}>
              {this.generateUsersAchievements(overview)}
              <Col span={8}>
                <Spin spinning={isFetching}>
                  <div className="widget">
                    <div className="widget-body">
                      <div className="widget-body-inner">
                        <div className="widget-title">
                          Fines (Loss of charger)
                        </div>
                        <div className="widget-count">
                          ${overview.penalties}
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </Col>
              {this.generateFacts(overview)}
            </Row>
          </React.Fragment>
          : null } 
          {item.type === 'venue' ? 
            <Row gutter={8}>
              <Col span={8}>
                <Spin spinning={isFetching}>
                  <div className="widget">
                    <div className="widget-body">
                      <div className="widget-body-inner">
                        <div className="widget-title">
                          Average charging time
                        </div>
                        <div className="widget-count">
                          {overview.averageChargingDuration}
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </Col>
              <Col span={8}>
                <Spin spinning={isFetching}>
                  <div className="widget">
                    <div className="widget-body">
                      <div className="widget-body-inner">
                        <div className="widget-title">
                          Sessions
                        </div>
                        <div className="widget-count">
                          {overview.sessions}
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </Col>
              <Col span={8}>
                <Spin spinning={isFetching}>
                  <div className="widget">
                    <div className="widget-body success">
                      <div className="widget-body-inner">
                        <div className="widget-title">
                          New Users
                        </div>
                        <div className="widget-count">
                          {overview.usersEnroll}
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </Col>
            </Row>
          : null } 

          <Row>
            <Col span={12}>
              <h3>Start of sessions by hour</h3>
              <Spin spinning={isFetching}>
                <ResponsiveContainer height={300} width='100%'>
                  <BarChart data={overview.sessionsStarts} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                   <XAxis dataKey="time"/>
                   <YAxis/>
                   <CartesianGrid strokeDasharray="3 3"/>
                   <Tooltip/>
                   <Bar dataKey="count" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </Spin>
            </Col>
            <Col span={12}>
              <h3>End of sessions by hour</h3>
              <Spin spinning={isFetching}>
                <ResponsiveContainer height={300} width='100%'>
                  <BarChart data={overview.sessionsEnds} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                   <XAxis dataKey="time"/>
                   <YAxis/>
                   <CartesianGrid strokeDasharray="3 3"/>
                   <Tooltip/>
                   <Bar dataKey="count" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </Spin>
            </Col>
          </Row>
          {item.type === 'organisation' ?
          <Row style={{marginTop: "24px"}}>
            <Spin spinning={isFetching}>
              <Row>
                <Col span={8}>
                  <h3>Memberships</h3>
                  <ResponsiveContainer height={180} width='100%'>
                    <PieChart>
                      <Pie
                        data={overview.membershipsChart}
                        dataKey="count"
                        cx="50%" cy="50%" 
                        innerRadius={60}
                        outerRadius={80} 
                        fill="#8884d8"
                        paddingAngle={5}
                      >
                        {
                          overview.membershipsChart.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                        }
                      </Pie>
                      <Tooltip content={<SubscriptionsTooltip />} />
                      <Legend align="left" layout='vertical' verticalAlign='middle' />
                    </PieChart>
                  </ResponsiveContainer> 
                </Col>
                <Col span={8}>
                  <h3>Payment Methods</h3>
                  <ResponsiveContainer height={180} width='100%'>
                    <PieChart>
                      <Pie
                        data={overview.paymentMethodsChart}
                        dataKey="count"
                        cx="50%" cy="50%" 
                        innerRadius={60}
                        outerRadius={80} 
                        fill="#8884d8"
                        paddingAngle={5}
                      >
                        {
                          overview.paymentMethodsChart.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                        }
                      </Pie>
                      <Tooltip/>
                      <Legend align="left" layout='vertical' verticalAlign='middle' />
                    </PieChart>
                  </ResponsiveContainer>
                </Col>
                <Col span={8}>
                  <h3>Visitors platform</h3>
                  <ResponsiveContainer height={180} width='100%'>
                    <PieChart>
                      <Pie
                        data={overview.visitorsPlatform}
                        dataKey="count"
                        cx="50%" cy="50%" 
                        innerRadius={60}
                        outerRadius={80} 
                        fill="#8884d8"
                        paddingAngle={5}
                      >
                        {
                          overview.visitorsPlatform.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                        }
                      </Pie>
                      <Tooltip/>
                      <Legend align="left" layout='vertical' verticalAlign='middle' />
                    </PieChart>
                  </ResponsiveContainer>
                </Col>
                </Row>
              </Spin>
              </Row>  : null }
          { (item.type === 'organisation' && overview.chargingCables.length ) ?
          <Row style={{marginTop: "24px"}}>
            <Spin spinning={isFetching}>
              <Col span={8}>
                <h3>Charging cables</h3>
                <ResponsiveContainer height={180} width='100%'>
                  <PieChart>
                    <Pie
                      data={overview.chargingCables}
                      dataKey="count"
                      cx="50%" cy="50%" 
                      innerRadius={60}
                      outerRadius={80} 
                      fill="#8884d8"
                      paddingAngle={5}
                    >
                      {
                        overview.chargingCables.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                      }
                    </Pie>
                    <Tooltip/>
                    <Legend align="left" layout='vertical' verticalAlign='middle' />
                  </PieChart>
                </ResponsiveContainer>
              </Col>
            </Spin>
          </Row> : null} 
          <Row style={{marginTop: "24px"}}>
            <h3>User registration for the period</h3>
            <Spin spinning={isFetching}>
              <ResponsiveContainer height={300} width='100%'>
                <LineChart data={overview.userRegistrations}>
                  <XAxis dataKey="time"/>
                  <YAxis/>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <Tooltip/>
                  <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{r: 8}}/>
                </LineChart>
              </ResponsiveContainer>
            </Spin>
          </Row>
          {item.type === 'organisation' ?
          <React.Fragment>
            <Row style={{marginTop: "24px"}}>
              <h3>Purchases of membership for the period</h3>
              <Spin spinning={isFetching}>
                <ResponsiveContainer height={300} width='100%'>
                  <LineChart data={overview.userMemberships}>
                    <XAxis dataKey="time"/>
                    <YAxis/>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip/>
                    <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{r: 8}}/>
                  </LineChart>
                </ResponsiveContainer>
              </Spin>
            </Row>
            <Row style={{marginTop: "24px"}}>
              <h3>Renewals of membership for the period</h3>
              <Spin spinning={isFetching}>
                <ResponsiveContainer height={300} width='100%'>
                  <LineChart data={overview.userRenewals}>
                    <XAxis dataKey="time"/>
                    <YAxis/>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip/>
                    <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{r: 8}}/>
                  </LineChart>
                </ResponsiveContainer>
              </Spin>
            </Row>
          </React.Fragment>
          : null }
          <Row style={{marginTop: "24px"}}>
            <h3>Sessions of users for the period</h3>
            <Spin spinning={isFetching}>
              <ResponsiveContainer height={300} width='100%'>
                <LineChart data={overview.userSessions}>
                  <XAxis dataKey="time"/>
                  <YAxis/>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <Tooltip/>
                  <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{r: 8}}/>
                </LineChart>
              </ResponsiveContainer>
            </Spin>
          </Row>
          <div style={{marginBottom: '50px'}}></div>
        </div>
      );
    })
  }
  render() {
    const { rangePickerValue, isFetching } = this.state;
    return (
      <Card
        style={{minHeight: '180px'}}
        >
        <div className="StatisticsScreen">
          <div className="usageElectronWrap">
            <Col span={24} className="textAlignRight">
              <Row className="date-container">
                <div className="usageElectron date-btns-container">
                  <button className={"ant-btn "+this.isActive('today')} onClick={() => this.selectDate('today')}>
                    Today
                  </button>
                  <button className={"ant-btn "+this.isActive('week')} onClick={() => this.selectDate('week')}>
                    Week
                  </button>
                  <button className={"ant-btn "+this.isActive('month')} onClick={() => this.selectDate('month')}>
                    Month
                  </button>
                  <button className={"ant-btn "+this.isActive('sixmonths')} onClick={() => this.selectDate('sixmonths')}>
                    Last 6 month
                  </button>
                  <button className={"ant-btn "+this.isActive('year')} onClick={() => this.selectDate('year')}>
                    Year
                  </button>
                  <button className={"ant-btn "+this.isActive('total')} onClick={() => this.selectDate('total')}>
                    Total
                  </button>
                </div>
                <div className="date-adds-container">
                  <RangePicker
                    value={rangePickerValue}
                    onChange={this.handleRangePickerChange}
                    allowClear={false}
                  />
                  <Button onClick={this.downloadUsersList_v2} className="getPdf" type="primary">Download Users Stats</Button>
                </div>
              </Row>
            </Col>
          </div>
          <Spin style={{minHeight: '90px'}} spinning={isFetching}>
            {this.renderStatistics()}
          </Spin>
        </div>
      </Card>
    )
  }
};

export default GlobalOrgStatistics;
