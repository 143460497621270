import React from 'react';
import { Modal, Button, Card, Input, Alert, Collapse, Timeline, Spin, Table } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import moment from 'moment-timezone';
import './styles.less';
import CreateAndEditTemplate from './components/CreateAndEditTemplate';

const Panel = Collapse.Panel;
const { TextArea } = Input;
const confirm = Modal.confirm;

const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
};
class SmsSender extends React.Component {
	state = {
		isFetching: false,
		templateList: [],
		textCustom: '',
    templateId: null,
    header: '',
    templateText: '',
		message: '',
		successSend: false,
		listMessages: [],
		timelineFetching: false,
    visibleAddTemplate: false,
    visibleEditTemplate: false
	}
  componentDidMount() {
    this.getSmsTemplates();
    if (this.props.userId) {
      this.getListMessages();
    }
  }
	getSmsTemplates = () => {
		this.setState({
			isFetching: true
		})
    fetchJSON('/api/v1/user/sms/templates', {
			method: 'get'
		}).then(response => {
			console.log(response);
			if (response.success) {
				this.setState({
					isFetching: false,
					templateList: response.data
				});
			}
     }).catch(error => {
				console.log(error);
				this.setState({
					isFetching: false
				});
     });
  }
  getData = () => {
    const smsTemplates = this.state.templateList;
    const data = smsTemplates ? smsTemplates : [];
    return data.map((item, index) => {
      return {
        key: item.id,
        header: item.header,
        value: item.value,
        id: item.id
      }
    })
  }
  columns = () => {
    return [{
      title: 'Header',
      dataIndex: 'header',
      key: 'header',
    },
    {
      title: 'Text',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Actions',
      key: 'actions',
	    render: (text, record) => (
        <span>
          <Button style={{marginBottom: '4px'}} size="small" onClick={() => {this.sendSms(record.id, record.value)}}>
            Send 
        	</Button>
          <Button style={{marginBottom: '4px'}} size="small" onClick={() => {this.deleteTemplate(record.id)}}>
            Delete
          </Button>
          <Button size="small" onClick={() => {this.onEdit(record.id, record.value, record.header)}}>
            Edit 
          </Button>          
        </span>
      )
    }];
  }
  sendSms = (templateId, text) => {
    let { userId, userIds } = this.props;
    if (!text || (!userId && (!userIds || !userIds.length))) return;
      if (userId) {
        userIds = [userId];
      }
    confirm({
      title: userId ? 'Send sms to user?' : 'Send sms to users?',
      onOk: () => {
        const promises = [];
        const responses = [];
        userIds.forEach((userId) => {
          promises.push(new Promise((resolveLocal, reject) => {
            this.sendText(userId,templateId, text, (response = false) => {
              if (response) {
                responses.push(response);
              }
              resolveLocal();
            })
          }));
        });
        Promise.all(promises).then((values) => {
          //console.log('responses', responses);
          if (responses.indexOf(400) === -1) {
            this.setState({
              isFetching: false,
              message: 'Successfully sent',
              successSend: true
            }, () => { 
              if (userId) {
                this.getListMessages();
              } 
            });  
          } else {
            this.setState({
              isFetching: false,
              message: 'There was an error, repeat again',
              successSend: false
            });      
          }
        })

      },
      onCancel() {},
    });
  }
  sendText = (userId, templateId, textCustom, callback) => {
    this.setState({
      isFetching: true
    })
    fetchJSON('/api/v1/user/sms/send', {
      method: 'post',
      body: {
        userId,
        templateId,
        textCustom
      }
    }).then(response => {
      //console.log(response);
      if (response.success) {
        callback(200);
      } else {
        callback(400);
      }
     }).catch(error => {
        callback(400);
        console.log(error);
      })
  }
  deleteTemplate = (templateId) => {
  confirm({
    title: `Delete template?`,
    onOk: () => {
      this.setState({
        isFetching: true
      })
      fetchJSON(`/api/v1/user/sms/templates/delete/${templateId}`, {
        method: 'PUT'
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            isFetching: false
          }, this.getSmsTemplates);
        } else {
          this.setState({
            isFetching: false
          });
        }
        }).catch(error => {
          console.log(error);
          this.setState({
            isFetching: false
          });
        });
      },
    onCancel() {},
  });
  }
  changeCustomText = e => {
  	this.setState({
      textCustom: e.target.value
    });
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
    if (!value) {
      this.getSmsTemplates();
    }
    if (key==='visibleEditTemplate' && !value) {
      this.setState({
        templateId: null
    })  
    }
  }
  onEdit = (templateId, text, header) => {
    this.setState({
      visibleEditTemplate: true,
      templateId: templateId,
      header: header,
      templateText: text
    })
  }
  getListMessages = () => {
  	this.setState({
			timelineFetching: true
		})
    fetchJSON(`/api/v1/user/sms/list/${this.props.userId}`, {
			method: 'get'
		}).then(response => {
			console.log(response);
			if (response.success) {
				this.setState({
					timelineFetching: false,
					listMessages: response.data
				});
			}
     }).catch(error => {
				console.log(error);
				this.setState({
					timelineFetching: false
				});
     });
  }
  renderTimeline = () => {
  	const { listMessages } = this.state;
  	if (!listMessages.length) return null;
  	return listMessages.map((item, index) => {
  		let senddate = moment.unix(item.senddate).tz('America/Los_Angeles').format('DD MMM YYYY h:mm A');
  		return (
  			<Timeline.Item key={index}>
  				<p><b>Text:</b> { item.template ? item.template.value : item.text }</p>
  				<p><b>Date:</b> { senddate }</p>
  			</Timeline.Item>
			)
  	})

  }
  render() {
  	const { isFetching, textCustom, message, successSend, listMessages, timelineFetching, visibleAddTemplate, 
      visibleEditTemplate, templateId, header, templateText } = this.state;
    const { userId, visible } = this.props;
    return (
      <Modal
      	className="nocardPadding"
        style={{ top: 20 }}
        width = {1040}
        visible={visible}
        title={userId ? `Send text to user #${userId}` : `Send text to users`}
        onOk={this.props.handleClose}
        onCancel={this.props.handleClose}
        footer={[
          <Button key="submit" type="primary" loading= {isFetching} onClick={this.props.handleClose}>
            Close
          </Button>
        ]}
      >
		    <Card loading={isFetching}>
		  	  { message ? 
		 		    <div style={{marginBottom: '20px'}}>
              <Alert message={message} type={ successSend ? "success" : "error" }/>
            </div> 
            : null }
          <Button key="add" type="primary" onClick={() => {this.modalVisible('visibleAddTemplate', true)}}>
            Add template 
          </Button>
          <div style={{'padding': '20px 0'}}>
			      <Table
          	  columns={ this.columns() }
          	  loading={ isFetching }
          	  dataSource={ this.getData() } 
             />
          </div>
		      <div style={{width: '400px'}}>
		        <TextArea placeholder={userId ? 'Write your text for the user' : 'Write your text for users'} autosize={{ minRows: 2, maxRows: 6 }} value={textCustom} onChange={this.changeCustomText} />
		      </div>
			    <div style={{'padding': '20px 0'}}>
		  		  <Button key="submit" type="primary" 
              onClick={() => {this.sendSms('customText', this.state.textCustom)}}>
	             Send custom text
	          </Button>
          </div>
          { userId && listMessages.length ? 
        	  <Collapse bordered={false}>
			        <Panel header="List of sent messages" key="1" style={customPanelStyle}>
			          <Spin spinning={timelineFetching}>
			            <Timeline style={{
			        	    'minHeight': '200px',
			        	    'maxHeight': '400px',
			        	    'overflow': 'scroll',
			      		    'padding': '5px 0 0 5px'
			              }}>
					          { this.renderTimeline() }
				          </Timeline> 
				        </Spin>
			        </Panel>
			      </Collapse> : null }
          { visibleAddTemplate ? 
            <CreateAndEditTemplate
              templateId={templateId}
              visible={visibleAddTemplate}
              value={null}
              header={null}
              handleClose={ () => { this.modalVisible('visibleAddTemplate', false) } } 
            /> 
          : null }      
          { visibleEditTemplate ? 
            <CreateAndEditTemplate
              templateId={templateId}
              visible={visibleEditTemplate}
              value={templateText}
              header={header}
              handleClose={ () => { this.modalVisible('visibleEditTemplate', false) } } 
            /> 
            : null }
	      </Card>
      </Modal>
    )
  }
};

export default SmsSender;
