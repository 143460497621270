import React from 'react';
import Settings from 'services/config/Settings';
import { Layout, Button, Spin, Row, Col, Card } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import moment from 'moment-timezone';

import domtoimage from 'dom-to-image';
import qrcode from 'screens/Dashboard/components/GroupSetting/qrcode';
import { saveAs } from 'file-saver';
import { CompactPicker } from 'react-color';
import ScanMeLogo from 'screens/Dashboard/screens/Venues/components/SingleVenue/components/scanMeLogo';
import JSZip from 'jszip';

const { Content } = Layout;
require('potrace-browser');

class SingleGroupSeries extends React.Component {
  state = {
  	isFetching: true,
    series: [],
    seriesString: '',
    created_at: '',
    name: '',
    loading: false,
    fillColor: '#000000',
    bgColor: '#FFFFFF',
    showIconScanMe: true,
    groupId: ''
  }
  componentDidMount() {
    document.title = `Single Group Series | ${Settings.title}`;
    const { seriesId } = this.props.match.params;
    this.setState({
      seriesId
    }, this.getSingleSeries);
  }
  generateQR = (groupId) => {
    const { fillColor, bgColor } = this.state;
    const typeNumber = 0;
    const errorCorrectionLevel = 'H';
    const qr = qrcode(typeNumber, errorCorrectionLevel);
    const url = `https://e2g.app/i/${groupId}`;
    qr.addData(url);
    qr.make();
    const render = qr.createTableTag(25, 0, fillColor, bgColor);
    if (render) {
      return {
        __html: render
      };
    }
    return {
      __html: 'error'
    };
  }
  getSingleSeries = () => {
    this.setState({
      isFetching: true
    });
    const { seriesId } = this.state;
    fetchJSON(`/api/v1/group/generate/${seriesId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          isFetching: false,
          name: response.data.name,
          created_at: moment.utc(response.data.created_at).tz('America/Los_Angeles').format('MM/DD/YY h:mm:ss a'),
          series: response.data.groups,
          seriesString: response.data.groups ? response.data.groups.join(', ') : ''
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        });
     });
  }
  downloadPNG = () => {
    const { series, name, created_at  } = this.state;

    this.setState({
      loading: true
    });
    const zip = new JSZip();

    let indexLoading = 0;
    const generateListLoop = (series, onEndCallback) => {
      handlerGenerateGroup(series[indexLoading], () => {
        indexLoading += 1;
        if (indexLoading < series.length) {
          generateListLoop(series, onEndCallback);
        } else {
          onEndCallback();
        }
      })
    }

    const handlerGenerateGroup = (groupId, callback) => {
      this.setState({
        groupId
      }, () => {
        domtoimage.toBlob(document.getElementById('qrTarget'))
          .then((dataUrl) => {
            zip.file(`qr-${groupId}.png`, new Blob([dataUrl]));
          }).then(() => {
            callback();
          });
      });
    }
  
    const onEndCallback = () => {
      console.log('onEndCallback');
      zip.generateAsync({type:"blob"})
        .then(function (blob) {
          saveAs(blob, `qr-list-${series.length}-${name}-${created_at}-png.zip`);
        });
      this.setState({
        loading: false
      })
    }

    generateListLoop(series, onEndCallback);
  }
  downloadSVG = () => {
    const { series, name, created_at } = this.state;

    this.doPreset(false, 'BW');

    this.setState({
      loading: true
    });
    const zip = new JSZip();

    let indexLoading = 0;
    const generateListLoop = (series, onEndCallback) => {
      handlerGenerateGroup(series[indexLoading], () => {
        indexLoading += 1;
        if (indexLoading < series.length) {
          generateListLoop(series, onEndCallback);
        } else {
          onEndCallback();
        }
      })
    }

    const handlerGenerateGroup = (groupId, callback) => {
      this.setState({
        groupId
      }, () => {
        domtoimage.toPng(document.getElementById('qrTarget'))
          .then((dataUrl) => {
            window.Potrace.loadImageFromUrl(dataUrl);
            window.Potrace.process(function(){
              const svg = window.Potrace.getSVG(1);
              zip.file(`qr-${groupId}.svg`, new Blob([svg]));
              callback();
            });
          })
      });
    }
  
    const onEndCallback = () => {
      console.log('onEndCallback');
      zip.generateAsync({type:"blob"})
        .then(function (blob) {
          saveAs(blob, `qr-list-${series.length}-${name}-${created_at}-svg.zip`);
        });
      this.setState({
        loading: false
      })
    }

    generateListLoop(series, onEndCallback);
  }
  handleColor = (color, colorName) => {
    this.setState({ [colorName]: color.hex });
  }
  doPreset = (e, presetName) => {
    let fillColor = '#000000';
    let bgColor = '#FFFFFF';
    switch(presetName) {
      case 'Brand': 
        fillColor = '#FC4554';
        break;
      case 'WB': 
        fillColor = '#FFFFFF';
        bgColor = '#000000';
        break;
      default:
    }
    this.setState({
      fillColor,
      bgColor
    })
  }
  toggleIcon = () => {
    const { showIconScanMe } = this.state;
    this.setState({
      'showIconScanMe': !showIconScanMe
    });
  }
  render() {
    const {
      isFetching,
      name,
      created_at,
      loading,
      fillColor,
      bgColor,
      showIconScanMe,
      groupId,
      seriesString
    } = this.state;

    const boxStyle = {
      position: 'relative',
      padding: '20px',
      background: bgColor,
      display: 'inline-block',
    }

  	return (
  	  <Content className="fill-bg-table">
        <Spin spinning={isFetching} >
          <Card>
    		  <h2 className="table-title">
            Series: {name}
          </h2>
          <h4 className="table-title">
            Created at: {created_at}
          </h4>
          <h4 className="table-title">
            Group IDs: {seriesString}
          </h4>
          <div style={{
            marginTop: '30px',
            marginBottom: '30px'
          }}>
            <Row gutter={8}>
              <Col span={5}>
                <h3>Download</h3>
                <Button onClick={ this.downloadPNG } loading={loading} style={{marginBottom: '8px', marginRight: '8px'}}>
                  Download PNG
                </Button>
                <Button onClick={ this.downloadSVG } loading={loading} style={{marginBottom: '8px'}}>
                  Download SVG (B&W only)
                </Button>
                <h3>Scan Me Icon</h3>
                <Button onClick={ this.toggleIcon } style={{marginBottom: '8px'}}>
                  {showIconScanMe ? 'Hide Icon' : 'Show Icon' } 
                </Button>
              </Col>
              <Col span={5}>
                <h3>Presets</h3>
                <Button onClick={ e => this.doPreset(e, 'Brand') } style={{marginBottom: '8px'}}>
                  Brand Colors
                </Button>
                <Button onClick={ e => this.doPreset(e, 'BW') } style={{marginBottom: '8px'}}>
                  Black and White
                </Button>
                <Button onClick={ e => this.doPreset(e, 'WB') } style={{marginBottom: '8px'}}>
                  White and Black
                </Button>
              </Col>
              <Col span={6}>
                <h3>Fill color</h3>
                <CompactPicker
                  color={ fillColor }
                  onChangeComplete={ e => this.handleColor(e, 'fillColor') }
                />
              </Col>
              <Col span={6}>
                <h3>Background Color</h3>
                <CompactPicker
                  color={ bgColor }
                  onChangeComplete={ e => this.handleColor(e, 'bgColor') }
                />
              </Col>
              
            </Row>
          </div>
          <div style={{textAlign: 'center'}}>
            <span id="qrTarget" style={boxStyle}>
              <span dangerouslySetInnerHTML={this.generateQR(groupId)}></span>
              {showIconScanMe ? <ScanMeLogo fillColor={fillColor} bgColor={bgColor} /> : null }
            </span>
          </div>
          </Card>
        </Spin>
      </Content>
    )
  }
}



export default SingleGroupSeries;