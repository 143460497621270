import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Row, Col, Spin } from 'antd';
import _ from 'lodash';

const FormItem = Form.Item;


const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

class ElectronSupernovaInfo extends React.Component {
  state = {
    isFetching: false, 
    electronId: '',
    electron: {
      string_id: '',
      status: {
        owner_type: 'supernova',
        owner_id: 0
      },
      venue: '',
      hardware_revision: '',
      cable_types: ''
    },
    fetchingStatus: false,
  }

  componentDidMount() {
  	this.getSingleElectron(); 
  }

  getSingleElectron = () => {
    this.setState({
      isFetching: true
    })
    fetchJSON(`/api/v1/electrons/${this.props.electronId}`, {
      method: 'get'
    }).then(response => {
      console.log(response)
      if (response.success) {
      	console.log(response.data);
        this.setState({
          isFetching: false,
          electron: response.data
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false
        })
   	 })
  }

  render() {
  	const { isFetching, electron } = this.state;
    const venueTitle = _.get(electron, 'supernova.group.group.venue_groups.venue.title');
    const supernovaId = _.get(electron, 'supernova.string_id');
    const groupId = _.get(electron, 'supernova.group.group.key_id');
  	return ( 
  		<FormItem {...formItemLayout}> 
        { supernovaId ?
        <Spin spinning={isFetching}>
        <Row gutter={8}>  
          <Col span={8}>
            <Row>
              <label className="form-label-custom">Supernova ID</label>
            </Row>
            <Row>
              <Input value={ supernovaId } readOnly={true} />
            </Row>      
          </Col>
          <Col span={8}>
            <Row>
              <label className="form-label-custom">Group ID</label>
            </Row>
            <Row>
              <Input value={ groupId ? groupId : null } readOnly={true} />
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <label className="form-label-custom">Venue</label>
            </Row>
            <Row>
              <Input value={ venueTitle ? venueTitle : null } readOnly={true} />
            </Row>
          </Col>
        </Row>
        </Spin> : null}
        </FormItem> 
      )
  }
 }
export default ElectronSupernovaInfo;