import React from 'react';
import { Table } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';


class PromoHistory extends React.Component {
  state = {
    filteredInfo: {},
    sortedInfo: {},
    filtered: false,
    data: []
  };
  componentDidMount() {
    document.title = 'Promo History';
  }
  columns = () => {
    let {
      sortedInfo,
      //filteredInfo
    } = this.state;
    sortedInfo = sortedInfo || {};
    // filteredInfo = filteredInfo || {};
    return [
    {
      title: '#',
      dataIndex: 'counter',
      key: 'counter',
      sortOrder: sortedInfo.columnKey === 'counter' && sortedInfo.order,
      sorter: (a, b) => a.counter - b.counter
    },
    {
      title: 'User ID',
      dataIndex: 'user_id',
      key: 'user_id',
      sortOrder: sortedInfo.columnKey === 'user_id' && sortedInfo.order,
      sorter: (a, b) => a.user_id - b.user_id
    },
    {
      title: 'User phone',
      dataIndex: 'userPhone',
      key: 'userPhone',
      render: (text, record) => (
        <Link to={`/users/${record.user_id}`} target="_blank">{record.userPhone}</Link>
      ),
    },
    {
      title: 'Happend',
      dataIndex: 'created_at',
      key: 'created_at',
    }, {
      title: 'Sessions during promo',
      dataIndex: 'sessionsDuringPromo',
      key: 'sessionsDuringPromo',
      sortOrder: sortedInfo.columnKey === 'sessionsDuringPromo' && sortedInfo.order,
      sorter: (a, b) => a.sessionsDuringPromo - b.sessionsDuringPromo
    }, {
      title: 'Sessions after promo',
      dataIndex: 'sessionsAfterPromo',
      key: 'sessionsAfterPromo',
      sortOrder: sortedInfo.columnKey === 'sessionsAfterPromo' && sortedInfo.order,
      sorter: (a, b) => a.sessionsAfterPromo - b.sessionsAfterPromo
    }];
  }
  handleTableChange = (pagination, filters, sorter) => {
    // console.log('handleTableChange', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  renderTable = () => {
    const { promocode } = this.props;
    const data = promocode.transactions ? promocode.transactions : [];
    let counter = 0;
    return data.map((item, index) => {
      let created_at = _.get(item, 'created_at');
      counter += 1;

      created_at = created_at ? moment(created_at).tz('America/Los_Angeles').format('DD MMM YYYY h:mm A') : null;
      return {
        counter: counter,
        key: item.id,
        user_id: item.uid,
        userPhone: _.get(item.user, 'phone'),
        created_at,
        sessionsDuringPromo: item.sessionsDuringPromo,
        sessionsAfterPromo: item.sessionsAfterPromo
      }
    })
  }
  render() {
    const { promocode } = this.props;
    return (
      <div className="wrapper">
        <div>
          <Row gutter={8}>
            <Col span={8}>
              <h1 className="table-title">
                Promocode Transactions - {promocode.transactions ? promocode.transactions.length : 0} total
              </h1>
            </Col>
          </Row>
          <Table
            columns={ this.columns() }
            dataSource={ this.renderTable() }
            pagination={{ pageSize: 100 }}
            onChange={ this.handleTableChange }
          />
        </div>
      </div>
    )
  }
};

export default PromoHistory;
