import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Modal, Button, Spin, Timeline, Table } from 'antd';
import moment from 'moment-timezone';
import _ from 'lodash';

import { fetchSecretQuote } from './data/actions'

class ElectronHistory extends React.Component {
  componentDidMount() {
  	let electronID = this.props.electronID ? parseInt(this.props.electronID, 10) : null;
    if (electronID) {
      if (!this.props.quote || this.lessThanAgo(this.props.createdFetch) || this.props.electronIdFetch !== electronID) {
      	this.props.dispatch(fetchSecretQuote(`/electrons/history/${this.props.electronID}`))
      }
    } else {
      if (this.props.modalView) this.props.handleClose();
    }
  }
  lessThanAgo = (date) => {
    if (!date) return false;
    const _AGO = 1000 * 60 * 1;
    let anMinuteAgo = Date.now() - _AGO;
    return date < anMinuteAgo;
  }
  renderTimeline = () => {
  	const { quote } = this.props;
  	const data = quote ? quote.data : [];
  	return data.map((item, index) => {
  		let actddate = moment.unix(item.actddate).tz('America/Los_Angeles').format('DD MMM YYYY h:mm A');
  		let venue = null;
  		let color;
  		let icon;
  		if (item.supernova_id) {
  			venue = <p><b>Venue:</b> ID {item.supernova.venue.id}; { item.supernova.venue.title }; { item.supernova.venue.address }</p>;
  		}
  		let act = item.act;

  		if (act === 'open') {
  			act = "Took";
  			color = "blue";
  			icon = "clock-circle-o"
  		} else if (act === 'close') {
  			act = "Returned";
  			color = "green";
  			icon = "safety"
  		} else {
  			act = "Lost";
  			color = "red";
  			icon = "exclamation-circle"
  		}
  		return (
          <Timeline.Item key={index} color={color} dot={<LegacyIcon type={icon} style={{ fontSize: '16px' }} />}>
              <p><b>{ act }</b>, { actddate }</p>
              <p><b>User:</b> { item.user.phone}</p>
              { item.supernova_id ? <p><b style={{'fontWeight': 'bold'}}>Supernova:</b> { item.supernova_id }</p> : null }
              { venue }
          </Timeline.Item>
        );
  	});
  }
  columns = () => {
    return [{
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
      render: (text, record) => (
        <span style={{color: record.colorStatus }}>
          {record.iconStatus}  {record.event}
        </span>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (text, record) => (
        <Link to={`/users/${record.user_id}`} target="_blank">{record.user}</Link>
      ),
    },
    {
      title: 'Supernova',
      dataIndex: 'supernova',
      key: 'supernova',
      render: (text, record) => (
        <span>
          { record.supernova ? 
            <Link to={`/supernovas/${record.supernovaId}`} target="_blank">{record.supernovaId}</Link>
            : null
          }
        </span>
      ),
    }, {
      title: 'Venue',
      dataIndex: 'venueTitle',
      key: 'venueTitle',
      render: (text, record) => (
        <span>
          <Link to={`/venues/${record.venueId}`} target="_blank">{record.venueTitle}</Link>
        </span>
      )
    }];
  }
  renderTable = () => {
    const { quote } = this.props;
    const data = quote ? quote.data : [];
    return data.map((item, index) => {
      console.log('item', item);
      let actddate = moment.unix(item.actddate).tz('America/Los_Angeles').format('DD MMM YYYY h:mm A');
      let color;
      let icon;
      let venueTitle = _.get(item.supernova, 'group.group.venue_groups.venue.title');
      let venueId = _.get(item.supernova, 'group.group.venue_groups.venue.id');
      let act = item.act;

      if (act === 'open') {
        act = "Took";
        color = "blue";
        icon = "clock-circle-o"
      } else if (act === 'close') {
        act = "Returned";
        color = "green";
        icon = "safety"
      } else if (act === 'lost') {
        act = "Lost";
        color = "red";
        icon = "exclamation-circle"
      } else {
        act = "Stolen";
        color = "red";
        icon = "exclamation-circle"
      }
      
      return {
        key: item.id,
        iconStatus: <LegacyIcon type={icon} style={{ fontSize: '16px' }} />,
        colorStatus: color,
        event: act,
        date: actddate,
        user_id: item.user ? item.user.id : null,
        user: item.user ? item.user.phone : null,
        supernova: item.supernova_id ? item.supernova_id : null,
        supernovaId: item.supernova_id ? item.supernova.string_id : null,
        venueTitle,
        venueId
      };
    });
  }
  render() {
  	const { electronID, isFetching, modalView } = this.props;
    return (
      <div>
        { modalView ? 
          <Modal
            style={{ top: 20 }}
            visible={this.props.visible}
            title={`History of electron: ${electronID}`}
            onOk={this.props.handleClose}
            onCancel={this.props.handleClose}
            footer={[
              <Button key="submit" type="primary" loading={ isFetching } onClick={this.props.handleClose}>
                Ok
              </Button>,
            ]}
          >
  				<Spin spinning={ isFetching } >
  	        <Timeline style={{
  	        	'minHeight': '200px',
  	        	'maxHeight': '400px',
  	        	'overflow': 'scroll',
  	        	'padding': '5px 0 0 5px'
  	        }}>
  				    { this.renderTimeline() }
  				  </Timeline>
          </Spin>
        </Modal>
        : 
          <div>
            <Table
              columns={ this.columns() }
              loading={ isFetching }
              dataSource={ this.renderTable() }
              pagination={{ pageSize: 7 }}
              onChange={ this.handleTableChange }
            />
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) =>{
  const { electronHistory } = state;
  const { quote, isFetching, createdFetch, electronId } = electronHistory;
  const electronIdFetch = electronId ? parseInt(electronId, 10) : false;
  return {
  	quote,
    isFetching,
    createdFetch,
    electronIdFetch
  }
}
export default connect(mapStateToProps)(ElectronHistory)