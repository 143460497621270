/**
 * fetchJSON use to communicate with the server
 * 
 */

import fetch from 'isomorphic-fetch';
import Auth from './Auth';


function checkStatus(response) {
  if(response.ok) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    error.status = response.status;
    throw error;
  }
}

function parseJSON(response, responseIsJson) {
  if (responseIsJson) return response.json();
  return response;
}

export default function enhancedFetch(url, options, responseIsJson = true) {
  options.headers = Object.assign({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }, options.headers);
  
  options.headers.Authorization = `bearer ${Auth.getToken()}`;

  if (options.removeHeaders) {
    options.headers = {};
  }

  if(typeof options.body !== 'string') {
    options.body = JSON.stringify(options.body);
  }
  return fetch(url, options)
    .then(checkStatus)
    .then(response => parseJSON(response, responseIsJson));
}