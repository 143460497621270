import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Button, Alert, Select, message, Row, Col } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const FormItem = Form.Item;
const { Option } = Select;

class NebulaUsersSettings extends React.Component {
	state = {
    putREQUEST: false,
    putFAILURE: false,
    putSUCCESS: false,
    putMessage: '',
    warehousesList: []
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({
        putREQUEST: true
      })
      fetchJSON('/api/v1/organisation/editNebulaUsersSettings', {
        method: 'PUT',
        body: fieldsValue
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false
          }, () => {
          	this.props.renderList();
          	this.props.handleClose();
          });
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false
          });
        }
       }).catch(error => {
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });
    })
  }
  render() {
    const { putFAILURE } = this.state; 
  	const { visible, handleClose, form, organisationId } = this.props;
  	const { getFieldDecorator } = form;
    return (
    	<Modal
        style={{ top: 20 }}
        visible={ visible }
        width={400}
        title="Nebula users settings"
        onOk={ handleClose }
        onCancel={ handleClose }
        footer={false}
      >
	      <Form layout="vertical" onSubmit={this.handleSubmit}>
	      	<div style={{'display': 'none'}}>
            {getFieldDecorator('organisationId', {
              rules: [{ required: true, message: 'Please enter a title!' }],
              initialValue: organisationId
            })(
              <Input />
            )}
          </div>
          <FormItem label="Surveys reports">
            {getFieldDecorator('reports_surveys', {
              rules: [{ required: true, message: '' }],
              initialValue: 0
            })(
              <Select
                style={{ width: '100%' }}
                placeholder="Please select"
              >
                <Option key={0} value={1} style={{color: 'green'}}>On</Option>
                <Option key={1} value={0} style={{color: 'red'}}>Off</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="Trends reports">
            {getFieldDecorator('reports_trends', {
              rules: [{ required: true, message: '' }],
              initialValue: 0
            })(
              <Select
                style={{ width: '100%' }}
                placeholder="Please select"
              >
                <Option key={0} value={1} style={{color: 'green'}}>On</Option>
                <Option key={1} value={0} style={{color: 'red'}}>Off</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="Users section">
            {getFieldDecorator('show_users_tab', {
              rules: [{ required: true, message: '' }],
              initialValue: 0
            })(
              <Select
                style={{ width: '100%' }}
                placeholder="Please select"
              >
                <Option key={0} value={1} style={{color: 'green'}}>On</Option>
                <Option key={1} value={0} style={{color: 'red'}}>Off</Option>
              </Select>
            )}
          </FormItem>
          <Button type="primary" htmlType="submit" style={{marginTop: '20px'}}>Submit</Button>
          <Button style={{marginTop: '20px', marginLeft: '16px'}} onClick={() => this.props.handleClose()}>Cancel</Button>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(NebulaUsersSettings);