import React from 'react';
import { LazyLog, ScrollFollow } from 'react-lazylog/es5';
import { Link } from 'react-router-dom';
import { Layout, Row, Col, message, Button, Modal  } from 'antd';
import SendCommand from './components/SendCommand';
import fetchJSON from 'services/utils/fetchJSON';
import Auth from 'services/utils/Auth';

const { Content } = Layout;
const confirm = Modal.confirm;

class SupernovasLog extends React.Component {
  state = {
    machineIdToSend: '',
    visibleShowCommand: false,
    following: true
  }
  componentDidMount() {
    document.title = 'Supernovas Log';
  }
  showCommandDialog = (key, value, machineId) => {
    this.setState({
      [key]: value,
      machineIdToSend: machineId
    });
  }
  successResultCommand = () => {
    message.success('Command successfully sent');
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  toggleFollow = () => {
    this.setState({
      following: !this.state.following
    })
  }
  runInventory = (source) => {
    confirm({
      title: 'Run the inventory?',
      onOk: () => {
        return new Promise((resolve, reject) => {
           fetchJSON(`/api/v1/supernova/talk/${source}`, {
            method: 'PUT',
            body: {
              command: 'INVENTORYRUN'
            }
          }).then(response => {
            console.log(response);
            if (response.success) {
              message.success('OK');
              resolve()
            } else {
              message.error('Error');
              reject();
            }
           }).catch(error => {
              console.log(error);
              message.error('Error');
              reject();
           });

        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  updateQueue = (source) => {
    fetchJSON(`/api/v1/supernova/talk/${source}`, {
      method: 'PUT',
      body: {
        command: 'UPDATEQUEUE'
      }
    }).then(response => {
      console.log(response);
      if (response.success) {
        message.success('OK');
      } else {
        message.error('Error');
      }
     }).catch(error => {
        console.log(error);
        message.error('Error');
     });
  }
  render() {
    const { visibleShowCommand, following } = this.state;
    const { logSource } = this.props.match.params;
    let url = process.env.NODE_ENV === 'development' ? 'https://supernovas.toool.co' : 'https://supernovas.cloud';
    console.log('process.env.NODE_ENV', process.env);
    if (process.env.REACT_APP_ENV === 'staging') {
      url = 'https://tsupernovas.electrontogo.com'
    }
    
    let isSupernova = false;
    let isGroup = false;

    try {
      isSupernova = logSource.substring(0, 2) === 'SN' || logSource.substring(0, 2) === 'SC';
    } catch (err) {
      // err
    }
    if (/^\d{4}$/.test(logSource)) {
      isGroup = true;
    }
    return (
      <Content className="fill-bg-table" style={{marginBottom: '50px'}}>
        <Row gutter={8}>
          <Col span={18}>
            <h1 className="table-title">
              {logSource} Log
              <Link to='/monitor' className="ant-btn ant-btn-sm" style={{marginLeft: '8px'}}>
                Monitor
              </Link>
              <Button className="ant-btn ant-btn-sm" onClick={ () => { this.showCommandDialog('visibleShowCommand', true, logSource) } } style={{marginLeft: '8px'}}>
                Send command
              </Button>
              <button onClick={e => this.runInventory(logSource)} className="ant-btn ant-btn-sm" style={{marginLeft: '8px'}}>
                Run Inventory
              </button>
              {!following ? <Button className="ant-btn ant-btn-sm" onClick={ this.toggleFollow } style={{marginLeft: '8px'}}>
                Start Following
              </Button> :
              <Button className="ant-btn ant-btn-sm" onClick={ this.toggleFollow  } style={{marginLeft: '8px'}}>
                Stop Following
              </Button> }
              { isSupernova ? <Link to={`/supernovas/${logSource}`} className="ant-btn ant-btn-sm" style={{marginLeft: '8px'}}>
                Supernova card
              </Link> : null }
              {isGroup ? <button onClick={e => this.updateQueue(logSource)} className="ant-btn ant-btn-sm" style={{marginLeft: '8px'}}>
                Update the queue
              </button> : null }
            </h1>
          </Col>
        </Row>

        <div className="wrapper">
          <div style={{ height: '500px', width: '100%', color: '#fff', background: '#000', padding: '10px' }}>
            <ScrollFollow
              startFollowing={following}
              render={({ onScroll, follow, startFollowing, stopFollowing }) => (
                <LazyLog url={`${url}/api/v1/machines/logs/${logSource}?token=${Auth.getToken()}`} stream onScroll={onScroll} follow={follow} />
              )}
            />
          </div>
        </div>
        { visibleShowCommand ?
          <SendCommand 
            visible={visibleShowCommand}
            successResult={this.successResultCommand}
            machineId={this.state.machineIdToSend}
            handleClose={ () => { this.modalVisible('visibleShowCommand', false) } }
            /> : null }
      </Content>
    )
  }
};

export default SupernovasLog;
