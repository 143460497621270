import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Button, message } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const FormItem = Form.Item;

class AssignToGroup extends React.Component {
	state = {
    putREQUEST: false,
    putFAILURE: false,
    putSUCCESS: false,
    putMessage: ''
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({
        putREQUEST: true
      })
      fetchJSON('/api/v1/supernova/assignToGroup', {
        method: 'POST',
        body: fieldsValue
      }).then(response => {
        console.log(response);
        if (response.success) {
          message.success(response.message);
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false
          }, () => {
          	this.props.renderGroups();
          	this.props.handleClose();
          });
        } else {
          message.error(response.message);
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false
          });
        }
       }).catch(error => {
          console.log(error);
          message.error("Error. Something went wrong. Repeat");
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });
    })
  }
  render() {
  	const { visible, handleClose, form, supernovaId } = this.props;
  	const { getFieldDecorator } = form;
    return (
    	<Modal
        style={{ top: 20 }}
        visible={ visible }
        width={400}
        title="Assign to the location ID"
        onOk={ handleClose }
        onCancel={ handleClose }
        footer={false}
      >
	      <Form layout="vertical" onSubmit={this.handleSubmit}>
	      	<div style={{'display': 'none'}}>
            {getFieldDecorator('supernovaId', {
              rules: [{ required: false, message: 'Please enter a title!' }],
              initialValue: supernovaId
            })(
              <Input />
            )}
          </div> 
          <FormItem label="Location ID">
            {getFieldDecorator('group_id', {
              rules: [{ required: true, message: 'Please enter a location ID!' }],
            })(
              <Input />
            )}
          </FormItem>
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(AssignToGroup);
