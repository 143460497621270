import React from 'react';
import _ from 'lodash';
import { CloseCircleOutlined } from '@ant-design/icons';
import {
  Table,
  Layout,
  Input,
  Row,
  Col,
  Button,
  Select,
  Tag,
  Tooltip,
  Modal,
  message,
} from 'antd';
import { Link } from 'react-router-dom';
import fetchJSON from 'services/utils/fetchJSON';
import SmsAuthenticationPrompt from 'screens/Dashboard/components/SmsAuthenticationPrompt/SmsAuthenticationPrompt';
import { TransferLocation } from './TransferLocation/TransferLocation';

import '../styles.less';

const { Content } = Layout;
const Search = Input.Search;
const { Option } = Select;

class VenuesTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
      filteredInfo: {},
      sortedInfo: {},
      searchText: '',
      filtered: false,
      data: [],
      searchData: [],
      detailedTable: false,
      extendedInfoLoaded: false,
      businessCaregotiesLoaded: false,
      searchParameter: false,
      selectedRowKeys: [],
      selectedRowValues: [],
      visibleSmsAuthenticationPrompt: false,
      smsAuthenticationAction: '',
      doAfterSmsAuthentication: false
    };
    this.totalNumberTable = 0;
    this.totalNumberInterval = null;
    this.TransferLocation = TransferLocation.bind(this);
  }
  componentDidMount() {
    this.mounted = true;
    this.getVenueList();
    this.getBusinessInfo();
    this.setState({
      pagination: {
        pageSizeOptions: ['10', '30', '50', '100'],
        showTotal: (total) => {
          return <p id="totalNumber" style={{ display: 'none' }}>{this.renderTotal(total)}</p>
        }
      }
    })
  }
  componentDidUpdate(prevProps) {
    if (this.props.venueIds && prevProps.venueIds) {
      if (this.props.venueIds.length !== prevProps.venueIds.length) {
        this.getVenueList()
      }
    }
  }
  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.totalNumberInterval);
  }
  getVenueList = () => {
    if (!this.mounted) return false;
    this.setState({
      isFetching: true
    })
    let bodyObj = {};
    if (this.props.venueIds) {
      bodyObj.venueIds = this.props.venueIds;
    }
    fetchJSON('/api/v1/venue/list', {
      method: 'put',
      body: bodyObj
    }).then(response => {
      if (!this.mounted) return false;
      if (response.success) {
        this.setState({
          isFetching: false,
          data: response.data,
        }, () => {
          this.props.updateTotalNumberTable(response.data.length)
          this.fillInExtendedInfo(response.data)
        })
      } else {
        this.setState({
          isFetching: false
        })
      }
    }).catch(error => {
      console.log(error);
      if (this.mounted) {
        this.setState({
          isFetching: false
        })
      }
    });
  }
  fillInExtendedInfo = (venues) => {
    let offlineSupernovasColumn = false;
    let subcategoryColumn = false;
    const promises = [];
    const pricingFilters = {
      monthly: [],
      hourly_rate: [],
      daily: []
    };
    const pricingStorage = {
      monthly: [],
      hourly_rate: [],
      daily: []
    };
    const businessInfoFilters = {
      orgType: [],
      orgCategory: [],
      businessSubcategory: []
    };
    const businessInfoStorage = {
      orgType: [],
      orgCategory: [],
      businessSubcategory: []
    };
    const countryFilters = [];
    const countryStorage = [];
    const organisationFilter = [];
    const organisationStorage = [];

    venues.forEach((venue) => {
      const organisationTitle = _.get(venue, 'organisation.organisation.name');
      if (organisationTitle) {
        if (organisationStorage.indexOf(organisationTitle) === -1) {
          organisationStorage.push(organisationTitle);
          organisationFilter.push({ text: organisationTitle, value: organisationTitle });
        }
      }
      if (venue.business_subcategory_id) {
        subcategoryColumn = true;
        if (businessInfoStorage.businessSubcategory.indexOf(venue.business_subcategory_id) === -1) {
          businessInfoStorage.businessSubcategory.push(venue.business_subcategory_id);
        }
      }
      if (businessInfoStorage.orgCategory.indexOf(venue.business_category_id) === -1) {
        businessInfoStorage.orgCategory.push(venue.business_category_id);
      }
      if (businessInfoStorage.orgType.indexOf(venue.business_type_id) === -1) {
        businessInfoStorage.orgType.push(venue.business_type_id);
      }
      if (venue.address_components && venue.address_components.country) {
        if (countryStorage.indexOf(venue.address_components.country) === -1) {
          countryStorage.push(venue.address_components.country);
          countryFilters.push({
            text: venue.address_components.country, value: venue.address_components.country
          })
        }
      }
      promises.push(new Promise((resolveLocal, reject) => {
        this.getPricingSettings(venue.uuid, venue, pricingFilters, pricingStorage, (response = false) => {
          resolveLocal();
        })
      }));
      const supernovas = _.get(venue, 'groups[0].group.supernovas');
      if (supernovas) {
        supernovas.forEach((supernova) => {
          const status = _.get(supernova, 'supernova.status');
          if (status && status === 'offline') {
            offlineSupernovasColumn = true;
          }
        })
      }
    })
    promises.push(new Promise((resolveLocal, reject) => {
      this.getBusinessInfo((response = false) => {
        resolveLocal();
      })
    }));

    Promise.all(promises).then(() => {
      Object.keys(businessInfoStorage).forEach((key) => {
        if (businessInfoStorage[key] && businessInfoStorage[key].length > 0) {
          businessInfoStorage[key].forEach((value) => {
            if (value && this.getBusinessInfoText(value, key) !== 'Other') {
              const businessInfoText = this.getBusinessInfoText(value, key);
              businessInfoFilters[key].push({ text: businessInfoText, value: businessInfoText })
            }
          })
        }
      })
      Object.keys(pricingFilters).forEach((key) => {
        pricingFilters[key].push({ text: 'Off', value: 'Off' });
      })
      Object.keys(businessInfoFilters).forEach((key) => {
        businessInfoFilters[key].push({ text: 'Other', value: 'Other' });
      })
      if (!this.mounted) return false;
      this.setState({
        offlineSupernovasColumn,
        pricingFilters,
        countryFilters,
        subcategoryColumn,
        businessInfoFilters,
        organisationFilter,
        isFetching: false,
        extendedInfoLoaded: true
      });
    }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false
      });
    });
  }
  getPricingSettings = (venueUuid, venue, pricingFilters, pricingStorage, callback) => {
    fetchJSON(`/api/v1/venue/pricing/${venueUuid}`, {
      method: 'get'
    }).then(response => {
      if (!this.mounted) return false;
      if (response.code === 200) {
        const pricingSettings = {
          settings: response.data,
          pricingPolicy: response.data.free_for_users,
          chargePerFreeUse: response.data.charge_per_free_use,
          plans: response.data.plans,
          currencyCode: response.data.currency_code,
          currencyGrapheme: response.data.currency_grapheme,
          currencyTemplate: response.data.currency_template
        };
        const { plans } = response.data;

        if (plans) {
          Object.keys(plans).forEach((key) => {
            pricingSettings[`plans_${plans[key].key_id}_is_active`] = plans[key].is_active;
            if (plans[key].is_active && plans[key].key_id !== 'annual') {
              let priceObj = this.getCurrencyText(plans[key].price, pricingSettings.currencyGrapheme, pricingSettings.currencyTemplate);
              if (parseInt(plans[key].price, 10) && pricingStorage[plans[key].key_id].indexOf(priceObj.value) === -1) {
                pricingStorage[plans[key].key_id].push(priceObj.value);
                pricingFilters[plans[key].key_id].push(priceObj);
              }
            }
          });
        }
        venue.pricingSettings = pricingSettings;
        callback('done');
      }
    }).catch(error => {
      callback('error');
      console.log(error);
    });
  }
  getCurrencyText = (price, grapheme, template) => {
    let result = template.replace('$', grapheme);
    let finalResult = {
      text: result.replace('1', parseFloat(price, 10)),
      value: result.replace('1', parseFloat(price, 10))
    }
    return finalResult
  }
  getBusinessInfo = (callback = '') => {
    if (!this.mounted) return false;
    fetchJSON('/api/v3/general/org-types', {
      method: 'get'
    }).then(response => {
      if (!this.mounted) return false;
      if (response.success) {
        this.setState({
          business_categories: response.data.business_categories,
          business_types: response.data.business_types,
          business_subcategories: response.data.business_subcategories,
          businessCaregotiesLoaded: true
        });
        if (callback) {
          callback('done')
        }
      }
    }).catch(error => {
      if (callback) callback('error');
      console.log(error);
    });
  }
  getBusinessInfoText = (value, type = '') => {
    const { business_categories, business_types, business_subcategories } = this.state;
    let text = '';
    if (type === 'orgCategory') {
      business_categories.forEach((category) => {
        if (category.id === value) {
          text = category.title;
        }
      })
    }
    if (type === 'orgType') {
      business_types.forEach((business_type) => {
        if (business_type.id === value) {
          text = business_type.title;
        }
      })
    }
    if (type === 'businessSubcategory') {
      business_subcategories.forEach((subcategory) => {
        if (subcategory.id === value) {
          text = subcategory.title;
        }
      })
    }
    return text;
  }
  trackTotalNumberValue = () => {
    clearInterval(this.totalNumberInterval);
    if (this.props.getTotalNumberTable() !== this.totalNumberTable) {
      this.props.updateTotalNumberTable(this.totalNumberTable);
    }
    this.totalNumberInterval = null;
  }
  renderTotal = (total) => {
    this.totalNumberTable = total;
    if (this.props.getTotalNumberTable() !== this.totalNumberTable && !this.totalNumberInterval) {
      this.totalNumberInterval = setInterval(this.trackTotalNumberValue, 100);
    }
    return total;
  }
  isEllipsisActive = (e) => {
    if (e) {
      return (e.offsetWidth < e.scrollWidth)
    }
    else {
      return false
    }
  }
  columns = () => {
    let { sortedInfo, organisationFilter, filteredInfo, detailedTable, offlineSupernovasColumn, pricingFilters, countryFilters, subcategoryColumn, businessInfoFilters } = this.state;
    pricingFilters = pricingFilters || {};
    countryFilters = countryFilters || [];
    organisationFilter = organisationFilter || [];
    businessInfoFilters = businessInfoFilters || {};
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    let venueTitleItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Location name</p>
          </div>
        )
      },
      dataIndex: 'title',
      key: 'title',
      width: 157,
      sorter: (a, b) => a.title > b.title ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`venueTitleText_${record.key}}`)) ? record.title : ''}>
            <Link to={`/venues/${record.uuid}`}>
              <p id={`venueTitleText_${record.key}}`} style={{ margin: 0, width: '140px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {record.title}
              </p>
            </Link>
          </Tooltip>
        </div>
      )
    };
    let organisationItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Organization</p>
          </div>
        )
      },
      dataIndex: 'organisation',
      key: 'organisation',
      width: 140,
      filters: organisationFilter,
      filteredValue: filteredInfo.organisation || null,
      onFilter: (value, record) => {
        return record.organisation === value;
      },
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`organisationTitleText_${record.key}}`)) ? record.organisation : ''}>
            <p id={`organisationTitleText_${record.key}}`} style={{ margin: 0, width: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {record.organisation}
            </p>
          </Tooltip>
        </div>
      )
    };
    let statusItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Status</p>
          </div>
        )
      },
      dataIndex: 'status',
      key: 'status',
      width: 86,
      filters: [
        { text: 'Active', value: 'Active' },
        { text: 'Demo', value: 'Demo' },
        { text: 'Inactive', value: 'Inactive' },
        { text: 'Suspended', value: 'Suspended' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => {
        return record.status.includes(value)
      },
      render: (text, record) => (
        <p style={{ margin: 0, color: record.statusColor }}>{record.status}</p>
      ),
    };
    let electronItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Electrons</p>
          </div>
        )
      },
      dataIndex: 'electrons',
      key: 'electrons',
      align: 'center',
      width: 100,
      sortOrder: sortedInfo.columnKey === 'electrons' && sortedInfo.order,
      sorter: (a, b) => a.electrons - b.electrons
    };
    let supernovasItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Supernovas</p>
          </div>
        )
      },
      dataIndex: 'supernovas',
      key: 'supernovas',
      align: 'center',
      width: 130,
      sortOrder: sortedInfo.columnKey === 'supernovas' && sortedInfo.order,
      sorter: (a, b) => a.supernovas - b.supernovas,
      filters: [
        { text: 'One or more offline', value: 'One or more offline' },
        { text: 'All online', value: 'All online' }
      ],
      filteredValue: filteredInfo.supernovas || null,
      onFilter: (value, record) => {
        if ((value === 'One or more offline' && record.supernovasOffline.length > 0) || (value === 'All online' && record.supernovasOffline.length === 0 && record.supernovas > 0)) {
          return true;
        }
      },
      render: (text, record) => (
        <div style={{ whiteSpace: 'nowrap', display: 'inline-flex' }}>
          {record.supernovas > 0 ?
            <Link style={{ display: 'inline-flex' }} to={`/venues/${record.uuid}?groups=1`}>
              <p style={{ margin: 0 }}>{record.supernovasOnline.length}</p>
              <p style={{ margin: 0, color: 'red', display: record.supernovasOffline.length > 0 ? '' : 'none' }}>/{record.supernovasOffline.length}</p>
            </Link>
            : 0}
        </div>
      ),
    };
    let locationIdItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Location ID</p>
          </div>
        )
      },
      dataIndex: 'groupKeyId',
      key: 'groupKeyId',
      width: 130,
      render: (text, record) => (
        <Link to={`/venues/${record.uuid}?groups=1`}>
          <Tag color={record.groupTagColor} style={{ cursor: "pointer", display: record.groupKeyId ? '' : 'none' }}>
            {record.groupKeyId}
          </Tag>
        </Link>
      ),
      align: 'center',
      filters: [
        { text: 'Has loc ID', value: 'Has loc ID' },
        { text: 'No Loc ID', value: 'No Loc ID' }
      ],
      filteredValue: filteredInfo.groupKeyId || null,
      onFilter: (value, record) => {
        return (record.groupKeyId && value === 'Has loc ID') || (!record.groupKeyId && value === 'No Loc ID')
      }
    };
    let installationTypeItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Installation</p>
          </div>
        )
      },
      dataIndex: 'installationType',
      key: 'installationType',
      align: 'center',
      width: 110,
      filters: [
        { text: 'Wall', value: 'Wall' },
        { text: 'Floor', value: 'Floor' },
        { text: 'Counter', value: 'Counter' }
      ],
      filteredValue: filteredInfo.installationType || null,
      onFilter: (value, record) => {
        return record.installationType.includes(value)
      }
    };
    let accessTypeItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: detailedTable ? 'nowrap' : '' }}>
            <p style={{ margin: 0 }}>Access type</p>
          </div>
        )
      },
      dataIndex: 'accessType',
      key: 'accessType',
      align: 'center',
      width: 120,
      filters: [
        { text: 'Public', value: 'Public' },
        { text: 'Private', value: 'Private' }
      ],
      filteredValue: filteredInfo.accessType || null,
      onFilter: (value, record) => {
        return record.accessType.includes(value)
      }
    };
    let durationItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Duration</p>
          </div>
        )
      },
      dataIndex: 'duration',
      key: 'duration',
      align: 'center',
      width: 110,
      filters: [
        { text: 'Permanent', value: 'Permanent' },
        { text: 'Temporary', value: 'Temporary' }
      ],
      filteredValue: filteredInfo.duration || null,
      onFilter: (value, record) => {
        return record.duration.includes(value)
      }
    };
    let offlineSupernovasItem = offlineSupernovasColumn ? {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Offline</p>
          </div>
        )
      },
      dataIndex: 'supernovasOffline',
      key: 'supernovasOffline',
      align: 'center',
      width: 110,
      filters: [
        { text: 'Show offline', value: 'Show offline' }
      ],
      filteredValue: filteredInfo.supernovasOffline || null,
      onFilter: (value, record) => {
        return value === 'Show offline' && record.supernovasOffline.length > 0;
      },
      render: (text, record) => (
        <div style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>
          <p style={{ margin: 0, color: 'red' }}>{record.supernovasOffline.length > 0 ? record.supernovasOffline[0] : ''}</p>
          <p style={{ margin: 0, display: record.supernovasOffline.length > 1 ? '' : 'none', color: 'red' }}>...</p>
        </div>
      )
    } : {};
    let whoPaysItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center' }}>
            <p style={{ margin: 0 }}>Who pays</p>
          </div>
        )
      },
      dataIndex: 'freeForUsers',
      key: 'freeForUsers',
      align: 'center',
      width: 120,
      filters: [
        { text: 'Users', value: 'Users' },
        { text: 'Org', value: 'Org' }
      ],
      filteredValue: filteredInfo.freeForUsers || null,
      onFilter: (value, record) => {
        return record.freeForUsers.includes(value)
      }
    };
    let paymentRequestItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Payment request</p>
          </div>
        )
      },
      dataIndex: 'paymentRequest',
      key: 'paymentRequest',
      align: 'center',
      width: 150,
      filters: [
        { text: 'On', value: 'On' },
        { text: 'Off', value: 'Off' }
      ],
      filteredValue: filteredInfo.paymentRequest || null,
      onFilter: (value, record) => {
        return record.paymentRequest === value
      },
      render: (text, record) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <p style={{ margin: 0, color: record.paymentRequest === 'On' ? 'green' : 'red' }}>{record.paymentRequest}</p>
        </div>
      )
    };
    let ticketsItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Tickets</p>
          </div>
        )
      },
      key: 'tickets',
      dataIndex: 'tickets',
      width: 100,
      align: 'center',
      sorter: (a, b) => a.tickets > b.tickets ? -1 : 1,
      sortOrder: sortedInfo.columnKey === 'tickets' && sortedInfo.order
    };
    let firstFreeItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>First free</p>
          </div>
        )
      },
      dataIndex: 'firstFree',
      key: 'firstFree',
      align: 'center',
      width: 100,
      filters: [
        { text: 'On', value: 'On' },
        { text: 'Off', value: 'Off' }
      ],
      filteredValue: filteredInfo.firstFree || null,
      onFilter: (value, record) => {
        return record.firstFree === value
      },
      render: (text, record) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <p style={{ margin: 0, color: record.firstFree === 'On' ? 'green' : 'red' }}>{record.firstFree}</p>
        </div>
      )
    };
    let hourlyPlanItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Hourly</p>
          </div>
        )
      },
      dataIndex: 'plansData.hourly_rate',
      key: 'hourly_rate',
      align: 'center',
      width: 75,
      filters: pricingFilters['hourly_rate'],
      filteredValue: filteredInfo.hourly_rate || null,
      onFilter: (value, record) => {
        return record.plansData.hourly_rate.includes(value)
      },
      render: (text, record) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <p style={{ margin: 0, color: record.plansData.hourly_rate === 'Off' ? 'red' : '' }}>{record.plansData.hourly_rate}</p>
        </div>
      )
    };
    let dailyPlanItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Daily</p>
          </div>
        )
      },
      dataIndex: 'plansData.daily',
      key: 'daily',
      align: 'center',
      width: 75,
      filters: pricingFilters['daily'],
      filteredValue: filteredInfo.daily || null,
      onFilter: (value, record) => {
        return record.plansData.daily.includes(value)
      },
      render: (text, record) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <p style={{ margin: 0, color: record.plansData.daily === 'Off' ? 'red' : '' }}>{record.plansData.daily}</p>
        </div>
      )
    };
    let monthlyPlanItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Monthly</p>
          </div>
        )
      },
      dataIndex: 'plansData.monthly',
      key: 'monthly',
      align: 'center',
      width: 95,
      filters: pricingFilters['monthly'],
      filteredValue: filteredInfo.monthly || null,
      onFilter: (value, record) => {
        return record.plansData.monthly.includes(value)
      },
      render: (text, record) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <p style={{ margin: 0, color: record.plansData.monthly === 'Off' ? 'red' : '' }}>{record.plansData.monthly}</p>
        </div>
      )
    };
    let annualPlanItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Annual</p>
          </div>
        )
      },
      dataIndex: 'plansData.annual',
      key: 'annual',
      align: 'center',
      width: 90,
      filters: [
        { text: 'On', value: 'On' },
        { text: 'Off', value: 'Off' }
      ],
      filteredValue: filteredInfo.annual || null,
      onFilter: (value, record) => {
        return (value === 'On' && record.plansData.annual !== 'Off') || (value === 'Off' && record.plansData.annual === 'Off')
      },
      render: (text, record) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <p style={{ margin: 0, color: record.plansData.annual === 'Off' ? 'red' : '' }}>{record.plansData.annual}</p>
        </div>
      )
    };
    let addressItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Address</p>
          </div>
        )
      },
      dataIndex: 'address',
      key: 'address',
      width: 200,
      filters: countryFilters,
      filteredValue: filteredInfo.address || null,
      onFilter: (value, record) => {
        return record.country ? record.country.includes(value) : null
      },
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`addressText_${record.key}}`)) ? record.address : ''}>
            <p id={`addressText_${record.key}}`} style={{ margin: 0, width: '170px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {record.address}
            </p>
          </Tooltip>
        </div>
      )
    };
    let businessTypeItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Type of business</p>
          </div>
        )
      },
      dataIndex: 'businessType',
      key: 'businessType',
      width: 140,
      filters: businessInfoFilters.orgType,
      filteredValue: filteredInfo.businessType || null,
      onFilter: (value, record) => {
        return (value === 'Other' && !record.businessType) || record.businessType === value
      }
    };
    let businessCategoryItem = {
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Type of Location</p>
          </div>
        )
      },
      dataIndex: 'businessCategory',
      key: 'businessCategory',
      width: 140,
      filters: businessInfoFilters.orgCategory,
      filteredValue: filteredInfo.businessCategory || null,
      onFilter: (value, record) => {
        return (value === 'Other' && !record.businessCategory) || record.businessCategory === value
      }
    };
    let businessSubcategoryItem = subcategoryColumn ? {
      title: () => {
        return (
          <div style={{ textAlign: 'center' }}>
            <p style={{ margin: 0 }}>Subtype of Location</p>
          </div>
        )
      },
      dataIndex: 'businessSubcategory',
      key: 'businessSubcategory',
      width: 140,
      filters: businessInfoFilters.businessSubcategory,
      filteredValue: filteredInfo.businessSubcategory || null,
      onFilter: (value, record) => {
        return (value === 'Other' && !record.businessSubcategory) || record.businessSubcategory === value
      }
    } : {};
    let contactsItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Contact</p>
          </div>
        )
      },
      dataIndex: 'contact',
      key: 'contact',
      width: 200,
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`contactText_${record.key}}`)) ? <ul style={{ listStyle: 'decimal', margin: 0 }}>{record.contactsList}</ul> : ''}>
            <p id={`contactText_${record.key}}`} style={{ margin: 0, width: '170px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {record.contact}
            </p>
          </Tooltip>
        </div>
      )
    };
    let sessionsItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            <p style={{ margin: 0 }}>Sessions</p>
          </div>
        )
      },
      dataIndex: 'sessions',
      key: 'sessions',
      width: 90,
      render: (text, record) => (
        <Link to={`/sessions`}>
          {record.sessions}
        </Link>
      ),
      align: 'center',
      sorter: (a, b) => a.sessions > b.sessions ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'sessions' && sortedInfo.order
    };
    if (detailedTable) {
      venueTitleItem.fixed = 'left';
    }
    const columns = [venueTitleItem];
    if (offlineSupernovasColumn) columns.push(offlineSupernovasItem)
    const generalColumns = [
      statusItem,
      locationIdItem,
      supernovasItem,
      electronItem,
      installationTypeItem,
      accessTypeItem,
      durationItem,
      whoPaysItem,
      ticketsItem
    ];
    const detailedColumns = [
      paymentRequestItem,
      firstFreeItem,
      hourlyPlanItem,
      dailyPlanItem,
      monthlyPlanItem,
      annualPlanItem,
      sessionsItem,
      contactsItem,
      addressItem
    ];
    if (!this.props.inOrg) detailedColumns.push(organisationItem);
    detailedColumns.push(
      businessTypeItem,
      businessCategoryItem,
      businessSubcategoryItem
    )
    columns.push(...generalColumns);
    if (detailedTable) {
      columns.push(...detailedColumns)
    }
    return columns;
  }
  getData = () => {
    const data = [];
    if (!this.state.data.length) return data;
    let venues = this.state.data;
    if (this.state.filtered) {
      venues = this.state.searchData;
    }
    venues.forEach((venue, i) => {
      let organisation = '';
      if (typeof venue.organisation === 'object' && venue.organisation.organisation && venue.organisation.organisation.name) {
        organisation = venue.organisation.organisation.name;
      }
      let status = 'Active';
      let statusColor = 'green';
      if (venue.status === 0) {
        status = 'Inactive';
        statusColor = 'blue';
      } else if (venue.status === 2) {
        status = 'Suspended';
        statusColor = 'red';
      } else if (venue.status === 1 && venue.is_demo) {
        status = 'Demo';
      }
      let electronLists = [];
      let supernovasList = {
        online: [],
        offline: []
      };
      let groupTagColor = 'red';
      venue.groups.forEach(groupObj => {
        let supernovas = _.get(groupObj.group, 'supernovas');
        if (supernovas) {
          supernovas.forEach(supernovaObj => {
            let electrons = _.get(supernovaObj.supernova, 'electrons');
            const status = _.get(supernovaObj.supernova, 'status');
            const stringId = _.get(supernovaObj.supernova, 'string_id');
            if (status === 'online') {
              groupTagColor = 'green';
              supernovasList.online.push(stringId);
            }
            else {
              supernovasList.offline.push(stringId);
            }
            if (electrons) {
              electronLists.push(...electrons);
            }
          });
        }
      });
      if (groupTagColor === 'red' && venue.status !== 1) {
        groupTagColor = 'green';
      }
      const groupKeyId = _.get(venue, 'groups[0].group.key_id');
      const group_domestic_id = _.get(venue, 'groups[0].group_id');
      const type_of_instalation = _.get(venue, 'groups[0].group.type_of_instalation');
      let installationType = 'Other';
      if (type_of_instalation === 'wall' || type_of_instalation === 'direct_wall') {
        installationType = 'Wall';
      } else if (type_of_instalation === 'counter') {
        installationType = 'Counter';
      } else if (type_of_instalation === 'floor') {
        installationType = 'Floor';
      }
      const pricingSettings = venue.pricingSettings;
      const plans = _.get(venue, 'pricingSettings.plans');
      let plansData = {
        annual: '',
        daily: '',
        hourly_rate: '',
        monthly: ''
      };
      let grapheme = pricingSettings.currencyGrapheme;
      if (plans && pricingSettings && grapheme) {
        Object.keys(plans).forEach((key) => {
          if (pricingSettings[`plans_${plans[key].key_id}_is_active`]) {
            let priceObj = this.getCurrencyText(plans[key].price, pricingSettings.currencyGrapheme, pricingSettings.currencyTemplate);
            plansData[plans[key].key_id] = priceObj.text;
          } else {
            plansData[plans[key].key_id] = 'Off';
          }
        });
      }
      const businessType = this.getBusinessInfoText(venue.business_type_id, 'orgType');
      const businessCategory = this.getBusinessInfoText(venue.business_category_id, 'orgCategory');
      let businessSubcategory = '';
      if (venue.business_subcategory_id) {
        businessSubcategory = this.getBusinessInfoText(venue.business_subcategory_id, 'businessSubcategory');
      }
      let contact = '';
      let contactsList = [];
      const newContactsList = [];
      if (venue.contacts_list && venue.contacts_list.length > 0) {
        let count = 1;
        venue.contacts_list.forEach((contact) => {
          const contactArr = [];
          if (contact.contact) {
            if (contact.contact.name) {
              contactArr.push(contact.contact.name);
            }
            if (contact.contact.phone) {
              contactArr.push(contact.contact.phone);
            }
            if (contact.contact.email) {
              contactArr.push(contact.contact.email);
            }
          }
          const fullContact = contactArr.join(', ');
          if (fullContact && newContactsList.indexOf(fullContact) === -1) {
            newContactsList.push(fullContact);
          }
        })
        if (newContactsList && newContactsList.length > 0) {
          contact = newContactsList[0];
          newContactsList.forEach((contact) => {
            contactsList.push(<li key={count}>{contact}</li>)
            count += 1;
          })
        }
      }

      data.push({
        key: i,
        id: venue.id,
        organisation_id: venue.organisation ? venue.organisation.id : null,
        uuid: venue.uuid,
        title: venue.title,
        address: venue.address,
        actual_status: venue.status,
        status: status,
        statusColor: statusColor,
        electrons: electronLists.length,
        organisation: organisation,
        groupKeyId: groupKeyId,
        installationType: installationType,
        groupTagColor,
        supernovas: supernovasList.online.length + supernovasList.offline.length,
        supernovasOnline: supernovasList.online,
        supernovasOffline: supernovasList.offline,
        accessType: venue.is_public ? 'Public' : 'Private',
        duration: venue.location_core === 'permanent' ? 'Permanent' : 'Temporary',
        freeForUsers: venue.free_for_users ? 'Org' : 'Users',
        paymentRequest: venue.request_payment_information ? 'On' : 'Off',
        tickets: venue.ticketsAmount,
        firstFree: venue.first_time_free ? 'On' : 'Off',
        plansData,
        grapheme,
        addressItem: venue.formatted_address,
        country: venue.address_components ? venue.address_components.country : '',
        businessType,
        businessCategory,
        businessSubcategory,
        contact,
        contactsList,
        sessions: venue.user_sessions,
        group_domestic_id
      })
    });
    return data;
  }
  handleTableChange = (pagination, filters, sorter) => {
    //console.log('handleTableChange', pagination, filters, sorter);
    setTimeout(() => {
      if (!this.mounted) return false;
      if (!document.getElementById("totalNumber")) {
        this.props.updateTotalNumberTable(0)
      }
    }, 100)
    // used for tooltips in cells (because it searchs for html element)
    setTimeout(() => {
      if (!this.mounted) return false;
      const { pagination } = this.state;
      this.setState({
        pagination: {
          ...pagination,
          update: !pagination.update
        }
      })
    }, 100)
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  changeSearchParameter = (value) => {
    const { searchText } = this.state;
    const searchObj = {
      target: {
        value: searchText
      }
    }
    this.setState({
      searchParameter: value
    }, () => this.onSearch(searchObj))
  }
  onSearch = (e) => {
    this.setState({ searchText: e.target.value });
    const { searchParameter, data } = this.state;
    const searchText = e.target.value;
    const reg = new RegExp(e.target.value, 'gi');
    this.setState({
      filtered: !!searchText,
      searchData: _.flatMap(data, record => {
        const organizationName = _.get(record, 'organisation.organisation.name');
        const groupId = _.get(record, 'groups[0].group.key_id');

        const nameMatch = record.title && record.title.length ? record.title.match(reg) && (searchParameter === 'venueTitle' || !searchParameter) : false;
        const orgMatch = organizationName && !this.props.inOrg ? organizationName.match(reg) && (searchParameter === 'orgName' || !searchParameter) : false;
        const groupIdMatch = groupId ? groupId.match(reg) && (searchParameter === 'groupId' || !searchParameter) : false;
        if (!nameMatch && !orgMatch && !groupIdMatch) {
          return null;
        }
        return {
          ...record,
        };
      }).filter(record => !!record),
    });
    if (e.target.value === '') {
      this.setState({
        data,
        filtered: null
      });
    }
  }
  emitEmpty = () => {
    this.setState({
      searchData: [],
      searchText: '',
      filtered: null,
      searchParameter: false
    });
  }
  onRowSelect = (selectedRowKeys, selectedRowValues) => {
    //console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRowValues);
    this.setState({
      selectedRowKeys,
      selectedRowValues
    });
  }
  deleteLocationRequest = (venueId) => {
    Modal.confirm({
      title: 'Are you sure you want to delete Location?',
      content: <div>
        <ul style={{ padding: 0, color: 'red' }}>
          <li>Linked Supernovas will be released</li>
          <li>All related information to LID will be erased</li>
        </ul>
      </div>,
      okText: 'Delete',
      onOk: () => {
        this.setState({
          visibleSmsAuthenticationPrompt: true,
          smsAuthenticationAction: 'delete_location',
          doAfterSmsAuthentication: () => {
            fetchJSON(`/api/v1/venue/${venueId}`, {
              method: 'delete'
            }).then(response => {
              if (!this.mounted) return false;
              if (response.success) {
                message.success('Success!');
                const { searchText } = this.state;
                if (searchText) {
                  window.location = '/venues';
                } else {
                  this.setState({
                    selectedRowKeys: [],
                    selectedRowValues: []
                  }, this.getVenueList)
                }
              } else {
                message.error(response.message);
              }
            }).catch(error => {
              console.log(error);
              message.error('ERR');
            });
          }
        });
      },
      onCancel() { },
      centered: true
    });
  }
  suspendLocation = (venueId) => {
    fetchJSON(`/api/v1/venue/${venueId}`, {
      method: 'post',
      body: {
        status: 2
      }
    }).then(response => {
      if (!this.mounted) return false;
      //console.log(response);
      if (response.success) {
        message.success('Success!');
        const { searchText } = this.state;
        if (searchText) {
          window.location = '/venues';
        } else {
          this.setState({
            selectedRowKeys: [],
            selectedRowValues: []
          }, this.getVenueList)
        }
      } else {
        message.error(response.message);
      }
    }).catch(error => {
      console.log(error);
      message.error('ERR');
    });
  }
  actions = (value) => {
    const { selectedRowValues } = this.state;
    if (!selectedRowValues || !selectedRowValues.length) {
      Modal.warning({
        title: 'Please, select one Location for this action.',
        maskClosable: true
      })
      return;
    }
    if (value === 'suspend' || value === 'delete') {
      if (selectedRowValues.length === 1) {
        if (value === 'suspend') {
          if (selectedRowValues[0].actual_status !== 2) {
            this.setState({
              visibleSmsAuthenticationPrompt: true,
              smsAuthenticationAction: 'suspend_location',
              doAfterSmsAuthentication: () => {
                this.suspendLocation(selectedRowValues[0].uuid);
              }
            });
          }
          else {
            Modal.warning({ title: 'This Location is suspended already.' });
          }
        }
        if (value === 'delete') {
          this.deleteLocationRequest(selectedRowValues[0].id)
        }
      }
      else {
        Modal.warning({ title: 'Please, select only one Location for this action.' });
        this.setState({
          selectedRowKeys: [],
          selectedRowValues: []
        })
      }
    }
    if (value === 'quarantine') {
      Modal.confirm({
        className: "modal-confirm-no-btns",
        title: 'COVID-19 Safety Feature',
        maskClosable: true,
        autoFocusButton: null,
        onCancel: () => {
          this.setState({ selectedRowValues: [], selectedRowKeys: [] })
        },
        content: (
          <div>
            <Row style={{ margin: '8px 0px 14px 0px' }}>
              Quarantine Electrons at this Location for 48 hours after each use.
            </Row>
            <Row style={{ justifyContent: 'center', display: 'flex' }}>
              <Button onClick={() => this.updateVenueList('covid_quarantine', 1)} type="primary">Activate</Button>
              <Button onClick={() => this.updateVenueList('covid_quarantine', 0)} type="primary" style={{ margin: '0px 12px' }}>Deactivate</Button>
              <Button onClick={() => this.setState({ selectedRowKeys: [], selectedRowValues: [] }, Modal.destroyAll)}>Cancel</Button>
            </Row>
          </div>
        ),
      })
    }
    if (value === 'transfer') {
      const venueUuids = [];
      const organisationIds = [];
      selectedRowValues.forEach((venue) => {
        if (venueUuids.indexOf(venue.uuid) === -1) {
          venueUuids.push(venue.uuid);
        }
        if (organisationIds.indexOf(venue.organisation_id) === -1) {
          organisationIds.push(venue.organisation_id);
        }
      });
      this.TransferLocation(venueUuids, organisationIds);
    }
  }
  changeQuarantineStatus = (groupId, value, callback) => {
    fetchJSON(`/api/v1/group/updateQuarantine/${groupId}`, {
      method: 'put',
      body: {
        covid_quarantine: value
      }
    }).then(response => {
      if (response.success) {
        callback(200);
      } else {
        callback(400);
      }
    }).catch(error => {
      console.log(error);
      callback(400);
    });
  }
  updateVenueList = (parameter, value) => {
    const { selectedRowValues } = this.state;
    let groupIds = [];
    selectedRowValues.forEach((venue) => {
      if (venue.group_domestic_id && groupIds.indexOf(venue.group_domestic_id) === -1) {
        groupIds.push(venue.group_domestic_id);
      }
    })
    const promises = [];
    const responses = [];
    if (groupIds.length) {
      groupIds.forEach((groupId) => {
        promises.push(new Promise((resolveLocal, reject) => {
          this.changeQuarantineStatus(groupId, value, (response = false) => {
            if (response) {
              responses.push(response);
            }
            resolveLocal();
          })
        }));
      });
      Promise.all(promises).then((values) => {
        if (responses.indexOf(400) === -1) {
          message.success('Done!');
          this.getVenueList();
          this.setState({
            selectedRowValues: [],
            selectedRowKeys: []
          }, Modal.destroyAll)
        } else {
          message.error('Something went wrong, please try again');
        }
      })
    } else {
      this.setState({
        selectedRowKeys: [],
        selectedRowValues: []
      }, Modal.destroyAll)
      message.success(`Selected Locations do not have linked supernovas.`);
    }
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  render() {
    const { isFetching, pagination, searchText, detailedTable, extendedInfoLoaded, businessCaregotiesLoaded,
      searchParameter, selectedRowKeys, visibleSmsAuthenticationPrompt, doAfterSmsAuthentication, smsAuthenticationAction
    } = this.state;
    const suffix = searchText ? <CloseCircleOutlined key="1" onClick={this.emitEmpty} /> : null;
    const searchBy = (
      <Select style={{ width: '150px' }} onChange={(value) => this.changeSearchParameter(value)} value={!searchParameter ? 'Search by' : searchParameter}>
        <Option value="venueTitle">Location name</Option>
        <Option value="groupId">Location ID</Option>
        <Option value="orgName" style={{ display: this.props.inOrg ? 'none' : '' }}>Organization name</Option>
      </Select>
    );
    return (
      <Content className="fill-bg-table">
        <Row gutter={8} style={{ marginBottom: '8px', alignItems: 'center' }}>
          <Col style={{ display: 'inline-flex' }} sm={12}>
            <Select style={{ width: '180px' }} placeholder="Actions" onChange={(value) => this.actions(value)} value={'Actions'}>
              <Option value="quarantine">Quarantine</Option>
              <Option value="suspend" style={{ color: 'red' }}>Suspend</Option>
              <Option value="delete" style={{ color: 'red' }}>Delete</Option>
            </Select>
            <Button onClick={() => this.setState({ detailedTable: !this.state.detailedTable })} style={{ marginLeft: '16px' }}>
              {detailedTable ? 'General' : 'Detailed'}
            </Button>
          </Col>
          <Col span={12}>
            <div style={{ width: 'fit-content', marginLeft: 'auto' }}>
              <Search
                style={{ marginBottom: '8px', width: '405px' }}
                disabled={isFetching}
                allowClear
                onChange={this.onSearch}
                placeholder="Search locations"
                addonBefore={searchBy}
              />
            </div>
          </Col>
        </Row>
        <Table
          className="scrollYTable"
          rowSelection={
            {
              selectedRowKeys: selectedRowKeys,
              onChange: this.onRowSelect
            }
          }
          columns={this.columns()}
          loading={isFetching || !extendedInfoLoaded || !businessCaregotiesLoaded}
          dataSource={extendedInfoLoaded && businessCaregotiesLoaded ? this.getData() : null}
          pagination={{
            ...pagination,
            showSizeChanger: true
          }}
          size="small"
          onChange={this.handleTableChange}
          scroll={detailedTable ? { x: 'max-content' } : {}}
          showSorterTooltip={false}
        />
        {visibleSmsAuthenticationPrompt ? <SmsAuthenticationPrompt
          visible={visibleSmsAuthenticationPrompt}
          action={smsAuthenticationAction}
          doAfter={doAfterSmsAuthentication}
          handleClose={() => { this.modalVisible('visibleSmsAuthenticationPrompt', false) }} />
          : null
        }
      </Content>
    )
  }
}

export default VenuesTable