import React from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';

import Auth from './Auth';
import fetchJSON from './fetchJSON';

import DashboardWrapper from 'components/DashboardWrapper/DashboardWrapper';


/**
 * when entering the page to check if the user has access to them
 */
const checkRole = (next) => {
	// create an AJAX request
	fetchJSON('/api/v1/auth/role', {
		method: 'get',
	}).then(data => {
		if (data.success) return next(data.role);
		if (data.role === 'wrongToken') return next(data.role);
		return next(false);
	}).catch((error) => {
		if (error.status === 500) {
			return next('error500');
		} else {
			return next(false);
		}
	});
};

export const checkCredentials = (allowedRole, Component) => {
	class AuthenticatedComponent extends React.Component {
		state = {
			isLoggedIn: false,
			role: ''
		}
		componentWillMount() {
			this.checkAuth();
		}
		checkAuth() {
			checkRole((role) => {
				const location = this.props.location;
				const redirect = location.pathname + location.search;
				if (allowedRole.indexOf(role) > -1) {
					this.setState({
						isLoggedIn: true,
						role: role
					});
				} else if (role === 'error500') {
					// eslint-disable-next-line react/prop-types
					this.props.history.push(`/error500?redirect=${redirect}`);
				} else if (role === 'wrongToken') {
					// eslint-disable-next-line react/prop-types
					this.props.history.push(`/logout`);
				} else if (role) {
					// eslint-disable-next-line react/prop-types
					this.props.history.push('/');
				} else {
					// eslint-disable-next-line react/prop-types
					this.props.history.push(`/login?redirect=${redirect}`);
				}
			})
		}
		render() {
			const { role } = this.state;
			return this.state.isLoggedIn
				? <DashboardWrapper role={role}><Component role={role} {...this.props} /></DashboardWrapper>
				: null;
		}
	}

	return withRouter(AuthenticatedComponent);
}

export const checkLogin = (redirectTo, next) => {
	if (Auth.isUserAuthenticated()) {
		return (<Redirect to={{ pathname: redirectTo }} />);
	} else {
		return next();
	}
};

export default checkCredentials;
