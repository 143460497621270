//import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';

const getFullGeneralData = function getFullGeneralData() {
  const bodyObj = this.getBodyObjGeneral();
  this.setState({
    essentialData: emptyEssentialDataObj,
    usageData: emptyUsageObj,
    utilisationData: emptyUtilisationObj,
    lifetimeData: emptyLifetimeObj,
    retentionData: emptyRetentionObj
  }, () => {
    this.getEssential(bodyObj);
    this.getLifetimeResults(bodyObj);
    this.getUtilisationData(bodyObj);
    this.getUsageData(bodyObj);
    this.getRetentionData(bodyObj);
  })
}
const getBodyObjGeneral = function getBodyObjGeneral() {
  const {
    filterByLocationType,
    targetOrgId,
    targetVenueId,
    targetBusinessTypeId,
    targetBusinessCategoryId,
    // defaultRangePickerValue,
    venuesCategoriesList,
    fullVenuesList,
    general_date_type,
    // multilocations
    overrideLocationChoiceGlobal,
    orgsIds,
    venuesIds
  } = this.props;
  const bodyObj = {
    filter_by_id: !filterByLocationType,
    organisation_id: targetOrgId,
    venue_id: targetVenueId,
    business_type_id: targetBusinessTypeId,
    business_category_id: targetBusinessCategoryId,
    // dates: defaultRangePickerValue,  
    venues_list_category: venuesCategoriesList,
    full_venues_list: fullVenuesList,
    general_date_type,
    use_custom_locs_arr: overrideLocationChoiceGlobal,
    custom_org_ids: orgsIds,
    custom_venues_ids: venuesIds
  };
  return bodyObj
}

const getRetentionData = function getRetentionData(body) {
  const { retentionWheelTimeOverride, retentionWheelRange, retentionAllTime } = this.state;
  body.should_time_override = retentionWheelTimeOverride;
  body.time_override = retentionWheelRange;
  body.all_time = retentionAllTime;

  this.setState({
    isRetentionFetching: true
  });
  fetchJSON('/api/v1/reports/general/getRetentionData', {
    method: 'post',
    body
  }).then(response => {
    //console.log(response, 'response');
    if (this.mounted) {
      this.setState({
        isRetentionFetching: false,
        retentionData: response.data || emptyRetentionObj
      });
    }
  }).catch(error => {
    console.log(error);
    if (this.mounted) { 
      this.setState({
        isRetentionFetching: false
      });
    }
  });
}
const getEssential = function getEssential(body) {
	this.setState({
		isEssentialFetching: true
	});
	fetchJSON('/api/v1/reports/general/getEssentialData', {
		method: 'post',
    body
	}).then(response => {
		//console.log(response, 'response');
		if (this.mounted) {
			this.setState({
				isEssentialFetching: false,
        essentialData: response.data || emptyEssentialDataObj,
        offline_supernova_warning: response.data ? response.data.offline_supernova_warning : false
			});
		}
	}).catch(error => {
		console.log(error);
		if (this.mounted) { 
			this.setState({
				isEssentialFetching: false
			});
		}
	});
}
const getLifetimeResults = function getLifetimeResults(body) {
  this.setState({
    isLifetimeFetching: true
  });
  fetchJSON('/api/v1/reports/general/getLifetimeResults', {
    method: 'post',
    body
  }).then(response => {
    //console.log(response, 'response');
    if (this.mounted) {
      this.setState({
        isLifetimeFetching: false,
        lifetimeData: response.data || emptyLifetimeObj
      });
    }
  }).catch(error => {
    console.log(error);
    if (this.mounted) { 
      this.setState({
        isLifetimeFetching: false
      });
    }
  });
}
const getUsageData = function getUsageData(body) {
  const { usageTimeOverride, usageRange } = this.state;
  body.should_time_override = usageTimeOverride;
  body.time_override = usageRange;

  this.setState({
    isUsageFetching: true
  });
  fetchJSON('/api/v1/reports/general/getUsageData', {
    method: 'post',
    body
  }).then(response => {
    // console.log(response, 'response');
    if (this.mounted) {
      this.setState({
        isUsageFetching: false,
        usageData: response.data || emptyUsageObj
      });
    }
  }).catch(error => {
    console.log(error);
    if (this.mounted) { 
      this.setState({
        isUsageFetching: false
      });
    }
  });
}
const getUtilisationData = function getUtilisationData(body) {
  const { utilisationTimeOverride, utilisationRange } = this.state;
  body.should_time_override = utilisationTimeOverride;
  body.time_override = utilisationRange;
  
  this.setState({
    isUtilisationFetching: true
  });
  fetchJSON('/api/v1/reports/general/getUtilisationData', {
    method: 'post',
    body
  }).then(response => {
    console.log(response, 'response');
    let utilisationVenueId;
    if (response.data && Array.isArray(response.data.venuesInfo)) {
      if (response.data.venuesInfo[0] && typeof response.data.venuesInfo[0] === 'object' && response.data.venuesInfo[0].venue_id) {
        utilisationVenueId = response.data.venuesInfo[0].venue_id;
      }
    }
    if (this.mounted) {
      this.setState({
        isUtilisationFetching: false,
        utilisationData: response.data || emptyUtilisationObj,
        utilisationVenueId
      });
    }
  }).catch(error => {
    console.log(error);
    if (this.mounted) { 
      this.setState({
        isUtilisationFetching: false
      });
    }
  });
}
const emptyLifetimeObj = {
  lifetime: {
    users: 0,
    sessions: 0,
    payment: '0',
    charging_time: '0',
    charging_avg: '0',
    daily_subs: 0,
    annual_subs: 0
  },
  best_day: {
    users: { amount: 0, day: ''},
    payment: { amount: '0', day: ''},
    daily_subs: { amount: 0, day: ''},
    annual_subs: { amount: 0, day: ''},
    sessions: { amount: 0, day: '' }
  }
};

const emptyEssentialDataObj = {
  locationsOnline: 0,
  supernovaStations: 0,
  supernovasPieData: [
    // { name: 'Deployed', count: 0 },
    { name: 'Online', count: 0 },
    { name: 'Offline', count: 0 },
    // { name: 'Maintenance', count: 0 },
    // { name: 'EOL', count: 0 }
  ],
  electrons: 0,
  electronsPieData: [
    { name: 'Deployed', count: 0 },
    { name: 'In use', count: 0 },
    { name: 'Lost', count: 0 },
    { name: 'Other', count: 0 }
    // { name: 'Maintenance', count: 0 },
    // { name: 'EOL', count: 0 }
  ],
  activeDelayReturn: 0
};
const emptyRetentionObj = {
  open: { users: 0, percentage: 0 },
  register: { users: 0, percentage: 0 },
  check_out: { users: 0, percentage: 0 },
  return: { users: 0, percentage: 0 }
};
const emptyUsageObj = {
  uniqueUsers: 0,
  uniqueUsersPieData: [
    { name: 'Returned', count: 0 },
    { name: 'New', count: 0 }
  ],
  sessions: 0,
  sessionsPieData: [
    { name: 'Returned users', count: 0 },
    { name: 'New users', count: 0 }
  ],
  avgSessionPerUser: 0,
  avgSessionPerDay: 0,
  graphWeeklyData: [],
  reference_lines_years_weeks: {},
  reference_lines_years_days: {},
  graphDailyData: [],
  usersFreeToPaid: 0,
  usersFreeToPaidPieData: [
    { name: 'Monthly', count: 0 },
    { name: 'Daily', count: 0 },
    { name: 'Annual', count: 0 }
  ],
  usersDailyToLongterm: 0,
  usersDailyToLongtermPieData: [
    { name: 'Monthly', count: 0 },
    { name: 'Annual', count: 0 }
  ]
};

const emptyUtilisationObj = {
  avgPerStation: 0,
  avgPerCharger: 0,
  barChartData: [],
  venuesInfo: [],
  venuesData: {}
};
export {
  getFullGeneralData,
	getEssential,
  getUtilisationData,
  getUsageData,
  getLifetimeResults,
  getRetentionData,
  getBodyObjGeneral,
	//
	emptyUsageObj,
	emptyUtilisationObj,
	emptyLifetimeObj,
	emptyEssentialDataObj,
  emptyRetentionObj
};