import React from 'react';
import { connect } from 'react-redux';
import Settings from 'services/config/Settings';
import { Table, Layout } from 'antd';
// import { ModalRoute } from 'react-router-modal';
// import { Link } from 'react-router-dom';
import { fetchSecretQuote } from './data/actions'
import moment from 'moment';

const { Content } = Layout;

class Transactions extends React.Component {
  state = {
    filteredInfo: {},
    sortedInfo: {},
  };
  componentDidMount() {
    document.title = `Transactions | ${Settings.title}`;
    if (!this.props.quote || this.lessThanAgo(this.props.createdFetch)) this.props.dispatch(fetchSecretQuote('/transaction/list'))
  }
  lessThanAgo = (date) => {
    if (!date) return false;
    const _AGO = 1000 * 60 * 3;
    let anMinuteAgo = Date.now() - _AGO;
    return date < anMinuteAgo;
  }
  columns = () => {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    //filteredInfo = filteredInfo || {};
    return [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'User ID',
      dataIndex: 'user_id',
      key: 'user_id',
      sortOrder: sortedInfo.columnKey === 'user_id' && sortedInfo.order,
      sorter: (a, b) => a.user_id - b.user_id
    },
    {
      title: 'Token',
      dataIndex: 'token',
      key: 'token',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Method',
      dataIndex: 'method',
      key: 'method',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sortOrder: sortedInfo.columnKey === 'amount' && sortedInfo.order,
      sorter: (a, b) => a.amount - b.amount
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   render: (text, record) => (
    //     <span>
    //       <Link to={"/transaction/"+record.id} className="ant-btn ant-btn-sm">
    //         Edit
    //       </Link>
    //     </span>
    //   ),
    // }
    ];
  }
  getData = () => {
    const data = [];
    if (!this.props.quote) return data;
    let response = this.props.quote.data;
    if (response.length) {
      response.forEach((transaction, i) => {
        let type = transaction.payment_instrument_type ? transaction.payment_instrument_type.replace('_',' ') : '';
        type = type.charAt(0).toUpperCase() + type.slice(1);
        let status = transaction.status ? transaction.status.replace('_',' ') : '';
        status = status.charAt(0).toUpperCase() + status.slice(1);
        let time = moment(transaction.created_at).format("DD MMM YY, h:mm a");
        let whiteList = ['authorized','submitted_for_settlement','settling','settlement_pending','settled', 'authorization_expired'];
        data.push({
          key: i,
          id: i,
          token: transaction.id,
          status: status ? <p style={{color:  whiteList.includes(transaction.status) ? 'green' : 'red'}}>{status}</p> : null,
          date: time,
          user_id: transaction.user_id,
          method: `${type} (${transaction.display})`,
          amount: transaction.amount,
          reason: transaction.plan_id ? <p><b>Plan:</b> { transaction.plan_id === 'year' ? 'Annual' : 'Daily'} subscription </p> : 'Other'
        })
      });
    }
    
    return data;
  }
  handleTableChange = (pagination, filters, sorter) => {
    console.log('handleTableChange', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    })
  }
  render() {
    const { isFetching, pagination } = this.props;
    return (
      <Content className="fill-bg-table">
        <h1 className="table-title">Transactions</h1>
        <Table
          columns={ this.columns() }
          loading={ isFetching }
          dataSource={ this.getData() }
          pagination={ pagination }
          onChange={ this.handleTableChange }
        />
      </Content>
    )
  }
};


const mapStateToProps = (state) =>{
  const { transactions } = state;
  const { quote, isFetching, createdFetch, pagination } = transactions;
  return {
    quote,
    isFetching,
    createdFetch,
    pagination
  }
}


export default connect(mapStateToProps)(Transactions)
