import React from 'react';
import { Redirect } from 'react-router-dom';

import Auth from 'services/utils/Auth';

class LogOut extends React.Component {

  componentDidMount() {
    Auth.deauthenticateUser();
    window.location.href = "/login";
  }

  render() { 
  	return (
  		<Redirect to="/login" /> 
  	)
  }
}

export default LogOut;
