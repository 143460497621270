import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Button, InputNumber, Select, Checkbox } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const FormItem = Form.Item;
const { Option } = Select;

class EditNebulaUser extends React.Component {
	state = {
    putREQUEST: false,
    putFAILURE: false,
    putSUCCESS: false,
    putMessage: '',
    warehousesList: []
  }
  componentDidMount() {
    this.getWarehousesList();
  }
  getWarehousesList = () => {
    const { organisationId } = this.props;
    fetchJSON(`/api/v1/organisation/warehouses/${organisationId}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      let warehousesList = [];
      const existingIds = [];
      if (response.data.warehouses) {
        response.data.warehouses.forEach((warehouse) => {
          existingIds.push(warehouse.id);
          warehousesList.push({
            id: warehouse.id,
            title: warehouse.title,
          });
        });
      }
      const { warehouses } = this.props.nebulaUser;
      console.log('warehouses', warehouses);
      if (warehouses) {
        warehouses.forEach((warehouse) => {
          if (existingIds.indexOf(warehouse.id) === -1) {
            warehousesList.push(warehouse);
            existingIds.push(warehouse.id);
          }
        })
      }
      this.setState({
        warehousesList,
        isFetching: false
      });

    }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false
      });
    });
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({
        putREQUEST: true
      })
      fetchJSON('/api/v1/organisation/editNebulaUser', {
        method: 'PUT',
        body: fieldsValue
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false
          }, () => {
          	this.props.renderList();
          	this.props.handleClose();
          });
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false
          });
        }
       }).catch(error => {
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });
    })
  }
  render() {
  	const { visible, handleClose, form, nebulaUser } = this.props;
    const { id, phone, warehousesIds, reports_trends, reports_surveys, show_users_tab } = nebulaUser;

  	const { getFieldDecorator } = form;
    const { warehousesList } = this.state;

    return (
    	<Modal
        style={{ top: 20 }}
        visible={ visible }
        width={400}
        title="Edit user"
        onOk={ handleClose }
        onCancel={ handleClose }
        footer={false}
      >
	      <Form layout="vertical" onSubmit={this.handleSubmit} >
          <FormItem>
          </FormItem>
	      	<div style={{'display': 'none'}}>
            {getFieldDecorator('id', {
              rules: [{ required: false, message: 'Please enter a title!' }],
              initialValue: id
            })(
              <Input />
            )}
          </div>
          <FormItem label="Phone">
            {getFieldDecorator('phone', {
              rules: [{ required: false, message: 'Please enter a phone!' }],
              initialValue: phone
            })(
              <InputNumber style={{width: '100%'}} />
            )}
          </FormItem>
          <FormItem label="Warehouses">
            {getFieldDecorator('warehouses', {
              rules: [{ required: true, message: 'Please select at least one warehouse!' }],
              initialValue: warehousesIds
            })(
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
              >
                {warehousesList.map((warehouse, i) => <Option key={i} value={warehouse.id}>{warehouse.title}</Option>)}
              </Select>
            )}
          </FormItem>
          <FormItem label="Surveys reports">
            {getFieldDecorator('reports_surveys', {
              rules: [{ required: true, message: '' }],
              initialValue: reports_surveys
            })(
              <Select
                style={{ width: '100%' }}
                placeholder="Please select"
              >
                <Option key={0} value={1} style={{color: 'green'}}>On</Option>
                <Option key={1} value={0} style={{color: 'red'}}>Off</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="Trends reports">
            {getFieldDecorator('reports_trends', {
              rules: [{ required: true, message: '' }],
              initialValue: reports_trends
            })(
              <Select
                style={{ width: '100%' }}
                placeholder="Please select"
              >
                <Option key={0} value={1} style={{color: 'green'}}>On</Option>
                <Option key={1} value={0} style={{color: 'red'}}>Off</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="Users section">
            {getFieldDecorator('show_users_tab', {
              rules: [{ required: true, message: '' }],
              initialValue: show_users_tab
            })(
              <Select
                style={{ width: '100%' }}
                placeholder="Please select"
              >
                <Option key={0} value={1} style={{color: 'green'}}>On</Option>
                <Option key={1} value={0} style={{color: 'red'}}>Off</Option>
              </Select>
            )}
          </FormItem>
          <Button type="primary" htmlType="submit" style={{marginTop: '20px'}}>Submit</Button>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(EditNebulaUser);
