import React from 'react';
import { Modal, Select, Checkbox, Input, message } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const confirm = Modal.confirm;
const { Option } = Select;

const TransferLocation = function TransferLocation(venueUuids, organisationIds) {
	//console.log('venueUuid', venueUuid, 'organisationIds', organisationIds);
	const getOrgList = function getOrgList() {
		return new Promise((resolve, reject) => {
			if (this.state) {
				this.setState({
					targetTransferOrgId: null,
					targetTransferOrgName: '',
					targetTransferCreateNewOrg: false
				});
				const { orgList } = this.state;
				if (orgList && Array.isArray(orgList) && orgList.length) {
					resolve();
					return;
				}
			}
			fetchJSON('/api/v1/organisation/list', {
				method: 'get'
			}).then(response => {
				console.log(response);
				let orgList = [];
				if (response.success) {
					orgList = response.data;
				}
				this.setState({
					orgList
				}, resolve)
			}).catch(error => {
				console.log(error);
				reject();
			});
		});
	}
	this.targetTransferOrgModal = null;

	const contentModal = () => {
		const { orgList, targetTransferCreateNewOrg } = this.state;
		let noOrgs = true;
		if (orgList && Array.isArray(orgList) && orgList.length > 1) {
			noOrgs = false;
		}
		return (
			<div>
				{!targetTransferCreateNewOrg && !noOrgs ? <Select defaultValue={'Select from the list'} style={{ width: '100%' }} onChange={this.targetTransferSelectOrg}>
					{orgList.map((org, index) => {
						if (organisationIds.indexOf(org.id) > -1) return null;
						return (
							<Option key={index} value={org.id}>{org.name}</Option>
						)
					})}
				</Select>
					:
					<Input
						className="addLocation__input"
						placeholder={'Organization name'}
						onChange={this.targetTransferEnterName}
					/>
				}
				{!noOrgs ? <Checkbox style={{ marginTop: '20px' }} onChange={this.targetTransferChangeCreateNewOrg}>
					Add new organization
				</Checkbox> : null}
			</div>
		);
	};

	const targetTransferSelectOrg = function (value) {
		console.log('e.target.value', value);
		this.setState({
			targetTransferOrgId: value
		}, () => {
			this.updateTTMSubmitButton(false);
		});
	}
	const updateTargetTransferModal = function () {
		this.targetTransferOrgModal.update({
			content: contentModal()
		});
	}
	const updateTTMSubmitButton = function (disabled = true) {
		this.targetTransferOrgModal.update({
			okButtonProps: { disabled },
		});
	}
	// this.transferLocationConfirm.update({
	//          okButtonProps: {}
	//        });
	const targetTransferChangeCreateNewOrg = function (e) {
		this.setState({
			targetTransferCreateNewOrg: e.target.checked,
			targetTransferOrgName: '',
			targetTransferOrgId: null
		}, this.updateTargetTransferModal);
		if (e.target.checked) {
			const { targetTransferOrgName } = this.state;
			if (!targetTransferOrgName) {
				this.updateTTMSubmitButton();
			}
		} else {
			this.updateTTMSubmitButton(false);
		}
	}

	const targetTransferEnterName = function (e) {
		this.setState({
			targetTransferOrgName: e.target.value,
			targetTransferOrgId: null
		});
		if (e.target.value) {
			this.updateTTMSubmitButton(false);
		} else {
			this.updateTTMSubmitButton();
		}
	}

	this.requestOrgList = getOrgList.bind(this);
	this.targetTransferChangeCreateNewOrg = targetTransferChangeCreateNewOrg.bind(this);
	this.targetTransferEnterName = targetTransferEnterName.bind(this);
	this.targetTransferSelectOrg = targetTransferSelectOrg.bind(this);
	this.updateTargetTransferModal = updateTargetTransferModal.bind(this);
	this.updateTTMSubmitButton = updateTTMSubmitButton.bind(this);

	this.requestOrgList().then(() => {

		this.targetTransferOrgModal = confirm({
			title: 'Select new Location owner where you want to transfer the location',
			content: contentModal(),
			okText: 'Transfer',
			onOk: () => {
				return new Promise((resolve, reject) => {
					const { targetTransferOrgName, targetTransferCreateNewOrg } = this.state;

					if (targetTransferCreateNewOrg && !targetTransferOrgName) {
						reject();
					}
					console.log(venueUuids, 'venueUuids')
					this.setState({
						visibleSmsAuthenticationPrompt: true,
						smsAuthenticationAction: 'transfer_location_owner',
						doAfterSmsAuthentication: () => {
							this.setState({
								isFetching: true,
							});

							const { targetTransferOrgName, targetTransferCreateNewOrg, targetTransferOrgId } = this.state;

							fetchJSON('/api/v1/venue/transfer/execute', {
								method: 'post',
								body: {
									venue_uuid_list: venueUuids,
									new_org_name: targetTransferOrgName,
									create_new_org: targetTransferCreateNewOrg,
									target_org_id: targetTransferOrgId
								}
							}).then(response => {
								console.log(response);
								message.success('The transfer successfully executed');
								setTimeout(() => {
									window.location.reload(true);
								}, 500);

							}).catch(error => {
								console.log(error);
								this.setState({
									isFetching: false,
								});
							});
						}
					});
					resolve();
				});
			},
			okButtonProps: { disabled: true },
			cancelButtonProps: { style: { display: 'none' } },
			maskClosable: true,
			centered: true
		});
	})
		.catch(err => {
			console.log('err', err);
		});
}

export {
	TransferLocation
};